import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { LevelGranulometry, LevelGranulometryFullFilled } from '../../../../LevelGranulometry';
import { getCaseUpperLevelsGrossFloorSurfaceDistribution } from '../../../../../cases/queries/getCaseUpperLevelsGrossFloorSurfaceDistribution';
import { getTopLevelHoppersSurface } from '../getTopLevelHoppersSurface';
import { getTopLevelOutsideInsulatedWallsThickness } from '../../walls/wallsThickness/getTopLevelOutsideInsulatedWallsThickness';
import {
  LARGE_TOP_LEVEL_DEFAULT_WIDTH,
  SMALL_TOP_LEVEL_DEFAULT_WIDTH
} from '../../walls/getTopLevelDefaultWidth';
import { getCaseUpperLevelsWithAdjustedGfsEffCount } from '../../../../../cases/queries/levels/counts/getCaseUpperLevelsWithAdjustedGfsEffCount';

export const getTopLevelAdjustedGrossFloorSurfaceEff = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled | LevelGranulometry
): number | undefined => {
  const previousLevelGfsEff = getCaseUpperLevelsGrossFloorSurfaceDistribution(
    caseGranulometry
  ).find((l) => l.level === levelGranulometry.level)?.gfsEff as number;
  const levelRealBuildSurface = levelGranulometry.realBuiltSurface;
  const levelHoppersSurface = getTopLevelHoppersSurface(caseGranulometry, levelGranulometry);
  const levelRealBuildSurfaceEff = (levelRealBuildSurface || 0) + levelHoppersSurface;
  const diff = caseGranulometry.diffBetweenRBSWithoutFloorSpaceAndRBSWithFloorSpace as number; // checked before
  const upperLevelsWithAdjustedGfsEffCount =
    getCaseUpperLevelsWithAdjustedGfsEffCount(caseGranulometry);
  const outsideWallsThickness = getTopLevelOutsideInsulatedWallsThickness(caseGranulometry);
  const levelWidth =
    previousLevelGfsEff <= 200 ? SMALL_TOP_LEVEL_DEFAULT_WIDTH : LARGE_TOP_LEVEL_DEFAULT_WIDTH;
  const levelGfsEff =
    (levelRealBuildSurfaceEff -
      diff / upperLevelsWithAdjustedGfsEffCount +
      2 * outsideWallsThickness * levelWidth -
      4 * outsideWallsThickness * outsideWallsThickness) /
    (1 - (2 * outsideWallsThickness) / levelWidth);

  return isFinite(levelGfsEff) ? levelGfsEff : 0;
};
