import { LevelGranulometry } from '../../LevelGranulometry';
import { getLevelAllButVerticalCirculationSectionsSurface } from './getLevelAllButVerticalCirculationSectionsSurface';

export const getTopLevelRealBuiltSurfaceIsLargeEnoughValidation = (
  levelGranulometry: LevelGranulometry
): boolean => {
  const levelContentSurface =
    (levelGranulometry.displayedWallThickness as number) * 10 +
    getLevelAllButVerticalCirculationSectionsSurface(levelGranulometry);
  return levelContentSurface <= (levelGranulometry.realBuiltSurface as number);
};
