import { merge, of, timer } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  throttleTime,
  withLatestFrom
} from 'rxjs/operators';
import { Epic } from 'redux-observable';
import * as R from 'ramda';
import { Action } from 'redux';
import { renderGranulometry } from '../../domain/legacy/methodsForGranulo/renderGranulometry';
import { State } from '../reducers';
import { granulometryUpdated } from '../actions/granulometryUpdated.action';
import { granulometryOutdated } from '../actions/granulometryOutdated.action';
import { granulometryRendering } from '../actions/granulometryRendering.action';
import { selectGranulometry } from '../selectors/granulometry/current.selector';
import { selectProjectProjection } from '../selectors/project/projectProjection.selector';
import { ProjectProjection } from '../../domain/projection/project/ProjectProjection';
import { MAXIMUM_PROJECT_CBS } from '../../constants/appConstants';
import { selectIsStoreRehydrated } from '../selectors/isStoreRehydrated.selector';
import { selectProject } from '../selectors/project';
import { selectRoute } from '../selectors/navigation/getRoute.selector';

const GRANULOMETRY_IDLE_TIME = 1000;

export const updateGranulometryEpic: Epic<Action, Action, State> = (actions$, state$) => {
  const project$ = state$.pipe(
    filter((state) => {
      return (
        selectIsStoreRehydrated(state) &&
        !!selectProject(state)?.surface &&
        !selectRoute(state).isDeliverablesExportReport
      );
    }),
    map(selectProjectProjection),
    distinctUntilChanged(),
    filter(Boolean),
    catchError((error) => of({ error: true, type: 'granulometry/failed', payload: error }))
  );

  return merge(
    project$.pipe(
      throttleTime(GRANULOMETRY_IDLE_TIME),
      switchMap(() => of(granulometryOutdated()))
    ),
    project$.pipe(
      debounceTime(GRANULOMETRY_IDLE_TIME),

      switchMap((project) =>
        timer(50).pipe(
          withLatestFrom(state$),
          switchMap(([, state]: [number, State]) => {
            const oldGranulometry = selectGranulometry(state);
            try {
              const projectProjection = project as ProjectProjection;
              const result =
                projectProjection.surface && projectProjection.surface.value <= MAXIMUM_PROJECT_CBS
                  ? renderGranulometry(project as ProjectProjection)
                  : oldGranulometry;
              if (Array.isArray(result)) {
                if (!R.equals(oldGranulometry, result)) {
                  return of(granulometryUpdated(result));
                }

                return of(granulometryUpdated());
              } else {
                return of({
                  error: true,
                  type: 'granulometry/failed',
                  payload: result || 'no result'
                });
              }
            } catch (e) {
              return of({ error: true, type: 'granulometry/failed', payload: e });
            }
          }),
          startWith(granulometryRendering())
        )
      )
    )
  );
};
