import MapboxDraw from '@mapbox/mapbox-gl-draw';
import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import { getArea } from '../../utils/geometry/getArea';
import { getPerimeter } from '../../utils/geometry/getPerimeter';
import { getCenter } from '../../utils/geometry/getCenter';
import { setDraw } from '../../mapboxDraw/setDraw';
import { updateMarkerFacades } from '../facades/marker/updateMarkerFacades';
import { updateFacadesProperties } from '../facades/updateFacadesProperties';
import { FloorSpaceFeature } from '../../../../domain/specification/floorSpace/FloorSpaceFeature';

export const onFormUpdateFloorSpace = (
  floorSpaceFeature: FloorSpaceFeature,
  draw: MapboxDraw,
  map: mapboxgl.Map
): FloorSpaceFeature => {
  const facades = updateFacadesProperties(floorSpaceFeature.properties.facades);
  const origin = facades[0].point1;

  const coords = facades.reduce((p, c) => [...p, c.point2], [facades[0].point1]);
  const feature = turf.polygon([coords], {
    ...floorSpaceFeature.properties,
    facades,
    origin,
    perimeter: getPerimeter(facades),
    center: getCenter(coords),
    area: getArea({
      ...floorSpaceFeature,
      geometry: { type: 'Polygon', coordinates: [coords] }
    })
  }) as FloorSpaceFeature;
  feature.id = floorSpaceFeature.id;
  setDraw(feature, draw);
  updateMarkerFacades(map)(feature);

  return feature;
};
