import mapboxgl, { SymbolLayer } from 'mapbox-gl';
import { Feature, Point } from 'geojson';
import { FloorSpaceFeature } from '../../../../../domain/specification/floorSpace/FloorSpaceFeature';
import path from 'path';
import { OTHERS_COLOR } from '../../../../../constants/appConstants';
import { FACADE_DRAW_LAYER, FACADE_DRAW_SOURCE } from '../../../mapboxDraw/draw.model';

const getMarkerFeatures = (
  floorSpace: FloorSpaceFeature
): Feature<Point, { name: string; id: string }>[] => {
  const features = [];
  floorSpace.properties.facades.forEach((facade) => {
    features.push({
      type: 'Feature',
      properties: {
        name: facade.index,
        id: facade.id,
        type: 'facade'
      },
      geometry: {
        type: 'Point',
        coordinates: facade.center
      }
    });
  });

  features.push({
    type: 'Feature',
    properties: {
      name: floorSpace.properties?.name,
      id: floorSpace.id,
      type: 'case'
    },
    geometry: {
      type: 'Point',
      coordinates: floorSpace.properties?.center
    }
  });

  return features;
};

export const updateMarkerFacades = (map: mapboxgl.Map) => (floorSpace: FloorSpaceFeature) => {
  const features = getMarkerFeatures(floorSpace);

  const sourceId = FACADE_DRAW_SOURCE + floorSpace.id;
  const layerId = FACADE_DRAW_LAYER + floorSpace.id;
  if (!map.getSource(sourceId)) {
    map.loadImage(path.resolve('facadeMarker.png'), (error, image) => {
      if (error) {
        console.error('Erreur update marker facade : ', error);
      } else {
        if (!map.listImages()?.find((name) => name === 'facadeMarker')) {
          // TODO to refactor do not loadImage if image already exist
          map.addImage('facadeMarker', image as HTMLImageElement | ImageBitmap);
        }
      }

      map.addSource(sourceId, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features
        }
      });

      map.addLayer({
        id: layerId,
        type: 'symbol',
        source: sourceId,
        layout: {
          'text-field': ['get', 'name'],
          'text-size': ['match', ['get', 'type'], 'facade', 12, 20],
          'icon-image': 'facadeMarker',
          'icon-size': 0.25,
          'text-radial-offset': 0.5
        },
        paint: {
          'text-color': ['match', ['get', 'type'], 'facade', OTHERS_COLOR, '#fff'],
          'icon-opacity': ['match', ['get', 'type'], 'facade', 1, 0]
        }
      } as SymbolLayer);
    });
  } else {
    map.getSource(sourceId).setData({
      type: 'FeatureCollection',
      features
    });
  }
};
