import { CaseGranulometry } from '../../CaseGranulometry';
import { getDefaultUnitConvertedPropertyValue } from '../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';

export type InsulationPosition = 'ETI' | 'ITI';

export const getCaseAverageInsulationPosition = (
  caseGranulometry: CaseGranulometry
): InsulationPosition =>
  (getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'internalInsulationRate'
  ) as number) >
  (getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'externalInsulationRate'
  ) as number)
    ? 'ETI'
    : 'ITI';
