import { CaseProjection } from '../projection/cases/CaseProjection';
import { getCaseBasementLevelsDefaultCount } from '../granulometry/cases/queries/levels/counts/getCaseBasementLevelsDefaultCount';
import { Surface } from '../specification/Surface';
import { makeBasementLevels } from '../specification/levels/queries/make/makeBasementLevels';
import { makeCaseProjectionTopLevels } from '../projection/cases/queries/levels/makeCaseProjectionTopLevels';
import { CaseFormDataLegacy, Distribution } from './caseFormData';

export const casesProjectionsToCasesFormData = (
  casesProjections: CaseProjection[]
): CaseFormDataLegacy[] => {
  return casesProjections.map((caseProjection) => {
    const basementLevelsCount =
      caseProjection.basementLevelsCount ??
      getCaseBasementLevelsDefaultCount(
        (caseProjection.projectedSurfaces.realBuiltSurface as Surface).value
      );

    return {
      id: caseProjection.id,
      builtInRamp: caseProjection.builtInRamp,
      realBuiltSurface: caseProjection.surfaces.realBuiltSurface?.value,
      cuttedBuiltSurface: caseProjection.surfaces.cuttedBuiltSurface?.value,
      surfaceForSale: caseProjection.surfaces.surfaceForSale?.value,
      maxSurfaceForSaleHasBeenForced:
        caseProjection.projectedSurfaces.maxSurfaceForSaleHasBeenForced,
      maxSurfaceForSaleByLevels: caseProjection.projectedSurfaces.maxSurfaceForSaleByLevels,
      distribution: caseProjection.distribution.map((lodgmentTypeRate) => ({
        ...caseProjection.lodgmentsTypesSpecifications.find(
          (lt) => lt.lodgmentType === lodgmentTypeRate.lodgmentType
        ),
        ...lodgmentTypeRate
      })) as Distribution, // TODO : Improve typing LodgmentTypesDistribution -> Distribution
      hall: caseProjection.hall,
      topLevelsCount: caseProjection.topLevelsCount,
      projectedTopLevelsCount: caseProjection.projectedTopLevelsCount,
      topLevelsData: makeCaseProjectionTopLevels(caseProjection),
      basementLevelCount: basementLevelsCount,
      basementLevelsData: makeBasementLevels(basementLevelsCount, caseProjection.levels),
      parkingData: caseProjection.parking,
      properties: caseProjection.properties,
      roofingType: caseProjection.roofingType,
      carpentryType: caseProjection.carpentryType,
      carpentryDayTimeOccultationsType: caseProjection.carpentryDayTimeOccultationsType,
      carpentryDayTimeOccultationsTypeIsMonobloc:
        caseProjection.carpentryDayTimeOccultationsTypeIsMonobloc,
      carpentryNightTimeOccultationsType: caseProjection.carpentryNightTimeOccultationsType,
      carpentryNightTimeOccultationsTypeIsMonobloc:
        caseProjection.carpentryNightTimeOccultationsTypeIsMonobloc,
      carpentryOccultationsMotorisationType: caseProjection.carpentryOccultationsMotorisationType,
      masonryType: caseProjection.masonryType,
      woodEnvelopeType: caseProjection.woodEnvelopeType,
      heatingDistribution: caseProjection.heatingDistribution,
      customDistribution: caseProjection.customDistribution,
      editingStage: caseProjection.editingStage,
      floorSpace: caseProjection.floorSpaceFeature
    };
  });
};
