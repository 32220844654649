import sasInfra, { SasInfraSection } from '../../../../../sections/circulationSections/sasInfra';
import { getDefaultUnitConvertedPropertyValue } from '../../../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const createBasementLevelSasInfra = (
  caseGranulometry: CaseGranulometry
): SasInfraSection => {
  const surface = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'sasSurface'
  ) as number;
  const width = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'sasWidth'
  ) as number;
  return sasInfra({ surface, width });
};
