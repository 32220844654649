import { LevelGranulometry, LevelGranulometryFullFilled } from '../../../LevelGranulometry';
import { roundWith2Decimal } from '../../../../../../utils/round/roundWith2Decimal';
import { getLevelRealBuiltSurfaceEff } from '../../surfaces/getLevelRealBuiltSurfaceEff';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import {
  getTopLevelDefaultWidth,
  LARGE_TOP_LEVEL_DEFAULT_WIDTH,
  SMALL_TOP_LEVEL_DEFAULT_WIDTH
} from './getTopLevelDefaultWidth';

export const getTopLevelDefaultWidthDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled | LevelGranulometry
): string => {
  let details: string[] = [];
  const levelRealBuiltSurfaceEff = getLevelRealBuiltSurfaceEff(caseGranulometry, levelGranulometry);
  const isSmallLevel = (levelRealBuiltSurfaceEff: number) => levelRealBuiltSurfaceEff <= 200;
  details = [
    ...details,
    '<b>' +
      (isSmallLevel(levelRealBuiltSurfaceEff)
        ? SMALL_TOP_LEVEL_DEFAULT_WIDTH
        : LARGE_TOP_LEVEL_DEFAULT_WIDTH) +
      ' m</b> de largeur par défaut pour un étage de Sdp Réelle VPP de ' +
      roundWith2Decimal(levelRealBuiltSurfaceEff) +
      ' m\u00B2 ' +
      (isSmallLevel(levelRealBuiltSurfaceEff) ? 'inférieure ou égale' : 'supérieure') +
      ' à 200 m\u00B2'
  ];
  details = [
    ...details,
    '<b>= ' +
      roundWith2Decimal(getTopLevelDefaultWidth(caseGranulometry, levelGranulometry)) +
      ' m</b>'
  ];
  return details.join('<br />');
};
