import { createSelector, Selector } from 'reselect';
import { sectionsIndividualRoute } from '../../../routes/toolboxPanels/sections';
import { selectPath } from '../navigation/getPath.selector';
import { SectionId } from '../../../domain/granulometry/sections/Section';
import { State } from '../../reducers';
import { Maybe } from '../../../utils/Maybe';

export const selectCurrentSectionIdFromRoute: Selector<State, Maybe<SectionId>> = createSelector(
  [selectPath],
  (path) => sectionsIndividualRoute.matchesPath(path).params?.sectionId
);
