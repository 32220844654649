import { OTHER_RBS_SECTIONS_COLOR } from '../../../../constants/appConstants';
import { getSectionName, Section, SectionDrawn } from '../Section';
import i18next from 'i18next';
import { getDefaultCasePropertyValue } from '../../../specification/cases/queries/get/properties/getDefaultCasePropertyValue';
import { CirculationName } from './CirculationSection';
import { SectionWithExposureRate } from '../SectionWithExposureRate';
import { IconObjectProps } from '../../../../components/ui/Icons/iconObject';
import { SectionWithWidth } from '../SectionWithWidth';

interface BearingProps {
  surface: number;
  exposureRate?: number;
  width: number;
}

export interface BearingSection
  extends Section,
    SectionDrawn,
    SectionWithWidth,
    SectionWithExposureRate {
  name: CirculationName.Bearing;
}

export const BEARING_ICON = {
  type: 'object',
  iconName: 'bearing',
  color: OTHER_RBS_SECTIONS_COLOR
} as IconObjectProps;

const bearing = ({ surface, width, exposureRate }: BearingProps): BearingSection => ({
  // Section
  id: 'NOID',
  name: CirculationName.Bearing,
  title: i18next.t('bearing.title'),
  type: 'circulation',
  displayedSurface: surface,
  // SectionDrawn
  surface,
  color: OTHER_RBS_SECTIONS_COLOR,
  icon: BEARING_ICON,
  // SectionWithExposureRate
  exposureRate,
  defaultExposureRate: getDefaultCasePropertyValue('bearingExposureRate'),
  // BearingSection
  width
});

export default bearing;

export const isSectionABearing = (section: Section): section is BearingSection =>
  getSectionName(section) === CirculationName.Bearing;

export const filterBearings = (sections: Section[]): BearingSection[] =>
  sections.filter(isSectionABearing);
