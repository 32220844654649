import { APP_COLOR } from '../../../../constants/appConstants';
import { getSectionName, Section, SectionDrawn } from '../Section';
import i18next from 'i18next';
import { getDefaultCasePropertyValue } from '../../../specification/cases/queries/get/properties/getDefaultCasePropertyValue';
import { CirculationName } from './CirculationSection';
import { SectionWithTheoreticalSurface } from '../SectionWithTheoreticalSurface';
import { SectionWithExposureRate } from '../SectionWithExposureRate';
import { IconObjectProps } from '../../../../components/ui/Icons/iconObject';
import { SectionWithWidth } from '../SectionWithWidth';
import { SectionWithLength } from '../SectionWithLength';

interface RampProps {
  surface: number;
  length: number;
  width: number;
  exposureRate: number;
  garageDoorHeight: number;
  garageDoorWidth: number;
  group: string;
}

export interface RampSection
  extends Section,
    SectionDrawn,
    SectionWithLength,
    SectionWithWidth,
    SectionWithTheoreticalSurface,
    SectionWithExposureRate {
  name: CirculationName.Ramp;
  garageDoorHeight: number;
  garageDoorWidth: number;
}

export const RAMP_ICON = {
  type: 'object',
  iconName: 'garageEntrance',
  color: APP_COLOR
} as IconObjectProps;

const ramp = ({
  surface,
  length,
  width,
  exposureRate,
  garageDoorHeight,
  garageDoorWidth,
  group
}: RampProps): RampSection => ({
  // Section
  id: 'NOID',
  name: CirculationName.Ramp,
  title: i18next.t('ramp.title'),
  type: 'circulation',
  surface,
  // SectionDrawn
  displayedSurface: surface,
  color: APP_COLOR,
  icon: RAMP_ICON,
  group,
  // SectionWithTheoreticalSurface
  theoreticalSurface: surface,
  defaultTheoreticalSurface:
    getDefaultCasePropertyValue('rampWidth') * getDefaultCasePropertyValue('rampLength'),
  // SectionWithExposureRate
  exposureRate,
  defaultExposureRate: getDefaultCasePropertyValue('rampExposureRate'),
  // RampSection
  length,
  width,
  garageDoorHeight,
  garageDoorWidth
});

export default ramp;

export const isSectionARamp = (section: Section): section is RampSection =>
  getSectionName(section) === CirculationName.Ramp;
