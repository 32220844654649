import * as R from 'ramda';
import { LevelGranulometry } from '../../../LevelGranulometry';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { hasCaseGranulometryDrawnFloorSpaceFeature } from '../../../../cases/queries/has/hasCaseGranulometryDrawnFloorSpaceFeature';
import { getCaseGranulometryDrawnFloorSpaceFeatureFacades } from '../../../../cases/queries/get/getCaseGranulometryDrawnFloorSpaceFeatureFacades';
import { FacadeSpecification } from '../../../../../specification/floorSpace/FacadeSpecification';
import { getTopLevelDefaultWidth } from './getTopLevelDefaultWidth';
import { getTopLevelDefaultLength } from './getTopLevelDefaultLength';
import { mustHaveFacadeLinearsRelativeToDefaultWidthAndLength } from '../../mustHave/mustHaveFacadeLinearsRelativeToDefaultWidthAndLength';
import { getTopLevelOutsideInsulatedWallsThickness } from './wallsThickness/getTopLevelOutsideInsulatedWallsThickness';

export const getTopLevelInsulatedFacadeInternalLinears = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry
): number[] => {
  if (hasCaseGranulometryDrawnFloorSpaceFeature(caseGranulometry)) {
    if (mustHaveFacadeLinearsRelativeToDefaultWidthAndLength(caseGranulometry, levelGranulometry)) {
      return [
        getTopLevelDefaultWidth(caseGranulometry, levelGranulometry),
        getTopLevelDefaultLength(caseGranulometry, levelGranulometry),
        getTopLevelDefaultWidth(caseGranulometry, levelGranulometry),
        getTopLevelDefaultLength(caseGranulometry, levelGranulometry)
      ];
    } else {
      return R.reduce<FacadeSpecification, number[]>(
        (acc, facade) => [
          ...acc,
          (facade.distance || 0) - getTopLevelOutsideInsulatedWallsThickness(caseGranulometry) * 2
        ],
        [] as number[]
      )(
        getCaseGranulometryDrawnFloorSpaceFeatureFacades(caseGranulometry) as FacadeSpecification[]
      );
    }
  } else {
    return [
      getTopLevelDefaultWidth(caseGranulometry, levelGranulometry),
      getTopLevelDefaultLength(caseGranulometry, levelGranulometry),
      getTopLevelDefaultWidth(caseGranulometry, levelGranulometry),
      getTopLevelDefaultLength(caseGranulometry, levelGranulometry)
    ];
  }
};
