import * as turf from '@turf/turf';
import { Axis } from '../../../../domain/specification/floorSpace/FacadeSpecification';
import { Position } from '@turf/helpers/dist/js/lib/geojson';

export const orientations = [
  'North',
  'South',
  'West',
  'East',
  'South-west',
  'South-east',
  'North-west',
  'North-east',
  'Not determined'
];

export type Orientation =
  | 'North'
  | 'South'
  | 'West'
  | 'East'
  | 'South-west'
  | 'South-east'
  | 'North-west'
  | 'North-east'
  | 'Not determined';

export const getOrientation = (
  center: Position,
  axis: Axis,
  polygon: GeoJSON.Feature<GeoJSON.Polygon>
): Orientation => {
  const expoNorth = !turf.booleanPointInPolygon([center[0], center[1] + 0.0000001], polygon);
  const expoSouth = !turf.booleanPointInPolygon([center[0], center[1] - 0.0000001], polygon);
  const expoWest = !turf.booleanPointInPolygon([center[0] - 0.0000001, center[1]], polygon);
  const expoEast = !turf.booleanPointInPolygon([center[0] + 0.0000001, center[1]], polygon);

  switch (axis) {
    case 'horizontal':
      if (expoNorth) return 'North';
      if (expoSouth) return 'South';
      break;
    case 'vertical':
      if (expoWest) return 'West';
      if (expoEast) return 'East';
      break;
    case 'diagonal':
      if (expoEast && expoSouth) return 'South-east';
      if (expoEast && expoNorth) return 'North-east';
      if (expoNorth && expoWest) return 'North-west';
      if (expoNorth && expoWest) return 'North-west';
      if (expoSouth && expoWest) return 'South-west';
      if (expoNorth && expoEast) return 'North-east';
      break;
  }
  return 'Not determined';
};
