import { CaseGranulometry } from '../../../domain/granulometry/cases/CaseGranulometry';
import { getDefaultUnitConvertedPropertyValue } from '../../../domain/legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { Section } from '../../../domain/granulometry/sections/Section';
import { roundWith2Decimal } from '../../../utils/round/roundWith2Decimal';
import { isSectionWithExposureRate } from '../../../domain/granulometry/sections/SectionWithExposureRate';
import { roundWith4Decimal } from '../../../utils/round/roundWith4Decimal';
import { getSectionTheoreticalExposureRate } from '../../../domain/granulometry/sections/queries/exposureRate/getSectionTheoreticalExposureRate';
import { isSectionWithWidth } from '../../../domain/granulometry/sections/SectionWithWidth';
import { isSectionWithLength } from '../../../domain/granulometry/sections/SectionWithLength';
import { isSectionAParking } from '../../../domain/granulometry/sections/parkingSections/ParkingSection';
import { isSectionACarBoxInfra } from '../../../domain/granulometry/sections/parkingSections/carBoxInfra';
import { isSectionAStairsShaft } from '../../../domain/granulometry/sections/circulationSections/stairsShaft';
import { isSectionAStairsShaftInfra } from '../../../domain/granulometry/sections/circulationSections/stairsShaftInfra';
import { isSectionAElevatorShaft } from '../../../domain/granulometry/sections/circulationSections/elevatorShaft';
import { isSectionAElevatorShaftInfra } from '../../../domain/granulometry/sections/circulationSections/elevatorShaftInfra';
import { isSectionARamp } from '../../../domain/granulometry/sections/circulationSections/ramp';
import { isSectionARampInfra } from '../../../domain/granulometry/sections/circulationSections/rampInfra';
import { isSectionAHall } from '../../../domain/granulometry/sections/circulationSections/hall';
import { isSectionALodgment } from '../../../domain/granulometry/sections/lodgmentSections/LodgmentSection';
import { RoomSpecification } from '../../../domain/specification/rooms/RoomSpecification';
import { getRoomSpecificationWidth } from '../../../domain/specification/rooms/queries/get/getRoomSpecificationWidth';
import { getRoomSpecificationLength } from '../../../domain/specification/rooms/queries/get/getRoomSpecificationLength';
import { getSectionDisplayedSurface } from '../../../domain/granulometry/sections/queries/surfaces/getSectionDisplayedSurface';
import { getRoomSpecificationDepth } from '../../../domain/specification/rooms/queries/get/getRoomSpecificationDepth';
import { getRoomSpecificationSurface } from '../../../domain/specification/rooms/queries/get/getRoomSpecificationSurface';
import { isSectionALowestLevelMetaSection } from '../../../domain/granulometry/sections/metaSections/lowestLevelMetaSection';
import { isSectionAFirstBasementLevelMetaSection } from '../../../domain/granulometry/sections/metaSections/firstBasementLevelMetaSection';
import { isSectionAGroundLevelMetaSection } from '../../../domain/granulometry/sections/metaSections/groundLevelMetaSection';
import { isSectionAMetaSection } from '../../../domain/granulometry/sections/metaSections/MetaSection';
import { keepOnlyKeysWithValues } from '../../../utils/keepOnlyKeysWithValues';
import { getLodgmentSectionHeatingSystem } from '../../../domain/granulometry/sections/queries/heatingSystem/getLodgmentSectionHeatingSystem';
import { isRoomSpecificationABalcony } from '../../../domain/specification/rooms/BalconySpecification';
import { isSectionAProjectMetaSection } from '../../../domain/granulometry/sections/metaSections/projectMetaSection';
import { isSectionWithGlazedSurface } from '../../../domain/granulometry/sections/SectionWithGlazedSurface';
import { getSectionGlazedLinear } from '../../../domain/granulometry/sections/queries/getSectionGlazedLinear';
import { LevelGranulometry } from '../../../domain/granulometry/levels/LevelGranulometry';
import { isSectionWithTechnicalSheaths } from '../../../domain/granulometry/sections/SectionWithTechnicalSheaths';
import { isSectionAnOutsideStairsShaftInfra } from '../../../domain/granulometry/sections/outsideSections/outsideStairShaftInfra';
import { isSectionAnOutsideStairsShaft } from '../../../domain/granulometry/sections/outsideSections/outsideStairShaft';
import { isSectionWithDepth } from '../../../domain/granulometry/sections/isSectionWithDepth';

export const adaptSection = (
  parentCase: CaseGranulometry,
  parentLevel: LevelGranulometry,
  section: Section
): Section =>
  keepOnlyKeysWithValues({
    // Section
    name: section.name,
    title: section.title,
    surface: roundWith2Decimal(section.displayedSurface),
    type: section.type,
    // SectionWithExposureRate
    exposureRate: isSectionWithExposureRate(section)
      ? roundWith4Decimal(getSectionTheoreticalExposureRate(section))
      : undefined,
    // SectionWithWidth
    width: isSectionWithWidth(section) ? roundWith2Decimal(section.width) : undefined,
    // SectionWithLength
    length: isSectionWithLength(section) ? roundWith2Decimal(section.length) : undefined,
    // SectionWithDepth
    depth: isSectionWithDepth(section) ? roundWith2Decimal(section.depth) : undefined,
    beamHeight: isSectionAParking(section) ? roundWith2Decimal(section.beamHeight) : undefined,
    beamWidth: isSectionAParking(section) ? roundWith2Decimal(section.beamWidth) : undefined,
    pillarThickness: isSectionAParking(section)
      ? roundWith2Decimal(section.pillarThickness)
      : undefined,
    pillarWidth: isSectionAParking(section) ? roundWith2Decimal(section.pillarWidth) : undefined,
    partitionThickness: isSectionACarBoxInfra(section)
      ? roundWith2Decimal(section.partitionThickness)
      : undefined,
    exposedWallConcreteRatio: isSectionAParking(section)
      ? roundWith2Decimal(section.outsideWallsConcreteRatio)
      : undefined, // TODO : Rename in backend with outsideWallsConcreteRatio
    // (Outside)StairsShaft(Infra)Section
    bearingLength:
      isSectionAStairsShaft(section) ||
      isSectionAStairsShaftInfra(section) ||
      isSectionAnOutsideStairsShaft(section) ||
      isSectionAnOutsideStairsShaftInfra(section)
        ? roundWith2Decimal(section.bearingLength)
        : undefined,
    bearingWidth:
      isSectionAStairsShaft(section) ||
      isSectionAStairsShaftInfra(section) ||
      isSectionAnOutsideStairsShaft(section) ||
      isSectionAnOutsideStairsShaftInfra(section)
        ? roundWith2Decimal(section.bearingWidth)
        : undefined,
    // ElevatorShaft(Infra)Section
    doorHeight:
      isSectionAElevatorShaft(section) || isSectionAElevatorShaftInfra(section)
        ? roundWith2Decimal(section.doorHeight)
        : undefined,
    doorWidth:
      isSectionAElevatorShaft(section) || isSectionAElevatorShaftInfra(section)
        ? roundWith2Decimal(section.doorWidth)
        : undefined,
    // Ramp(Infra)Section
    garageDoorHeight:
      isSectionARamp(section) || isSectionARampInfra(section)
        ? roundWith2Decimal(section.garageDoorHeight)
        : undefined,
    garageDoorWidth:
      isSectionARamp(section) || isSectionARampInfra(section)
        ? roundWith2Decimal(section.garageDoorWidth)
        : undefined,
    // Hall Section
    entranceHeight: isSectionAHall(section) ? roundWith2Decimal(section.entranceHeight) : undefined,
    entranceLength: isSectionAHall(section) ? roundWith2Decimal(section.entranceLength) : undefined,
    // LodgmentSection
    realLivingSurface: isSectionALodgment(section)
      ? roundWith2Decimal(section.displayedSurface)
      : undefined,
    rooms: isSectionALodgment(section)
      ? section.rooms.map((roomSpecification: RoomSpecification) =>
          keepOnlyKeysWithValues({
            name: roomSpecification.name,
            title: roomSpecification.title,
            width: roundWith2Decimal(getRoomSpecificationWidth(roomSpecification)),
            length: roundWith2Decimal(
              getRoomSpecificationLength(
                section.rooms,
                getSectionDisplayedSurface(section),
                getDefaultUnitConvertedPropertyValue(
                  parentCase.initialSpecifications,
                  'balconySurfaceOnRealLivingSurfaceRate'
                ) as number,
                roomSpecification
              )
            ),
            depth: roundWith2Decimal(getRoomSpecificationDepth(roomSpecification)),
            surface: roundWith2Decimal(
              getRoomSpecificationSurface(
                section.rooms,
                getSectionDisplayedSurface(section),
                getDefaultUnitConvertedPropertyValue(
                  parentCase.initialSpecifications,
                  'balconySurfaceOnRealLivingSurfaceRate'
                ) as number,
                roomSpecification
              )
            ),
            bodyguardType: isRoomSpecificationABalcony(roomSpecification) // Unused cause admin v1 doesn't recognize it
              ? roomSpecification.bodyguardType
              : undefined
          })
        )
      : undefined,
    heatingSystem: isSectionALodgment(section)
      ? getLodgmentSectionHeatingSystem(section)
      : undefined,
    balconyBodyguardType: isSectionALodgment(section)
      ? section.rooms.find(isRoomSpecificationABalcony)?.bodyguardType
      : undefined,
    technicalSheathCount: isSectionWithTechnicalSheaths(section)
      ? section.technicalSheathCount
      : undefined,
    // SectionWithGlazedSurface
    glazedLinear:
      isSectionWithGlazedSurface(section) && isSectionWithExposureRate(section)
        ? roundWith2Decimal(getSectionGlazedLinear(parentCase, parentLevel, section))
        : undefined,
    // MetaSection
    circulationLaneInfraLength:
      isSectionALowestLevelMetaSection(section) || isSectionAFirstBasementLevelMetaSection(section)
        ? roundWith2Decimal(section.circulationLaneInfraLength)
        : undefined,
    extendedSurface: isSectionAGroundLevelMetaSection(section)
      ? roundWith2Decimal(section.extendedSurface)
      : undefined,
    lodgmentSurfaceForSale: isSectionAGroundLevelMetaSection(section)
      ? roundWith2Decimal(section.lodgmentSurfaceForSale)
      : undefined,
    ceilingNetHeight:
      isSectionAMetaSection(section) && !isSectionAProjectMetaSection(section)
        ? roundWith2Decimal(section.ceilingNetHeight)
        : undefined,
    elevatorShaftCount: isSectionAGroundLevelMetaSection(section)
      ? section.elevatorShaftCount
      : undefined,
    stairsShaftCount: isSectionAGroundLevelMetaSection(section)
      ? section.stairsShaftCount
      : undefined,
    smokeExtractionDuctsCount: isSectionAGroundLevelMetaSection(section)
      ? section.smokeExtractionDuctsCount
      : undefined
  });
