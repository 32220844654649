import React from 'react';
import { useSelector } from 'react-redux';
import { selectMapElementsToDraw } from '../../../../../store/selectors/map/mapElementsToDraw.selector';
import { Card } from '../../../../ui/Card';
import { CardTitle } from '../../../../ui/CardTitle';
import SectionMap from '../../../../ui/SectionMap';
import { roundWith2Decimal } from '../../../../../utils/round/roundWith2Decimal';
import { INFO_COLOR } from '../../../../../constants/appConstants';
import { Input } from '../../../../ui/input/Input';
import { FormGroup } from '../../../../ui/FormGroup';
import { Note } from '../../../../ui/Note';
import { useTranslation } from 'react-i18next';

export const FloorSpaceParcelsCard = () => {
  const { parcels } = useSelector(selectMapElementsToDraw);
  const { t } = useTranslation();

  if (!parcels) return null;

  return (
    <Card>
      <CardTitle>Parcelles</CardTitle>
      {parcels.map((parcel, index) => (
        <SectionMap
          id={`floorSpaceParcel${index}`}
          sectionIconProps={{
            type: 'custom',
            customText: parcel.properties.numero,
            strokeStyle: 'dashed',
            color: INFO_COLOR
          }}
          infoList={[' ', ' ', roundWith2Decimal(parcel.properties.area) + 'm2']}
          key={index}
        />
      ))}
      <FormGroup horizontal={true}>
        <div style={{ width: '50%' }}>
          <Input
            label={t('floorSpaceWindow.parcelsCard.surface.label')}
            name={`floorSpaceFeatureParcelsSurface`}
            type="number"
            suffix="m&#178;"
            value={roundWith2Decimal(
              parcels.reduce((p, c) => p + (c.properties.area && c.properties.area), 0)
            )}
            className="highlighted"
            disabled={true}
          />
        </div>
        <div style={{ width: '50%' }}></div>
      </FormGroup>
      <Note>{t('floorSpaceWindow.parcelsNote')}</Note>
    </Card>
  );
};
