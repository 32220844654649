import * as R from 'ramda';
import { FloorSpaceFeature } from '../../FloorSpaceFeature';

export const getFloorSpaceFeaturesWithGeometry = (
  floorSpaceFeatures: FloorSpaceFeature[]
): FloorSpaceFeature[] =>
  R.pipe<[FloorSpaceFeature[]], FloorSpaceFeature[], FloorSpaceFeature[]>(
    R.filter<FloorSpaceFeature>(R.has('geometry')),
    R.reject(R.isNil)
  )(floorSpaceFeatures);
