import { createSelector, Selector } from 'reselect';
import { selectCurrentCaseProjection } from '../../projection/currentCase/projection.selector';
import { State } from '../../../reducers';
import { Maybe } from '../../../../utils/Maybe';
import { ifDefined } from '../../../../utils/ifDefined';

export const selectCurrentCaseSpecifiedBasementLevelsCount: Selector<
  State,
  Maybe<number>
> = createSelector(
  [selectCurrentCaseProjection],
  ifDefined((caseSpecification) => caseSpecification.basementLevelsCount)
);
