import { roundWith2Decimal } from '../../../../../../utils/round/roundWith2Decimal';
import { getTopLevelMinimumBearingSurface } from './getTopLevelMinimumBearingSurface';
import { LevelGranulometry, LevelGranulometryWithLodgments } from '../../../LevelGranulometry';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { getBearingTechnicalSheathsSurface } from '../../../../sections/queries/bearingTechnicalSheaths/getBearingTechnicalSheathsSurface';
import { getCaseElevatorShaftSectionsCount } from '../../../../cases/queries/sections/counts/getCaseElevatorShaftSectionsCount';
import { getCaseDataPropertyValue } from '../../../../../specification/cases/queries/get/properties/getCaseDataPropertyValue';
import { getTopLevelSectionsWithTechnicalSheathsCount } from '../counts/getTopLeveSectionWithTechnicalSheathsCount';

export const getTopLevelMinimumBearingSurfaceDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry | LevelGranulometryWithLodgments
): string => {
  let details: string[] = [];
  const elevatorsCount = getCaseElevatorShaftSectionsCount(caseGranulometry);
  const bearingWidth = getCaseDataPropertyValue(
    caseGranulometry.initialSpecifications,
    'bearingWidth'
  ) as number;
  const floorSurface =
    bearingWidth * (elevatorsCount === 0 ? 1.3 : elevatorsCount === 1 ? 1.6 : 1.6 + 1.3);
  details = [
    ...details,
    '<b>' +
      roundWith2Decimal(floorSurface) +
      '</b> m\u00B2 de surface de plancher minimale relative à la largeur de <i>Palier</i> [' +
      bearingWidth +
      ' m] et à ' +
      elevatorsCount +
      ' <i>Ascenseur' +
      (elevatorsCount > 1 ? 's' : '') +
      '</i>'
  ];

  details = [
    ...details,
    '<i>&nbsp;&nbsp;-> soit : ' +
      bearingWidth +
      ' * ' +
      (elevatorsCount === 0 ? '1.3' : elevatorsCount === 1 ? '1.6' : '(1.6 + 1.3)') +
      '</i>'
  ];

  const sectionsWithTechnicalSheathsCount = getTopLevelSectionsWithTechnicalSheathsCount(
    levelGranulometry as LevelGranulometry
  );
  details = [
    ...details,
    '<b>* (1 + max(0, ' +
      sectionsWithTechnicalSheathsCount +
      '</b> <i>Sections utiles</i> et <i>Locaux techniques</i> <b>- 3</b> portes<b>) / 2</b> murs<b>)</b>'
  ];

  details = [
    ...details,
    '<b>+ ' +
      roundWith2Decimal(
        getBearingTechnicalSheathsSurface(caseGranulometry, levelGranulometry).value
      ) +
      ' m\u00B2</b> d’emprise de gaines techniques palières <i>– GTP</i>'
  ];

  details = [
    ...details,
    '<b>= ' +
      roundWith2Decimal(
        getTopLevelMinimumBearingSurface(caseGranulometry, levelGranulometry).value
      ) +
      ' m\u00B2</b>'
  ];
  return details.join('<br />');
};
