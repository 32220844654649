import { matchesPath } from '../matchesPath';
import { orCreate, RouteWithMakePath } from '../panel';

export type BuildingRouteParams = { projectId: string; buildingId: string };
export const buildingRoute: RouteWithMakePath<BuildingRouteParams> = {
  path: `/project/:projectId/buildings/:buildingId`,
  makePath: ({ projectId, buildingId }) =>
    `/project/${orCreate(projectId)}/buildings/${buildingId}`,
  matchesPath: matchesPath<BuildingRouteParams>(/\/project\/([^/]+)\/buildings\/(\w+)/, {
    projectId: 1,
    buildingId: 2
  })
};

export type BuildingsRouteParams = { projectId: string };
export const buildingsRoute: RouteWithMakePath<BuildingsRouteParams> = {
  path: `/project/:projectId/buildings`,
  makePath: ({ projectId }) => `/project/${orCreate(projectId)}/buildings`,
  matchesPath: matchesPath<BuildingsRouteParams>(/\/project\/([^/]+)\/buildings/, {
    projectId: 1
  })
};
