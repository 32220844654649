import { getCasesSpecificationsById } from '../specification/project/queries/cases/getProjectCasesById';
import { CaseId } from '../specification/cases/CaseSpecification';
import { Maybe } from '../../utils/Maybe';
import { Surface } from '../specification/Surface';
import * as R from 'ramda';
import { mapCases } from './map/mapCases';
import { getCaseProjectionFromCaseSpecification } from '../projection/cases/queries/getCaseProjectionFromCaseSpecification';
import { DEFAULT_PROJECT_SURFACE } from '../../constants/appConstants';
import { Project } from './Project';
import { ProjectProjection } from '../projection/project/ProjectProjection';

export const projectCasesSurfaces = (project: Project): ProjectProjection => {
  const casesSpecificationsById = getCasesSpecificationsById(project);

  const specifiedSurfaces: Record<CaseId, Maybe<Surface>> = R.mapObjIndexed(
    (eachCase) => eachCase.surfaces.cuttedBuiltSurface,
    casesSpecificationsById
  );

  const alreadySpecifiedCuttedBuiltSurface: Surface = R.compose<
    [Record<CaseId, Maybe<Surface>>],
    Maybe<Surface>[],
    Surface[],
    Surface
  >(
    Surface.sum,
    (values) => values.filter(Boolean) as Surface[],
    R.values
  )(specifiedSurfaces);

  const unspecifiedSurfacesCount = R.compose<
    [Record<CaseId, Maybe<Surface>>],
    Maybe<Surface>[],
    undefined[],
    number
  >(
    R.length,
    (values) => values.filter((s) => s === undefined) as [],
    R.values
  )(specifiedSurfaces);

  if (!unspecifiedSurfacesCount) {
    return mapCases((eachCase) =>
      getCaseProjectionFromCaseSpecification(
        specifiedSurfaces[eachCase.id] as Surface,
        true
      )(eachCase)
    )(project) as ProjectProjection;
  }

  // After the rehydrate :
  // PATCH : To make the project in production older than the 2021-03-30 works
  // project land.surfaces has been replaced by project.surface
  // TODO : It should be done with a migration script on the database
  if (!project.surface) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    if (project.land?.surfaces?.totalArea) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      project.surface = new Surface(project.land.surfaces.totalArea);
    }
  }

  project.projectedSurface =
    project.surface !== undefined ? project.surface : new Surface(DEFAULT_PROJECT_SURFACE);

  const distributableCuttedBuiltSurface: Surface = new Surface(
    Math.max(1, project.projectedSurface.value - alreadySpecifiedCuttedBuiltSurface.value)
  );

  const cuttedBuiltSurfaceToDistributeByCase = new Surface(
    distributableCuttedBuiltSurface.value / unspecifiedSurfacesCount
  );

  return mapCases((eachCase) =>
    getCaseProjectionFromCaseSpecification(
      specifiedSurfaces[eachCase.id] || cuttedBuiltSurfaceToDistributeByCase,
      true
    )(eachCase)
  )(project) as ProjectProjection;
};
