import { ReportEntry } from '../../domain/report/ReportEntry';
import { roundWith2Decimal } from '../../utils/round/roundWith2Decimal';
import { sumCo2QuantitiesInReportBy } from '../utils/sumCo2QuantitiesInReportBy';
import * as R from 'ramda';
import { sortReportByRefSsLot2 } from '../utils/sortReportByRefSsLot2';

export interface ClimaWinJsonEntry {
  element: string;
  co2Title: string;
  co2Id: string;
  refLot: string;
  refSsLot1: string;
  co2Quantity: string;
  unit: string;
  uniqueIdClimaWin: string;
}

export type ClimaWinJson = Partial<ClimaWinJsonEntry>[];

const columns: (keyof ClimaWinJsonEntry)[] = [
  'element',
  'co2Title',
  'co2Id',
  'refLot',
  'refSsLot1',
  'co2Quantity',
  'unit',
  'uniqueIdClimaWin'
];

export const makeClimaWinJson = (reportEntries: ReportEntry[]): ClimaWinJson =>
  R.pipe<[ReportEntry[]], ReportEntry[], ReportEntry[], ClimaWinJson>(
    sumCo2QuantitiesInReportBy(['ssLot2', 'co2Id', 'co2Title']),
    sortReportByRefSsLot2,
    R.reduce<ReportEntry, ClimaWinJson>((acc, reportEntry) => {
      const hasToBeSkipped =
        reportEntry.co2Quantity === 0 ||
        isNaN(reportEntry.co2Quantity) ||
        !!reportEntry.co2Id.match(/[a-zA-Z]/);
      return !hasToBeSkipped
        ? [
            ...acc,
            columns.reduce((obj: Partial<ClimaWinJsonEntry>, key) => {
              let value: string;
              if (key === 'element') {
                value =
                  reportEntry.ssLot2 !== ''
                    ? reportEntry.ssLot2
                    : reportEntry.ssLot1 !== ''
                    ? reportEntry.ssLot1
                    : reportEntry.lot;
              } else if (key === 'unit') {
                value = reportEntry.co2QuantityUnit;
              } else if (key === 'co2Quantity') {
                value = roundWith2Decimal(reportEntry.co2Quantity).toString().replace('.', ',');
              } else if (key === 'refSsLot1') {
                value = reportEntry.refSsLot1 !== '' ? reportEntry.refSsLot1.split('.')[1] : '';
              } else if (key === 'uniqueIdClimaWin') {
                value = '';
              } else {
                value = reportEntry[key];
              }
              // Cleaning string values to preserve csv format
              obj[key] = value
                .toString()
                .replace(/;|,|:|\s\s/gm, ' ')
                .replace('  ', ' ')
                .replace('  ', ' ')
                .replace('  ', ' ');
              return obj;
            }, {})
          ]
        : acc;
    }, [])
  )(reportEntries);
