import { Angle, Axis } from '../../../../domain/specification/floorSpace/FacadeSpecification';

export const getAxis = (angle: Angle): Axis => {
  angle = Math.abs(angle);
  if (angle > 20 && angle <= 70) return 'diagonal';
  if (angle > 70 && angle <= 120) return 'horizontal';
  if (angle > 120 && angle <= 160) return 'diagonal';
  if (angle > 160 && angle <= 180) return 'vertical';
  else return 'vertical'; // angle >= 0 && angle <= 20
};
