import { BuildingGranulometry } from '../../../domain/granulometry/buildings/BuildingGranulometry';
import { CaseGranulometry } from '../../../domain/granulometry/cases/CaseGranulometry';
import { roundWith2Decimal } from '../../../utils/round/roundWith2Decimal';
import { getCaseDataRealBuiltSurface } from '../../../domain/legacy/methodsForCases/getCaseDataRealBuiltSurface';
import { roundWith4Decimal } from '../../../utils/round/roundWith4Decimal';
import { getCaseDataCuttedBuiltSurfaceRate } from '../../../domain/legacy/methodsForCases/getCaseDataCuttedBuiltSurfaceRate';
import { getCaseDataCuttedBuiltSurface } from '../../../domain/legacy/methodsForCases/getCaseDataCuttedBuiltSurface';
import { getCaseDataSurfaceForSaleRate } from '../../../domain/legacy/methodsForCases/getCaseDataSurfaceForSaleRate';
import { getCaseDataSurfaceForSale } from '../../../domain/legacy/methodsForCases/getCaseDataSurfaceForSale';
import { getCaseDataSurfaceForSaleRealRate } from '../../../domain/legacy/methodsForCases/getCaseDataSurfaceForSaleRealRate';
import { adaptProperties } from './adaptProperties';
import { adaptLevels } from './adaptLevels';
import { getCaseMaxLodgmentSectionsCountInTopLevels } from '../../../domain/granulometry/cases/queries/sections/counts/getCaseMaxLodgmentSectionsCountInTopLevels';
import { hasCaseLodgmentsWithCollectiveGasHeating } from '../../../domain/granulometry/cases/queries/has/hasCaseLodgmentsWithCollectiveGasHeating';
import { hasCaseLodgmentsWithDistrictHeating } from '../../../domain/granulometry/cases/queries/has/hasCaseLodgmentsWithDistrictHeating';
import { hasCaseLodgmentsWithCollectiveHeatPumpOnGroundWaterHeating } from '../../../domain/granulometry/cases/queries/has/hasCaseLodgmentsWithCollectiveHeatPumpOnGroundWaterHeating';
import { hasCaseLodgmentsWithCollectiveWoodHeating } from '../../../domain/granulometry/cases/queries/has/hasCaseLodgmentsWithCollectiveWoodHeating';
import { getCaseGranulometryTopLevelsCount } from '../../../domain/granulometry/cases/queries/levels/counts/getCaseGranulometryTopLevelsCount';
import { getCaseAdjoiningsCount } from '../../../domain/granulometry/cases/queries/get/getCaseAdjoiningsCount';
import { getCaseTopLevelsHeight } from '../../../domain/granulometry/cases/queries/levels/getCaseTopLevelsHeight';

export const adaptCase = (
  buildingGranulometry: BuildingGranulometry,
  caseGranulometry: CaseGranulometry
) => ({
  id: caseGranulometry.id,
  name: caseGranulometry.name,
  title: caseGranulometry.title,
  adjoiningsCount: getCaseAdjoiningsCount(buildingGranulometry, caseGranulometry),
  realBuiltSurface: roundWith2Decimal(
    getCaseDataRealBuiltSurface(caseGranulometry.initialSpecifications)
  ),
  cuttedBuiltSurfaceRate: roundWith4Decimal(
    getCaseDataCuttedBuiltSurfaceRate(caseGranulometry.initialSpecifications)
  ),
  cuttedBuiltSurface: roundWith2Decimal(
    getCaseDataCuttedBuiltSurface(caseGranulometry.initialSpecifications)
  ),
  surfaceForSaleRate: roundWith4Decimal(
    getCaseDataSurfaceForSaleRate(caseGranulometry.initialSpecifications)
  ),
  surfaceForSale: roundWith2Decimal(
    getCaseDataSurfaceForSale(caseGranulometry.initialSpecifications)
  ),
  surfaceForSaleRealRate: roundWith4Decimal(
    getCaseDataSurfaceForSaleRealRate(caseGranulometry.initialSpecifications)
  ),
  topLevelCount: getCaseGranulometryTopLevelsCount(caseGranulometry),
  basementLevelCount: caseGranulometry.initialSpecifications.basementLevelCount,
  builtInRamp: caseGranulometry.initialSpecifications.builtInRamp ? 1 : 0,
  hall: caseGranulometry.initialSpecifications.hall ? 1 : 0,
  topLevelsHeight: roundWith2Decimal(getCaseTopLevelsHeight(caseGranulometry)),
  levels: adaptLevels(caseGranulometry),
  maxLodgmentCountByLevel: getCaseMaxLodgmentSectionsCountInTopLevels(caseGranulometry),
  carpentryType: caseGranulometry.initialSpecifications.carpentryType,
  carpentryDayTimeOccultationsType:
    caseGranulometry.initialSpecifications.carpentryDayTimeOccultationsType,
  carpentryDayTimeOccultationsTypeIsMonobloc: caseGranulometry.initialSpecifications
    .carpentryDayTimeOccultationsTypeIsMonobloc
    ? 1
    : 0,
  carpentryNightTimeOccultationsType:
    caseGranulometry.initialSpecifications.carpentryNightTimeOccultationsType,
  carpentryNightTimeOccultationsTypeIsMonobloc: caseGranulometry.initialSpecifications
    .carpentryNightTimeOccultationsTypeIsMonobloc
    ? 1
    : 0,
  carpentryOccultationsMotorisationType:
    caseGranulometry.initialSpecifications.carpentryOccultationsMotorisationType,
  masonryType: caseGranulometry.initialSpecifications.masonryType,
  woodEnvelopeType: caseGranulometry.initialSpecifications.woodEnvelopeType,
  heatingDistribution: caseGranulometry.initialSpecifications.heatingDistribution,
  hasLodgmentsWithCollectiveGasHeating: hasCaseLodgmentsWithCollectiveGasHeating(caseGranulometry)
    ? 1
    : 0,
  hasLodgmentsWithDistrictHeating: hasCaseLodgmentsWithDistrictHeating(caseGranulometry) ? 1 : 0,
  hasLodgmentsWithCollectiveHeatPumpOnGroundWaterHeating:
    hasCaseLodgmentsWithCollectiveHeatPumpOnGroundWaterHeating(caseGranulometry) ? 1 : 0,
  hasLodgmentsWithCollectiveWoodHeating: hasCaseLodgmentsWithCollectiveWoodHeating(caseGranulometry)
    ? 1
    : 0,
  ...adaptProperties(caseGranulometry)
});
