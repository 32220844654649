import { createSelector, Selector } from 'reselect';
import { State } from '../../../reducers';
import { Section } from '../../../../domain/granulometry/sections/Section';
import { selectCurrentSectionIdFromRoute } from '../../navigation/sectionIdFromRoute.selector';
import { selectAllSectionsInGranulometry } from './allSectionsInGranulometry.selector';
import { Maybe } from '../../../../utils/Maybe';

export const selectCurrentSectionGranulometry: Selector<State, Maybe<Section>> = createSelector(
  [selectAllSectionsInGranulometry, selectCurrentSectionIdFromRoute],
  (allSections, sectionId) =>
    allSections && sectionId ? allSections.find((section) => section.id === sectionId) : undefined
);
