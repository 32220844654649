import { Project } from '../../project/Project';
import { mapCases } from '../../project/map/mapCases';
import { CarpentryTypeName } from '../../specification/cases/CarpentryType';
import { CaseEditingStageName } from '../../specification/cases/CaseSpecification';

export const migrateProject6To7 = (project: Project): Project => {
  return mapCases((eachCase) => {
    return {
      ...eachCase,
      carpentryType: eachCase.carpentryType || CarpentryTypeName.PVC,
      customDistribution: eachCase.customDistribution || {},
      editingStage: eachCase.editingStage || CaseEditingStageName.Granulometry
    };
  })(project);
};
