import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../ui/input/Input';
import { roundForSurfacesInputs } from '../../../../utils/round/roundForSurfacesInputs';
import { caseRealBuiltSurfaceIsValid } from '../../../../domain/checks/cases/caseRealBuiltSurfaceIsValid';
import { CaseEditingStageName } from '../../../../domain/specification/cases/CaseSpecification';
import { Note } from '../../../ui/Note';
import { selectCurrentCaseEditingStage } from '../../../../store/selectors/specification/currentCase/editingStage.selector';
import { Surface } from '../../../../domain/specification/Surface';
import { secureInputChange } from '../../../../utils/secureInputChange';
import { selectCurrentCaseProjection } from '../../../../store/selectors/projection/currentCase/projection.selector';
import { selectCurrentBuildingId } from '../../../../store/selectors/project/currentBuildingId.selector';
import { selectCurrentCaseIdFromRoute } from '../../../../store/selectors/navigation/caseIdFromRoute.selector';
import { selectProjectProjection } from '../../../../store/selectors/project/projectProjection.selector';
import { ProjectProjection } from '../../../../domain/projection/project/ProjectProjection';
import { caseSurfaceChanged } from '../../../../store/actions/caseSurfaceChanged.action';
import { getCaseRealBuiltSurfaceMinimum } from '../../../../domain/specification/cases/queries/get/surfaces/getCaseRealBuiltSurfaceMinimum';
import { getCaseRealBuiltSurfaceMaximum } from '../../../../domain/specification/cases/queries/get/surfaces/getCaseRealBuiltSurfaceMaximum';

export const CaseRealBuiltSurfaceInput = () => {
  const buildingId = useSelector(selectCurrentBuildingId);
  const caseId = useSelector(selectCurrentCaseIdFromRoute);
  const projectProjection = useSelector(selectProjectProjection) as ProjectProjection;
  const caseProjection = useSelector(selectCurrentCaseProjection);
  const editingStage = useSelector(selectCurrentCaseEditingStage);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCaseRBSChange = React.useCallback(
    (rbs: number | null): void => {
      if (buildingId && caseId) {
        dispatch(
          caseSurfaceChanged(buildingId, caseId, {
            ...caseProjection?.surfaces,
            realBuiltSurface:
              rbs !== null && rbs !== 0 ? new Surface(secureInputChange(rbs)) : undefined
          })
        );
      }
    },
    [dispatch, caseId, buildingId, caseProjection]
  );

  if (!caseProjection) return null;

  const minRbs = roundForSurfacesInputs(getCaseRealBuiltSurfaceMinimum(caseProjection));
  const maxRbs = roundForSurfacesInputs(
    getCaseRealBuiltSurfaceMaximum(projectProjection, caseProjection)
  );

  return (
    <>
      <Input
        label={t('realBuiltSurface.label')}
        info={t('realBuiltSurface.infos')}
        handleChange={handleCaseRBSChange}
        color={
          caseRealBuiltSurfaceIsValid(projectProjection, caseProjection) ? undefined : 'orange'
        }
        name="realBuiltSurface"
        suffix="m&#178;"
        style={{ marginTop: '3px' }}
        type="number"
        min={0}
        max={10000}
        step={10}
        value={
          caseProjection.surfaces.realBuiltSurface
            ? roundForSurfacesInputs(caseProjection.surfaces.realBuiltSurface.value)
            : undefined
        }
        placeholder={roundForSurfacesInputs(
          caseProjection.projectedSurfaces.realBuiltSurface.value
        )}
        disabled={editingStage === CaseEditingStageName.Distribution}
      />
      <Note
        style={{
          borderRadius: '0 0 3px 3px',
          marginTop: '-5px',
          padding: '8px 6px 4px 6px'
        }}>
        <div onClick={() => handleCaseRBSChange(minRbs)}>min = {minRbs + 'm\u00B2'}</div>
        <div onClick={() => handleCaseRBSChange(maxRbs)}>max = {maxRbs + 'm\u00B2'}</div>
      </Note>
    </>
  );
};
