import * as React from 'react';
import { ChangeEvent } from 'react';

interface TextInputProps {
  disabled?: boolean;
  name: string;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  type: 'text' | 'password';
  value?: string | number;
  style?: React.CSSProperties;
  placeholder?: string;
  tabIndex?: number;
}

export const TextInput = ({
  disabled,
  name,
  handleChange,
  type,
  value,
  style,
  placeholder,
  tabIndex
}: TextInputProps) => (
  <input
    disabled={disabled}
    id={name}
    name={name}
    onChange={handleChange}
    type={type}
    value={value}
    style={style}
    autoComplete="off"
    placeholder={placeholder}
    tabIndex={tabIndex}
    readOnly={!handleChange}
  />
);
