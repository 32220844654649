import { DrawCreateEvent, DrawUpdateEvent } from '@mapbox/mapbox-gl-draw';
import { createFloorSpaceFeature } from '../../../domain/specification/floorSpace/queries/floorSpaceFeature/createFloorSpaceFeature';
import { store } from '../../../store/configureStore';
import {
  floorSpaceAdded,
  floorSpaceChanged,
  removeFloorSpace
} from '../../../store/actions/floorSpace.actions';
import { resizePolygon } from '../utils/polygon/resizePolygon';
import { selectProject } from '../../../store/selectors/project';
import { State } from '../../../store/reducers';
import { getFloorSpaceInProjectFromFloorSpaceId } from '../../../domain/core/queries/getCaseIdFromFloorSpaceId';
import { FloorSpaceFeature } from '../../../domain/specification/floorSpace/FloorSpaceFeature';
import { FireEvents } from '../types';
import { DeleteDrawPayload, ResizeEventPayload } from './draw.events';
import { DrawRepository, FeaturePolygonWithId, FeatureWithId } from './draw.repository';
import { DrawHelperServices } from '../domain/drawHelper/drawHelper.services';
import { ModePhase } from './mode';
import { translateCoordinatesToNewCenter } from '../utils/polygon/translatePolygonOnPoint';
import { getCenter } from '../utils/geometry/getCenter';
import { createPolygon } from '../utils/polygon/polygon';
import { MapElementsToDraw } from '../../../store/selectors/map/mapElementsToDraw.selector';
import { isFloorSpace } from '../domain/floorSpace/floorSpace.model';

export class DrawServices {
  drawRepo: DrawRepository;
  drawHelperServices: DrawHelperServices;
  updateFacades: (floorSpace: FloorSpaceFeature) => void;

  constructor(
    drawRepo: DrawRepository,
    updateFacades: (floorSpace: FloorSpaceFeature) => void,
    drawHelperServices: DrawHelperServices
  ) {
    this.drawRepo = drawRepo;
    this.updateFacades = updateFacades;
    this.drawHelperServices = drawHelperServices;
  }

  async create(e: DrawCreateEvent) {
    const pendingDrawHelper = this.drawHelperServices.findPendingDrawHelper();
    const polygon = e.features[0] as FeaturePolygonWithId;
    if (pendingDrawHelper) {
      polygon.properties.drawHelper = this.drawHelperServices.linkWithFeature(
        pendingDrawHelper,
        polygon
      );
    }

    const feature = createFloorSpaceFeature(polygon);
    store.dispatch(floorSpaceAdded(feature));
    const project = selectProject(store.getState() as State);
    if (!project) return;
    const floorSpaceFeature = getFloorSpaceInProjectFromFloorSpaceId(project, feature.id);
    if (!isFloorSpace(floorSpaceFeature)) return;
    this.drawRepo.create(floorSpaceFeature);
    this.updateFacades(floorSpaceFeature);
    store.dispatch(floorSpaceChanged(floorSpaceFeature));
  }

  delete({ features, drawHelperMode }: DeleteDrawPayload) {
    this.drawRepo.removeFacade(features);
    const feature = features[0];
    this.drawRepo.draw.delete(feature.id);

    if (this.drawHelperServices.isLinkedFeature(feature)) {
      drawHelperMode && this.drawHelperServices.unLinkFeature(feature.id);
      !drawHelperMode && this.drawHelperServices.deleteByFeatureId(feature.id);
    }
    store.dispatch(removeFloorSpace(feature.id));
  }

  update(e: DrawUpdateEvent & ModePhase) {
    const polygon = e.features[0] as FeaturePolygonWithId;
    if (!polygon) {
      console.error('No polygon');
      return;
    }

    const feature = createFloorSpaceFeature(polygon);
    store.dispatch(floorSpaceChanged(feature));
    this.drawRepo.updateProperties(feature);
    this.updateFacades(feature);
  }

  reInitFeatures(floorSpaceState: MapElementsToDraw) {
    floorSpaceState.floorSpaceFeatures.forEach((floorSpace) => {
      this.updateFacades(floorSpace);
      if (floorSpace.properties.shape?.lock) {
        this.lockFeature(floorSpace.id);
      }
    });
  }

  lockFeature(featureId: string) {
    this.drawHelperServices.lockFeature(featureId);
  }

  resizeDraw({ payload }: FireEvents<ResizeEventPayload>) {
    if (!payload.selectedFeatures || payload.selectedFeatures?.features.length === 0)
      throw new Error('Aucun polygon sélectionné');
    const polygon = payload.selectedFeatures.features[0] as FeaturePolygonWithId;

    const coordinates = polygon.geometry?.coordinates[0];
    const { coordinates: resizedCoordinates, ratio } = resizePolygon(coordinates, payload.size);
    const movedPolygon = translateCoordinatesToNewCenter(
      resizedCoordinates,
      getCenter(coordinates)
    );
    const feature = createFloorSpaceFeature({
      ...polygon,
      geometry: createPolygon(movedPolygon)
    });

    store.dispatch(floorSpaceChanged(feature));
    this.drawRepo.reCreate(feature);
    this.updateFacades(feature);

    if (this.drawHelperServices.isLinkedFeature(feature)) {
      this.drawHelperServices.resize(ratio, feature);
    }
  }

  updateAllDraw() {
    const featureCollection = this.drawRepo.findAll();
    featureCollection.features.forEach((feature) => {
      if (!feature.id) return;
      this.drawRepo.updateProperties(feature as FeatureWithId);
    });
  }
}
