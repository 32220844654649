import { ProjectProjection } from '../../../../../projection/project/ProjectProjection';
import { CaseProjection } from '../../../../../projection/cases/CaseProjection';
import { Surface } from '../../../../Surface';
import {
  DEFAULT_RBS_TO_CBS_RATIO,
  MAXIMUM_PROJECT_CBS
} from '../../../../../../constants/appConstants';
import { getProjectSumOfCasesRealBuiltSurfaces } from '../../../../project/queries/cases/getProjectSumOfCasesRealBuiltSurfaces';
import { getProjectSumOfCasesTechnicalSectionsSurfaces } from '../../../../project/queries/cases/getProjectSumOfCasesTechnicalSectionsSurfaces';

export const getCaseRealBuiltSurfaceMaximum = (
  projectProjection: ProjectProjection,
  caseProjection: CaseProjection
): number => {
  const cbs = (caseProjection.projectedSurfaces.cuttedBuiltSurface as Surface).value;
  const sfs = (caseProjection.projectedSurfaces.surfaceForSale as Surface).value;
  const rbs = (caseProjection.projectedSurfaces.realBuiltSurface as Surface).value;
  const sumOfCasesTechnicalSectionsSurfaces =
    getProjectSumOfCasesTechnicalSectionsSurfaces(projectProjection);
  const cbsToSfsRatio = sfs !== 0 && cbs !== 0 ? sfs / cbs : 0;
  return (
    MAXIMUM_PROJECT_CBS / DEFAULT_RBS_TO_CBS_RATIO +
    (sumOfCasesTechnicalSectionsSurfaces
      ? sumOfCasesTechnicalSectionsSurfaces / (cbsToSfsRatio * DEFAULT_RBS_TO_CBS_RATIO)
      : 0) -
    getProjectSumOfCasesRealBuiltSurfaces(projectProjection) +
    rbs
  );
};
