import { CaseGranulometry } from '../../CaseGranulometry';
import { getLodgmentSectionHeatingSystem } from '../../../sections/queries/heatingSystem/getLodgmentSectionHeatingSystem';
import { isHeatingSystemACollectiveHeatPumpOnGroundWaterHeating } from '../../../../specification/lodgmentTypes/HeatingSystem';

export const hasCaseLodgmentsWithCollectiveHeatPumpOnGroundWaterHeating = (
  caseGranulometry: CaseGranulometry
): boolean =>
  caseGranulometry.initialSpecifications.distribution.some((distributedLodgmentSection) =>
    isHeatingSystemACollectiveHeatPumpOnGroundWaterHeating(
      getLodgmentSectionHeatingSystem(distributedLodgmentSection)
    )
  );
