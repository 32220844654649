import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectCurrentCaseGrossFloorSurfaceEff } from '../../../../store/selectors/granulometry/currentCase/grossFloorSurfaceEff.selector';
import { Input } from '../../../ui/input/Input';
import { roundForSurfacesInputs } from '../../../../utils/round/roundForSurfacesInputs';

export const CaseGrossFloorSurfaceEffInput = () => {
  const grossFloorSurfaceEff = useSelector(selectCurrentCaseGrossFloorSurfaceEff);
  const { t } = useTranslation();
  return (
    <Input
      label={t('grossFloorSurfaceEff.label')}
      info={t('grossFloorSurfaceEff.infos')}
      name="caseGrossFloorSurfaceEff"
      suffix="m&#178;"
      style={{ marginTop: '3px' }}
      type="number"
      value={grossFloorSurfaceEff ? roundForSurfacesInputs(grossFloorSurfaceEff.value) : ''}
      disabled={true}
    />
  );
};
