import { APP_COLOR } from '../../../../constants/appConstants';
import { getSectionName, Section, SectionDrawn } from '../Section';
import i18next from 'i18next';
import { getDefaultCasePropertyValue } from '../../../specification/cases/queries/get/properties/getDefaultCasePropertyValue';
import { CirculationName } from './CirculationSection';
import { SectionWithTheoreticalSurface } from '../SectionWithTheoreticalSurface';
import { ELEVATOR_SHAFT_ICON } from './elevatorShaft';
import { SectionWithWidth } from '../SectionWithWidth';
import { SectionWithLength } from '../SectionWithLength';

interface ElevatorShaftInfraProps {
  surface: number;
  length: number;
  width: number;
  doorHeight: number;
  doorWidth: number;
  group: string;
}

export interface ElevatorShaftInfraSection
  extends Section,
    SectionDrawn,
    SectionWithLength,
    SectionWithWidth,
    SectionWithTheoreticalSurface {
  name: CirculationName.ElevatorShaftInfra;
  doorHeight: number;
  doorWidth: number;
}

const elevatorShaftInfra = ({
  surface,
  length,
  width,
  doorHeight,
  doorWidth,
  group
}: ElevatorShaftInfraProps): ElevatorShaftInfraSection => ({
  // Section
  id: 'NOID',
  name: CirculationName.ElevatorShaftInfra,
  title: i18next.t('elevatorShaftInfra.title'),
  type: 'circulation',
  displayedSurface: surface,
  // SectionDrawn
  surface,
  color: APP_COLOR,
  icon: ELEVATOR_SHAFT_ICON,
  group,
  // SectionWithTheoreticalSurface
  theoreticalSurface: surface,
  defaultTheoreticalSurface:
    getDefaultCasePropertyValue('elevatorShaftWidth') *
    getDefaultCasePropertyValue('elevatorShaftLength'),
  // ElevatorShaftInfraSection
  length,
  width,
  doorHeight,
  doorWidth
});

export default elevatorShaftInfra;

export const isSectionAElevatorShaftInfra = (
  section: Section
): section is ElevatorShaftInfraSection =>
  getSectionName(section) === CirculationName.ElevatorShaftInfra;
