import mapbox from './config/mapboxgl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Dispatch } from 'redux';
import { initZoom } from './config/handleZoom';
import { MapElementsToDraw } from '../../store/selectors/map/mapElementsToDraw.selector';
import getCloserFeatureFromPoint from './utils/geometry/getCloserFeatureFromPoint';
import { selectParcel } from './domain/parcel/selectParcel';
import { drawEvents } from './mapboxDraw/draw.events';
import { reInit } from './reInit';
import { Feature, Polygon } from 'geojson';
import * as React from 'react';
import { addOrRemoveParcelAction } from '../../store/actions/parcels.actions';
import { getMapboxDrawOptions, getMapboxOptions } from './config/options';
import { Position } from '@turf/helpers/dist/js/lib/geojson';
import { initMapControl } from './control';
import mapboxgl from 'mapbox-gl';
import { drawHelperEvents } from './domain/drawHelper/drawHelper.events';
import { DrawRepository } from './mapboxDraw/draw.repository';
import { updateMarkerFacades } from './domain/facades/marker/updateMarkerFacades';
import { MapRepository } from './map/map.repository';
import { MapServices } from './map/map.services';
import { DrawHelperServices } from './domain/drawHelper/drawHelper.services';
import { DrawServices } from './mapboxDraw/draw.services';
import { getCustomModes } from './mapboxDraw/mode';
import { selectFloorSpace } from '../../store/actions/floorSpace.actions';
import { FloorSpaceFeatureId } from '../../domain/specification/floorSpace/FloorSpaceFeature';

export const initEvents = (map: mapboxgl.Map, draw: MapboxDraw) => {
  const drawRepo = new DrawRepository(map, draw);
  const updateFacades = updateMarkerFacades(map);
  const mapRepo = new MapRepository(map);
  const mapServices = new MapServices(mapRepo);
  const drawHelperServices = new DrawHelperServices(mapServices, drawRepo);
  const drawServices = new DrawServices(drawRepo, updateFacades, drawHelperServices);

  drawHelperEvents(map, drawHelperServices);
  drawEvents(map, drawServices);
};

export const initMapBox = (
  mapContainer: React.MutableRefObject<HTMLDivElement>,
  location: Position,
  dispatch: Dispatch,
  floorSpaceState: MapElementsToDraw,
  caseLabel: string | undefined
) => {
  const map =
    location && (new mapbox.Map(getMapboxOptions(mapContainer, location)) as mapboxgl.Map);
  const draw: MapboxDraw = new MapboxDraw(
    getMapboxDrawOptions(
      getCustomModes((id: FloorSpaceFeatureId) => dispatch(selectFloorSpace(id)), MapboxDraw.modes),
      caseLabel
    )
  );

  initMapControl(map, draw, dispatch);

  if (floorSpaceState.isMapInitialized) {
    return reInit(draw, map, dispatch, floorSpaceState, caseLabel);
  }

  map.on('load', () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    map.once('sourcedata', 'parcelsFillLayer', (e: { features: Feature<Polygon>[] }) => {
      map.addSource('mapbox-dem', {
        type: 'raster-dem',
        url: 'mapbox://mapbox.terrain-rgb',
        tileSize: 512,
        maxzoom: 14
      });
      map.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 });
      const closerParcel = getCloserFeatureFromPoint(e.features, location);
      dispatch(addOrRemoveParcelAction(selectParcel(map, closerParcel)));
      initZoom(map);
    });
  });

  initEvents(map, draw);

  return { map, draw };
};
