import { CaseGranulometry } from '../../../../granulometry/cases/CaseGranulometry';

export const updateCaseGranulometryTopLevelsCount = (
  caseGranulometry: CaseGranulometry,
  topLevelsCount: number
): CaseGranulometry => ({
  ...caseGranulometry,
  initialSpecifications: {
    ...caseGranulometry.initialSpecifications,
    projectedTopLevelsCount: topLevelsCount
  }
});
