import * as React from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';
import { TopLevelSurfaceName } from '../../../domain/granulometry/cases/queries/surfaces/getCaseTopLevelsSurfaces';
import { Column1 } from './TotalTopLevelLines/Column1';
import { Column2 } from './TotalTopLevelLines/Column2';
import { Column3 } from './TotalTopLevelLines/Column3';
import { Column4 } from './TotalTopLevelLines/Column4';
import { Column4Enlarged } from './TotalTopLevelLines/Column4/Column4Enlarged';

interface TotalTopLevelLinesProps {
  isToolboxEnlarged: boolean;
  selectedLevelSurface: TopLevelSurfaceName;
}

export const TotalTopLevelLines = ({
  isToolboxEnlarged,
  selectedLevelSurface
}: TotalTopLevelLinesProps) => (
  <div className="line total-top-level-line">
    <Column1 />
    <ScrollSyncPane>
      <Column2 />
    </ScrollSyncPane>
    <Column3 />
    {isToolboxEnlarged ? (
      <Column4Enlarged />
    ) : (
      <Column4 selectedLevelSurface={selectedLevelSurface} />
    )}
  </div>
);
