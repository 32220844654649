import { ConnectedRouter } from 'connected-react-router';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';
import 'infra/sentry';
import { history, persistor, store } from './store/configureStore';
import './i18n';
import './style/css/style.css';
import { DefaultFallback } from './DefaultFallback';
import { App as BareApp } from './App';

Error.stackTraceLimit = Infinity;

const loadingGate = <div>Chargement</div>;

const App = () => (
  <Provider store={store}>
    <PersistGate loading={loadingGate} persistor={persistor}>
      <ConnectedRouter history={history}>
        <BareApp />
      </ConnectedRouter>
    </PersistGate>
  </Provider>
);

const render = () => {
  ReactDOM.render(
    <Sentry.ErrorBoundary showDialog fallback={DefaultFallback}>
      <App />
    </Sentry.ErrorBoundary>,
    document.getElementById('root') as HTMLElement
  );
};

render();
export { FloorSpaceProperties } from './domain/specification/floorSpace/FloorSpaceFeature';
