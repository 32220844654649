import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { LevelGranulometry, LevelGranulometryFullFilled } from '../../../LevelGranulometry';
import { getLevelRealBuiltSurfaceEff } from '../../surfaces/getLevelRealBuiltSurfaceEff';
import { getTopLevelDefaultWidth } from './getTopLevelDefaultWidth';
import { hasCaseGranulometryDrawnFloorSpaceFeature } from '../../../../cases/queries/has/hasCaseGranulometryDrawnFloorSpaceFeature';
import { getTopLevelGrossFloorSurfaceEff } from '../surfaces/grossFloorSurfaces/getTopLevelGrossFloorSurfaceEff';

export const getTopLevelDefaultLength = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled | LevelGranulometry
): number =>
  (hasCaseGranulometryDrawnFloorSpaceFeature(caseGranulometry)
    ? getTopLevelGrossFloorSurfaceEff(caseGranulometry, levelGranulometry)
    : getLevelRealBuiltSurfaceEff(caseGranulometry, levelGranulometry)) /
  getTopLevelDefaultWidth(caseGranulometry, levelGranulometry);
