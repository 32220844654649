import { getDefaultUnitConvertedPropertyValue } from '../../../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getTopLevelInternalInsulationThickness = (
  caseGranulometry: CaseGranulometry
): number => {
  const internalInsulationRate = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'internalInsulationRate'
  ) as number;
  const internalInsulationThickness = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'internalInsulationThickness'
  ) as number;
  return internalInsulationRate * internalInsulationThickness;
};
