import { CaseGranulometry } from '../../CaseGranulometry';
import { getLodgmentSectionHeatingSystem } from '../../../sections/queries/heatingSystem/getLodgmentSectionHeatingSystem';
import { isHeatingSystemACollectiveGasHeating } from '../../../../specification/lodgmentTypes/HeatingSystem';

export const hasCaseLodgmentsWithCollectiveGasHeating = (
  caseGranulometry: CaseGranulometry
): boolean =>
  caseGranulometry.initialSpecifications.distribution.some((distributedLodgmentSection) =>
    isHeatingSystemACollectiveGasHeating(
      getLodgmentSectionHeatingSystem(distributedLodgmentSection)
    )
  );
