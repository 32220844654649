import { FloorSpaceFeature } from '../../FloorSpaceFeature';
import { getArea } from '../../../../../services/mapBox/utils/geometry/getArea';
import { getPerimeter } from '../../../../../services/mapBox/utils/geometry/getPerimeter';
import { getCenter } from '../../../../../services/mapBox/utils/geometry/getCenter';
import { getFacadeSpecifications } from '../facadeSpecification/getFacadeSpecifications';
import { FeaturePolygonWithId } from '../../../../../services/mapBox/mapboxDraw/draw.repository';

export const createFloorSpaceFeature = (
  polygon: FeaturePolygonWithId | FloorSpaceFeature
): FloorSpaceFeature => {
  const points = polygon.geometry.coordinates[0];
  const facades = getFacadeSpecifications(points);

  return {
    ...polygon,
    properties: {
      ...polygon.properties,
      area: getArea(polygon),
      perimeter: getPerimeter(facades),
      facades,
      center: getCenter(points),
      name: polygon.properties?.name || '',
      origin: polygon.properties?.origin || points[0]
    }
  };
};
