import * as React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import PanelTitle from '../PanelTitle';
import { Button } from '../../../../components/ui/Button';
import { Card } from '../../../../components/ui/Card';
import { CardsList } from '../../../../components/ui/CardsList';
import { DataType, dataTypes } from '../../../../domain/DataType';
import { FormGroup } from '../../../../components/ui/FormGroup';
import { Nomenclature, UNTEC_NOMENCLATURE_ID } from '../../../../domain/Nomenclature';
import { Note } from '../../../../components/ui/Note';
import { Option, Select } from '../../../../components/ui/Select';
import { SpecialLabel } from '../../../../components/ui/SpecialLabel';
import { downloadComEnv } from './cstb/downloadComEnv';
import { makeExcelJson } from '../../../../api/excel/makeExcelJson';
import { downloadExcel } from './excel/downloadExcel';
import { selectComEnvJson } from '../../../../store/selectors/deliverables/comEnvJson.selector';
import { selectContributeursReport } from '../../../../store/selectors/contributeursReport.selector';
import { selectLotsReport } from '../../../../store/selectors/lotsReport.selector';
import { selectProjectId, selectProjectName } from '../../../../store/selectors/project';
import { downloadPerrenoud } from './perrenoud/downloadPerrenoud';
import { makePerrenoudJson } from '../../../../api/perrenoud/makePerrenoudJson';
import { selectUserLicenceType } from '../../../../store/selectors/user/userLicenceType.selector';
import { selectComEthJson } from '../../../../store/selectors/deliverables/comEthJson.selector';
import { downloadComEth } from './cstb/downloadComEth';
import { ReportEntry } from '../../../../domain/report/ReportEntry';
import { downloadVizcab } from './vizcab/downloadVizcab';
import { makeVizcabJson } from '../../../../api/vizcab/makeVizcabJson';
import { makeClimaWinJson } from '../../../../api/climaWin/makeClimaWinJson';
import { downloadClimaWin } from './climaWin/downloadClimaWin';
import { makeNoocoJson } from '../../../../api/nooco/makeNoocoJson';
import { downloadNooco } from './nooco/downloadNooco';
import { makePleiadesJson } from '../../../../api/pleiades/makePleiadesJson';
import { downloadPleiades } from './pleiades/downloadPleiades';
import {
  goToDeliverablesArchives,
  goToDeliverablesExport,
  goToDeliverablesExportReport
} from '../../../../store/actions/navigations.actions';
import { selectNomenclatures } from '../../../../store/selectors/nomenclatures.selector';
import { selectIsReportOutdated } from '../../../../store/selectors/report/isReportOutdated.selector';
import { getReportWithRecalculatedPrices } from './ReportTable/customPricesLists/getReportWithRecalculatedPrices';
import { selectProjectCurrentCustomPriceList } from '../../../../store/selectors/project/projectCurrentCustomPriceList.selector';
import { downloadSustainEcho } from './sustainEcho/downloadSustainEcho';
import { makeSustainEchoJson } from '../../../../api/sustainEcho/makeSustainEchoJson';
import { downloadCype } from './cype/downloadCype';
import { makeCypeJson } from '../../../../api/cype/makeCypeJson';

export const DeliverablesPanelInner = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const projectId = useSelector(selectProjectId) as string;
  const licenseType = useSelector(selectUserLicenceType);
  const isReportOutdated = useSelector(selectIsReportOutdated);

  const nomenclatures = [
    ...useSelector(selectNomenclatures),
    { id: UNTEC_NOMENCLATURE_ID, title: 'UNTEC', description: '', elements: [] }
  ] as Nomenclature[];

  const nomenclatureToOption = (nomenclature: Nomenclature): Option => ({
    label:
      nomenclature.title === 'UNTEC'
        ? nomenclature.title + ' (bientôt disponible)'
        : nomenclature.title,
    value: nomenclature.id,
    disabled: nomenclature.title === 'UNTEC'
  });

  const [selectedNomenclature, selectNomenclature] = React.useState<Nomenclature | undefined>(
    undefined
  );
  const selectNomenclatureId = (id: string) =>
    selectNomenclature(nomenclatures.find(R.propEq('id', id)));

  const [selectedDataType, selectDataType] = React.useState<DataType | undefined>(undefined);

  /*
  const [selectedNomenclatureElement, selectNomenclatureElement] = React.useState<
    NomenclatureElement | undefined
  >(undefined);
  const selectNomenclatureElementId = (id: string) =>
    selectNomenclatureElement(selectedNomenclature?.elements.find(R.propEq('id', id)));
  */

  const comEnv =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    process.env.GB_FEAT_DELIVERABLE_COMENV === 'true' ? useSelector(selectComEnvJson) : false;

  const comEth =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    process.env.GB_FEAT_DELIVERABLE_COMETH === 'true' ? useSelector(selectComEthJson) : false;

  const projectName = useSelector(selectProjectName) || '';

  // TODO : mettre handle click Perrenoud et XLSX Format ici
  const currentCustomPriceList = useSelector(selectProjectCurrentCustomPriceList);

  const contributeursReport = useSelector(selectContributeursReport) as ReportEntry[];
  const contributeursReportWithRecalculatedPrices =
    contributeursReport && currentCustomPriceList
      ? getReportWithRecalculatedPrices(contributeursReport, currentCustomPriceList, true)
      : contributeursReport;

  const lotsReport = useSelector(selectLotsReport) as ReportEntry[];
  const lotsReportWithRecalculatedPrices =
    lotsReport && currentCustomPriceList
      ? getReportWithRecalculatedPrices(lotsReport, currentCustomPriceList, true)
      : lotsReport;

  const [isExcelDownloading, setIsExcelDownloading] = React.useState<boolean>(false);

  if (!licenseType) {
    return null;
  }

  return (
    <CardsList className={'deliverables-panel'}>
      <PanelTitle title={t('Deliverables')} />
      <Tabs
        onSelect={(index) => {
          index
            ? dispatch(goToDeliverablesArchives(projectId))
            : dispatch(goToDeliverablesExport(projectId));
        }}>
        <Card>
          <TabList>
            <Tab>{t('Export Data')}</Tab>
            <Tab>{t('Archives')}</Tab>
          </TabList>
        </Card>
        <TabPanel>
          {!isReportOutdated ? (
            <>
              <Card>
                <SpecialLabel label={t('Reports')} />
                <FormGroup>
                  <div style={{ marginBottom: '5px' }}>
                    <label htmlFor="naming">{t('Naming')}</label>
                    <Select
                      id={'naming'}
                      options={nomenclatures?.map(nomenclatureToOption)}
                      handleChange={({ value }: Option) => {
                        selectNomenclatureId(value as string);
                      }}
                      value={selectedNomenclature && nomenclatureToOption(selectedNomenclature)}
                      disabled={!nomenclatures || nomenclatures.length === 0}
                      style={{ marginTop: '3px' }}
                      placeholder={t('Select a naming')}
                    />
                  </div>
                  <div style={{ marginBottom: '5px' }}>
                    <label htmlFor="dataTypes">{t('Data types')}</label>
                    <Select
                      id={'dataTypes'}
                      options={dataTypes.filter(
                        (dataType: DataType) =>
                          !(licenseType === 'Access' && dataType.value.match(/cost/))
                      )}
                      handleChange={({ value }: Option) => {
                        selectDataType(dataTypes.find((dataType) => dataType.value === value));
                      }}
                      value={selectedDataType}
                      disabled={!selectedNomenclature}
                      style={{ marginTop: '3px' }}
                      placeholder={t('Select a data type')}
                    />
                  </div>
                  <div style={{ marginTop: '8px' }}>
                    <Button
                      disabled={
                        selectedNomenclature === undefined || selectedDataType === undefined
                      }
                      content={t('Display') as string}
                      handleClick={() =>
                        selectedNomenclature?.id &&
                        dispatch(
                          goToDeliverablesExportReport(
                            projectId,
                            selectedNomenclature?.id,
                            selectedDataType?.value as string
                          )
                        )
                      }
                    />
                    {process.env.GB_FEAT_DELIVERABLE_XLSX === 'true' && (
                      <Button
                        style={{ marginLeft: '8px' }}
                        content={t('Download') + ' (' + t('XLSX Format') + ')'}
                        disabled={
                          selectedNomenclature === undefined ||
                          selectedDataType === undefined ||
                          isExcelDownloading
                        }
                        withLoader={isExcelDownloading}
                        withoutContent={isExcelDownloading}
                        handleClick={() => {
                          if (selectedNomenclature && selectedDataType) {
                            const excelJson = makeExcelJson(
                              selectedNomenclature.title === 'Lots'
                                ? lotsReportWithRecalculatedPrices
                                : contributeursReportWithRecalculatedPrices,
                              selectedDataType,
                              projectName
                            );
                            setIsExcelDownloading(true);
                            downloadExcel(excelJson, selectedDataType, projectName, () =>
                              setIsExcelDownloading(false)
                            );
                          }
                        }}
                      />
                    )}
                  </div>
                </FormGroup>
              </Card>
              {(process.env.GB_FEAT_DELIVERABLE_PERRENOUD === 'true' ||
                process.env.GB_FEAT_DELIVERABLE_VIZCAB === 'true' ||
                process.env.GB_FEAT_DELIVERABLE_SUSTAINECHO === 'true' ||
                process.env.GB_FEAT_DELIVERABLE_CLIMAWIN === 'true' ||
                process.env.GB_FEAT_DELIVERABLE_COMENV === 'true' ||
                process.env.GB_FEAT_DELIVERABLE_COMETH === 'true') && (
                <Card>
                  <SpecialLabel label={t('Partners exports')} />
                  <FormGroup>
                    <div style={{ marginTop: '3px' }}>
                      {process.env.GB_FEAT_DELIVERABLE_PERRENOUD === 'true' && (
                        <Button
                          content="Perrenoud"
                          style={{ marginRight: '8px', marginBottom: '8px' }}
                          handleClick={() => {
                            if (contributeursReportWithRecalculatedPrices) {
                              downloadPerrenoud(
                                makePerrenoudJson(contributeursReportWithRecalculatedPrices),
                                projectName
                              );
                            }
                          }}
                        />
                      )}
                      {process.env.GB_FEAT_DELIVERABLE_VIZCAB === 'true' && (
                        <Button
                          content="Vizcab"
                          style={{ marginRight: '8px', marginBottom: '8px' }}
                          handleClick={() => {
                            if (contributeursReportWithRecalculatedPrices) {
                              downloadVizcab(
                                makeVizcabJson(contributeursReportWithRecalculatedPrices),
                                projectName
                              );
                            }
                          }}
                        />
                      )}
                      {process.env.GB_FEAT_DELIVERABLE_SUSTAINECHO === 'true' && (
                        <Button
                          content="SustainEcho"
                          style={{ marginRight: '8px', marginBottom: '8px' }}
                          handleClick={() => {
                            if (contributeursReportWithRecalculatedPrices) {
                              downloadSustainEcho(
                                makeSustainEchoJson(contributeursReportWithRecalculatedPrices),
                                projectName
                              );
                            }
                          }}
                        />
                      )}
                      {process.env.GB_FEAT_DELIVERABLE_CLIMAWIN === 'true' && (
                        <Button
                          content="ClimaWin 2020"
                          style={{ marginRight: '8px', marginBottom: '8px' }}
                          handleClick={() => {
                            if (contributeursReportWithRecalculatedPrices) {
                              downloadClimaWin(
                                makeClimaWinJson(contributeursReportWithRecalculatedPrices),
                                projectName
                              );
                            }
                          }}
                        />
                      )}
                      {process.env.GB_FEAT_DELIVERABLE_COMENV === 'true' && comEnv && (
                        <Button
                          content="COMENV"
                          style={{ marginRight: '8px', marginBottom: '8px' }}
                          handleClick={() => {
                            downloadComEnv(comEnv, projectName);
                          }}
                        />
                      )}
                      {process.env.GB_FEAT_DELIVERABLE_COMETH === 'true' && comEth && (
                        <Button
                          content="COMETH"
                          style={{ marginRight: '8px', marginBottom: '8px' }}
                          handleClick={() => {
                            downloadComEth(comEth, projectName);
                          }}
                        />
                      )}
                      {process.env.GB_FEAT_DELIVERABLE_NOOCO === 'true' && (
                        <Button
                          content="Nooco"
                          style={{ marginRight: '8px', marginBottom: '8px' }}
                          handleClick={() => {
                            if (contributeursReportWithRecalculatedPrices) {
                              downloadNooco(
                                makeNoocoJson(contributeursReportWithRecalculatedPrices),
                                projectName
                              );
                            }
                          }}
                        />
                      )}
                      {process.env.GB_FEAT_DELIVERABLE_CYPE === 'true' && (
                        <Button
                          content="ELODIE by CYPE"
                          style={{ marginRight: '8px', marginBottom: '8px' }}
                          handleClick={() => {
                            if (contributeursReportWithRecalculatedPrices) {
                              downloadCype(
                                makeCypeJson(contributeursReportWithRecalculatedPrices),
                                projectName
                              );
                            }
                          }}
                        />
                      )}
                      {process.env.GB_FEAT_DELIVERABLE_PLEIADES === 'true' && (
                        <Button
                          content="Pleiades"
                          style={{ marginRight: '8px', marginBottom: '8px' }}
                          handleClick={() => {
                            if (contributeursReportWithRecalculatedPrices) {
                              downloadPleiades(
                                makePleiadesJson(contributeursReportWithRecalculatedPrices),
                                projectName
                              );
                            }
                          }}
                        />
                      )}
                    </div>
                  </FormGroup>
                </Card>
              )}
            </>
          ) : (
            <Card>
              <Note>
                Les données ne sont pas encore disponibles pour cette modélisation. Vous pouvez
                relancer un calcul du projet pour pouvoir les afficher.
                <br />
                <br />
                Pour revenir à votre modélisation précédemment calculée vous pouvez utiliser la
                fonction &quot;annuler la saisie&quot; autan de fois que nécessaire.
              </Note>
            </Card>
          )}
        </TabPanel>
        <TabPanel>
          <Scrollbars
            autoHide={true}
            autoHideTimeout={1000}
            autoHideDuration={200}
            autoHeight={true}
            autoHeightMin="100%"
            autoHeightMax="100%"
            style={{ width: '100%' }}
            renderTrackVertical={(properties) => (
              <div {...properties} className="track-vertical" />
            )}>
            <Note>Cet espace permettra de lister et de re-télécharger les exports réalisés.</Note>
          </Scrollbars>
        </TabPanel>
      </Tabs>
    </CardsList>
  );
};
