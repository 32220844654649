import { DRAW_HELPER_MOVING_FEATURE_STYLE_LAYER } from './drawHelper.model';
import { LoadImageOptions } from '../../map/map.repository';
import mapboxgl from 'mapbox-gl';
import { movingLayerFilterActive, movingLayerFilterInActive } from '../../styles/draw.style';

export const getParamsZIndexMovingLayer = (
  drawHelperLayerId: string
): { id: string; beforeId: string }[] => [
  { id: drawHelperLayerId, beforeId: 'gl-draw-polygon-fill-inactive.cold' },
  {
    id: DRAW_HELPER_MOVING_FEATURE_STYLE_LAYER + '-active.cold',
    beforeId: 'gl-draw-polygon-fill-inactive.cold'
  },
  {
    id: DRAW_HELPER_MOVING_FEATURE_STYLE_LAYER + '-inactive.cold',
    beforeId: 'gl-draw-polygon-fill-inactive.cold'
  },
  {
    id: DRAW_HELPER_MOVING_FEATURE_STYLE_LAYER + '-active.hot',
    beforeId: 'gl-draw-polygon-fill-inactive.cold'
  },
  {
    id: DRAW_HELPER_MOVING_FEATURE_STYLE_LAYER + '-inactive.hot',
    beforeId: 'gl-draw-polygon-fill-inactive.cold'
  }
];

export const getParamsAddRasterLayer = (
  loadImageOptions: Omit<LoadImageOptions, 'imagePath'>
): mapboxgl.RasterLayer => ({
  id: loadImageOptions.layerName,
  type: 'raster',
  source: loadImageOptions.sourceName,
  layout: {
    visibility: 'visible'
  },
  paint: {
    'raster-opacity': 1
  },
  metadata: loadImageOptions.metadata
});

export const toggleHideLayer =
  (setFilter: mapboxgl.Map['setFilter']) => (layerId: string, hide: boolean) => {
    const filter = hide ? ['==', 'user_display', true] : null;
    setFilter(layerId + '-inactive.cold', [...movingLayerFilterInActive, filter].filter(Boolean));
    setFilter(layerId + '-inactive.hot', [...movingLayerFilterInActive, filter].filter(Boolean));
    setFilter(layerId + '-active.cold', [...movingLayerFilterActive, filter].filter(Boolean));
    setFilter(layerId + '-active.hot', [...movingLayerFilterActive, filter].filter(Boolean));
  };
