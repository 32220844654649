import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { asPassword, Password, User } from '../../../../domain/User';
import { Card } from '../../../../components/ui/Card';
import { SpecialLabel } from '../../../../components/ui/SpecialLabel';
import { Window } from '../../../../components/ui/Window';
import { FormGroup } from '../../../../components/ui/FormGroup';
import { Input } from '../../../ui/input/Input';
import { Button } from '../../../../components/ui/Button';
import { Form } from '../../../../components/ui/Form';
import { getEditUserTriggerId } from '../../../../routes/toolboxPanels/triggers';
import { selectUserLicenceType } from '../../../../store/selectors/user/userLicenceType.selector';
import userState from '../../../../store/reducers/user.slice';
import { selectIsPasswordChanging } from '../../../../store/selectors/user/isPasswordChanging.selector';
import { usePrevious } from '../../../hooks/usePrevious.hook';
import { selectUser } from 'store/selectors/user/user.selector';

const UserWindow: React.FC = () => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const licenseType = useSelector(selectUserLicenceType);
  const passwordChanging = useSelector(selectIsPasswordChanging);

  const [password1, setPassword1] = React.useState<Password>(asPassword(''));
  const [password2, setPassword2] = React.useState<Password>(asPassword(''));
  const [isPasswordChanged, setIsPasswordChanged] = React.useState<boolean>(false);

  const previousPasswordChangingState = usePrevious(passwordChanging);

  React.useEffect(() => {
    if (!passwordChanging && previousPasswordChangingState) {
      setPassword1(asPassword(''));
      setPassword2(asPassword(''));
      setIsPasswordChanged(true);
    }
  });

  const dispatch = useDispatch();

  return (
    <Window title={t('Edit profile')} triggerId={getEditUserTriggerId()}>
      <Form>
        <Card>
          <SpecialLabel label="Informations" />
          <FormGroup>
            <div>
              <label htmlFor="username">{t('Username')}</label>
              <Input type="text" name="username" value={(user as User).userName} disabled={true} />
            </div>
          </FormGroup>
        </Card>
        <Card>
          <SpecialLabel label={t('License')} />
          <FormGroup>
            <div>
              <label htmlFor="license">{t('License type')}</label>
              <Input type="text" name="license" value={licenseType} disabled={true} />
            </div>
          </FormGroup>
        </Card>
        <Card>
          <SpecialLabel label={t('Password')} />
          <FormGroup>
            <div>
              <label htmlFor="password">{t('Password')}</label>
              <Input
                type="password"
                name="password"
                value={password1}
                handleChange={(event) => setPassword1(event.target.value as Password)}
              />
            </div>
            <div>
              <label htmlFor="repassword">{t('Repeat password')}</label>
              <Input
                type="password"
                name="repassword"
                value={password2}
                handleChange={(event) => setPassword2(event.target.value as Password)}
              />
            </div>
            <div>
              <Button
                disabled={!password1 || !password2 || password1 !== password2}
                size="small"
                appearance="outline"
                style={{ float: 'right', marginTop: '5px' }}
                content={t('Modify password')}
                handleClick={() => {
                  dispatch(userState.actions.changePassword({ newPassword: password1 }));
                }}
              />
              {password1 !== password2 && <>Les mots de passe ne correspondent pas</>}
              {!password1 && !password2 && isPasswordChanged && <>Mot de passe changé !</>}
            </div>
          </FormGroup>
        </Card>
      </Form>
    </Window>
  );
};

export default UserWindow;
