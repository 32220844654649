import { Epic, ofType } from 'redux-observable';
import { Action } from 'redux';
import { State } from '../reducers';
import { CREATE_NEW_PROJECT } from '../actions/createNewProject.action';
import { goToProject } from '../actions/navigations.actions';
import { map } from 'rxjs/operators';

export const goToProjectAfterInitializationEpic: Epic<Action, Action, State> = (actions$) =>
  actions$.pipe(
    ofType(CREATE_NEW_PROJECT),
    map(() => goToProject())
  );
