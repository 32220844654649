import { CaseGranulometry } from '../../../cases/CaseGranulometry';
import { LevelGranulometry } from '../../LevelGranulometry';
import { isHighestLevel } from '../is/isHighestLevel';
import { isGroundLevel } from '../is/isGroundLevel';
import { getCaseGranulometryDrawnFloorSpaceFeatureArea } from '../../../cases/queries/get/getCaseGranulometryDrawnFloorSpaceFeatureArea';
import { hasCaseGranulometryDrawnFloorSpaceFeature } from '../../../cases/queries/has/hasCaseGranulometryDrawnFloorSpaceFeature';
import { getTopLevelForcedGrossFloorSurfaceEff } from '../topLevels/surfaces/grossFloorSurfaces/getTopLevelForcedGrossFloorSurfaceEff';
import { roundWith2Decimal } from '../../../../../utils/round/roundWith2Decimal';
import { getPreviousUpperLevel } from '../getPreviousUpperLevels';
import { getTopLevelDistributedGrossFloorSurfaceEff } from '../topLevels/surfaces/grossFloorSurfaces/getTopLevelDistributedGrossFloorSurfaceEff';
import { isFirstTopLevel } from '../is/isFirstTopLevel';

export const mustHaveFacadeLinearsRelativeToDefaultWidthAndLength = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry
): boolean => {
  if (hasCaseGranulometryDrawnFloorSpaceFeature(caseGranulometry)) {
    if (isGroundLevel(levelGranulometry)) {
      // If the case has drawn floor space feature,
      // and the current level is ground level : return false
      return false;
    } else {
      const groundLevelDrawnGfsEff = getCaseGranulometryDrawnFloorSpaceFeatureArea(
        caseGranulometry
      ) as number;
      const levelForcedGfsEff = getTopLevelForcedGrossFloorSurfaceEff(
        caseGranulometry,
        levelGranulometry
      );
      // LEVEL IS FORCED :
      if (levelForcedGfsEff !== undefined) {
        const isForcedLevelGfsEffEqualGroundLevelGfsEff =
          roundWith2Decimal(levelForcedGfsEff as number) ===
          roundWith2Decimal(groundLevelDrawnGfsEff as number);
        if (isForcedLevelGfsEffEqualGroundLevelGfsEff) {
          // If the current level GFS EFF is forced and equal ground level GFS EFF,
          // ...and at least one of the previous levels isn't forced or not equal ground level GFS EFF : return true
          // OR ... and all the previous levels are forced and equal ground level GFS EFF : return false
          if (isFirstTopLevel(levelGranulometry)) {
            return false;
          }
          return !areEachPreviousLevelsEqualToGroundLevelGfsEff(
            caseGranulometry,
            levelGranulometry,
            groundLevelDrawnGfsEff
          );
        }
        // If the current level GFS EFF is forced and is NOT equal ground level GFS EFF : return true
        return true;
      } else {
        // LEVEL IS NOT FORCED
        const savedTopLevelsGfsEffFirstDistribution =
          caseGranulometry.savedTopLevelsGfsEffFirstDistribution
            ? caseGranulometry.savedTopLevelsGfsEffFirstDistribution.find(
                (l) => l.level === levelGranulometry.level
              )?.gfsEff
            : undefined;
        const levelDistributedGfsEff =
          savedTopLevelsGfsEffFirstDistribution ||
          (getTopLevelDistributedGrossFloorSurfaceEff(
            caseGranulometry,
            levelGranulometry
          ) as number);
        const isDistributedLevelGfsEffEqualGroundLevelGfsEff =
          roundWith2Decimal(levelDistributedGfsEff) === roundWith2Decimal(groundLevelDrawnGfsEff);
        if (isDistributedLevelGfsEffEqualGroundLevelGfsEff) {
          if (isFirstTopLevel(levelGranulometry)) {
            return false;
          }
          return !areEachPreviousLevelsEqualToGroundLevelGfsEff(
            caseGranulometry,
            levelGranulometry,
            groundLevelDrawnGfsEff
          );
        } else {
          if (isHighestLevel(caseGranulometry, levelGranulometry)) {
            // If the current level GFS EFF is not forced and is the highest level : return true
            return true;
          }
          // If the current level GFS EFF is not forced and is not the highest level or ground level : return true
          return true;
        }
      }
    }
  } else {
    // If the case hasn't drawn floor space feature : return true
    return true;
  }
};

const areEachPreviousLevelsEqualToGroundLevelGfsEff = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry,
  groundLevelDrawnGfsEff: number
) =>
  !getPreviousUpperLevel(caseGranulometry, levelGranulometry)
    .map((previousLevel) => {
      const savedTopLevelsGfsEffFirstDistribution =
        caseGranulometry.savedTopLevelsGfsEffFirstDistribution
          ? caseGranulometry.savedTopLevelsGfsEffFirstDistribution.find(
              (l) => l.level === previousLevel.level
            )?.gfsEff
          : undefined;
      const previousLevelDistributedGfsEff =
        savedTopLevelsGfsEffFirstDistribution ||
        (getTopLevelDistributedGrossFloorSurfaceEff(caseGranulometry, previousLevel) as number);
      if (
        roundWith2Decimal(previousLevelDistributedGfsEff) ===
        roundWith2Decimal(groundLevelDrawnGfsEff)
      ) {
        return true;
      }
      return false;
    })
    .some((bol) => bol === false);
