import * as R from 'ramda';
import { CaseGranulometry } from '../../cases/CaseGranulometry';
import { LevelGranulometry } from '../../levels/LevelGranulometry';
import { createBasementLevelCirculationLaneInfra } from '../../levels/queries/basementLevels/sections/create/createBasementLevelCirculationLaneInfra';
import { isSectionACarBoxInfra } from '../../sections/parkingSections/carBoxInfra';
import { isSectionACarParkInfra } from '../../sections/parkingSections/carParkInfra';
import { isSectionACirculationLaneInfra } from '../../sections/circulationSections/circulationLaneInfra';
import { isSectionABearing } from '../../sections/circulationSections/bearing';
import { isSectionASasInfra } from '../../sections/circulationSections/sasInfra';
import { getLevelFullFilledContent } from '../../levels/queries/content/getLevelFullFilledContent';

export const updateCirculationLaneInBasementLevel = (
  levelGranulometry: LevelGranulometry,
  caseGranulometry: CaseGranulometry
): LevelGranulometry => {
  const levelContent = getLevelFullFilledContent(levelGranulometry);
  const currentCirculationLane = levelContent.find(isSectionACirculationLaneInfra);
  if (currentCirculationLane) {
    const newCirculationLane = createBasementLevelCirculationLaneInfra(
      caseGranulometry,
      [
        ...levelContent.filter(isSectionACarBoxInfra),
        ...levelContent.filter(isSectionACarParkInfra)
      ],
      levelContent.find(isSectionABearing)?.displayedSurface || 0,
      levelContent.find(isSectionASasInfra)?.displayedSurface || 0,
      caseGranulometry.initialSpecifications.basementLevelsData.find(
        (l) => l.level === levelGranulometry.level
      )?.realBuiltSurface,
      levelGranulometry.basementSurfaceBeforeSpecifiedRBS
    );
    if (newCirculationLane) {
      currentCirculationLane.surface = newCirculationLane.surface;
      currentCirculationLane.displayedSurface = newCirculationLane.displayedSurface;
      currentCirculationLane.width = newCirculationLane.width;
    } else {
      levelGranulometry.content = R.reject(isSectionACirculationLaneInfra, levelContent);
    }
  }

  return levelGranulometry;
};
