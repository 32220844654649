import { getCaseDataInitialLodgmentsData } from './getCaseDataInitialLodgmentsData';
import { getCaseDataInitialLevelsData } from './getCaseDataInitialLevelsData';
import { LevelSFSAdjusment } from './fillCaseLevels';
import { CaseFormDataLegacy, TopLevelDataLegacy } from '../caseFormData';
import { LodgmentType } from '../../specification/lodgmentTypes/LodgmentType';
import { LodgmentSection } from '../../granulometry/sections/lodgmentSections/LodgmentSection';
import { LodgmentTypeSpecification } from '../../specification/lodgmentTypes/LodgmentTypeSpecification';
import { getSectionTheoreticalSurface } from '../../granulometry/sections/queries/surfaces/getSectionTheoreticalSurface';
import { roundForSurfacesInputs } from '../../../utils/round/roundForSurfacesInputs';

export interface FilledLevelsData extends TopLevelDataLegacy {
  surfaceForSale: number;
  remainingSurfaceForSale: number;
  filledSurface: number;
  lodgmentCount: number;
  lodgments: { [key in LodgmentType]: (LodgmentSection & LodgmentTypeSpecification)[] };
  parkingRate?: number;
}

/* Get the filled levels data in the given case */
export const getCaseFilledLevelsData = (
  caseData: CaseFormDataLegacy,
  levelsSFSAdjustments?: LevelSFSAdjusment[]
) => {
  const filledLodgmentsData = getCaseDataInitialLodgmentsData(caseData);
  const filledLevelsData = getCaseDataInitialLevelsData(
    caseData,
    levelsSFSAdjustments
  ) as FilledLevelsData[];

  // For each lodgment type
  for (let h = 0; h < filledLodgmentsData.length; h++) {
    if (filledLodgmentsData[h].count !== 0) {
      const lodgmentType = filledLodgmentsData[h].type;
      // console.log('Fill case with lodgment type :', lodgmentType);
      const maxTryLoop = 20; // TODO : DURTY !
      for (let i = 0; i <= maxTryLoop; i++) {
        if (filledLodgmentsData[h].remaining > 0) {
          // For each level
          for (let j = 0; j < filledLevelsData.length; j++) {
            // We skip basement levels
            if (filledLevelsData[j].level < 0) continue;
            // console.log('Level :', filledLevelsData[j].level);
            const lodgmentDefaultData = { ...filledLodgmentsData[h].defaultData };
            const lodgmentTheoricalSurface = getSectionTheoreticalSurface(lodgmentDefaultData);
            if (
              lodgmentTheoricalSurface <=
              roundForSurfacesInputs(filledLevelsData[j].remainingSurfaceForSale)
            ) {
              // console.log('Add a ' + lodgmentType + '!');
              // Add default theoreticalSurface entry in cloned default data
              // lodgmentDefaultData.theoreticalSurface = lodgmentDefaultData.surface;
              // Add the lodgment in level lodgments data
              filledLevelsData[j].lodgments[lodgmentType].push(lodgmentDefaultData);
              // Remove lodgment surface form the current level surface left
              filledLevelsData[j].remainingSurfaceForSale -= lodgmentTheoricalSurface;
              // console.log(
              //  'Remaining surface for sale :',
              //  filledLevelsData[j].remainingSurfaceForSale.toFixed(2) + 'm2'
              // );
              // Increment lodgment surface to the current level surface filled value
              filledLevelsData[j].filledSurface += lodgmentTheoricalSurface;
              // Increment lodgment count
              filledLevelsData[j].lodgmentCount++;
              // Remove 1 from remaining lodgment
              filledLodgmentsData[h].remaining--;
              // console.log('Remaining ' + lodgmentType + ':', filledLodgmentsData[h].remaining);
              if (filledLodgmentsData[h].remaining === 0) {
                // console.log('No more remaining ' + lodgmentType + '!');
                break;
              }
            }
          }
        }
      }
    }
  }

  return filledLevelsData;
};
