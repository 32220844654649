import classNames from 'classnames';
import * as React from 'react';
import { APP_COLOR, GROUND_COLOR } from '../../../constants/appConstants';
import { GoBuildIcons, iconObjectIcons } from '../../../utils/icons/iconObjectIcons';
import { IconFont } from './IconFont';
import { uniqId } from '../../../infra/uniqId';

export interface IconObjectProps {
  /** float: the background opacity between 0 and 1 (default 0.2) */
  backgroundOpacity?: number;
  /** boolean: clickable if the icon is clickable */
  clickable?: boolean;
  /** string: the icon color */
  color?: string;
  /** string: the icon secondary color */
  color2?: string;
  /** string: the custom css properties to add for the icon */
  customCss?: string;
  /** string: the text to display if type='custom' (max 12 characters). */
  customText?: string;
  /**
   * string: the text position in the icon
   * (center|top center|top left|top right|bottom center|bottom left|bottom right).
   */
  customTextPosition?:
    | 'center'
    | 'top center'
    | 'top left'
    | 'top right'
    | 'bottom center'
    | 'bottom left'
    | 'bottom right';
  /** boolean: if we need a css colorization of the result. */
  dev?: boolean;
  /** boolean: if the icon is initialy disabled */
  disabled?: boolean;
  /** string: the name of the icon if type='object|menu' */
  iconName?: GoBuildIcons; // GoBuildIcons;
  /** string: a text box displayed on the bottom right corner */
  label?: string;
  /** onclick: the event on click */
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  /** onMouseEnter: the event on mouseEnter */
  onMouseEnter?: (event: React.MouseEvent<HTMLElement>) => void;
  /** onMouseLeave: the event on mouseLeave */
  onMouseLeave?: (event: React.MouseEvent<HTMLElement>) => void;
  /** boolean: if the icon is initialy selected */
  selected?: boolean;
  /** string: the icon stroke style (plain|dashed|opened) */
  strokeStyle?: 'plain' | 'dashed' | 'opened';
  /** mixed: (boolean|string) the icon title attribute (note : maybe use as tooltip) */
  title?: any;
  /** string: the icon type (custom|object|menu) */
  type?: 'custom' | 'object' | 'menu';
  /** string: a unique id attributed to the wrapper of the result */
  id?: string;
  className?: string;
}

export const IconObject = (props: IconObjectProps) => {
  const type = props.type ? props.type : 'custom';
  const customText = props.customText ? props.customText : null;
  const customTextPosition = props.customTextPosition ? props.customTextPosition : 'center';
  const customCss = props.customCss ? props.customCss : null;
  const iconName = props.iconName ? props.iconName : null;
  const color = props.color ? props.color : APP_COLOR;
  const color2 = props.color2 ? props.color2 : GROUND_COLOR;
  const backgroundOpacity = props.backgroundOpacity ? props.backgroundOpacity : 0.2;
  const id = props.id || 'iconObject' + uniqId();
  const strokeStyle = props.strokeStyle ? props.strokeStyle : 'plain';
  const label = props.label ? props.label : null;
  const title = props.title ? props.title : null;
  const clickable = props.clickable || props.clickable === false ? props.clickable : true;
  const selected = props.selected ? props.selected : false;
  const disabled = props.disabled ? props.disabled : false;

  // CSS
  let strokeCss = '';
  if (strokeStyle === 'dashed') {
    strokeCss = 'stroke:' + color + ';stroke-dasharray:2,3;';
  } else if (strokeStyle === 'opened') {
    strokeCss = 'fill:' + color + ';';
  } else {
    strokeCss = 'stroke:' + color + ';';
  }

  const iconObjectBackgroundStyle: any = {
    fill: color,
    opacity: backgroundOpacity
  };
  // $exclusiveCss is a private constiable agregatting the css properties to stylize this icon only.
  let exclusiveCss =
    '#' +
    id +
    '.iconObjectClickable:hover .iconObjectBackground { opacity:' +
    (backgroundOpacity + 0.2) +
    '; }' +
    ' #' +
    id +
    ' .iconObjectStroke { ' +
    strokeCss +
    ' }' +
    ' #' +
    id +
    ' .iconObjectLabel { border-color:' +
    color +
    '; }' +
    ' #' +
    id +
    ' .iconSecondBackground, #' +
    id +
    '.iconObjectSelected[data-type="menu"] .iconBackground, #' +
    id +
    '.iconObjectSelected[data-type="menu"] .iconSecondBackground { fill:' +
    color2 +
    '; }' +
    ' #' +
    id +
    ' .iconObjectIcon *:not(.iconBackground):not(.iconSecondBackground) { fill:' +
    color +
    '; }';
  // $customCss is added at the end of the exclusive css with the addition of the icon id on every properties begin.
  if (customCss !== null) {
    exclusiveCss += customCss.replace(/([[\]"=\-:()#.a-zA-Z0-9\s]*\s{)/gm, '#' + id + ' $1');
  }
  const css = '<style>' + exclusiveCss + '</style>';
  // HTML

  const iconObjectClass = classNames(
    'iconObject',
    {
      iconObjectClickable: clickable,
      iconObjectSelected: selected,
      iconObjectDisabled: disabled
    },
    props.className
  );

  const rest: { title?: string } = {};
  if (title) {
    rest.title = title;
  }

  let goodIconName: GoBuildIcons = 'empty';
  if (iconName !== null) {
    goodIconName = iconName;
  } else if (!customText) {
    goodIconName = 'unknown';
  }

  return (
    <React.Fragment>
      <div dangerouslySetInnerHTML={{ __html: css }} />
      <div
        className={iconObjectClass}
        onClick={props.onClick}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        id={id}
        data-type={type}
        {...rest}>
        {type !== 'menu' && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            className="iconObjectBackgroundStroke">
            <rect
              className="iconObjectBackground"
              x="0"
              y="0"
              height="32"
              width="32"
              rx="3"
              ry="3"
              style={iconObjectBackgroundStyle}
            />
            {strokeStyle === 'opened' ? (
              <path
                className="iconObjectStroke"
                d="M31,29c0,1.1-0.9,2-2,2H3c-1.1,0-2-0.9-2-2v-7.5H0V29c0,1.7,1.3,3,3,3h26c1.7,0,3-1.3,3-3v-7.5h-1V29z M29,0H3 C1.3,0,0,1.3,0,3v7.5h1V3c0-1.1,0.9-2,2-2h26c1.1,0,2,0.9,2,2v7.5h1V3C32,1.3,30.7,0,29,0z"
              />
            ) : (
              <rect
                className="iconObjectStroke"
                x="0.5"
                y="0.5"
                height="31"
                width="31"
                rx="2.5"
                ry="2.5"
              />
            )}
            <rect
              className="iconObjectInnerBackground"
              x="3"
              y="3"
              height="26"
              width="26"
              rx="1.5"
              ry="1.5"
              fill="none"
            />
          </svg>
        )}
        <div className="iconObjectIcon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 500 500"
            dangerouslySetInnerHTML={{ __html: iconObjectIcons[goodIconName] }}
          />
        </div>
        {customText && customText.length && (
          <div className="iconObjectCustomText">
            <div className="iconObjectCustomTextInner" data-position={customTextPosition}>
              <IconFont
                id={id + '-icon-font'}
                returnString={customText}
                color={color}
                lineSpacing={2.5}
              />
            </div>
          </div>
        )}
        {label && label.length && (
          <div className="iconObjectLabel">
            <IconFont id={id + '-icon-font2'} returnString={label} color={color} lineSpacing={0} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
