import i18next from 'i18next';
import { APP_COLOR } from '../../../../constants/appConstants';
import { getSectionName, Section, SectionDrawn } from '../Section';
import { getDefaultCasePropertyValue } from '../../../specification/cases/queries/get/properties/getDefaultCasePropertyValue';
import { SectionWithTheoreticalSurface } from '../SectionWithTheoreticalSurface';
import { OutsideSectionName } from './OutsideSection';
import { OUTSIDE_STAIR_SHAFT_ICON } from './outsideStairShaft';
import { SectionWithWidth } from '../SectionWithWidth';

interface OutsideStairsShaftInfraProps {
  id: string;
  surface: number;
  length: number;
  width: number;
  bearingLength: number;
  bearingWidth: number;
  group: string;
}

export interface OutsideStairsShaftInfraSection
  extends Section,
    SectionDrawn,
    SectionWithWidth,
    SectionWithTheoreticalSurface {
  name: OutsideSectionName.OutsideStairShaftInfra;
  length: number;
  bearingLength: number;
  bearingWidth: number;
}

const outsideStairShaftInfra = ({
  id,
  surface,
  length,
  width,
  bearingLength,
  bearingWidth,
  group
}: OutsideStairsShaftInfraProps): OutsideStairsShaftInfraSection => ({
  // Section
  id,
  name: OutsideSectionName.OutsideStairShaftInfra,
  title: i18next.t('outsideStairShaftInfra.title'),
  type: 'outside',
  displayedSurface: surface,
  // SectionDrawn
  surface,
  color: APP_COLOR,
  icon: OUTSIDE_STAIR_SHAFT_ICON,
  group,
  // SectionWithTheoreticalSurface
  theoreticalSurface: undefined,
  defaultTheoreticalSurface:
    getDefaultCasePropertyValue('outsideStairShaftWidth') *
    getDefaultCasePropertyValue('outsideStairShaftLength'),
  // StairsShaftSection
  length,
  width,
  bearingLength,
  bearingWidth
});

export default outsideStairShaftInfra;

export const isSectionAnOutsideStairsShaftInfra = (
  section: Section
): section is OutsideStairsShaftInfraSection =>
  getSectionName(section) === OutsideSectionName.OutsideStairShaftInfra;
