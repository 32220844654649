import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getCaseFifthFacadeTriggerId } from '../../../../../routes/toolboxPanels/triggers';
import { Window } from '../../../../ui/Window';
import { Form } from '../../../../ui/Form';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCaseLabel } from '../../../../../store/selectors/project/currentCaseLabel.selector';
import { IconObject } from '../../../../ui/Icons/iconObject';
import { useParams } from 'react-router';
import { goToCase, goToCaseSuperstructure } from '../../../../../store/actions/navigations.actions';
import { RoofingTypes } from './RoofingTypes';
import { CaseGranulometryEditFifthFacadeRouteParams } from '../../../../../routes/toolboxPanels/cases';

export const CaseFifthFacadeWindow = () => {
  const { projectId, caseId } = useParams<CaseGranulometryEditFifthFacadeRouteParams>();
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClickClose = React.useCallback(() => {
    if (caseId && projectId) dispatch(goToCase(projectId, caseId));
  }, [dispatch, projectId, caseId]);

  const handleClickNext = React.useCallback(() => {
    caseId && projectId && dispatch(goToCaseSuperstructure(projectId, caseId));
  }, [dispatch, projectId, caseId]);

  if (!caseLabel) return null;

  return (
    <Window
      title={t('Fifth facade')}
      triggerId={getCaseFifthFacadeTriggerId(caseLabel)}
      topButtons={
        <>
          <IconObject iconName="arrowTop" type="menu" disabled={true} />
          <IconObject iconName="arrowBottom" type="menu" onClick={handleClickNext} />
          <IconObject iconName="close" type="menu" onClick={handleClickClose} />
        </>
      }>
      <Form>
        <RoofingTypes />
      </Form>
    </Window>
  );
};

export default CaseFifthFacadeWindow;
