import { getDefaultUnitConvertedPropertyValue } from '../../../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import ventilationGroupInfra from '../../../../../sections/circulationSections/ventilationGroupInfra';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const createBasementLevelVentilationGroupInfra = (
  caseGranulometry: CaseGranulometry,
  group: string
) => {
  const width = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'ventilationGroupInfraWidth'
  ) as number;
  const length = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'ventilationGroupInfraLength'
  ) as number;
  const surface = width * length;
  return ventilationGroupInfra({ surface, width, length, group });
};
