import { CONVERT_API_URL, ENDPOINT_CREATE_IMAGE, post } from './api/services.api';

export interface ConvertFileOptions {
  file: string;
  pageToConvert: number;
}

const convertAsUrlString = (file: File): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const createFile = async (selectedFile: File, pageToConvert: number): Promise<string> => {
  const file = await convertAsUrlString(selectedFile);
  const body: ConvertFileOptions = { file: file as string, pageToConvert };
  const res = await post(ENDPOINT_CREATE_IMAGE, body);
  const fileName = await res.text();

  return CONVERT_API_URL() + '/' + fileName;
};
