import { createSelector, Selector } from 'reselect';
import { Maybe } from '../../../utils/Maybe';
import { State } from '../../../store/reducers';
import { selectCurrentCaseIdFromRoute } from './caseIdFromRoute.selector';
import { selectBuildings } from '../specification/buildings.selector';
import { BuildingSpecification } from '../../../domain/specification/buildings/BuildingSpecification';
import { CaseId } from '../../../domain/specification/cases/CaseSpecification';
import { hasCaseId } from '../../../domain/specification/cases/queries/has/hasCaseId';

export const selectParentBuildingFromCaseRoute: Selector<
  State,
  Maybe<BuildingSpecification>
> = createSelector(
  [selectCurrentCaseIdFromRoute, selectBuildings],
  (caseId: Maybe<CaseId>, buildings: Maybe<BuildingSpecification[]>) =>
    buildings && caseId ? buildings.find(({ cases }) => cases.some(hasCaseId(caseId))) : undefined
);
