import * as R from 'ramda';
import { Project } from '../../project/Project';
import { defaultCaseProperties } from '../../../resources/defaultCaseProperties';
import { Property } from '../../specification/cases/Property';
import { CaseSpecification } from '../../specification/cases/CaseSpecification';
import { mapCases } from '../../project/map/mapCases';
import { addCaseProperty } from '../utils/addCaseProperty';

export const migrateProject8To9 = (project: Project): Project =>
  mapCases((eachCase) =>
    R.pipe<[CaseSpecification], CaseSpecification, CaseSpecification, CaseSpecification>(
      (cS) =>
        addCaseProperty(
          cS,
          defaultCaseProperties.find(
            (property) => property.name === 'smokeExtractionDuctsCount'
          ) as Property,
          cS.properties.findIndex((property) => property.name === 'topLevelCeilingHeight')
        ),
      (cS) =>
        addCaseProperty(
          cS,
          defaultCaseProperties.find(
            (property) => property.name === 'smokeExtractionDuctsWidth'
          ) as Property,
          cS.properties.findIndex((property) => property.name === 'carParkWidth')
        ),
      (cS) =>
        addCaseProperty(
          cS,
          defaultCaseProperties.find(
            (property) => property.name === 'smokeExtractionDuctsDepth'
          ) as Property,
          cS.properties.findIndex((property) => property.name === 'carParkWidth')
        )
    )(eachCase)
  )(project);
