import { getDefaultUnitConvertedPropertyValue } from '../../../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { getRampSectionTheoreticalWallsLinear } from './getRampSectionTheoreticalWallsLinear';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { EXPONENT, MULTIPLIER_COEFFICIENT } from '../../getSectionWallsLinear';

export const getRampSectionTheoreticalWallsLinearDetails = (
  caseGranulometry: CaseGranulometry
): string => {
  let details: string[] = [];
  details = [
    ...details,
    '<b>' +
      roundWith2Decimal(
        (getDefaultUnitConvertedPropertyValue(
          caseGranulometry.initialSpecifications,
          'rampLength'
        ) as number) *
          (getDefaultUnitConvertedPropertyValue(
            caseGranulometry.initialSpecifications,
            'rampWidth'
          ) as number)
      ) +
      ' m\u00B2</b> de surface de rampe'
  ];
  const hideNumbers = !(process.env.GB_ENV === 'dev' || process.env.GB_ENV === 'recette');
  details = [
    ...details,
    '<b>* ' +
      (hideNumbers
        ? 'coefficient multiplicateur'
        : MULTIPLIER_COEFFICIENT + '<sup>' + EXPONENT + '</sup>') +
      '</b>'
  ];
  details = [
    ...details,
    '<b>= ' + roundWith2Decimal(getRampSectionTheoreticalWallsLinear(caseGranulometry)) + ' mL</b>'
  ];
  return details.join('<br />');
};
