import { LevelGranulometryWithBearingRelativeSections } from '../../../LevelGranulometry';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import bearing, { BearingSection } from '../../../../sections/circulationSections/bearing';
import { IconObjectProps } from '../../../../../../components/ui/Icons/iconObject';
import { getTopLevelBearingSurface } from './surfaces/getTopLevelBearingSurface';
import { ERROR_COLOR, OTHER_RBS_SECTIONS_COLOR } from '../../../../../../constants/appConstants';
import { getDefaultUnitConvertedPropertyValue } from '../../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { getTopLevelMinimumBearingSurface } from '../surfaces/getTopLevelMinimumBearingSurface';

export const getTopLevelBearingSection = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryWithBearingRelativeSections,
  withMinimumBearingSurface: boolean
): BearingSection[] => {
  const bearingSurface = getTopLevelBearingSurface(
    caseGranulometry,
    levelGranulometry,
    withMinimumBearingSurface
  );

  if (bearingSurface === 0) {
    return [];
  }

  const bearingSection = bearing({
    surface: bearingSurface,
    width: getDefaultUnitConvertedPropertyValue(
      caseGranulometry.initialSpecifications,
      'bearingWidth'
    ) as number
  });

  const minimumBearingSurface = getTopLevelMinimumBearingSurface(
    caseGranulometry,
    levelGranulometry
  ).value;

  if (bearingSurface < minimumBearingSurface) {
    bearingSection.color = ERROR_COLOR;
    (bearingSection.icon as IconObjectProps).color = ERROR_COLOR;
    bearingSection.surface = minimumBearingSurface;
  } else {
    (bearingSection.icon as IconObjectProps).color = OTHER_RBS_SECTIONS_COLOR; // To prevent bug
  }

  return [bearingSection];
};
