import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from '../../../../ui/DataTable';
import { Column, ComponentPropsGetterR } from 'react-table';
import {
  isRoomSpecificationAnyLivingRoomKitchen,
  LivingRoomKitchenSpecification
} from '../../../../../domain/specification/rooms/LivingRoomKitchenSpecification';
import { RoomSpecification } from '../../../../../domain/specification/rooms/RoomSpecification';
import { getSectionTheoreticalSurface } from '../../../../../domain/granulometry/sections/queries/surfaces/getSectionTheoreticalSurface';
import { LodgmentTypeSpecification } from '../../../../../domain/specification/lodgmentTypes/LodgmentTypeSpecification';
import { useSelector } from 'react-redux';
import { getRoomSpecificationSurface } from '../../../../../domain/specification/rooms/queries/get/getRoomSpecificationSurface';
import { getRoomSpecificationWidth } from '../../../../../domain/specification/rooms/queries/get/getRoomSpecificationWidth';
import { getRoomSpecificationLength } from '../../../../../domain/specification/rooms/queries/get/getRoomSpecificationLength';
import { getRoomSpecificationDepth } from '../../../../../domain/specification/rooms/queries/get/getRoomSpecificationDepth';
import { isRoomSpecificationABalcony } from '../../../../../domain/specification/rooms/BalconySpecification';
import { roundWith2Decimal } from '../../../../../utils/round/roundWith2Decimal';
import { getDefaultUnitConvertedPropertyValue } from '../../../../../domain/legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { selectCurrentCaseGranulometry } from '../../../../../store/selectors/granulometry/currentCase/currentCaseGranulometry.selector';
import { APP_COLOR, DARK_GRAY_COLOR, WARNING_COLOR } from '../../../../../constants/appConstants';

interface RoomsTableProps {
  lodgmentTypeSpecification: LodgmentTypeSpecification;
}

export const RoomsTable = ({ lodgmentTypeSpecification }: RoomsTableProps) => {
  const { t } = useTranslation();
  const caseGranulometry = useSelector(selectCurrentCaseGranulometry);

  if (!caseGranulometry) return null;

  const balconySurfaceOnRealLivingSurfaceRate = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'balconySurfaceOnRealLivingSurfaceRate'
  ) as number;

  let rooms: (RoomSpecification | { name: string; title: string; surface: number })[] = [
    ...lodgmentTypeSpecification.rooms
  ];
  const totalSurface = getSectionTheoreticalSurface(lodgmentTypeSpecification);
  const totalLine = {
    name: 'total',
    title: 'Total',
    // depth: getTotals(rooms, 'depth'),
    surface: totalSurface
  };
  // Add or update total line depends if already exists
  let totalLineExists = false;
  rooms.map((room, key: number) => {
    if (room.name === 'total') {
      rooms[key] = totalLine;
      totalLineExists = true;
    }
  });
  if (!totalLineExists) {
    rooms = [...rooms, totalLine];
  }

  const getTrProps: ComponentPropsGetterR = (state, rowInfo) => {
    if (rowInfo) {
      const roomSpecification = rowInfo.row._original as RoomSpecification;
      const roomSurface = getRoomSpecificationSurface(
        lodgmentTypeSpecification.rooms,
        getSectionTheoreticalSurface(lodgmentTypeSpecification),
        balconySurfaceOnRealLivingSurfaceRate,
        roomSpecification
      );
      let colorValue = APP_COLOR;
      if (rowInfo.row.name === 'total') {
        colorValue = 'white';
      }
      if (isRoomSpecificationABalcony(roomSpecification)) {
        colorValue = DARK_GRAY_COLOR;
      }
      if (
        roomSurface < 0 ||
        (isRoomSpecificationAnyLivingRoomKitchen(rowInfo.row._original as RoomSpecification) &&
          roomSurface < 15)
      ) {
        colorValue = WARNING_COLOR;
      }
      return {
        style: {
          background: rowInfo.row.name === 'total' ? APP_COLOR : 'white',
          color: colorValue,
          fontStyle: isRoomSpecificationABalcony(roomSpecification) ? 'italic' : 'normal'
        }
      };
    }
    return {};
  };

  const getTrGroupProps = () => {
    return {
      style: {
        minHeight: 'auto'
      }
    };
  };

  // Set columns
  const columns: Column[] = [
    {
      accessor: 'name',
      show: false
    },
    {
      Header: t('Room'),
      accessor: 'title',
      width: 80,
      Cell: (cellInfo) => <span>{cellInfo.row.title ? t(cellInfo.row.title) : '-'}</span>
    },
    {
      Header: t('Length'),
      headerStyle: { textAlign: 'right' },
      style: { textAlign: 'right' },
      accessor: 'name',
      width: 53,
      Cell: (cellInfo) => {
        const roomSpec = cellInfo.row._original as LivingRoomKitchenSpecification;
        if (cellInfo.row.name === 'total') {
          return '';
        }
        return (
          <span>
            {roundWith2Decimal(
              getRoomSpecificationLength(
                lodgmentTypeSpecification.rooms,
                getSectionTheoreticalSurface(lodgmentTypeSpecification),
                balconySurfaceOnRealLivingSurfaceRate,
                roomSpec
              )
            ) || '-'}
          </span>
        );
      }
    },
    {
      Header: t('Width'),
      headerStyle: { textAlign: 'right' },
      style: { textAlign: 'right' },
      accessor: 'name',
      width: 48,
      Cell: (cellInfo) => {
        const roomSpec = cellInfo.row._original as LivingRoomKitchenSpecification;
        if (cellInfo.row.name === 'total') {
          return '';
        }
        return <span>{roundWith2Decimal(getRoomSpecificationWidth(roomSpec)) || '-'}</span>;
      }
    },
    {
      Header: t('Depth'),
      headerStyle: { textAlign: 'right' },
      style: { textAlign: 'right' },
      accessor: 'name',
      Cell: (cellInfo) => {
        const roomSpec = cellInfo.row._original as LivingRoomKitchenSpecification;
        if (cellInfo.row.name === 'total') {
          return '';
        }
        return <span>{roundWith2Decimal(getRoomSpecificationDepth(roomSpec)) || '-'}</span>;
      }
    },
    {
      Header: t('Theoretical surface'),
      headerStyle: { textAlign: 'right' },
      style: { textAlign: 'right' },
      accessor: 'name',
      Cell: (cellInfo) => {
        const roomSpec = cellInfo.row._original as LivingRoomKitchenSpecification;
        if (cellInfo.row.name === 'total') {
          return <b>{totalSurface}</b>;
        }
        return (
          <b>
            {roundWith2Decimal(
              getRoomSpecificationSurface(
                lodgmentTypeSpecification.rooms,
                getSectionTheoreticalSurface(lodgmentTypeSpecification),
                balconySurfaceOnRealLivingSurfaceRate,
                roomSpec
              )
            ) || '-'}
          </b>
        );
      }
    }
  ];
  // Return data table
  return (
    <div style={{ paddingTop: '15px' }}>
      <DataTable
        data={rooms}
        columns={columns}
        defaultPageSize={100}
        filterable={false}
        getTrProps={getTrProps}
        getTrGroupProps={getTrGroupProps}
        showPaginationTop={false}
        showPaginationBottom={false}
      />
    </div>
  );
};

export default RoomsTable;
