import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentCaseLabel } from '../../../../store/selectors/project/currentCaseLabel.selector';
import { selectCurrentCaseLodgmentsCount } from '../../../../store/selectors/granulometry/currentCase/lodgmentsCount.selector';
import { Input } from '../../../ui/input/Input';

export const Column3 = () => {
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const lodgmentsCount = useSelector(selectCurrentCaseLodgmentsCount);

  if (!caseLabel) return null;

  return (
    <div className="column-3">
      <Input
        name={`${caseLabel}TotalTopLevelLodgmentCount`}
        type="number"
        size="small"
        disabled
        value={lodgmentsCount || 0}
      />
    </div>
  );
};
