import * as R from 'ramda';
import { Project } from '../../project/Project';
import { RoomSpecification } from '../../specification/rooms/RoomSpecification';
import { BasicRoomSpecification } from '../../specification/rooms/BasicRoomSpecification';
import { LodgmentTypeSpecification } from '../../specification/lodgmentTypes/LodgmentTypeSpecification';
import { defaultLodgmentsSections } from '../../granulometry/sections/lodgmentSections/LodgmentSection';
import { mapCases } from '../../project/map/mapCases';
import { isRoomSpecificationAClearance } from '../../granulometry/rooms/clearance';
import { LodgmentType } from '../../specification/lodgmentTypes/LodgmentType';

const migrateClearanceRoom =
  (lodgmentTypeSpecification: LodgmentTypeSpecification) =>
  (roomSpecification: BasicRoomSpecification): BasicRoomSpecification =>
    R.pipe<[BasicRoomSpecification], BasicRoomSpecification>(
      R.assoc(
        'defaultLength',
        (
          defaultLodgmentsSections[lodgmentTypeSpecification.lodgmentType].rooms.find(
            (lr) => lr.name === roomSpecification.name
          ) as BasicRoomSpecification
        ).defaultLength
      )
    )(roomSpecification);

const migrateRooms = (
  lodgmentTypeSpecification: LodgmentTypeSpecification,
  roomSpecifications: RoomSpecification[]
) =>
  R.map((roomSpecification: RoomSpecification) =>
    R.pipe<[RoomSpecification], RoomSpecification>(
      R.when(
        (r) => isRoomSpecificationAClearance(r),
        (r) => migrateClearanceRoom(lodgmentTypeSpecification)(r as BasicRoomSpecification)
      )
    )(roomSpecification)
  )(roomSpecifications);

export const migrateProject23To24 = (project: Project): Project =>
  mapCases((eachCase) => ({
    ...eachCase,
    lodgmentsTypesSpecifications: R.map(
      (lodgmentTypeSpecification: LodgmentTypeSpecification): LodgmentTypeSpecification =>
        R.when(
          (lTS: LodgmentTypeSpecification) =>
            lTS.lodgmentType === LodgmentType.T4 ||
            lTS.lodgmentType === LodgmentType.T4C ||
            lTS.lodgmentType === LodgmentType.T5,
          (lTS: LodgmentTypeSpecification) =>
            R.over<LodgmentTypeSpecification, RoomSpecification[]>(
              R.lensProp('rooms'),
              (roomSpecifications: RoomSpecification[]) => migrateRooms(lTS, roomSpecifications)
            )(lTS)
        )(lodgmentTypeSpecification)
    )(eachCase.lodgmentsTypesSpecifications)
  }))(project);
