import { CaseId } from '../../domain/specification/cases/CaseSpecification';
import { matchesPath } from '../matchesPath';
import { LodgmentType } from '../../domain/specification/lodgmentTypes/LodgmentType';
import { orCreate, Route, RouteWithMakePath } from '../panel';

export type CaseGranulometryEditFloorSpaceRouteParams = { projectId: string; caseId: CaseId };
export const caseGranulometryEditFloorSpaceRoute: RouteWithMakePath<CaseGranulometryEditFloorSpaceRouteParams> =
  {
    path: `/project/:projectId/cases/:caseId/granulometry/edit/floorSpace`,
    makePath: ({ projectId, caseId }) =>
      `/project/${orCreate(projectId)}/cases/${caseId}/granulometry/edit/floorSpace`,
    matchesPath: matchesPath<CaseGranulometryEditFloorSpaceRouteParams>(
      /\/project\/([^/]+)\/cases\/([\w]+)\/granulometry\/edit\/floorSpace/,
      {
        projectId: 1,
        caseId: 2
      }
    )
  };

export type CaseGranulometryEditBasementsRouteParams = {
  projectId: string;
  caseId: CaseId;
};
export const caseGranulometryEditBasementsRoute: RouteWithMakePath<CaseGranulometryEditBasementsRouteParams> =
  {
    path: `/project/:projectId/cases/:caseId/granulometry/edit/basements`,
    makePath: ({ projectId, caseId }) =>
      `/project/${orCreate(projectId)}/cases/${caseId}/granulometry/edit/basements`,
    matchesPath: matchesPath<CaseGranulometryEditBasementsRouteParams>(
      /\/project\/([^/]+)\/cases\/([\w]+)\/granulometry\/edit\/basements/,
      {
        projectId: 1,
        caseId: 2
      }
    )
  };

export type CaseGranulometryEditFifthFacadeRouteParams = {
  projectId: string;
  caseId: CaseId;
};
export const caseGranulometryEditFifthFacadeRoute: RouteWithMakePath<CaseGranulometryEditFifthFacadeRouteParams> =
  {
    path: `/project/:projectId/cases/:caseId/granulometry/edit/fifthFacade`,
    makePath: ({ projectId, caseId }) =>
      `/project/${orCreate(projectId)}/cases/${caseId}/granulometry/edit/fifthFacade`,
    matchesPath: matchesPath<CaseGranulometryEditFifthFacadeRouteParams>(
      /\/project\/([^/]+)\/cases\/([\w]+)\/granulometry\/edit\/fifthFacade/,
      {
        projectId: 1,
        caseId: 2
      }
    )
  };

export type CaseGranulometryEditSuperstructureRouteParams = {
  projectId: string;
  caseId: CaseId;
};
export const caseGranulometryEditSuperstructureRoute: RouteWithMakePath<CaseGranulometryEditSuperstructureRouteParams> =
  {
    path: `/project/:projectId/cases/:caseId/granulometry/edit/superstructure`,
    makePath: ({ projectId, caseId }) =>
      `/project/${orCreate(projectId)}/cases/${caseId}/granulometry/edit/superstructure`,
    matchesPath: matchesPath<CaseGranulometryEditSuperstructureRouteParams>(
      /\/project\/([^/]+)\/cases\/([\w]+)\/granulometry\/edit\/superstructure/,
      {
        projectId: 1,
        caseId: 2
      }
    )
  };

export type CaseGranulometryEditEnergyAndNetworksRouteParams = {
  projectId: string;
  caseId: CaseId;
};
export const caseGranulometryEditEnergyAndNetworksRoute: RouteWithMakePath<CaseGranulometryEditEnergyAndNetworksRouteParams> =
  {
    path: `/project/:projectId/cases/:caseId/granulometry/edit/energyAndNetworks`,
    makePath: ({ projectId, caseId }) =>
      `/project/${orCreate(projectId)}/cases/${caseId}/granulometry/edit/energyAndNetworks`,
    matchesPath: matchesPath<CaseGranulometryEditEnergyAndNetworksRouteParams>(
      /\/project\/([^/]+)\/cases\/([\w]+)\/granulometry\/edit\/energyAndNetworks/,
      {
        projectId: 1,
        caseId: 2
      }
    )
  };

export type CaseGranulometryEditInfrastructureRouteParams = {
  projectId: string;
  caseId: CaseId;
};
export const caseGranulometryEditInfrastructureRoute: RouteWithMakePath<CaseGranulometryEditInfrastructureRouteParams> =
  {
    path: `/project/:projectId/cases/:caseId/granulometry/edit/infrastructure`,
    makePath: ({ projectId, caseId }) =>
      `/project/${orCreate(projectId)}/cases/${caseId}/granulometry/edit/infrastructure`,
    matchesPath: matchesPath<CaseGranulometryEditInfrastructureRouteParams>(
      /\/project\/([^/]+)\/cases\/([\w]+)\/granulometry\/edit\/infrastructure/,
      {
        projectId: 1,
        caseId: 2
      }
    )
  };

export type CaseGranulometryEditGroundAdaptationsRouteParams = {
  projectId: string;
  caseId: CaseId;
};
export const caseGranulometryEditGroundAdaptationsRoute: RouteWithMakePath<CaseGranulometryEditGroundAdaptationsRouteParams> =
  {
    path: `/project/:projectId/cases/:caseId/granulometry/edit/groundAdaptations`,
    makePath: ({ projectId, caseId }) =>
      `/project/${orCreate(projectId)}/cases/${caseId}/granulometry/edit/groundAdaptations`,
    matchesPath: matchesPath<CaseGranulometryEditGroundAdaptationsRouteParams>(
      /\/project\/([^/]+)\/cases\/([\w]+)\/granulometry\/edit\/groundAdaptations/,
      {
        projectId: 1,
        caseId: 2
      }
    )
  };

export type CaseGranulometryEditPeripheralReinforcementsRouteParams = {
  projectId: string;
  caseId: CaseId;
};
export const caseGranulometryEditPeripheralReinforcementsRoute: RouteWithMakePath<CaseGranulometryEditPeripheralReinforcementsRouteParams> =
  {
    path: `/project/:projectId/cases/:caseId/granulometry/edit/peripheralReinforcements`,
    makePath: ({ projectId, caseId }) =>
      `/project/${orCreate(projectId)}/cases/${caseId}/granulometry/edit/peripheralReinforcements`,
    matchesPath: matchesPath<CaseGranulometryEditPeripheralReinforcementsRouteParams>(
      /\/project\/([^/]+)\/cases\/([\w]+)\/granulometry\/edit\/peripheralReinforcements/,
      {
        projectId: 1,
        caseId: 2
      }
    )
  };

export type CaseGranulometryEditOutdoorsRouteParams = {
  projectId: string;
  caseId: CaseId;
};
export const caseGranulometryEditOutdoorsRoute: RouteWithMakePath<CaseGranulometryEditOutdoorsRouteParams> =
  {
    path: `/project/:projectId/cases/:caseId/granulometry/edit/outdoors`,
    makePath: ({ projectId, caseId }) =>
      `/project/${orCreate(projectId)}/cases/${caseId}/granulometry/edit/outdoors`,
    matchesPath: matchesPath<CaseGranulometryEditOutdoorsRouteParams>(
      /\/project\/([^/]+)\/cases\/([\w]+)\/granulometry\/edit\/outdoors/,
      {
        projectId: 1,
        caseId: 2
      }
    )
  };

export type CaseGranulometryEditOtherPropertiesRouteParams = {
  projectId: string;
  caseId: CaseId;
};
export const caseGranulometryEditOtherPropertiesRoute: RouteWithMakePath<CaseGranulometryEditOtherPropertiesRouteParams> =
  {
    path: `/project/:projectId/cases/:caseId/granulometry/edit/otherProperties`,
    makePath: ({ projectId, caseId }) =>
      `/project/${orCreate(projectId)}/cases/${caseId}/granulometry/edit/otherProperties`,
    matchesPath: matchesPath<CaseGranulometryEditOtherPropertiesRouteParams>(
      /\/project\/([^/]+)\/cases\/([\w]+)\/granulometry\/edit\/otherProperties/,
      {
        projectId: 1,
        caseId: 2
      }
    )
  };

export type CaseGranulometryEditPropertyRouteParams = {
  projectId: string;
  caseId: CaseId;
  propertyName: string;
};
export const caseGranulometryEditPropertyRoute: RouteWithMakePath<CaseGranulometryEditPropertyRouteParams> =
  {
    path: `/project/:projectId/cases/:caseId/granulometry/edit/otherProperties/property/:propertyName`,
    makePath: ({ projectId, caseId, propertyName }) =>
      `/project/${orCreate(
        projectId
      )}/cases/${caseId}/granulometry/edit/otherProperties/property/${propertyName}`,
    matchesPath: matchesPath<CaseGranulometryEditPropertyRouteParams>(
      /\/project\/([^/]+)\/cases\/([\w]+)\/granulometry\/edit\/otherProperties\/property\/([^/]+)/,
      {
        projectId: 1,
        caseId: 2,
        propertyName: 3
      }
    )
  };

export type CaseGranulometryEditLevelRouteParams = {
  projectId: string;
  caseId: CaseId;
  levelId: string;
};
export const caseGranulometryEditLevelRoute: RouteWithMakePath<CaseGranulometryEditLevelRouteParams> =
  {
    path: `/project/:projectId/cases/:caseId/granulometry/edit/level/:levelId`,
    makePath: ({ projectId, caseId, levelId }) =>
      `/project/${orCreate(projectId)}/cases/${caseId}/granulometry/edit/level/${levelId}`,
    matchesPath: matchesPath<CaseGranulometryEditLevelRouteParams>(
      /\/project\/([^/]+)\/cases\/([\w]+)\/granulometry\/edit\/level\/([^/]+)/,
      {
        projectId: 1,
        caseId: 2,
        levelId: 3
      }
    )
  };

export type CaseGranulometryEditLodgmentTypeRoomRouteParams = {
  projectId: string;
  caseId: CaseId;
  lodgmentType: LodgmentType;
  roomIndex: string;
};
export const caseGranulometryEditLodgmentTypeRoomRoute: RouteWithMakePath<CaseGranulometryEditLodgmentTypeRoomRouteParams> =
  {
    path: `/project/:projectId/cases/:caseId/granulometry/edit/lodgment-type/:lodgmentTypeId/:roomIndex`,
    makePath: ({ projectId, caseId, lodgmentType, roomIndex }) =>
      `/project/${orCreate(
        projectId
      )}/cases/${caseId}/granulometry/edit/lodgment-type/${lodgmentType}/${roomIndex}`,
    matchesPath: matchesPath<CaseGranulometryEditLodgmentTypeRoomRouteParams>(
      /\/project\/([^/]+)\/cases\/([\w]+)\/granulometry\/edit\/lodgment-type\/(\w+)\/([0-9]+)/,
      {
        projectId: 1,
        caseId: 2,
        lodgmentType: 3,
        roomIndex: 4
      }
    )
  };

export type CaseGranulometryEditLodgmentTypeRouteParams = {
  projectId: string;
  caseId: string;
  lodgmentType: LodgmentType;
};
export const caseGranulometryEditLodgmentTypeRoute: RouteWithMakePath<CaseGranulometryEditLodgmentTypeRouteParams> =
  {
    path: `/project/:projectId/cases/:caseId/granulometry/edit/lodgment-type/:lodgmentType`,
    makePath: ({ projectId, caseId, lodgmentType }) =>
      `/project/${orCreate(
        projectId
      )}/cases/${caseId}/granulometry/edit/lodgment-type/${lodgmentType}`,
    matchesPath: matchesPath<CaseGranulometryEditLodgmentTypeRouteParams>(
      /\/project\/([^/]+)\/cases\/([\w]+)\/granulometry\/edit\/lodgment-type\/(\w+)/,
      {
        projectId: 1,
        caseId: 2,
        lodgmentType: 3
      }
    )
  };

export type CaseGranulometryEditRouteParams = {
  projectId: string;
  caseId: string;
};
export const caseGranulometryEditRoute: Route<CaseGranulometryEditRouteParams> = {
  path: `/project/:projectId/cases/:caseId/granulometry/edit`,
  matchesPath: matchesPath<CaseGranulometryEditRouteParams>(
    /\/project\/([^/]+)\/cases\/([\w]+)\/granulometry\/edit/,
    {
      projectId: 1,
      caseId: 2
    }
  )
};

export type CaseGranulometryRouteParams = {
  projectId: string;
  caseId: string;
};
export const caseGranulometryRoute: RouteWithMakePath<CaseGranulometryRouteParams> = {
  path: `/project/:projectId/cases/:caseId/granulometry`,
  makePath: ({ projectId, caseId }) =>
    `/project/${orCreate(projectId)}/cases/${caseId}/granulometry`,
  matchesPath: matchesPath<CaseGranulometryRouteParams>(
    /\/project\/([^/]+)\/cases\/([\w]+)\/granulometry/,
    {
      projectId: 1,
      caseId: 2
    }
  )
};

export type CaseRouteParams = { projectId: string; caseId: string };
export const caseRoute: RouteWithMakePath<CaseRouteParams> = {
  path: `/project/:projectId/cases/:caseId`,
  makePath: caseGranulometryRoute.makePath,
  matchesPath: matchesPath<CaseRouteParams>(/\/project\/([^/]+)\/cases\/([\w]+)/, {
    projectId: 1,
    caseId: 2
  })
};

export type CasesRouteParams = { projectId: string };
export const casesRoute: Route<CasesRouteParams> = {
  path: `/project/:projectId/cases/`,
  matchesPath: matchesPath<CasesRouteParams>(/\/project\/([^/]+)\/cases/, {
    projectId: 1
  })
};
