import { CaseProjection, MaxSurfaceForSaleByLevel } from '../../CaseProjection';
import { Surface } from '../../../../specification/Surface';
import { isTopLevel } from '../../../../granulometry/levels/queries/is/isTopLevel';
import { getCaseProjectionFromCaseSpecification } from '../getCaseProjectionFromCaseSpecification';
import { runFakeGranulo } from './runFakeGranulo';
import { getCaseMaxSurfaceForSale } from './getCaseMaxSurfaceForSale';
import { isSectionABearing } from '../../../../granulometry/sections/circulationSections/bearing';
import { getCaseMaxSurfaceForSaleByTopLevels } from './getCaseMaxSurfaceForSaleByTopLevels';
import { roundWith2Decimal } from '../../../../../utils/round/roundWith2Decimal';
import { getLevelFullFilledContent } from '../../../../granulometry/levels/queries/content/getLevelFullFilledContent';

const MAX_SFS_CALCULATION_ITERATION_COUNT = 4;

export const calculateMaxSurfaceForSale = (caseProjection: CaseProjection): CaseProjection => {
  // Get the useful surface relatives entries :
  const projectedCBS = caseProjection.projectedSurfaces.cuttedBuiltSurface as Surface;
  const isSFSFilledByUser = caseProjection.surfaces.surfaceForSale !== undefined;
  const defaultCalculatedSFS = (caseProjection.projectedSurfaces.surfaceForSale as Surface).value;
  // Initiate calculation variables (without initial surface relatives entries)
  let updatedCaseProjection: CaseProjection = {
    ...caseProjection,
    surfaces: {
      ...caseProjection.surfaces,
      surfaceForSale: undefined,
      realBuiltSurface: undefined
    },
    projectedSurfaces: {
      ...caseProjection.projectedSurfaces,
      // @ts-ignore
      surfaceForSale: undefined,
      // @ts-ignore
      realBuiltSurface: undefined
    }
  }; // evolving case projection
  const maxSfsByLevelsByIteration: MaxSurfaceForSaleByLevel[][] = []; // iteration recorder
  // Start running a series of calculation :
  for (let i = 1; i <= MAX_SFS_CALCULATION_ITERATION_COUNT; i++) {
    // Inject the previous iteration result into the case projection
    updatedCaseProjection = getCaseProjectionFromCaseSpecification(
      projectedCBS,
      false
    )({
      ...updatedCaseProjection,
      surfaces: updatedCaseProjection.projectedSurfaces, // it's like if projection are forced
      customDistribution: [] // maxSfs is only on granulometry stage
    });
    // Run fake granulo
    const fakeGranulo = runFakeGranulo(updatedCaseProjection);
    // Get the case in the resulted building granulometry
    const caseGranulometryFromFakeGranulo = fakeGranulo.cases[0];
    // Get the sum of all top levels bearing surface
    const sumOfMinimumBearingSurface = caseGranulometryFromFakeGranulo.levels.reduce(
      (acc, levelGranulometry) => {
        if (isTopLevel(caseGranulometryFromFakeGranulo, levelGranulometry)) {
          const bearing = getLevelFullFilledContent(levelGranulometry).find(isSectionABearing);
          return bearing ? acc + bearing.displayedSurface : acc;
        }
        return acc;
      },
      0
    );
    // Save it in the updated projection
    updatedCaseProjection.projectedSurfaces.sumOfMinimumBearingSurface = sumOfMinimumBearingSurface
      ? new Surface(sumOfMinimumBearingSurface)
      : Surface.EMPTY;
    // Calculate the max SFS by top levels
    const maxSfsByLevels = getCaseMaxSurfaceForSaleByTopLevels(caseGranulometryFromFakeGranulo);
    // Push the result in the recorder
    maxSfsByLevelsByIteration.push(maxSfsByLevels);
    // If there is other iteration to run :
    if (i !== MAX_SFS_CALCULATION_ITERATION_COUNT) {
      // Re-inject the max SFS calculation value
      // updatedCaseProjection.projectedSurfaces.realBuiltSurface = undefined; // remove value for case spec
      // updatedCaseProjection.projectedSurfaces.cuttedBuiltSurface = undefined; // remove value for case spec
      updatedCaseProjection.projectedSurfaces.surfaceForSale = new Surface(
        getCaseMaxSurfaceForSale(maxSfsByLevels)
      );
    } else {
      // Sometimes the lodgment count / shab max is oscillates each two iteration, so we pick the higher one
      const theMaxOne = Math.max(
        getCaseMaxSurfaceForSale(maxSfsByLevelsByIteration[0]),
        getCaseMaxSurfaceForSale(maxSfsByLevelsByIteration[1]),
        getCaseMaxSurfaceForSale(maxSfsByLevelsByIteration[2]),
        getCaseMaxSurfaceForSale(maxSfsByLevelsByIteration[3])
      );
      // Then we get the max sfs by levels corresponding to the max one
      const correspondingMaxSfsByLevels = maxSfsByLevelsByIteration.find(
        (maxSfsByLevels) => getCaseMaxSurfaceForSale(maxSfsByLevels) === theMaxOne
      ) as unknown as MaxSurfaceForSaleByLevel[];
      // Check if the default calculated sfs is higher than the calculated max one
      const isCalculatedSFSHigherThanMaxSFS = defaultCalculatedSFS >= theMaxOne;
      // Check if the SFS filled by user is equal to max
      const isSFSFilledByUserEqualToMaxSFS =
        isSFSFilledByUser &&
        roundWith2Decimal((caseProjection.surfaces.surfaceForSale as Surface).value) ===
          roundWith2Decimal(theMaxOne);
      // Check if the max SFS must be forced
      const maxSurfaceForSaleMustBeenForced =
        (!isSFSFilledByUser && isCalculatedSFSHigherThanMaxSFS) || isSFSFilledByUserEqualToMaxSFS;
      // If true and there is no SFS filled by user : we automaticaly use the max one
      const sfs = maxSurfaceForSaleMustBeenForced ? theMaxOne : defaultCalculatedSFS;
      // We built the new resulting case projection
      updatedCaseProjection = {
        ...caseProjection,
        projectedSurfaces: {
          ...caseProjection.projectedSurfaces,
          surfaceForSale: new Surface(sfs),
          sumOfMinimumBearingSurface: new Surface(sumOfMinimumBearingSurface),
          maxSurfaceForSale: new Surface(theMaxOne),
          maxSurfaceForSaleHasBeenForced: maxSurfaceForSaleMustBeenForced, // note : there if several consequences in the final granulo
          maxSurfaceForSaleByLevels: correspondingMaxSfsByLevels
        }
      };
    }
  }
  return updatedCaseProjection;
};
