import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getCaseEnergyAndNetworksTriggerId } from '../../../../../routes/toolboxPanels/triggers';
import { Card } from '../../../../ui/Card';
import { Window } from '../../../../ui/Window';
import { Form } from '../../../../ui/Form';
import { useDispatch, useSelector } from 'react-redux';
import {
  goToCase,
  goToCaseGroundAdaptations,
  goToCaseOutdoors
} from '../../../../../store/actions/navigations.actions';
import { useParams } from 'react-router';
import { IconObject } from '../../../../ui/Icons/iconObject';
import { selectCurrentCaseLabel } from '../../../../../store/selectors/project/currentCaseLabel.selector';
import { Note } from '../../../../ui/Note';
import { HeatingSystemsCard } from './HeatingSystemsCard';
import { HeatingDistributionCard } from './HeatingDistributionCard';
import { CaseGranulometryEditEnergyAndNetworksRouteParams } from '../../../../../routes/toolboxPanels/cases';

export const CaseEnergyAndNetworksWindow = () => {
  const { projectId, caseId } = useParams<CaseGranulometryEditEnergyAndNetworksRouteParams>();
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClickClose = React.useCallback(() => {
    if (caseId && projectId) dispatch(goToCase(projectId, caseId));
  }, [dispatch, projectId, caseId]);

  const handleClickPrev = React.useCallback(() => {
    caseId && projectId && dispatch(goToCaseGroundAdaptations(projectId, caseId));
  }, [dispatch, projectId, caseId]);

  const handleClickNext = React.useCallback(() => {
    caseId && projectId && dispatch(goToCaseOutdoors(projectId, caseId));
  }, [dispatch, projectId, caseId]);

  if (!caseLabel) return null;

  return (
    <Window
      title={t('Energy and networks')}
      triggerId={getCaseEnergyAndNetworksTriggerId(caseLabel)}
      topButtons={
        <>
          <IconObject iconName="arrowTop" type="menu" onClick={handleClickPrev} />
          <IconObject iconName="arrowBottom" type="menu" onClick={handleClickNext} />
          <IconObject iconName="close" type="menu" onClick={handleClickClose} />
        </>
      }>
      <Form>
        <HeatingSystemsCard />
        <HeatingDistributionCard />
        <Card>
          <Note>
            Ce panneau permettra de gérer l’administration de l’ensemble des dispositifs techniques
            d’adduction, production, distribution et diffusion des solutions énergétiques en lien
            avec les besoins en électricité basse tension, chauffage, ventilation, ECS, eau potable,
            communication, courants faibles.
          </Note>
        </Card>
      </Form>
    </Window>
  );
};
