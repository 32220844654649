import { BackgroundLayer, FillLayer, LineLayer, SymbolLayer } from 'mapbox-gl';
import {
  APP_COLOR,
  GRAY_COLOR,
  GROUND_COLOR,
  INFO_COLOR,
  SOFTROCKS_COLOR,
  WATER_COLOR
} from '../../../constants/appConstants';

export const buildingsFillLayer: FillLayer = {
  id: 'buildingsFillLayer',
  source: 'cadastre_layers',
  'source-layer': 'batiments',
  type: 'fill',
  minzoom: 14,
  paint: {
    'fill-color': SOFTROCKS_COLOR,
    'fill-outline-color': APP_COLOR,
    'fill-opacity': 0.1
  }
};

export const buildingsLineLayer: LineLayer = {
  id: 'buildingsLineLayer',
  source: 'cadastre_layers',
  'source-layer': 'batiments',
  type: 'line',
  minzoom: 14,
  paint: {
    'line-color': SOFTROCKS_COLOR,
    'line-width': 1,
    'line-opacity': 0.1
  }
};

export const parcelsFillLayer: FillLayer = {
  id: 'parcelsFillLayer',
  source: 'cadastre_layers',
  'source-layer': 'parcelles',
  type: 'fill',
  minzoom: 14,
  paint: {
    'fill-color': GROUND_COLOR,
    'fill-opacity': 0.1,
    'fill-outline-color': GROUND_COLOR
  }
};

export const parcelsLineLayer: LineLayer = {
  id: 'parcelsLineLayer',
  source: 'cadastre_layers',
  'source-layer': 'parcelles',
  type: 'line',
  minzoom: 14,
  paint: {
    'line-color': GROUND_COLOR,
    'line-width': 1,
    'line-opacity': 0.3
  }
};

export const selectedParcelFillLayer: FillLayer = {
  id: 'selectedParcelFillLayer',
  source: 'selected_parcels',
  type: 'fill',
  minzoom: 14,
  layout: {},
  paint: {
    'fill-color': INFO_COLOR,
    'fill-opacity': 0.25
  }
};

export const selectedParcelLineLayer: LineLayer = {
  id: 'selectedParcelLineLayer',
  type: 'line',
  source: 'selected_parcels',
  layout: {},
  paint: {
    'line-color': INFO_COLOR,
    'line-width': 2,
    'line-opacity': 1,
    'line-dasharray': [2, 2]
  }
};

export const numeroSymbolLayer: SymbolLayer = {
  id: 'numeroSymbolLayer',
  type: 'symbol',
  source: 'cadastre_layers',
  'source-layer': 'parcelles',
  minzoom: 16.5,
  layout: {
    'text-field': ['get', 'numero'],
    'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
    'text-radial-offset': 0.5,
    'text-justify': 'auto'
    // 'text-font': 'Barlow ExtraLight'
  }
};

export const waterLayer: FillLayer = {
  id: 'water',
  source: 'mapbox-streets',
  'source-layer': 'water',
  type: 'fill',
  paint: {
    'fill-color': WATER_COLOR,
    'fill-opacity': 0.1
  }
};

export const roadLineLayer: LineLayer = {
  id: 'roadLineLayer',
  source: 'mapbox-streets',
  'source-layer': 'road',
  type: 'line',
  minzoom: 14,
  paint: {
    'line-color': GRAY_COLOR,
    'line-width': 2,
    'line-opacity': 0.2
  }
};

export const backgroundLayer: BackgroundLayer = {
  id: 'backgroundLayer',
  source: 'mapbox-streets',
  // 'source-layer': 'road',
  type: 'background',
  paint: {
    'background-color': '#fff',
    'background-opacity': 0.3
  }
};

export const layers = [
  backgroundLayer,
  waterLayer,
  roadLineLayer,
  buildingsFillLayer,
  buildingsLineLayer,
  parcelsFillLayer,
  parcelsLineLayer,
  numeroSymbolLayer
];
