import { getCaseDataAdjustedLevelsData } from './getCaseDataAdjustedLevelsData';
import { CaseGranulometry } from '../../granulometry/cases/CaseGranulometry';
import { getTopLevelMinimumBearingSurface } from '../../granulometry/levels/queries/topLevels/surfaces/getTopLevelMinimumBearingSurface';
import { getInFullFilledTopLevelBearingSectionSurface } from '../../granulometry/levels/queries/topLevels/sections/surfaces/getInFullFilledTopLevelBearingSectionSurface';
import { getFilledCaseGranulometry } from './fillCaseLevels/getFilledCaseGranulometry';
import { isTopLevel } from '../../granulometry/levels/queries/is/isTopLevel';

type LevelDelta = { level: number; delta: number };
export type LevelSFSAdjusment = { level: number; adjustment: number };

/* Fill the case object with adjusted levels data */
export const fillCaseLevels = (caseGranulometry: CaseGranulometry): CaseGranulometry => {
  // # Step : 1 : Normal granulo (without using minimum bearing surfaces)
  const adjustedLevelsData = getCaseDataAdjustedLevelsData(caseGranulometry.initialSpecifications);
  const filledCaseGranulometry = getFilledCaseGranulometry(
    adjustedLevelsData,
    caseGranulometry,
    false
  );

  // # Step : 2 : Check bearing surface vs bearing minimum surface delta
  if (
    // hasCase2TopLevelsAtLeast(filledCaseGranulometry) &&
    // hasCaseLodgmentInGroundLevel(filledCaseGranulometry) &&
    !filledCaseGranulometry.initialSpecifications.maxSurfaceForSaleHasBeenForced
  ) {
    let positiveBearingSurfacesDelta: LevelDelta[] = [];
    let negativeBearingSurfacesDelta: LevelDelta[] = [];
    // For each levels
    for (let i = 0; i < filledCaseGranulometry.levels.length; i++) {
      const levelGranulometry = filledCaseGranulometry.levels[i];
      // Check top levels only
      if (isTopLevel(caseGranulometry, levelGranulometry)) {
        // Get delta
        const bearingSurfaceDelta =
          getInFullFilledTopLevelBearingSectionSurface(levelGranulometry).value -
          getTopLevelMinimumBearingSurface(caseGranulometry, levelGranulometry).value;
        // Sort in arrays bearing surface delta
        if (bearingSurfaceDelta < 0) {
          negativeBearingSurfacesDelta = [
            ...negativeBearingSurfacesDelta,
            { level: levelGranulometry.level, delta: bearingSurfaceDelta }
          ];
        } else {
          positiveBearingSurfacesDelta = [
            ...positiveBearingSurfacesDelta,
            { level: levelGranulometry.level, delta: bearingSurfaceDelta }
          ];
        }
      }
    }

    // # Step 3 : If there is at least one negative delta :
    // we deduct the negative values on positive ones and we recalculate everything
    if (negativeBearingSurfacesDelta.length !== 0) {
      const totalNegativeSurfacesDelta = negativeBearingSurfacesDelta.reduce(
        (acc, levelDelta) => acc + levelDelta.delta,
        0
      );
      const surfaceToAddOnEachPositiveDeltaLevelsSFS =
        (totalNegativeSurfacesDelta / positiveBearingSurfacesDelta.length) * -1;
      // For each levels
      let levelsSFSAdjustments: LevelSFSAdjusment[] = [];
      for (let i = 0; i < filledCaseGranulometry.levels.length; i++) {
        const levelGranulometry = filledCaseGranulometry.levels[i];
        // Top levels only
        if (isTopLevel(caseGranulometry, levelGranulometry)) {
          // Get delta
          const bearingSurfaceDelta =
            getInFullFilledTopLevelBearingSectionSurface(levelGranulometry).value -
            getTopLevelMinimumBearingSurface(caseGranulometry, levelGranulometry).value;
          // Get and record adjustment to do on level SFS
          // if the delta is negative : the delta is deducted
          // if the delta is positive : the above calculated surface is added
          const adjustment =
            bearingSurfaceDelta < 0
              ? bearingSurfaceDelta
              : surfaceToAddOnEachPositiveDeltaLevelsSFS;
          levelsSFSAdjustments = [
            ...levelsSFSAdjustments,
            { level: levelGranulometry.level, adjustment }
          ];
        }
      }

      const reAdjustedLevelsData = getCaseDataAdjustedLevelsData(
        caseGranulometry.initialSpecifications,
        levelsSFSAdjustments
      );
      const reFilledCaseGranulometry = getFilledCaseGranulometry(
        reAdjustedLevelsData,
        caseGranulometry,
        true
      );

      return reFilledCaseGranulometry;
    }
    return filledCaseGranulometry;
  }

  return filledCaseGranulometry;
};
