import * as R from 'ramda';
import { CaseGranulometry } from '../../../../granulometry/cases/CaseGranulometry';
import { TopLevelDataLegacy } from '../../../caseFormData';
import { getCaseUpperLevels } from '../../../../granulometry/cases/queries/levels/getCaseUpperLevels';
import { mustTopLevelGfsEffBeAdjusted } from '../../../../granulometry/levels/queries/topLevels/surfaces/grossFloorSurfaces/mustTopLevelGfsEffBeAdjusted';
import { getCaseUpperLevelsWithAdjustedGfsEffCount } from '../../../../granulometry/cases/queries/levels/counts/getCaseUpperLevelsWithAdjustedGfsEffCount';
import { getCaseDataSpecifiedTopLevelCount } from '../../../../specification/cases/queries/levels/counts/getCaseDataSpecifiedTopLevelCount';
import { getCaseTopLevelsAutoCountFromRealBuiltSurface } from '../../../../projection/cases/queries/levels/topLevelsCount/getCaseTopLevelsAutoCountFromRealBuiltSurface';
import { getTopLevelForcedGrossFloorSurfaceEff } from '../../../../granulometry/levels/queries/topLevels/surfaces/grossFloorSurfaces/getTopLevelForcedGrossFloorSurfaceEff';
import { LevelGranulometry } from '../../../../granulometry/levels/LevelGranulometry';

export const adjustGfsEffRelativeHighestLevelRbs = (
  caseGranulometry: CaseGranulometry
): CaseGranulometry => {
  // 1 : Calculate the RBS difference between case with ant without floor space
  const caseWithoutFloorSpaceRBS = caseGranulometry.initialSpecifications
    .realBuiltSurface as number;
  const caseWithFloorSpaceRBS = (
    caseGranulometry.initialSpecifications.gfsEffRelativeTopLevelsRBS as TopLevelDataLegacy[]
  ).reduce((acc, l) => acc + (l.realBuiltSurface as number), 0);
  const caseRBSDiff = caseWithFloorSpaceRBS - caseWithoutFloorSpaceRBS;
  const caseDataSpecifiedTopLevelCount = getCaseDataSpecifiedTopLevelCount(
    caseGranulometry.initialSpecifications
  );
  const caseTopLevelsAutoCountFromRealBuiltSurface = getCaseTopLevelsAutoCountFromRealBuiltSurface(
    caseGranulometry.initialSpecifications.realBuiltSurface as number
  );
  const hasLessSpecifiedTopLevelCountThanDefaultCount =
    caseDataSpecifiedTopLevelCount !== undefined &&
    caseDataSpecifiedTopLevelCount < caseTopLevelsAutoCountFromRealBuiltSurface;
  caseGranulometry = R.assoc(
    'diffBetweenRBSWithoutFloorSpaceAndRBSWithFloorSpace',
    hasLessSpecifiedTopLevelCountThanDefaultCount && caseRBSDiff >= 0
      ? caseRBSDiff * -1
      : caseRBSDiff
  )(caseGranulometry);

  // 2 : Adjust levels to correct this difference
  let upperLevelsWithAdjustedGfsEffCount =
    getCaseUpperLevelsWithAdjustedGfsEffCount(caseGranulometry);
  let forceAllUnforcedGfsEffTopLevelsAdjustment = false;

  // In the case of the gfs eff distribution give all level at the same gfsEff
  // but we still need an adjustment (ex : 5 facades or special floor space drawing...)
  // we force the gfsEff adjustment on each unforced upper levels
  if (caseRBSDiff !== 0 && upperLevelsWithAdjustedGfsEffCount === 0) {
    forceAllUnforcedGfsEffTopLevelsAdjustment = true;
    upperLevelsWithAdjustedGfsEffCount = R.reject<LevelGranulometry>(
      (l) => getTopLevelForcedGrossFloorSurfaceEff(caseGranulometry, l) !== undefined
    )(getCaseUpperLevels(caseGranulometry)).length;
  }

  getCaseUpperLevels(caseGranulometry).forEach((levelGranulometry) => {
    if (
      mustTopLevelGfsEffBeAdjusted(caseGranulometry, levelGranulometry) ||
      (forceAllUnforcedGfsEffTopLevelsAdjustment && upperLevelsWithAdjustedGfsEffCount !== 0)
    ) {
      const levelIndex = (
        caseGranulometry.initialSpecifications.gfsEffRelativeTopLevelsRBS as TopLevelDataLegacy[]
      ).findIndex((l) => l.level === levelGranulometry.level);
      caseGranulometry = R.assocPath(
        ['initialSpecifications', 'gfsEffRelativeTopLevelsRBS', levelIndex, 'realBuiltSurface'],
        ((
          (
            caseGranulometry.initialSpecifications
              .gfsEffRelativeTopLevelsRBS as TopLevelDataLegacy[]
          )[levelIndex] as TopLevelDataLegacy
        ).realBuiltSurface as number) -
          caseRBSDiff / upperLevelsWithAdjustedGfsEffCount
      )(caseGranulometry) as CaseGranulometry;
    }
  });

  return caseGranulometry;
};
