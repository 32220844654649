import * as React from 'react';
import { Window } from 'components/ui/Window';
import { useDispatch, useSelector } from 'react-redux';
import { goToCase, goToProjectDetails } from '../../../../store/actions/navigations.actions';
import { useParams } from 'react-router';
import { IconObject } from '../../../ui/Icons/iconObject';
import {
  getCaseFloorSpaceTriggerId,
  getProjectDetailsFloorSpaceTriggerId
} from '../../../../routes/toolboxPanels/triggers';
import { useTranslation } from 'react-i18next';
import { FloorSpace } from './FloorSpace';
import { selectCurrentCaseLabel } from '../../../../store/selectors/project/currentCaseLabel.selector';
import { selectCurrentCaseIdFromRoute } from '../../../../store/selectors/navigation/caseIdFromRoute.selector';
import { CaseGranulometryEditFloorSpaceRouteParams } from '../../../../routes/toolboxPanels/cases';

export const FloorSpaceWindow = () => {
  const { projectId } = useParams<CaseGranulometryEditFloorSpaceRouteParams>();
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const caseId = useSelector(selectCurrentCaseIdFromRoute);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClickClose = React.useCallback(() => {
    if (projectId && caseId) {
      dispatch(goToCase(projectId, caseId));
    } else if (projectId) {
      dispatch(goToProjectDetails(projectId));
    }
  }, [dispatch, projectId, caseId]);

  return (
    <Window
      className="floorSpaceWindow"
      title={`${t('floorSpaceWindow.title')}${
        caseLabel ? ' ' + t('floorSpaceWindow.ofTheCase') + ' ' + caseLabel : ''
      }`}
      triggerId={
        caseLabel ? getCaseFloorSpaceTriggerId(caseLabel) : getProjectDetailsFloorSpaceTriggerId()
      }
      topButtons={
        <>
          <IconObject iconName="close" type="menu" onClick={handleClickClose} />
        </>
      }
      large={true}
      noScroll={true}>
      <FloorSpace />
    </Window>
  );
};
