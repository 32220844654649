import * as React from 'react';
import classNames from 'classnames';
import { IconObject } from '../../../ui/Icons/iconObject';
import { Panel } from '../../../../interfaces/Panel';

interface Props {
  currentPanel?: Panel;
  disabled: boolean;
  goToProject: () => void;
  goToProjectDetails: () => void;
  goToFirstBuilding: () => void;
  goToFirstCase: () => void;
  goToSections: () => void;
  goToSmartRequests: () => void;
  goToDeliverables: () => void;
}

export const BareToolBoxMenu: React.FC<Props> = ({
  currentPanel,
  disabled,
  goToProject,
  goToProjectDetails,
  goToFirstBuilding,
  goToFirstCase,
  goToSections,
  goToSmartRequests,
  goToDeliverables
}) => {
  return (
    <div className="icon-menu">
      <div className="icon-menu-inner">
        <div
          className={classNames('icon-menu-item', {
            'icon-menu-item--enabled': currentPanel === Panel.ProjectDetails
          })}>
          <IconObject
            disabled={disabled && currentPanel !== Panel.ProjectDetails}
            iconName="lands"
            id="lands"
            type="menu"
            onClick={currentPanel !== Panel.ProjectDetails ? goToProjectDetails : goToProject}
          />
        </div>
        <div
          className={classNames('icon-menu-item', {
            'icon-menu-item--enabled': currentPanel === Panel.Buildings
          })}>
          <IconObject
            disabled={disabled && currentPanel !== Panel.Buildings}
            iconName="buildings"
            id="buildings"
            type="menu"
            onClick={currentPanel !== Panel.Buildings ? goToFirstBuilding : goToProject}
          />
        </div>
        <div
          className={classNames('icon-menu-item', {
            'icon-menu-item--enabled': currentPanel === Panel.Cases
          })}>
          <IconObject
            disabled={disabled && currentPanel !== Panel.Cases}
            iconName="cases"
            id="cases"
            type="menu"
            onClick={currentPanel !== Panel.Cases ? goToFirstCase : goToProject}
          />
        </div>
        <div
          className={classNames('icon-menu-item', {
            'icon-menu-item--enabled': currentPanel === Panel.Sections
          })}>
          <IconObject
            disabled={disabled && currentPanel !== Panel.Sections}
            iconName="sections"
            id="sections"
            type="menu"
            onClick={currentPanel !== Panel.Sections ? goToSections : goToProject}
          />
        </div>
        <div
          className={classNames('icon-menu-item', {
            'icon-menu-item--enabled': currentPanel === Panel.SmartRequests
          })}>
          <IconObject
            disabled={disabled && currentPanel !== Panel.SmartRequests}
            iconName="smartRequests"
            id="smartRequests"
            type="menu"
            onClick={currentPanel !== Panel.SmartRequests ? goToSmartRequests : goToProject}
          />
        </div>
        <div
          className={classNames('icon-menu-item', {
            'icon-menu-item--enabled': currentPanel === Panel.Deliverables
          })}>
          <IconObject
            disabled={disabled && currentPanel !== Panel.Deliverables}
            iconName="deliverables"
            id="deliverables"
            type="menu"
            onClick={currentPanel !== Panel.Deliverables ? goToDeliverables : goToProject}
          />
        </div>
      </div>
    </div>
  );
};
