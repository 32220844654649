import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup } from '../../../../ui/FormGroup';
import { Input } from '../../../../ui/input/Input';
import { Option, Select } from '../../../../ui/Select';
import { CommonPremiseSection } from '../../../../../domain/granulometry/sections/commonPremiseSections/CommonPremiseSection';
import { ShopSection } from '../../../../../domain/granulometry/sections/shopSection/shopSection';
import { useSelector } from 'react-redux';
import { selectCurrentLevelGranulometry } from '../../../../../store/selectors/granulometry/currentLevel/granulometry.selector';
import { selectCurrentCaseGranulometry } from '../../../../../store/selectors/granulometry/currentCase/currentCaseGranulometry.selector';
import { roundWith2Decimal } from '../../../../../utils/round/roundWith2Decimal';
import { getSectionRealOutsideWallsLinear } from '../../../../../domain/granulometry/sections/queries/walls/realWalls/getSectionRealOutsideWallsLinear';
import { getSectionDefaultGlazedLinear } from '../../../../../domain/granulometry/sections/queries/getSectionDefaultGlazedLinear';
import { getSectionGlazedSurface } from '../../../../../domain/granulometry/sections/queries/surfaces/getSectionGlazedSurface';
import { getSectionGlazedSurfaceDetails } from '../../../../../domain/granulometry/sections/queries/surfaces/getSectionGlazedSurface.details';
import { secureInputChange } from '../../../../../utils/secureInputChange';

interface GlazedSurfaceInputProps {
  prefix: string;
  index: number;
  section: CommonPremiseSection | ShopSection;
  handleChange: (value: number | string | null, index: number, input: string) => void;
}

export const GlazedSurfaceInput = ({
  prefix,
  index,
  section,
  handleChange
}: GlazedSurfaceInputProps) => {
  const levelGranulometry = useSelector(selectCurrentLevelGranulometry);
  const caseGranulometry = useSelector(selectCurrentCaseGranulometry);

  const { t } = useTranslation();

  const inputUnitOptions: Option[] = [
    { label: 'mL', value: 'mL' },
    { label: '%', value: '%' }
  ];

  const [selectedInputUnitOption, setSelectedInputUnitOption] = React.useState<Option>(
    inputUnitOptions[0]
  );

  const isLinearSelected = selectedInputUnitOption.value === 'mL';
  const sectionRealOutsideWallsLinear =
    !!levelGranulometry && !!caseGranulometry
      ? getSectionRealOutsideWallsLinear(caseGranulometry, levelGranulometry, section)
      : 0;
  const min = 0;
  const max = isLinearSelected ? roundWith2Decimal(sectionRealOutsideWallsLinear) : 100;

  if (!levelGranulometry || !caseGranulometry) return null;

  return (
    <div className={'glazedSurfaceInput'}>
      <FormGroup horizontal={true} wrap={false}>
        <div>
          <Input
            name={prefix + 'FacadeLinear'}
            label={t('glazedSurfaceInput.facadeLinear.label')}
            type="number"
            value={roundWith2Decimal(sectionRealOutsideWallsLinear)}
            disabled={true}
            suffix={'mL'}
          />
        </div>
        <div>
          <label style={{ width: (isLinearSelected ? '55' : '75') + 'px' }}>
            {t(
              'glazedSurfaceInput.glazedSurface' + (isLinearSelected ? 'Linear' : 'Rate') + '.label'
            )}
          </label>
          <div>
            <div>
              <Input
                name={prefix + 'GlazedLinear'}
                type="number"
                min={min}
                max={max}
                minMax={{
                  min,
                  max,
                  unit: isLinearSelected ? 'mL' : '%'
                }}
                value={
                  section.glazedLinear !== undefined
                    ? roundWith2Decimal(
                        isLinearSelected
                          ? section.glazedLinear
                          : (section.glazedLinear / sectionRealOutsideWallsLinear) * 100
                      )
                    : undefined
                }
                handleChange={(value) => {
                  const convertedValue =
                    selectedInputUnitOption.value === '%' && value !== null
                      ? (value * sectionRealOutsideWallsLinear) / 100
                      : value;
                  handleChange(secureInputChange(convertedValue, min, max), index, 'glazedLinear');
                }}
                placeholder={roundWith2Decimal(
                  isLinearSelected
                    ? getSectionDefaultGlazedLinear(caseGranulometry, levelGranulometry, section)
                    : section.defaultGlazedRate * 100
                )}
              />
            </div>
            <Select
              id={prefix + 'Type'}
              options={inputUnitOptions}
              handleChange={(option: Option) => setSelectedInputUnitOption(option)}
              value={inputUnitOptions.find(
                (option) => option.value === selectedInputUnitOption.value
              )}
            />
          </div>
        </div>
        <div>
          <label>=</label>
        </div>
        <div>
          <Input
            name={prefix + 'GlazedLinear'}
            label={t('glazedSurfaceInput.glazedSurface.label')}
            info={getSectionGlazedSurfaceDetails(caseGranulometry, levelGranulometry, section)}
            type="number"
            value={roundWith2Decimal(
              getSectionGlazedSurface(caseGranulometry, levelGranulometry, section)
            )}
            disabled={true}
            suffix={'m&#178;'}
          />
        </div>
      </FormGroup>
    </div>
  );
};
