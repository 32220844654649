import { CaseFormDataLegacy } from '../caseFormData';
import { LodgmentType } from '../../specification/lodgmentTypes/LodgmentType';

export const getCaseDataLodgmentDistribution = (caseData: CaseFormDataLegacy) => {
  const clonedLodgmentDistribution = [...caseData.distribution];
  const needToBeReversed =
    clonedLodgmentDistribution[0].lodgmentType.toLowerCase() === LodgmentType.T1;
  if (needToBeReversed) {
    clonedLodgmentDistribution.reverse();
  }

  return clonedLodgmentDistribution;
};
