import { createSelector, Selector } from 'reselect';
import { State } from '../../../reducers';
import { selectCurrentSectionIdFromRoute } from '../../navigation/sectionIdFromRoute.selector';
import { selectGranulometry } from '../current.selector';
import { CaseGranulometry } from '../../../../domain/granulometry/cases/CaseGranulometry';
import { getLevelFullFilledContent } from '../../../../domain/granulometry/levels/queries/content/getLevelFullFilledContent';

export const selectCurrentSectionCaseGranulometry: Selector<State, CaseGranulometry | undefined> =
  createSelector(
    [selectGranulometry, selectCurrentSectionIdFromRoute],
    (granulometry, sectionId) => {
      if (granulometry && sectionId) {
        for (const buildingGranulometry of granulometry) {
          for (const caseGranulometry of buildingGranulometry.cases) {
            for (const levelGranulometry of caseGranulometry.levels) {
              if (
                getLevelFullFilledContent(levelGranulometry).find(
                  (section) => section.id === sectionId
                )
              ) {
                return caseGranulometry;
              }
            }
          }
        }
      }
      return undefined;
    }
  );
