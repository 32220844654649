import { initZoom } from './config/handleZoom';
import { updateMarkerFacades } from './domain/facades/marker/updateMarkerFacades';
import { Dispatch } from 'redux';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import mapboxgl from 'mapbox-gl';
import { initParcelLayer } from './domain/parcel/initParcelLayer';
import { MapElementsToDraw } from '../../store/selectors/map/mapElementsToDraw.selector';
import { activateOrDeactivateFloorSpace } from './domain/floorSpace/activateOrDeactivateFloorSpace';
import { initEvents } from './init';
import { DrawServices } from './mapboxDraw/draw.services';
import { DrawHelperServices } from './domain/drawHelper/drawHelper.services';
import { DrawRepository } from './mapboxDraw/draw.repository';
import { MapRepository } from './map/map.repository';
import { MapServices } from './map/map.services';

export const reInit = (
  draw: MapboxDraw,
  map: mapboxgl.Map,
  dispatch: Dispatch,
  floorSpaceState: MapElementsToDraw,
  caseLabel?: string
) => {
  const drawRepo = new DrawRepository(map, draw);
  const updateFacades = updateMarkerFacades(map);
  const mapRepo = new MapRepository(map);
  const mapServices = new MapServices(mapRepo);
  const drawHelperServices = new DrawHelperServices(mapServices, drawRepo);
  const drawServices = new DrawServices(drawRepo, updateFacades, drawHelperServices);

  const onLoad = new Promise<{ map: mapboxgl.Map; draw: MapboxDraw }>((resolve) => {
    map.on('load', () => {
      draw.add({
        type: 'FeatureCollection',
        features: floorSpaceState.floorSpaceFeatures
      });

      initZoom(map);
      initEvents(map, draw);

      resolve({ map, draw });
    });
  });

  onLoad.then(() => {
    floorSpaceState.parcels && initParcelLayer(map, floorSpaceState.parcels);
    drawServices.reInitFeatures(floorSpaceState);

    activateOrDeactivateFloorSpace(draw, floorSpaceState)(caseLabel);
  });

  return { map, draw };
};
