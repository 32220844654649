import { LevelGranulometryFullFilled } from '../../LevelGranulometry';
import { getTopLevelHoppersSurface } from '../topLevels/surfaces/getTopLevelHoppersSurface';
import { CaseGranulometry } from '../../../cases/CaseGranulometry';
import { getLevelRealBuiltSurface } from './getLevelRealBuiltSurface';

export const getLevelRealBuiltSurfaceEff = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled
): number => {
  return (
    getLevelRealBuiltSurface(levelGranulometry, caseGranulometry.initialSpecifications) +
    getTopLevelHoppersSurface(caseGranulometry, levelGranulometry)
  );
};
