import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initMapBox } from '../../../../services/mapBox/init';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { FloorSpaceMapCard } from './FloorSpaceMapCard';
import { FloorSpaceSideCards } from './FloorSpaceSideCards';
import { selectMapElementsToDraw } from '../../../../store/selectors/map/mapElementsToDraw.selector';
import { selectProjectPosition } from '../../../../store/selectors/map/projectPosition.selector';
import classNames from 'classnames';
import { selectCurrentCaseLabel } from '../../../../store/selectors/project/currentCaseLabel.selector';
import {
  PROJECT_LOCATION_DEFAULT_LATITUDE,
  PROJECT_LOCATION_DEFAULT_LONGITUDE
} from '../../../../domain/ProjectLocation';
import { Position } from '@turf/helpers/dist/js/lib/geojson';
import { FloorSpaceSideDrawMode } from './FloorSpaceSideDrawMode';
import { FloorSpaceCollection } from '../../../../domain/specification/floorSpace/FloorSpaceFeature';
import { fireDrawHelperEvents } from '../../../../services/mapBox/domain/drawHelper/drawHelper.events';

export interface MaybeUiFloorSpace {
  map: mapboxgl.Map | undefined;
  draw: MapboxDraw | undefined;
}
export interface UiFloorSpace {
  map: mapboxgl.Map;
  draw: MapboxDraw;
}

export const FloorSpace = () => {
  const dispatch = useDispatch();

  const mapElements = useSelector(selectMapElementsToDraw);
  const projetPosition = useSelector(selectProjectPosition);
  const caseLabel = useSelector(selectCurrentCaseLabel);

  const withSideCards = mapElements.parcels || mapElements.floorSpaceFeatures.length !== 0;

  const [drawHelperMode, setDrawHelperMode] = useState(false);

  const [ui, setUi] = useState<MaybeUiFloorSpace>({
    map: undefined,
    draw: undefined
  });

  const mapContainer = useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (!ui.map) {
      const { map, draw } = initMapBox(
        mapContainer,
        projetPosition ||
          ([
            PROJECT_LOCATION_DEFAULT_LATITUDE,
            PROJECT_LOCATION_DEFAULT_LONGITUDE
          ] as unknown as Position),
        dispatch,
        mapElements,
        caseLabel
      );
      return setUi({ map, draw });
    }
  }, [projetPosition]);

  useEffect(() => {
    if (ui.map) fireDrawHelperEvents(ui.map).hide();
  }, [drawHelperMode]);

  const toggleSetDrawHelperMode = () => setDrawHelperMode((state) => !state);

  return (
    <div className={classNames('floorSpace', { withSideCards })}>
      <FloorSpaceMapCard
        mapContainer={mapContainer}
        ui={ui as UiFloorSpace}
        setMode={toggleSetDrawHelperMode}
        drawHelperMode={drawHelperMode}
      />
      {withSideCards && !drawHelperMode && <FloorSpaceSideCards ui={ui} />}
      {drawHelperMode && (
        <FloorSpaceSideDrawMode
          ui={ui as UiFloorSpace}
          floorSpaceCollection={ui.draw?.getAll() as FloorSpaceCollection}
          toggleSetDrawHelperMode={toggleSetDrawHelperMode}
        />
      )}
    </div>
  );
};
