import * as R from 'ramda';
import { CaseGranulometry } from '../../cases/CaseGranulometry';
import { LodgmentType } from '../../../specification/lodgmentTypes/LodgmentType';
import { redistributeCaseTopLevelsWithCustomDistribution } from './redistributeCaseTopLevelsWithCustomDistribution';
import { redistributeCaseBasementLevelsWithCustomDistribution } from './redistributeCaseBasementLevelsWithCustomDistribution';
import { LevelGranulometry } from '../../levels/LevelGranulometry';
import { redistributeCaseFoundationsWithCustomDistribution } from './redistributeCaseFoundationsWithCustomDistribution';
import { updateCaseGranulometryWithCustomDistribution } from './updateCaseGranulometryWithCustomDistribution';

export type CountByLodgmentType = { [key in LodgmentType]: number } | {};

export const redistributeCaseWithCustomDistribution = (
  caseGranulometry: CaseGranulometry
): CaseGranulometry =>
  R.tryCatch<CaseGranulometry>(
    R.over(
      R.lensProp('levels'),
      R.pipe(
        (levels: LevelGranulometry[]) =>
          redistributeCaseTopLevelsWithCustomDistribution(levels, caseGranulometry),
        (levels: LevelGranulometry[]) =>
          redistributeCaseBasementLevelsWithCustomDistribution(levels, caseGranulometry),
        (levels: LevelGranulometry[]) =>
          redistributeCaseFoundationsWithCustomDistribution(levels, caseGranulometry)
      )
    ),
    (error, cage) => {
      // eslint-disable-next-line no-console
      console.error(error);
      return cage;
    }
  )(updateCaseGranulometryWithCustomDistribution(caseGranulometry));
