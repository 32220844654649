import * as React from 'react';
import { useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { Route } from 'react-router';
import * as Sentry from '@sentry/react';
import { selectHasGranulometry } from '../../../store/selectors/granulometry/hasGranulometryRendering.selector';
import { Form } from '../../../components/ui/Form';
import { CasesPanelWindows } from '../windows/CasesPanelWindows';
import { CardToScroll } from './CardToScroll';
import ScrollGradient from './ScrollGradient';
import { TopLevelSurfaceName } from '../../../domain/granulometry/cases/queries/surfaces/getCaseTopLevelsSurfaces';
import { caseGranulometryEditRoute } from '../../../routes/toolboxPanels/cases';
import { DefaultFallback } from '../../../DefaultFallback';

interface GranulometryViewProps {
  isToolboxEnlarged: boolean;
  setIsToolboxEnlarged: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GranulometryView = ({
  isToolboxEnlarged,
  setIsToolboxEnlarged
}: GranulometryViewProps) => {
  const hasGranulometry = useSelector(selectHasGranulometry);

  const [selectedLevelSurface, setSelectedLevelSurface] = React.useState<TopLevelSurfaceName>(
    process.env.GB_FEAT_GFS === 'true' ? 'grossFloorSurfaceEff' : 'realBuiltSurface'
  );

  return (
    <Sentry.ErrorBoundary fallback={DefaultFallback} showDialog>
      <>
        <Route path={caseGranulometryEditRoute.path} component={CasesPanelWindows} />
        <Form className="granulometry">
          <Scrollbars
            autoHide={true}
            autoHideTimeout={1000}
            autoHideDuration={200}
            autoHeight={true}
            autoHeightMin="100%"
            autoHeightMax="100%"
            style={{ width: '100%', height: 'calc(100vh - 116px)' }}
            renderTrackVertical={(properties) => <div {...properties} className="track-vertical" />}
            onScrollFrame={(values) => {
              const scrollGradient = document.querySelector('.scroll-gradient');
              if (scrollGradient !== null) {
                if (values.clientHeight + values.scrollTop === values.scrollHeight) {
                  scrollGradient.classList.add('end');
                } else {
                  scrollGradient.classList.remove('end');
                }
              }
            }}>
            {hasGranulometry && (
              <CardToScroll
                isToolboxEnlarged={isToolboxEnlarged}
                setIsToolboxEnlarged={setIsToolboxEnlarged}
                selectedLevelSurface={selectedLevelSurface}
                setSelectedLevelSurface={setSelectedLevelSurface}
              />
            )}
          </Scrollbars>
          <ScrollGradient />
        </Form>
      </>
    </Sentry.ErrorBoundary>
  );
};
