import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import { ReportTable } from './ReportTable';
import { IconObject } from '../../../../components/ui/Icons/iconObject';
import { selectProjectId } from '../../../../store/selectors/project';
import { selectReportDataTypeLabel } from '../../../../store/selectors/deliverables/reportDataTypeLabel.selector';
import { Checkbox } from '../../../ui/Checkbox';
import { goToDeliverablesExport } from '../../../../store/actions/navigations.actions';
import { selectIsReportOutdated } from '../../../../store/selectors/report/isReportOutdated.selector';
import { selectReportNomenclatureTitle } from '../../../../store/selectors/deliverables/reportNomenclatureTitle.selector';

export const ReportTableModal = () => {
  const projectId = useSelector(selectProjectId) as string;
  const isReportOutdated = useSelector(selectIsReportOutdated);
  const dataTypeLabel = useSelector(selectReportDataTypeLabel);
  const nomenclatureTitle = useSelector(selectReportNomenclatureTitle);

  const [apiVersion, setApiVersion] = React.useState<1 | 2>(1);

  const dispatch = useDispatch();

  if (isReportOutdated) {
    return null;
  }

  return (
    <Modal
      overlayElement={(props, contentEl) =>
        props &&
        contentEl && (
          <div {...props}>
            <IconObject
              iconName="closeCurrentProject"
              type="menu"
              className="close"
              onClick={() => dispatch(goToDeliverablesExport(projectId))}
            />
            {contentEl}
          </div>
        )
      }
      isOpen={true}
      onRequestClose={() => dispatch(goToDeliverablesExport(projectId))}
      className="modal"
      overlayClassName="modalOverlay"
      parentSelector={() => document.querySelector('#root') as HTMLElement}>
      <h2 style={{ paddingBottom: '10px' }}>
        {`${nomenclatureTitle} : ${dataTypeLabel}`}{' '}
        {process.env.GB_BACKEND_PREVIEW === 'true' && (
          <Checkbox
            label="Données V2"
            selected={apiVersion === 2}
            checkboxName="apiVersion"
            handleCheck={() => setApiVersion(apiVersion === 1 ? 2 : 1)}
            style={{ position: 'absolute', zIndex: 1 }}
          />
        )}
      </h2>

      <ReportTable apiVersion={apiVersion} />
    </Modal>
  );
};
