import { APP_COLOR } from '../../../../constants/appConstants';
import { getSectionName, Section, SectionDrawn } from '../Section';
import i18next from 'i18next';
import { getDefaultCasePropertyValue } from '../../../specification/cases/queries/get/properties/getDefaultCasePropertyValue';
import { CirculationName } from './CirculationSection';
import { SectionWithTheoreticalSurface } from '../SectionWithTheoreticalSurface';
import { SectionWithExposureRate } from '../SectionWithExposureRate';
import { IconObjectProps } from '../../../../components/ui/Icons/iconObject';
import { SectionWithWidth } from '../SectionWithWidth';

interface SasInfraProps {
  surface: number;
  width: number;
}

export interface SasInfraSection
  extends Section,
    SectionDrawn,
    SectionWithWidth,
    SectionWithTheoreticalSurface,
    SectionWithExposureRate {
  name: CirculationName.SasInfra;
}

export const SAS_ICON = {
  type: 'object',
  iconName: 'sas',
  color: APP_COLOR
} as IconObjectProps;

const sasInfra = ({ surface, width }: SasInfraProps): SasInfraSection => ({
  // Section
  id: 'NOID',
  name: CirculationName.SasInfra,
  title: i18next.t('sasInfra.title'),
  type: 'circulation',
  displayedSurface: surface,
  // SectionDrawn
  surface,
  color: APP_COLOR,
  icon: SAS_ICON,
  // SectionWithTheoreticalSurface
  theoreticalSurface: undefined,
  defaultTheoreticalSurface: getDefaultCasePropertyValue('sasSurface'),
  // SectionWithExposureRate
  exposureRate: undefined,
  defaultExposureRate: 0,
  // SasSection
  width
});

export default sasInfra;

export const isSectionASasInfra = (section: Section): section is SasInfraSection =>
  getSectionName(section) === 'sasInfra';
