import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { goToAppMenu, goToAppMenuEditUser } from 'store/actions/navigations.actions';
import { selectProjectId } from 'store/selectors/project';
import { selectRoute } from 'store/selectors/navigation/getRoute.selector';
import userState from 'store/reducers/user.slice';
import { Button } from '../../ui/Button';
import { Card } from '../../ui/Card';
import { IconObject } from '../../ui/Icons/iconObject';
import { SpecialLabel } from '../../ui/SpecialLabel';
import { selectUser } from 'store/selectors/user/user.selector';

const UserCard = () => {
  const user = useSelector(selectUser);
  const projectId = useSelector(selectProjectId) || '';
  const currentRoute = useSelector(selectRoute);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const onLogoutClick = React.useCallback(() => {
    dispatch(userState.actions.logout());
  }, [dispatch]);

  if (!user) return null;

  return (
    <Card className="userCard">
      <div className="col1">
        <div>
          <IconObject iconName="account" title="Account" type="menu" clickable={false} />
        </div>
        <div>
          <IconObject iconName="logout" title="Logout" type="menu" onClick={onLogoutClick} />
        </div>
      </div>
      <div className="col2">
        <SpecialLabel label={t('My profile')} />
        <div className="username">
          {user.userName} <span className="id">(ID:{user.id})</span>
        </div>
        <div className="name">{user.firstName + ' ' + user.lastName}</div>
        <div className="email">{user.email}</div>
        {user.account.name !== '' && (
          <div className="account">
            {t('Account')} : {user.account.name}
          </div>
        )}
        <div className="license">
          {t('License')} : {user.account.licence.type.toUpperCase()}
        </div>
        <div className="buttons">
          <Button
            id="editUser"
            appearance={currentRoute.isAppMenuEditUser ? 'plain' : 'outline'}
            content={t('Update')}
            handleClick={() => {
              if (projectId && currentRoute) {
                dispatch(
                  currentRoute.isAppMenuEditUser
                    ? goToAppMenu(projectId)
                    : goToAppMenuEditUser(projectId)
                );
              }
            }}
            size="small"
          />
        </div>
      </div>
    </Card>
  );
};

export default UserCard;
