import * as R from 'ramda';
import { BuildingId, overCases } from '../specification/buildings/BuildingSpecification';
import { checkThatBuildingExists } from '../checks/buildings/buildingExists';
import { createCaseSpecification } from '../specification/cases/queries/create/createCaseSpecification';
import { overBuilding } from '../project/over/overBuilding';
import { Project } from '../project/Project';

export const addACase =
  (buildingId: BuildingId) =>
  (project: Project): Project => {
    checkThatBuildingExists(buildingId)(project);

    return overBuilding(buildingId)(
      overCases(R.append(createCaseSpecification({ withRamp: false })))
    )(project);
  };
