import { createSelector, Selector } from 'reselect';
import { selectCurrentCaseProjection } from '../../projection/currentCase/projection.selector';
import { State } from '../../../reducers';
import {
  CaseEditingStage,
  CaseEditingStageName
} from '../../../../domain/specification/cases/CaseSpecification';
import { selectCurrentCaseHasCustomDistribution } from './hasCustomDistribution.selector';

export const selectCurrentCaseEditingStage: Selector<State, CaseEditingStage> = createSelector(
  [selectCurrentCaseProjection, selectCurrentCaseHasCustomDistribution],
  (caseProjection, hasCustomDistribution) =>
    caseProjection?.editingStage ||
    (hasCustomDistribution ? CaseEditingStageName.Distribution : CaseEditingStageName.Granulometry)
);
