import { IconObjectProps } from '../../../components/ui/Icons/iconObject';
import * as R from 'ramda';
import { ParkingName } from './parkingSections/ParkingSection';
import { FundationsName } from './fundationsSections/FundationsSection';
import { CirculationName } from './circulationSections/CirculationSection';
import { RoofingName } from './roofingSections/RoofingSection';
import { OtherSectionName } from './otherSections/OtherSection';
import { SlabSectionName } from './slabSections/SlabSection';
import { LodgmentType } from '../../specification/lodgmentTypes/LodgmentType';
import { OutsideSectionName } from './outsideSections/OutsideSection';
import { MetaSectionName } from './metaSections/MetaSection';
import { TechnicalPremiseSectionName } from './technicalPremiseSections/TechnicalPremiseSection';
import { CommonPremiseSectionName } from './commonPremiseSections/CommonPremiseSection';

export type SectionName =
  | 'shop'
  | 'office'
  | CirculationName.Bearing
  | CirculationName.BearingInfra
  | CirculationName.CirculationLaneInfra
  | CirculationName.ElevatorShaft
  | CirculationName.ElevatorShaftInfra
  | CirculationName.Hall
  | CirculationName.Ramp
  | CirculationName.RampInfra
  | CirculationName.SasInfra
  | CirculationName.SmokeExtractionDucts
  | CirculationName.SmokeExtractionDuctsInfra
  | CirculationName.StairsShaft
  | CirculationName.StairsShaftInfra
  | CirculationName.VentilationGroupInfra
  | FundationsName.SuperficialFundations
  | FundationsName.DeepFundations
  | FundationsName.PileFundations
  | LodgmentType.T1
  | LodgmentType.T2C
  | LodgmentType.T2
  | LodgmentType.T3C
  | LodgmentType.T3
  | LodgmentType.T4C
  | LodgmentType.T4
  | LodgmentType.T5
  | RoofingName.TileRoofing
  | RoofingName.FlatRoofing
  | OtherSectionName.CaveSection
  | TechnicalPremiseSectionName.BicycleParkingSection
  | TechnicalPremiseSectionName.TrashcanSection
  | TechnicalPremiseSectionName.TechnicalSection
  | TechnicalPremiseSectionName.ElectricalTransformerSection
  | TechnicalPremiseSectionName.OpticalFiberSection
  | CommonPremiseSectionName.RestaurantSection
  | CommonPremiseSectionName.CafeteriaSection
  | CommonPremiseSectionName.BalneaSection
  | CommonPremiseSectionName.CollectiveKitchenSection
  | CommonPremiseSectionName.ReceptionHallSection
  | CommonPremiseSectionName.MeetingSection
  | ParkingName.CarBoxInfra
  | ParkingName.CarParkInfra
  | OutsideSectionName.OutsideStairShaft
  | OutsideSectionName.OutsideStairShaftInfra
  | OutsideSectionName.OutsideCarPark
  | OutsideSectionName.OutsideCirculationLane
  | OutsideSectionName.Plantation
  | SlabSectionName.OffsetSlab
  | SlabSectionName.OutsideSlab
  | MetaSectionName.FirstBasementLevelMetaSection
  | MetaSectionName.LowestLevelMetaSection
  | MetaSectionName.GroundLevelMetaSection
  | MetaSectionName.HighestLevelMetaSection
  | MetaSectionName.ProjectMetaSection;

export type SectionTitle = string;

export type SectionType =
  | 'lodgment'
  | 'roofing'
  | 'shop'
  | 'office'
  | 'others'
  | 'outside'
  | 'fundations'
  | 'circulation'
  | 'parking'
  | 'slab'
  | 'meta';

/* note : mainly buildingMap relatives properties */
export interface SectionDrawn {
  surface: number;
  color: string;
  icon: IconObjectProps;
  ceilingHeight?: number;
  group?: string;
  firstInGroup?: boolean;
  lastInGroup?: boolean;
  openedAbove?: boolean;
  openedBelow?: boolean;
  openedPrev?: boolean;
  openedNext?: boolean;
  inSectionWrappers?: boolean;
  inFirstSectionWrapper?: boolean;
  inSecondSectionWrapper?: boolean;
}

export interface SectionWithMinimumSurface extends Section {
  minimumDisplayedSurface: number;
}

export type SectionId = string;

export interface Section {
  id: SectionId;
  name: SectionName;
  title: SectionTitle;
  type: SectionType;
  displayedSurface: number;
}

type GetSectionName = (section: Section) => SectionName;
export const getSectionName: GetSectionName = R.prop('name');

type GetSectionTitle = (section: Section) => SectionTitle;
export const getSectionTitle: GetSectionTitle = R.prop('title');

type GetSectionType = (section: Section) => SectionType;
export const getSectionType: GetSectionType = R.prop('type');
