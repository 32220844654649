import * as R from 'ramda';
import { Project } from '../../project/Project';
import { defaultCaseProperties } from '../../../resources/defaultCaseProperties';
import { Property } from '../../specification/cases/Property';
import { CaseSpecification } from '../../specification/cases/CaseSpecification';
import { mapCases } from '../../project/map/mapCases';
import { addCaseProperty } from '../utils/addCaseProperty';

export const migrateProject7To8 = (project: Project): Project =>
  mapCases((eachCase) =>
    R.pipe<
      [CaseSpecification],
      CaseSpecification,
      CaseSpecification,
      CaseSpecification,
      CaseSpecification
    >(
      (cS) =>
        addCaseProperty(
          cS,
          defaultCaseProperties.find(
            (property) => property.name === 'stairsShaftCount'
          ) as Property,
          cS.properties.findIndex((property) => property.name === 'topLevelCeilingHeight')
        ),
      (cS) =>
        addCaseProperty(
          cS,
          defaultCaseProperties.find(
            (property) => property.name === 'elevatorShaftCount'
          ) as Property,
          cS.properties.findIndex((property) => property.name === 'topLevelCeilingHeight')
        ),
      R.dissoc('stairsShaft'),
      R.dissoc('elevatorShaft')
    )(eachCase)
  )(project);
