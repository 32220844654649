import { Granulometry } from '../../granulometry/Granulometry';
import { ProjectProjection } from '../../projection/project/ProjectProjection';
import { casesProjectionsToCasesFormData } from '../casesProjectionsToCasesFormData';
import { NonEmptyArray } from '../../../utils/NonEmptyArray';
import { renderBuilding } from './renderBuilding';
import { CaseProjection } from '../../projection/cases/CaseProjection';
import { BuildingGranulometry } from '../../granulometry/buildings/BuildingGranulometry';

export const renderGranulometry = (project: ProjectProjection): Granulometry => {
  try {
    return project.buildings.map((buildingProjection, buildingIndex) => {
      const casesFormData = casesProjectionsToCasesFormData(
        buildingProjection.cases.map(
          (eachCase) =>
            ({
              ...eachCase,
              distribution: eachCase.projectedDistribution,
              properties: eachCase.projectedProperties,
              surfaces: eachCase.projectedSurfaces
            } as CaseProjection)
        )
      );

      return renderBuilding(buildingProjection, buildingIndex, casesFormData);
    }) as NonEmptyArray<BuildingGranulometry>;
  } catch (error) {
    return error;
  }
};
