import * as R from 'ramda';
import { CaseProjection } from '../../CaseProjection';
import { getCaseTopLevels } from '../../../../specification/cases/queries/get/levels/getCaseTopLevels';

export const makeCaseProjectionTopLevels = (caseProjection: CaseProjection) => {
  return R.times((i) => {
    const level = caseProjection.projectedTopLevelsCount - i - 1;
    const found = getCaseTopLevels(caseProjection).find((l) => l.level === level);
    const def = { level };
    return found || def;
  }, caseProjection.projectedTopLevelsCount);
};
