import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentCaseLabel } from '../../../../store/selectors/project/currentCaseLabel.selector';
import { TopLevelSurfaceName } from '../../../../domain/granulometry/cases/queries/surfaces/getCaseTopLevelsSurfaces';
import { selectCurrentCaseTopLevelsTotalSurfaces } from '../../../../store/selectors/granulometry/currentCase/topLevelsTotalSurfaces.selector';
import { TopLevelTotalSurfaceInput } from './Column4/TopLevelsTotalSurfaceInput';
import { selectCurrentCaseProjection } from '../../../../store/selectors/projection/currentCase/projection.selector';
import { selectCurrentCaseGrossFloorSurfaceEff } from '../../../../store/selectors/granulometry/currentCase/grossFloorSurfaceEff.selector';
import { roundWith1Decimal } from '../../../../utils/round/roundWith1Decimal';
import { hasCaseProjectionDrawnFloorSpaceFeature } from '../../../../domain/projection/cases/queries/has/hasCaseProjectionDrawnFloorSpaceFeature';

interface Column4Props {
  selectedLevelSurface: TopLevelSurfaceName;
}

export const Column4Inner = ({ selectedLevelSurface }: Column4Props) => {
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const totalTopLevelsSurfaces = useSelector(selectCurrentCaseTopLevelsTotalSurfaces);
  const caseProjection = useSelector(selectCurrentCaseProjection);
  const caseRealBuiltSurface = caseProjection.projectedSurfaces.realBuiltSurface.value;
  const grossFloorSurfaceEff = useSelector(selectCurrentCaseGrossFloorSurfaceEff);

  if (!caseLabel || !totalTopLevelsSurfaces) return null;

  const isRbsSelected = selectedLevelSurface === 'realBuiltSurface';
  const isGfsEffSelected = selectedLevelSurface === 'grossFloorSurfaceEff';

  const hasFloorSpace = hasCaseProjectionDrawnFloorSpaceFeature(caseProjection);

  const totalValue = totalTopLevelsSurfaces[selectedLevelSurface].value;

  const color =
    (!hasFloorSpace &&
      isRbsSelected &&
      roundWith1Decimal(totalValue) !== roundWith1Decimal(caseRealBuiltSurface)) ||
    (hasFloorSpace &&
      isGfsEffSelected &&
      roundWith1Decimal(totalValue) !== roundWith1Decimal(grossFloorSurfaceEff?.value || 0))
      ? 'orange'
      : undefined;

  return <TopLevelTotalSurfaceInput caseLabel={caseLabel} color={color} totalValue={totalValue} />;
};

export const Column4 = ({ selectedLevelSurface }: Column4Props) => {
  return (
    <div className="column-4">
      <div className="cell">
        <Column4Inner selectedLevelSurface={selectedLevelSurface} />
      </div>
    </div>
  );
};
