import { createSelector, Selector } from 'reselect';
import { selectPath } from '../navigation';
import { deliverablesExportReportRoute } from '../../../routes/toolboxPanels/deliverables';
import { State } from '../../reducers';
import { DataTypeValue } from '../../../domain/DataType';
import { Maybe } from '../../../utils/Maybe';

export const selectReportDataTypeValueFromRoute: Selector<
  State,
  Maybe<DataTypeValue>
> = createSelector(
  [selectPath],
  (path) => deliverablesExportReportRoute.matchesPath(path).params?.reportDataType
);
