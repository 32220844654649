import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router';
import { appMenuEditUserRoute } from '../../../routes/appMenu';
import UserWindow from './UserWindow/UserWindow';

const AppMenuWindows: React.FC<RouteComponentProps> = () => {
  return (
    <div id="appMenuWindows" className="appMenuWindows">
      <Route path={appMenuEditUserRoute.path} component={UserWindow} />
    </div>
  );
};

export default AppMenuWindows;
