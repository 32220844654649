import { Maybe } from '../../../utils/Maybe';
import {
  MaybeFloorSpaceFeature,
  FloorSpaceFeatureId
} from '../../specification/floorSpace/FloorSpaceFeature';
import { CaseId, CaseSpecification } from '../../specification/cases/CaseSpecification';
import { Project } from '../../project/Project';
import { hasFloorSpaceFeatureId } from '../../specification/floorSpace/queries/floorSpaceFeature/hasFloorSpaceFeatureId';

export const getCaseFromFloorSpaceId = (
  project: Project,
  floorSpaceId: FloorSpaceFeatureId
): Maybe<CaseSpecification> => {
  const cases: CaseSpecification[] = project.buildings.reduce(
    (cs, building) => [...cs, ...building.cases],
    [] as CaseSpecification[]
  );
  return cases.find(hasFloorSpaceFeatureId(floorSpaceId));
};

export const getCaseIdFromFloorSpaceId = (
  project: Project,
  floorSpaceId: FloorSpaceFeatureId
): Maybe<CaseId> => {
  return getCaseFromFloorSpaceId(project, floorSpaceId)?.id;
};

export const getFloorSpaceInProjectFromFloorSpaceId = (
  project: Project,
  floorSpaceId: FloorSpaceFeatureId
): MaybeFloorSpaceFeature => {
  const cases: CaseSpecification[] = project.buildings.reduce(
    (p, c) => [...p, ...c.cases],
    [] as CaseSpecification[]
  );
  const cage = cases.find(hasFloorSpaceFeatureId(floorSpaceId));

  return cage?.floorSpaceFeature as MaybeFloorSpaceFeature;
};
