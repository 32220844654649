import { CaseGranulometry } from '../CaseGranulometry';
import { getTheoreticalCaseGrossFloorSurfaceEff } from './surfaces/getTheoreticalCaseGrossFloorSurfaceEff';
import { getCaseGranulometryDrawnFloorSpaceFeatureArea } from './get/getCaseGranulometryDrawnFloorSpaceFeatureArea';
import { MAXIMUM_TOP_LEVEL_NUMBER } from '../../../../constants/appConstants';
import { getCaseDataSpecifiedTopLevelCount } from '../../../specification/cases/queries/levels/counts/getCaseDataSpecifiedTopLevelCount';
import { getTopLevelsSpecificationsTotalForcedGrossFloorSurfaceEff } from '../../../specification/cases/queries/levels/surfaces/getTopLevelsSpecificationsTotalForcedGrossFloorSurfaceEff';
import { getTopLevelsSpecificationsTotalForcedGrossFloorSurfaceEffCount } from '../../../specification/cases/queries/levels/counts/getTopLevelsSpecificationsTotalForcedGrossFloorSurfaceEffCount';
import * as R from 'ramda';

export type GrossFloorSurfaceEffByLevel = { level: number; gfsEff: number }[];

export const MINIMUM_LEVEL_GFSEFF = 73; // m2 : to let level get filled by bearing + 35m2 T1

export const getCaseUpperLevelsGrossFloorSurfaceDistribution = (
  caseGranulometry: CaseGranulometry
): GrossFloorSurfaceEffByLevel => {
  const floorSpaceArea = getCaseGranulometryDrawnFloorSpaceFeatureArea(caseGranulometry) as number;
  const theoreticalCaseGrossFloorSurface =
    getTheoreticalCaseGrossFloorSurfaceEff(caseGranulometry).value;
  let remainingGfsEff = theoreticalCaseGrossFloorSurface;
  let levelIndex = 0;
  let upperLevelsGfsDistribution: GrossFloorSurfaceEffByLevel = [];

  const specifiedTopLevelsCount = getCaseDataSpecifiedTopLevelCount(
    caseGranulometry.initialSpecifications
  );
  const theoreticalResultingUpperLevelsCount = Math.ceil(
    (remainingGfsEff - floorSpaceArea) / floorSpaceArea
  );

  // If there is no specified top levels count
  // or if the specified top levels count is equal or under than the theoretical one :
  if (
    specifiedTopLevelsCount === undefined ||
    theoreticalResultingUpperLevelsCount === specifiedTopLevelsCount - 1 // why -1 ? to remove RC
  ) {
    const maxTopLevelsCount = (specifiedTopLevelsCount || MAXIMUM_TOP_LEVEL_NUMBER) - 1; // why -1 ? to remove RC
    while (remainingGfsEff > 0 && levelIndex < maxTopLevelsCount) {
      const temporaryRemainingGfsEff = remainingGfsEff - floorSpaceArea;

      const upperLevelGfsEff =
        temporaryRemainingGfsEff > 0.5 // Tolerance to prevent dust of gfsEff (ex : 0.0078)
          ? temporaryRemainingGfsEff > floorSpaceArea
            ? floorSpaceArea
            : temporaryRemainingGfsEff
          : 0;

      remainingGfsEff =
        temporaryRemainingGfsEff > floorSpaceArea ? remainingGfsEff - upperLevelGfsEff : 0;

      levelIndex++;

      upperLevelsGfsDistribution = [
        ...upperLevelsGfsDistribution,
        { level: levelIndex, gfsEff: upperLevelGfsEff }
      ];
    }

    if (remainingGfsEff > 0) {
      upperLevelsGfsDistribution.map(
        (l) => (l.gfsEff += remainingGfsEff / upperLevelsGfsDistribution.length)
      );
    }
  }

  // If the specified top levels count is upper or lower than the theoretical one :
  else {
    while (levelIndex < specifiedTopLevelsCount - 1) {
      // why -1 ? to remove RC
      levelIndex++;
      upperLevelsGfsDistribution = [
        ...upperLevelsGfsDistribution,
        {
          level: levelIndex,
          gfsEff: (remainingGfsEff - floorSpaceArea) / (specifiedTopLevelsCount - 1)
        }
      ];
    }
  }

  // Manage forced levels :
  const totalForcedLevelCount = getTopLevelsSpecificationsTotalForcedGrossFloorSurfaceEffCount(
    caseGranulometry.initialSpecifications.topLevelsData
  );
  if (totalForcedLevelCount !== 0) {
    const totalForcedLevelSurface = getTopLevelsSpecificationsTotalForcedGrossFloorSurfaceEff(
      caseGranulometry.initialSpecifications.topLevelsData
    );
    const totalUnforcedLevelSurface = upperLevelsGfsDistribution.reduce((acc, gfsEffByLevel) => {
      const forcedGfsEff = caseGranulometry.initialSpecifications.topLevelsData.find(
        (l) => l.level === gfsEffByLevel.level
      )?.grossFloorSurfaceEff;
      return acc + (forcedGfsEff ? 0 : gfsEffByLevel.gfsEff);
    }, 0);
    const totalUnforcedLevelCount = upperLevelsGfsDistribution.length - totalForcedLevelCount;
    const gfsEffToRedistribute =
      theoreticalCaseGrossFloorSurface -
      floorSpaceArea -
      totalUnforcedLevelSurface -
      totalForcedLevelSurface;
    upperLevelsGfsDistribution = upperLevelsGfsDistribution.reduce((acc, gfsEffByLevel) => {
      const forcedGfsEff = caseGranulometry.initialSpecifications.topLevelsData.find(
        (l) => l.level === gfsEffByLevel.level
      )?.grossFloorSurfaceEff;
      return [
        ...acc,
        forcedGfsEff
          ? { ...gfsEffByLevel, gfsEff: forcedGfsEff }
          : {
              ...gfsEffByLevel,
              gfsEff: gfsEffByLevel.gfsEff + gfsEffToRedistribute / totalUnforcedLevelCount
            }
      ];
    }, [] as GrossFloorSurfaceEffByLevel);
  }

  // Remove too empty highest levels (only if top level count hasn't been forced)
  if (specifiedTopLevelsCount === undefined) {
    const gfsEffToBeRedistributed = upperLevelsGfsDistribution.reduce((acc, gfsEffByLevel) => {
      return gfsEffByLevel.gfsEff < MINIMUM_LEVEL_GFSEFF ? acc + gfsEffByLevel.gfsEff : 0;
    }, 0);
    if (gfsEffToBeRedistributed !== 0) {
      upperLevelsGfsDistribution = R.reject(
        (gfsEffByLevel: { level: number; gfsEff: number }) =>
          gfsEffByLevel.gfsEff < MINIMUM_LEVEL_GFSEFF
      )(upperLevelsGfsDistribution);
      upperLevelsGfsDistribution = upperLevelsGfsDistribution.reduce(
        (acc, gfsEffByLevel) => [
          ...acc,
          {
            ...gfsEffByLevel,
            gfsEff:
              gfsEffByLevel.gfsEff + gfsEffToBeRedistributed / upperLevelsGfsDistribution.length
          }
        ],
        [] as GrossFloorSurfaceEffByLevel
      );
    }
  }

  return upperLevelsGfsDistribution;
};
