import { ProjectProjection } from '../../projection/project/ProjectProjection';
import { CaseProjection } from '../../projection/cases/CaseProjection';
import { roundWithNoDecimal } from '../../../utils/round/roundWithNoDecimal';
import { getCaseRealBuiltSurfaceMaximum } from '../../specification/cases/queries/get/surfaces/getCaseRealBuiltSurfaceMaximum';

export const caseRealBuiltSurfaceIsLargerThanTheMaximum = (
  projectProjection: ProjectProjection,
  caseProjection: CaseProjection
): boolean =>
  roundWithNoDecimal(caseProjection.projectedSurfaces.realBuiltSurface.value) >
  roundWithNoDecimal(getCaseRealBuiltSurfaceMaximum(projectProjection, caseProjection));
