import * as R from 'ramda';
import { Project } from '../../project/Project';
import { CaseSpecification } from '../../specification/cases/CaseSpecification';
import { defaultCaseProperties } from '../../../resources/defaultCaseProperties';
import { Property } from '../../specification/cases/Property';
import { mapCases } from '../../project/map/mapCases';
import { addCaseProperty } from '../utils/addCaseProperty';

export const migrateProject10To11 = (project: Project): Project =>
  mapCases((eachCase) =>
    R.pipe<[CaseSpecification], CaseSpecification>((cS) =>
      addCaseProperty(
        cS,
        defaultCaseProperties.find(
          (property) => property.name === 'hasDoubledLodgmentInsideInsulating'
        ) as Property,
        cS.properties.findIndex((property) => property.name === 'installationCostRatio')
      )
    )(eachCase)
  )(project);
