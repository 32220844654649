import { createSelector, Selector } from 'reselect';
import { State } from '../../../reducers';
import { LevelGranulometry } from '../../../../domain/granulometry/levels/LevelGranulometry';
import { selectCurrentCaseGranulometry } from '../currentCase/currentCaseGranulometry.selector';
import { selectCurrentlyEditedLevel } from '../../navigation/currentlyEditedLevel.selector';
import { getCaseLevels } from '../../../../domain/granulometry/cases/queries/levels/getCaseLevels';
import { Maybe } from '../../../../utils/Maybe';

export const selectCurrentLevelGranulometry: Selector<
  State,
  Maybe<LevelGranulometry>
> = createSelector(
  [selectCurrentCaseGranulometry, selectCurrentlyEditedLevel],
  (caseGranulometry, levelSpecification) =>
    caseGranulometry && levelSpecification
      ? getCaseLevels(caseGranulometry).find((level) => level.level === levelSpecification.level)
      : undefined
);
