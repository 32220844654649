import elevatorShaftInfra, {
  ElevatorShaftInfraSection
} from '../../../../../sections/circulationSections/elevatorShaftInfra';
import { getDefaultUnitConvertedPropertyValue } from '../../../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const createBasementLevelElevatorShaftInfra = (
  caseGranulometry: CaseGranulometry,
  group: string
): ElevatorShaftInfraSection => {
  const length = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'elevatorShaftLength'
  ) as number;
  const width = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'elevatorShaftWidth'
  ) as number;
  const surface = length * width;
  const doorHeight = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'elevatorDoorHeight'
  ) as number;
  const doorWidth = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'elevatorDoorWidth'
  ) as number;
  return elevatorShaftInfra({
    surface,
    length,
    width,
    doorHeight,
    doorWidth,
    group
  });
};
