import { DEFAULT_RBS_TO_CBS_RATIO } from '../../../../../constants/appConstants';
import { Surface } from '../../../../specification/Surface';

export const getMinRbsSurface = (
  cbs: Surface,
  technicalPremiseSectionsSurface: Surface,
  cbsToSfsRatio: number
): Surface =>
  new Surface(
    cbsToSfsRatio !== 0
      ? cbs.value / DEFAULT_RBS_TO_CBS_RATIO +
        (technicalPremiseSectionsSurface.value !== 0
          ? technicalPremiseSectionsSurface.value / (cbsToSfsRatio * DEFAULT_RBS_TO_CBS_RATIO)
          : 0)
      : 0
  );
