import * as R from 'ramda';
import { RouteMatch } from './matchesPath';

export interface Route<P extends Record<string, string>> {
  path: string;
  makePath?: (params: Record<keyof P, string | number>) => string;
  matchesPath: (path: string) => RouteMatch<P>;
}

export interface RouteWithMakePath<P extends Record<string, string>> extends Route<P> {
  makePath: (params: Record<keyof P, string | number>) => string;
}

export const orCreate = R.defaultTo('create');
