import { LevelGranulometry } from '../../../LevelGranulometry';
import { getCaseDataProperty } from '../../../../../specification/cases/queries/get/properties/getCaseDataProperty';
import { isGroundLevel } from '../../is/isGroundLevel';
import { mustHaveAHallSection } from '../../mustHave/mustHaveAHallSection';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { getInFullFilledTopLevelBearingSectionSurface } from '../sections/surfaces/getInFullFilledTopLevelBearingSectionSurface';
import { getTopLevelMinimumBearingSurface } from './getTopLevelMinimumBearingSurface';
import { getInFullFilledTopLevelHallSectionSurface } from '../sections/surfaces/getInFullFilledTopLevelHallSectionSurface';
import { isSectionAHall } from '../../../../sections/circulationSections/hall';
import bearing, { isSectionABearing } from '../../../../sections/circulationSections/bearing';
import { getTopLevelRbsRulerWidth } from '../rulers/getTopLevelRbsRulerWidth';
import { getTopLevelRbsRulerLeft } from '../rulers/getTopLevelRbsRulerLeft';
import { getTopLevelSfsRulerWidth } from '../rulers/getTopLevelSfsRulerWidth';
import { getTopLevelSfsRulerLeft } from '../rulers/getTopLevelSfsRulerLeft';
import { getLevelDisplayedWallThickness } from '../../getLevelDisplayedWallThickness';
import { getDefaultUnitConvertedPropertyValue } from '../../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { getLevelFullFilledContent } from '../../content/getLevelFullFilledContent';

export const tryToAdjustGroundLevelTooSmallBearingSurface = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry
): LevelGranulometry => {
  // For :
  // -> ground level only
  if (isGroundLevel(levelGranulometry)) {
    // -> with a hall and or a technical section
    const hasHall = mustHaveAHallSection(caseGranulometry, levelGranulometry);
    if (hasHall) {
      // -> with a bearing surface under the minimum bearing surface
      const bearingSurface = getInFullFilledTopLevelBearingSectionSurface(levelGranulometry).value;
      const miminumBearingSurface = getTopLevelMinimumBearingSurface(
        caseGranulometry,
        levelGranulometry
      ).value;
      if (bearingSurface < miminumBearingSurface) {
        // We get the missing surface from hall and technical section surface (if possible)
        const missingBearingSurface = miminumBearingSurface - bearingSurface;
        const hallSurface = hasHall
          ? getInFullFilledTopLevelHallSectionSurface(levelGranulometry).value
          : 0;

        // We try to adjust the surface of the hall and the technical surface to lef enought space for the bearing
        let adjustedHallSurface: number;
        let processToHallAdjustment = true;

        if (hasHall) {
          const minHallSurface = getCaseDataProperty(
            caseGranulometry.initialSpecifications,
            'hallSurface'
          )?.min as number;
          adjustedHallSurface = hallSurface - missingBearingSurface;
          if (minHallSurface > adjustedHallSurface) {
            processToHallAdjustment = false;
          }
        }

        if (processToHallAdjustment) {
          const adjustedContent = [...getLevelFullFilledContent(levelGranulometry)].map(
            (section) => {
              if (isSectionAHall(section)) {
                section.surface = adjustedHallSurface;
                section.displayedSurface = adjustedHallSurface;
              }
              if (isSectionABearing(section)) {
                return bearing({
                  surface: miminumBearingSurface,
                  width: getDefaultUnitConvertedPropertyValue(
                    caseGranulometry.initialSpecifications,
                    'bearingWidth'
                  ) as number
                });
              }
              return section;
            }
          );

          // Update level granulometry
          const updatedLevelGranulometry = {
            ...levelGranulometry,
            content: adjustedContent,
            bearingSurface: miminumBearingSurface
          };
          return {
            ...updatedLevelGranulometry,
            displayedWallThickness: getLevelDisplayedWallThickness(updatedLevelGranulometry),
            rbsRulerWidth: getTopLevelRbsRulerWidth(updatedLevelGranulometry),
            rbsRulerLeft: getTopLevelRbsRulerLeft(updatedLevelGranulometry),
            sfsRulerWidth: getTopLevelSfsRulerWidth(updatedLevelGranulometry),
            sfsRulerLeft: getTopLevelSfsRulerLeft(updatedLevelGranulometry)
          };
        }
      }
    }
  }
  return levelGranulometry;
};
