import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column, DerivedDataObject, Filter } from 'react-table';
import * as R from 'ramda';
import { Maybe } from '../../../../../utils/Maybe';
import {
  propertiesGroups,
  propertiesSubGroups,
  propertiesUnits,
  Property,
  propertyGroup,
  propertySubGroup
} from '../../../../../domain/specification/cases/Property';
import { DataTable } from '../../../../ui/DataTable';
import { getCasePropertyTriggerId } from '../../../../../routes/toolboxPanels/triggers';
import { setCurrentlyDisplayedCasePropertiesNames } from '../../../../../store/actions/setDisplayedCasePropertiesNames.action';
import { goToCaseGranulometryEditProperty } from '../../../../../store/actions/navigations.actions';
import { selectProjectId } from '../../../../../store/selectors/project';
import { selectCurrentCaseIdFromRoute } from '../../../../../store/selectors/navigation/caseIdFromRoute.selector';
import { selectCurrentlyDisplayedCasePropertiesNames } from '../../../../../store/selectors/navigation/currentlyDisplayedCasePropertiesNames.selector';
import { selectCurrentCaseProperties } from '../../../../../store/selectors/specification/currentCase/properties.selector';
import { selectCurrentCaseLabel } from '../../../../../store/selectors/project/currentCaseLabel.selector';
import { createFilter } from '../../../panels/deliverables/ReportTable/createFilter';

const getPropertiesNames = (properties: { name: string }[]) =>
  R.pluck<'name', { name: string }>('name', properties);

interface CasePropertiesProps {
  currentEditedProperty: Maybe<Property>;
}

const CaseProperties = ({ currentEditedProperty }: CasePropertiesProps) => {
  const projectId = useSelector(selectProjectId);
  const caseId = useSelector(selectCurrentCaseIdFromRoute);
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const currentCaseProperties = useSelector(selectCurrentCaseProperties);
  const currentlyDisplayedCasePropertiesNames = useSelector(
    selectCurrentlyDisplayedCasePropertiesNames
  ) as string[];
  const dispatch = useDispatch();

  const setDisplayedCasePropertiesNames = React.useCallback(
    (propertiesNames) => {
      dispatch(setCurrentlyDisplayedCasePropertiesNames(propertiesNames));
    },
    [dispatch]
  );

  const [dataTablePageSize, setDataTablePageSize] = React.useState(10);
  const [dataTableSortedData, setDataTableSortedData] = React.useState([]);

  if (!caseLabel || !currentCaseProperties) return null;

  const propertiesInfo = currentCaseProperties.map((property: Property) => ({
    ...property,
    value: property.value === undefined ? property.defaultValue : property.value,
    asCustomValue: !!property.value
  }));

  const columns: Column[] = [
    {
      Header: 'Groupe',
      accessor: 'group',
      width: 70,
      style: { overflow: 'visible' },
      className: 'merged casePropertyMergedLine',
      Cell: (props) => (
        <div className="merged-wrapper">
          <b>{props.row.title}</b> : {props.row.value + '' + props.row.unit}
        </div>
      ),
      filterMethod: (filter: Filter, row: DerivedDataObject) =>
        propertyGroup(row._original) === filter?.value,
      Filter: createFilter(propertiesGroups(propertiesInfo))
    },
    {
      Header: 'Sous-Groupe',
      accessor: 'subGroup',
      Cell: '',
      width: 70,
      filterMethod: (filter: Filter, row: DerivedDataObject) =>
        propertySubGroup(row._original) === filter?.value,
      Filter: createFilter(propertiesSubGroups(propertiesInfo))
    },
    {
      Header: 'Titre/Nom',
      accessor: 'title',
      Cell: '',
      filterMethod: (filter: Filter, row: DerivedDataObject) => {
        const regexp = RegExp(filter.value, 'i');
        const title = row.title;
        const titleWithoutAccents = title.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        const name = row._original.name;
        return title.match(regexp) || titleWithoutAccents.match(regexp) || name.match(regexp);
      }
    },
    {
      Header: 'Valeur',
      accessor: 'value',
      width: 40,
      Cell: '',
      filterMethod: (filter: Filter, row: DerivedDataObject) =>
        row._original.value.toString() === filter.value
    },
    {
      Header: 'Unité',
      accessor: 'unit',
      width: 34,
      filterMethod: (filter: Filter, row: DerivedDataObject) => {
        return row[filter.id] === filter.value;
      },
      Filter: createFilter(propertiesUnits(propertiesInfo)),
      Cell: ''
    }
  ];

  const onTrGroupClickCallbackFunc = (current: Property) => {
    if (projectId && caseId)
      dispatch(goToCaseGranulometryEditProperty(projectId, caseId, current.name));
  };

  const getCurrentPage = (
    currentEditedProperty: Maybe<Property>,
    dataTablePageSize: number,
    dataTableSortedData: Array<DerivedDataObject>
  ) => {
    if (currentEditedProperty && dataTableSortedData !== undefined) {
      const propertyIndex = dataTableSortedData.findIndex(
        ({ _original: { name } }) => name === currentEditedProperty.name
      );
      return Math.floor(propertyIndex / dataTablePageSize);
    } else {
      return undefined;
    }
  };

  const refreshCurrentlyDisplayedCasePropertiesNames = (dataTableState: {
    sortedData: DerivedDataObject[];
  }) => {
    const sortedProperties = dataTableState.sortedData.map(
      (derivedDataObject: DerivedDataObject) => derivedDataObject._original
    );
    const sortedPropertiesNames = getPropertiesNames(sortedProperties);

    if (
      JSON.stringify(sortedPropertiesNames) !==
      JSON.stringify(currentlyDisplayedCasePropertiesNames)
    ) {
      setDisplayedCasePropertiesNames(sortedPropertiesNames);
    }
  };

  return (
    <DataTable
      data={propertiesInfo}
      current={currentEditedProperty}
      columns={columns}
      defaultPageSize={25}
      minRows={10}
      onTrGroupClickCallback={onTrGroupClickCallbackFunc}
      id="casePropertiesTable"
      rowId={(property: Property) => getCasePropertyTriggerId(caseLabel, property.name)}
      page={getCurrentPage(currentEditedProperty, dataTablePageSize, dataTableSortedData)}
      onFetchData={(state) => {
        setDataTableSortedData(state.sortedData);
        setDataTablePageSize(state.pageSize);
        refreshCurrentlyDisplayedCasePropertiesNames(state);
      }}
    />
  );
};

export default CaseProperties;
