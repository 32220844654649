import { CaseFormDataLegacy } from '../caseFormData';
import { getCaseDataProperty } from '../../specification/cases/queries/get/properties/getCaseDataProperty';
import { getCaseDataPropertyValue } from '../../specification/cases/queries/get/properties/getCaseDataPropertyValue';
import { roundWith2Decimal } from '../../../utils/round/roundWith2Decimal';
import { roundWith4Decimal } from '../../../utils/round/roundWith4Decimal';
import { defaultCaseProperties } from '../../../resources/defaultCaseProperties';
import { captureMessage } from '../../../infra/sentry';
import { CaseSpecification } from '../../specification/cases/CaseSpecification';

const convertPropertyValue = (unit: string, value: number) => {
  if (unit === 'cm') {
    return roundWith2Decimal(value / 100);
  } else if (unit === 'mm') {
    return roundWith2Decimal(value / 1000);
  } else if (unit === '%') {
    return roundWith4Decimal(value / 100);
  } else {
    return roundWith2Decimal(value);
  }
};

export const getDefaultUnitConvertedPropertyValue = (
  caseData: Partial<CaseFormDataLegacy> | CaseSpecification,
  propertyName: string
): number | string | undefined => {
  let property = getCaseDataProperty(caseData, propertyName);
  if (!property) {
    // To make it works with old project
    property = getCaseDataProperty({ properties: defaultCaseProperties }, propertyName);
    if (!property) {
      captureMessage(`Could not find property ${propertyName} in case properties`, {
        level: 'warning',
        tags: { category: 'granulo' },
        extra: {
          caseProperties: caseData.properties
        }
      });
    } else {
      return convertPropertyValue(property.unit, property.defaultValue);
    }
  } else {
    return convertPropertyValue(
      property.unit,
      getCaseDataPropertyValue(caseData, propertyName) as number
    );
  }
};
