import { getCaseUpperLevels } from '../getCaseUpperLevels';
import { mustTopLevelGfsEffBeAdjusted } from '../../../../levels/queries/topLevels/surfaces/grossFloorSurfaces/mustTopLevelGfsEffBeAdjusted';
import { CaseGranulometry } from '../../../CaseGranulometry';

export const getCaseUpperLevelsWithAdjustedGfsEffCount = (caseGranulometry: CaseGranulometry) =>
  getCaseUpperLevels(caseGranulometry).reduce(
    (acc, levelGranulometry) =>
      mustTopLevelGfsEffBeAdjusted(caseGranulometry, levelGranulometry) ? acc + 1 : acc,
    0
  );
