import * as turf from '@turf/turf';
import { Position } from '@turf/helpers/dist/js/lib/geojson';
import {
  Distance,
  Line,
  MaybeLine
} from '../../../../domain/specification/floorSpace/FacadeSpecification';
import { getAngle } from './getAngle';
import { normalizeSourceCoordinates } from './coordinates';
import Decimal from 'decimal.js';
import { divide, multiply } from '../../../decimal/calc';
import { roundWith8Decimal } from '../../../../utils/round/roundWith8Decimal';

export const getLineDistance = (from: Position, to: Position): Distance =>
  roundWith8Decimal(turf.distance(from, to, { units: 'meters' }));

export const getLinesFromCoordinates = (coordinates: Position[]): Line[] => {
  coordinates = normalizeSourceCoordinates(coordinates);
  return coordinates
    .map((coordinate, i) => {
      if (i + 1 === coordinates.length) {
        return;
      }
      let startPoint = coordinates[i];
      let endPoint = coordinates[i + 1] || coordinates[0];
      if (i === coordinates.length) {
        startPoint = coordinates[i - 1];
        endPoint = coordinates[0];
      }
      return {
        angle: getAngle(startPoint, endPoint),
        distance: getLineDistance(startPoint, endPoint)
      };
    })
    .filter(Boolean) as Line[];
};

const growLineFromRatio =
  (ratio: Decimal) =>
  (line: Line): Line => {
    return { ...line, distance: multiply(line.distance)(ratio).toNumber() };
  };

export const resizeLines = (coordinates: Position[], ratio: Decimal) => {
  const oldLines = getLinesFromCoordinates(coordinates);
  return oldLines.map(growLineFromRatio(ratio)).filter(Boolean);
};

export const getRatioToResizeLine = (
  newDistance: number,
  coordinates: Position[],
  lineIndex: number
): Decimal => {
  const oldLines = getLinesFromCoordinates(coordinates);
  const oldLine = oldLines[lineIndex];
  if (!oldLine || !oldLine.distance) throw new Error("La line du polygon n'existe pas");
  return divide(newDistance)(oldLine.distance);
};

export const getLinesFromWidthHeight = ({
  width,
  height
}: {
  width: number;
  height: number;
}): MaybeLine[] => {
  const ratio = divide(height)(width).toNumber();
  const newWidth = 250;
  const length = multiply(ratio)(newWidth).toNumber();

  return [
    { distance: newWidth, angle: 90 },
    { distance: length, angle: 180 },
    { distance: newWidth, angle: 270 },
    { distance: null, angle: 0 }
  ];
};
