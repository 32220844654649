import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from '../../../../ui/Card';
import { IconObject } from '../../../../ui/Icons/iconObject';
import { SpecialLabel } from '../../../../ui/SpecialLabel';
import { HeatingDistribution } from '../../../../../domain/specification/cases/HeatingDistribution';
import { Option, Select } from '../../../../ui/Select';
import { caseSpecificationChanged } from '../../../../../store/actions/changeCaseSpecification.action';
import { useParams } from 'react-router';
import { selectCurrentCaseHeatingDistribution } from '../../../../../store/selectors/specification/currentCase/caseHeatingDistribution.selector';
import { GoBuildIcons } from '../../../../../utils/icons/iconObjectIcons';
import { CaseGranulometryEditEnergyAndNetworksRouteParams } from '../../../../../routes/toolboxPanels/cases';

export const HeatingDistributionCard = () => {
  const { caseId } = useParams<CaseGranulometryEditEnergyAndNetworksRouteParams>();
  const heatingDistribution = useSelector(
    selectCurrentCaseHeatingDistribution
  ) as HeatingDistribution;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectHeatingDistributionOptions: Option[] = [
    {
      label: t(HeatingDistribution.UnderfloorHeating + '.label'),
      value: HeatingDistribution.UnderfloorHeating
    },
    {
      label: t(HeatingDistribution.IsolatedElementsHeating + '.label'),
      value: HeatingDistribution.IsolatedElementsHeating
    }
  ];

  const selectedHeatingDistribution = selectHeatingDistributionOptions.find(
    (option) => option.value === heatingDistribution
  );

  if (!caseId || process.env.GB_FEAT_HEATING_DISTRIBUTION === 'false') return null;

  return (
    <Card>
      <SpecialLabel label={t('Heating distribution')} />
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '4px' }}>
        <IconObject
          type="menu"
          iconName={selectedHeatingDistribution?.value as GoBuildIcons}
          clickable={false}
        />
        <Select
          id={`case${caseId}HeatingDistribution`}
          options={selectHeatingDistributionOptions}
          handleChange={(option: Option) => {
            dispatch(
              caseSpecificationChanged(caseId, {
                heatingDistribution: option.value as HeatingDistribution
              })
            );
          }}
          value={selectedHeatingDistribution}
          style={{ marginLeft: '6px', flexBasis: '150px' }}
        />
      </div>
    </Card>
  );
};
