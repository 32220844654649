import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getCaseInfrastructureTriggerId } from '../../../../../routes/toolboxPanels/triggers';
import { Card } from '../../../../ui/Card';
import { SpecialLabel } from '../../../../ui/SpecialLabel';
import { Window } from '../../../../ui/Window';
import { Form } from '../../../../ui/Form';
import { useDispatch, useSelector } from 'react-redux';
import {
  goToCase,
  goToCaseGroundAdaptations,
  goToCaseSuperstructure
} from '../../../../../store/actions/navigations.actions';
import { useParams } from 'react-router';
import { IconObject } from '../../../../ui/Icons/iconObject';
import { selectCurrentCaseLabel } from '../../../../../store/selectors/project/currentCaseLabel.selector';
import { CarBoxFormGroup } from '../LevelWindow/CarBox';
import { CarParkFormGroup } from '../LevelWindow/CarPark';
import { TotalCaseParking } from '../LevelWindow/TotalCaseParking';
import { CaseGranulometryEditInfrastructureRouteParams } from '../../../../../routes/toolboxPanels/cases';

export const CaseInfrastructureWindow = () => {
  const { projectId, caseId } = useParams<CaseGranulometryEditInfrastructureRouteParams>();
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClickClose = React.useCallback(() => {
    if (caseId && projectId) dispatch(goToCase(projectId, caseId));
  }, [dispatch, projectId, caseId]);

  const handleClickPrev = React.useCallback(() => {
    caseId && projectId && dispatch(goToCaseSuperstructure(projectId, caseId));
  }, [dispatch, projectId, caseId]);

  const handleClickNext = React.useCallback(() => {
    caseId && projectId && dispatch(goToCaseGroundAdaptations(projectId, caseId));
  }, [dispatch, projectId, caseId]);

  if (!caseLabel) return null;

  return (
    <Window
      title={t('Infrastructure')}
      triggerId={getCaseInfrastructureTriggerId(caseLabel)}
      topButtons={
        <>
          <IconObject iconName="arrowTop" type="menu" onClick={handleClickPrev} />
          <IconObject iconName="arrowBottom" type="menu" onClick={handleClickNext} />
          <IconObject iconName="close" type="menu" onClick={handleClickClose} />
        </>
      }>
      <Form>
        <Card>
          <SpecialLabel label={t('Distribution of parking spaces in the case') + ' ' + caseLabel} />
          <CarBoxFormGroup />
          <CarParkFormGroup />
          <TotalCaseParking />
        </Card>
      </Form>
    </Window>
  );
};

export default CaseInfrastructureWindow;
