import * as R from 'ramda';
import { CaseId, CaseSpecification } from '../../../cases/CaseSpecification';
import { Project } from '../../../../project/Project';
import { BuildingSpecification } from '../../../buildings/BuildingSpecification';

export const getProjectLastCaseId = (project: Project): CaseId => {
  return R.pipe(
    R.last,
    (building: BuildingSpecification) => building.cases,
    R.last,
    (c: CaseSpecification) => c.id
  )(project.buildings);
};
