import * as R from 'ramda';
import { Project } from '../../project/Project';
import { LodgmentTypeSpecification } from '../../specification/lodgmentTypes/LodgmentTypeSpecification';
import { defaultLodgmentsSections } from '../../granulometry/sections/lodgmentSections/LodgmentSection';
import { mapCases } from '../../project/map/mapCases';

interface LodgmentTypeSpecificationV4 extends LodgmentTypeSpecification {
  surface?: number;
}
export const migrateProject4To5 = (project: Project): Project => {
  return mapCases((eachCase) => ({
    ...eachCase,
    lodgmentsTypesSpecifications: R.map((lodgmentTypeSpecification: LodgmentTypeSpecificationV4) =>
      lodgmentTypeSpecification.surface !== undefined &&
      lodgmentTypeSpecification.theoreticalSurface === undefined
        ? R.pipe<
            [LodgmentTypeSpecificationV4],
            LodgmentTypeSpecificationV4,
            LodgmentTypeSpecificationV4,
            LodgmentTypeSpecificationV4,
            LodgmentTypeSpecificationV4,
            LodgmentTypeSpecificationV4,
            LodgmentTypeSpecificationV4,
            LodgmentTypeSpecification
          >(
            R.assoc(
              'defaultTheoreticalSurface',
              defaultLodgmentsSections[lodgmentTypeSpecification.lodgmentType]
                .defaultTheoreticalSurface
            ),
            R.assoc(
              'theoreticalSurface',
              defaultLodgmentsSections[lodgmentTypeSpecification.lodgmentType]
                .defaultTheoreticalSurface !== lodgmentTypeSpecification.surface
                ? lodgmentTypeSpecification.surface
                : undefined
            ),
            R.assoc(
              'minimumDisplayedSurface',
              defaultLodgmentsSections[lodgmentTypeSpecification.lodgmentType]
                .minimumDisplayedSurface
            ),
            R.assoc(
              'defaultExposureRate',
              defaultLodgmentsSections[lodgmentTypeSpecification.lodgmentType].defaultExposureRate
            ),
            R.when<LodgmentTypeSpecificationV4, LodgmentTypeSpecificationV4>(
              (lTS) =>
                defaultLodgmentsSections[lodgmentTypeSpecification.lodgmentType]
                  .defaultTheoreticalSurface === lTS.exposureRate,
              R.dissoc('exposureRate')
            ),
            R.assoc(
              'displayedSurface',
              defaultLodgmentsSections[lodgmentTypeSpecification.lodgmentType]
                .defaultTheoreticalSurface
            ),
            R.assoc(
              'surface',
              defaultLodgmentsSections[lodgmentTypeSpecification.lodgmentType]
                .defaultTheoreticalSurface
            )
          )(lodgmentTypeSpecification)
        : lodgmentTypeSpecification
    )(eachCase.lodgmentsTypesSpecifications)
  }))(project);
};
