import { CaseProjection } from '../../../../projection/cases/CaseProjection';
import { CaseFormDataLegacy } from '../../../../legacy/caseFormData';
import { getDefaultUnitConvertedPropertyValue } from '../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';

export const getBasementLevelOutsideWallsLinear = (
  parentCase: CaseProjection | CaseFormDataLegacy,
  carBoxCount: number,
  carParkCount: number,
  currentCaseBasementLevelsCount: number
) => {
  let levelOutsideWallInfraLinear = 0;

  // Ventilation group
  if (currentCaseBasementLevelsCount > 1) {
    const ventilationGroupInfraWidth = getDefaultUnitConvertedPropertyValue(
      parentCase,
      'ventilationGroupInfraWidth'
    ) as number;
    const ventilationGroupInfraLength = getDefaultUnitConvertedPropertyValue(
      parentCase,
      'ventilationGroupInfraLength'
    ) as number;
    levelOutsideWallInfraLinear += ventilationGroupInfraWidth + ventilationGroupInfraLength;
  }

  // Ramp
  if (parentCase.builtInRamp) {
    const rampInfraNumberOfSides = 2;
    const rampLength = getDefaultUnitConvertedPropertyValue(parentCase, 'rampLength') as number;
    const rampWidth = getDefaultUnitConvertedPropertyValue(parentCase, 'rampWidth') as number;
    const rampExposureRate = getDefaultUnitConvertedPropertyValue(
      parentCase,
      'rampExposureRate'
    ) as number;
    levelOutsideWallInfraLinear +=
      rampInfraNumberOfSides * (rampLength + rampWidth) * rampExposureRate;
  }

  // Bearing infra
  const bearingInfraNumberOfSides = 2;
  const bearingInfraSurface = getDefaultUnitConvertedPropertyValue(
    parentCase,
    'bearingInfraSurface'
  ) as number;
  const bearingInfraWidth = getDefaultUnitConvertedPropertyValue(
    parentCase,
    'bearingInfraWidth'
  ) as number;
  const bearingInfraExposureRate = getDefaultUnitConvertedPropertyValue(
    parentCase,
    'bearingInfraExposureRate'
  ) as number;
  levelOutsideWallInfraLinear +=
    bearingInfraNumberOfSides *
    (bearingInfraSurface / bearingInfraWidth + bearingInfraWidth) *
    bearingInfraExposureRate;

  // Circulation lane infra
  const circulationLaneInfraAdditionalExposureRateRatio = 6; // TODO : Should we add it in case properties
  const circulationLaneInfraWidth = getDefaultUnitConvertedPropertyValue(
    parentCase,
    'circulationLaneWidth'
  ) as number;
  levelOutsideWallInfraLinear +=
    circulationLaneInfraAdditionalExposureRateRatio * circulationLaneInfraWidth;

  // Car box infra
  const carBoxAdditionalExposureRateRatio = 1.2; // TODO : Should we add it in case properties
  const carBoxWidth = getDefaultUnitConvertedPropertyValue(parentCase, 'carBoxWidth') as number;
  levelOutsideWallInfraLinear += carBoxCount * carBoxAdditionalExposureRateRatio * carBoxWidth;

  // Car park infra
  const carParkAdditionalExposureRateRatio = 1.2; // TODO : Should we add it in case properties
  const carParkWidth = getDefaultUnitConvertedPropertyValue(parentCase, 'carParkWidth') as number;
  levelOutsideWallInfraLinear += carParkCount * carParkAdditionalExposureRateRatio * carParkWidth;

  return levelOutsideWallInfraLinear;
};
