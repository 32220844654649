import stairsShaft, {
  StairsShaftSection
} from '../../../../../sections/circulationSections/stairsShaft';
import { getDefaultUnitConvertedPropertyValue } from '../../../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const createTopLevelStairsShaftSection = (
  caseGranulometry: CaseGranulometry,
  group: string
): StairsShaftSection => {
  const length = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'stairsShaftLength'
  ) as number;
  const width = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'stairsShaftWidth'
  ) as number;
  const surface = length * width;
  const bearingLength = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'stairsShaftBearingLength'
  ) as number;
  const bearingWidth = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'stairsShaftBearingWidth'
  ) as number;
  return stairsShaft({
    surface,
    length,
    width,
    bearingLength,
    bearingWidth,
    group
  });
};
