import { matchesPath } from '../matchesPath';
import { orCreate, Route, RouteWithMakePath } from '../panel';

export type ProjectInitRouteParams = { projectId: string };
export const projectInitRoute: RouteWithMakePath<ProjectInitRouteParams> = {
  path: `/project/:projectId`,
  makePath: ({ projectId }) => `/project/${orCreate(projectId)}`,
  matchesPath: matchesPath<ProjectDetailsRouteParams>(/\/project\/([^/]+)/, {
    projectId: 1
  })
};
export type ProjectDetailsRouteParams = { projectId: string };
export const projectDetailsRoute: RouteWithMakePath<ProjectDetailsRouteParams> = {
  path: `/project/:projectId/details`,
  makePath: ({ projectId }) => `/project/${orCreate(projectId)}/details`,
  matchesPath: matchesPath<ProjectDetailsRouteParams>(/\/project\/([^/]+)\/details/, {
    projectId: 1
  })
};

export type ProjectDetailsEditRouteParams = { projectId: string };
export const projectDetailsEditRoute: Route<ProjectDetailsEditRouteParams> = {
  path: `/project/:projectId/details/edit`,
  makePath: ({ projectId }) => `/project/${orCreate(projectId)}/details/edit`,
  matchesPath: matchesPath<ProjectDetailsEditRouteParams>(/\/project\/([^/]+)\/details\/edit/, {
    projectId: 1
  })
};

export type ProjectDetailsEditGingerRouteParams = { projectId: string };
export const projectDetailsEditGingerRoute: RouteWithMakePath<ProjectDetailsEditGingerRouteParams> =
  {
    path: `/project/:projectId/details/edit/ginger`,
    makePath: ({ projectId }) => `/project/${orCreate(projectId)}/details/edit/ginger`,
    matchesPath: matchesPath<ProjectDetailsEditGingerRouteParams>(
      /\/project\/([^/]+)\/details\/edit\/ginger/,
      {
        projectId: 1
      }
    )
  };

export type ProjectDetailsEditFloorSpaceRouteParams = { projectId: string };
export const projectDetailsEditFloorSpaceRoute: RouteWithMakePath<ProjectDetailsEditFloorSpaceRouteParams> =
  {
    path: `/project/:projectId/details/edit/floorSpace`,
    makePath: ({ projectId }) => `/project/${orCreate(projectId)}/details/edit/floorSpace`,
    matchesPath: matchesPath<ProjectDetailsEditFloorSpaceRouteParams>(
      /\/project\/([^/]+)\/details\/edit\/floorSpace/,
      {
        projectId: 1
      }
    )
  };
