import outsideStairShaft, {
  OutsideStairsShaftSection
} from '../../../../../sections/outsideSections/outsideStairShaft';
import { getDefaultUnitConvertedPropertyValue } from '../../../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const createTopLevelOutsideStairsShaftSection = (
  caseGranulometry: CaseGranulometry,
  group: string,
  id: string
): OutsideStairsShaftSection => {
  const length = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'outsideStairShaftLength'
  ) as number;
  const width = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'outsideStairShaftWidth'
  ) as number;
  const surface = length * width;
  const bearingLength = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'outsideStairShaftBearingLength'
  ) as number;
  const bearingWidth = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'outsideStairsShaftBearingWidth'
  ) as number;
  return outsideStairShaft({
    id,
    surface,
    length,
    width,
    bearingLength,
    bearingWidth,
    group
  });
};
