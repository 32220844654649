import { matchesPath } from '../matchesPath';
import { RouteWithMakePath } from '../panel';

export type SmartRequestsRouteParams = { projectId: string };
export const smartRequestsRoute: RouteWithMakePath<SmartRequestsRouteParams> = {
  path: `/project/:projectId/smart-requests`,
  makePath: ({ projectId }) => `/project/${projectId}/smart-requests`,
  matchesPath: matchesPath<SmartRequestsRouteParams>(/\/project\/([^/]+)\/smart-requests/, {
    projectId: 1
  })
};

export type SmartRequestRouteParams = { projectId: string; request: string };
export const smartRequestRoute: RouteWithMakePath<SmartRequestRouteParams> = {
  path: `/project/:projectId/smart-requests/:request`,
  makePath: ({ projectId, request }) => `/project/${projectId}/smart-requests/${request}`,
  matchesPath: matchesPath<SmartRequestRouteParams>(
    /\/project\/([^/]+)\/smart-requests\/([\w ]+)/,
    {
      projectId: 1,
      request: 2
    }
  )
};
