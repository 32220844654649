import * as React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from '../../../../components/ui/Card';
import { CardsList } from '../../../../components/ui/CardsList';
import { Note } from '../../../../components/ui/Note';
import { IconObject } from '../../../../components/ui/Icons/iconObject';
import { selectBuildingIdFromRoute } from '../../../../store/selectors/navigation/buildingIdFromRoute.selector';
import { selectCurrentBuildingLabel } from '../../../../store/selectors/project/currentBuildingLabel.selector';
import { selectFirstBuildingId } from '../../../../store/selectors/specification/firstBuildingId.selector';
import { selectProjectId } from '../../../../store/selectors/project';
import { selectBuildings } from '../../../../store/selectors/specification/buildings.selector';
import { selectCanAddABuilding } from '../../../../store/selectors/core/canAddABuilding.selector';
import { buildingAdded } from '../../../../store/actions/buildingAdded.action';
import { buildingRemoved } from '../../../../store/actions/buildingRemoved.action';
import { goToBuilding } from '../../../../store/actions/navigations.actions';
import PanelTitle from '../PanelTitle';
import PositioningBuildingCard from './PositioningBuildingCard';
import { BuildingId } from '../../../../domain/specification/buildings/BuildingSpecification';
import BuildingTitleCard from './BuildingTitleCard';

const BuildingsPanel: React.FC = () => {
  const buildings = useSelector(selectBuildings);
  const canAddABuilding = useSelector(selectCanAddABuilding);
  const projectId = useSelector(selectProjectId);
  const firstBuildingId = useSelector(selectFirstBuildingId);
  const selectedBuildingId = useSelector(selectBuildingIdFromRoute);
  const selectedBuildingLabel = useSelector(selectCurrentBuildingLabel);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const onAddBuilding = React.useCallback(() => {
    dispatch(buildingAdded());
  }, [dispatch]);
  const onRemoveBuilding = React.useCallback(
    (id: BuildingId) => {
      dispatch(buildingRemoved(id));
    },
    [dispatch]
  );
  const onBuildingSelect = React.useCallback(
    (id: BuildingId) => {
      if (projectId) {
        dispatch(goToBuilding(projectId, id));
      }
    },
    [dispatch, projectId]
  );

  if (!projectId || !firstBuildingId || !buildings) return null;

  return (
    <div className="panel panel-buildings">
      {buildings && selectedBuildingId && (
        <CardsList className="buildings-panel">
          <PanelTitle title={t('Buildings')} />
          <Tabs
            selectedIndex={buildings.findIndex((b) => b.id === selectedBuildingId)}
            onSelect={(index) => onBuildingSelect(buildings[index].id)}>
            <Card>
              <TabList>
                {buildings.map((building, index) => (
                  <Tab key={building.id}>
                    <span>{(index || 0) + 1}</span>
                  </Tab>
                ))}
              </TabList>
              <div className="add-remove-buttons">
                {selectedBuildingId && (
                  <IconObject
                    iconName="remove"
                    type="menu"
                    disabled={buildings.length === 1}
                    onClick={() => {
                      if (
                        confirm(
                          t('Are you sure you want to delete building {{buildingLabel}}?', {
                            caseLabel: selectedBuildingLabel
                          })
                        )
                      ) {
                        setImmediate(() => onRemoveBuilding(selectedBuildingId));
                      }
                    }}
                    title={t('Remove building {{buildingLabel}}', {
                      buildingLabel: selectedBuildingLabel
                    })}
                  />
                )}
                <IconObject
                  iconName="add"
                  type="menu"
                  disabled={!canAddABuilding}
                  onClick={() => {
                    setImmediate(onAddBuilding);
                  }}
                  title={t('Add a building at the end of the list')}
                />
              </div>
            </Card>

            {buildings.map((building, index) => (
              <TabPanel key={building.id}>
                <Scrollbars
                  autoHide={true}
                  autoHideTimeout={1000}
                  autoHideDuration={200}
                  autoHeight={true}
                  autoHeightMin="100%"
                  autoHeightMax="100%"
                  style={{ width: '100%' }}
                  renderTrackVertical={(properties: unknown) => (
                    <div {...properties} className="track-vertical" />
                  )}>
                  <PositioningBuildingCard
                    buildings={buildings}
                    building={building}
                    buildingIndex={index}
                  />
                  <BuildingTitleCard buildingSpecification={building} buildingIndex={index} />
                  <Card>
                    <Note>
                      <p>
                        Cet espace permettra de gérer les données relatives au bâtiment {index + 1}.
                      </p>
                      <p>
                        Il sera notamment possible de modifier l&apos;intitulé et la position du
                        bâtiment, sa relation avec les bâtiments attenants, la gestion des niveaux
                        de sous-sol partagés, la forme urbaine...
                      </p>
                    </Note>
                  </Card>
                </Scrollbars>
              </TabPanel>
            ))}
          </Tabs>
        </CardsList>
      )}
    </div>
  );
};

export default BuildingsPanel;
