import { ProjectProjection } from '../../../../../projection/project/ProjectProjection';
import { CaseProjection } from '../../../../../projection/cases/CaseProjection';
import * as R from 'ramda';
import { MINIMUM_CASE_CBS } from '../../../../../../constants/appConstants';
import { getProjectCasesReservedCuttedBuiltSurface } from '../../../../project/queries/cases/getProjectCasesReservedCuttedBuiltSurface';

export const getCaseCuttedBuiltSurfaceMaximum = (
  projectProjection: ProjectProjection,
  caseProjection: CaseProjection
): number =>
  R.max(
    MINIMUM_CASE_CBS,
    projectProjection.projectedSurface.value -
      getProjectCasesReservedCuttedBuiltSurface(projectProjection).value +
      (caseProjection.surfaces.cuttedBuiltSurface !== undefined
        ? caseProjection.surfaces.cuttedBuiltSurface.value
        : MINIMUM_CASE_CBS)
  );
