import { getDefaultUnitConvertedPropertyValue } from '../../../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { EXPONENT, MULTIPLIER_COEFFICIENT } from '../../getSectionWallsLinear';

export const getRampSectionTheoreticalWallsLinear = (
  caseGranulometry: CaseGranulometry
): number => {
  const length = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'rampLength'
  ) as number;
  const width = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'rampWidth'
  ) as number;
  const surface = length * width;
  return surface !== 0 ? surface * MULTIPLIER_COEFFICIENT * Math.pow(surface, EXPONENT) : 0;
};
