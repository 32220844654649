import { CardsList } from '../../../../ui/CardsList';
import { WindowScroll } from '../../../../ui/WindowScroll';
import React, { useEffect, useState } from 'react';
import { UiFloorSpace } from '../FloorSpace';
import { UploadCard } from './UploadCard';
import { fireDrawHelperEvents } from '../../../../../services/mapBox/domain/drawHelper/drawHelper.events';
import { fireDrawEvents } from '../../../../../services/mapBox/mapboxDraw/draw.events';
import { ResizeCard } from './ResizeCard';
import { NewDistanceForLine } from '../../../../../services/mapBox/utils/polygon/resizePolygon';
import { DrawCard } from './DrawCard';
import { Button } from '../../../../ui/Button';
import { LoadImageOptions } from '../../../../../services/mapBox/map/map.repository';
import {
  FloorSpaceCollection,
  FloorSpaceFeature,
  getFloorSpaceByDrawHelperId,
  isFloorSpaceFeature
} from '../../../../../domain/specification/floorSpace/FloorSpaceFeature';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { useDispatch } from 'react-redux';
import { lockFloorSpace } from '../../../../../store/actions/floorSpace.actions';
import { getLoadImageOptions } from '../../../../../services/mapBox/domain/drawHelper/drawHelper.model';
import { createFile } from '../../../../../services/pdfToImages/create.pdfToImages';
import { deleteImage } from '../../../../../services/pdfToImages/delete.pdfToImages';

interface FloorSpaceSideDrawModeProps {
  ui: UiFloorSpace;
  toggleSetDrawHelperMode: () => void;
  floorSpaceCollection?: FloorSpaceCollection;
}

export interface StepState {
  upload?: LoadImageOptions;
  step2: { draw?: FloorSpaceFeature; displayDrawButton: boolean };
  resize?: NewDistanceForLine;
  number: number;
}

export const FloorSpaceSideDrawMode = ({
  ui,
  toggleSetDrawHelperMode,
  floorSpaceCollection
}: FloorSpaceSideDrawModeProps) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState<StepState>({ number: 1, step2: { displayDrawButton: true } });

  useEffect(() => {
    if (!step.upload) return;
    const feature = getFloorSpaceByDrawHelperId(step.upload.layerName, floorSpaceCollection);
    if (feature) {
      setStep({ ...step, step2: { ...step.step2, draw: feature, displayDrawButton: false } });
    }
  }, [floorSpaceCollection]);

  const goStepBack = () => setStep({ ...step, number: step.number - 1 >= 1 ? step.number - 1 : 1 });
  const goStepForward = () =>
    setStep({ ...step, number: step.number + 1 < 3 ? step.number + 1 : 3 });

  const removeImage = () => {
    if (!step.upload) return;
    fireDrawHelperEvents(ui.map).remove({ layerName: step.upload.layerName });
    setStep({ ...step, upload: undefined });
    deleteImage(step.upload.imagePath);
  };

  const addImage = async (selectedFile: File, pageToConvert: number) => {
    const fileUrl = await createFile(selectedFile, pageToConvert);
    const options = getLoadImageOptions(fileUrl);
    fireDrawHelperEvents(ui.map).addDrawHelper(options);
    setStep({ ...step, upload: options });
  };

  const removeDraw = () => {
    if (!isFloorSpaceFeature(step.step2.draw)) return;
    fireDrawEvents(ui.map).delete({ features: [step.step2.draw], drawHelperMode: true });
    setStep({ ...step, step2: { displayDrawButton: true } });
  };

  const onClickResize = (size: NewDistanceForLine) => {
    try {
      if (!isFloorSpaceFeature(step.step2.draw)) return;
      fireDrawEvents(ui.map).resize({
        selectedFeatures: { type: 'FeatureCollection', features: [step.step2.draw] },
        size
      });
    } catch (e) {
      console.error(e);
    }
  };

  const validate = () => {
    if (!isFloorSpaceFeature(step.step2.draw)) return;
    dispatch(lockFloorSpace(step.step2.draw));
    fireDrawHelperEvents(ui.map).validate({ featureId: step.step2.draw.id });
    toggleSetDrawHelperMode();
    if (!step.upload) return;
    deleteImage(step.upload.imagePath);
  };

  const facadeCount = step.step2?.draw?.properties.facades?.length || 0;

  return (
    <CardsList>
      <WindowScroll>
        {step.number === 1 && (
          <UploadCard
            addImage={addImage}
            onClickRemoveImage={removeImage}
            step={step}
            goStepForward={goStepForward}
          />
        )}
        {step.number === 2 && (
          <DrawCard
            drawPolygon={() => (ui.draw as MapboxDraw).changeMode('draw_polygon')}
            removePolygon={removeDraw}
            step={step}
            goStepBack={goStepBack}
            goStepForward={goStepForward}
          />
        )}
        {step.number === 3 && (
          <ResizeCard
            resize={onClickResize}
            setStep={setStep}
            step={step}
            goStepBack={goStepBack}
            facadeCount={facadeCount}
          />
        )}
        {step.resize && (
          <div>
            <Button content={'Terminer'} handleClick={validate} />
          </div>
        )}
      </WindowScroll>
    </CardsList>
  );
};
