import { createSelector, Selector } from 'reselect';
import { State } from '../../reducers';
import { selectPath } from '../navigation';
import { matchesProject } from '../../../routes/matches/matchesProject';

export const selectIsExistingProject: Selector<State, boolean> = createSelector(
  [selectPath],
  (path) => {
    const requestedProjectId = matchesProject(path).params?.projectId;
    return Boolean(requestedProjectId && requestedProjectId !== 'create');
  }
);
