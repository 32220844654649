import { DrawMode } from '@mapbox/mapbox-gl-draw';
import mapboxgl from 'mapbox-gl';
import { DrawServices } from './draw.services';
import { FeatureCollection } from 'geojson';
import { NewDistanceForLine } from '../utils/polygon/resizePolygon';
import { FeaturePolygonWithId } from './draw.repository';

export const RotateMode = 'RotateMode' as DrawMode;

export const drawEvents = (map: mapboxgl.Map, drawServices: DrawServices) => {
  map.on('draw.create', (e) => drawServices.create(e));

  map.on('draw.update', (e) => drawServices.update(e));

  map.on('draw.delete', (e) => drawServices.delete(e));

  map.on('draw.resize', (e) => drawServices.resizeDraw(e));

  map.on('draw.updateAll', () => drawServices.updateAllDraw());
};
export interface ResizeEventPayload {
  selectedFeatures: FeatureCollection;
  size: NewDistanceForLine;
}

export interface DeleteDrawPayload {
  features: FeaturePolygonWithId[];
  drawHelperMode?: boolean;
}

export const fireDrawEvents = (map: mapboxgl.Map) => ({
  resize: (payload: ResizeEventPayload) => map.fire('draw.resize', { payload }),
  updateAll: () => map.fire('draw.updateAll'),
  delete: (payload: DeleteDrawPayload) => map.fire('draw.delete', payload)
});
