import { createSelector, Selector } from 'reselect';
import { Maybe } from '../../../utils/Maybe';
import { Panel } from '../../../interfaces/Panel';
import { buildingsRoute } from '../../../routes/toolboxPanels/buildings';
import { casesRoute } from '../../../routes/toolboxPanels/cases';
import { deliverablesRoute } from '../../../routes/toolboxPanels/deliverables';
import { projectDetailsRoute } from '../../../routes/toolboxPanels/projectDetails';
import { sectionsRoute } from '../../../routes/toolboxPanels/sections';
import { smartRequestsRoute } from '../../../routes/toolboxPanels/smartRequest';
import { State } from '../../../store/reducers';
import { selectPath } from './getPath.selector';

export const selectCurrentPanel: Selector<State, Maybe<Panel>> = createSelector(
  [selectPath],
  (path: string) => {
    if (projectDetailsRoute.matchesPath(path).matches) {
      return Panel.ProjectDetails;
    }
    if (buildingsRoute.matchesPath(path).matches) {
      return Panel.Buildings;
    }
    if (casesRoute.matchesPath(path).matches) {
      return Panel.Cases;
    }
    if (sectionsRoute.matchesPath(path).matches) {
      return Panel.Sections;
    }
    if (smartRequestsRoute.matchesPath(path).matches) {
      return Panel.SmartRequests;
    }
    if (deliverablesRoute.matchesPath(path).matches) {
      return Panel.Deliverables;
    }
    return undefined;
  }
);
