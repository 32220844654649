import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getLodgmentTypeRoomTriggerId } from '../../../../../../routes/toolboxPanels/triggers';
import { setLodgmentTypeRoom } from '../../../../../../store/actions/setLodgmentTypeRoom.action';
import {
  goToGranulometryLodgmentType,
  goToGranulometryLodgmentTypeRoom
} from '../../../../../../store/actions/navigations.actions';
import { IconObject } from '../../../../../ui/Icons/iconObject';
import { Window } from '../../../../../ui/Window';
import RoomWindowInner from './RoomWindowInner';
import { selectCurrentlyEditedLodgmentType } from '../../../../../../store/selectors/specification/currentCase/currentlyEditedLodgmentType.selector';
import { selectCurrentCaseLabel } from '../../../../../../store/selectors/project/currentCaseLabel.selector';
import { RoomSpecification } from '../../../../../../domain/specification/rooms/RoomSpecification';
import { BodyguardType } from '../../../../../../domain/specification/rooms/BalconySpecification';
import { CaseGranulometryEditLodgmentTypeRoomRouteParams } from '../../../../../../routes/toolboxPanels/cases';

export const RoomWindow = () => {
  const {
    caseId,
    projectId,
    roomIndex: roomParam
  } = useParams<CaseGranulometryEditLodgmentTypeRoomRouteParams>();
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const lodgmentType = useSelector(selectCurrentlyEditedLodgmentType);
  const roomIndex = roomParam ? parseInt(roomParam, 10) : NaN;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSetLodgmentTypeRoom = React.useCallback(
    (
      value: number | BodyguardType | null,
      fieldName: 'length' | 'width' | 'depth' | 'surface' | 'bodyguardType'
    ) => {
      if (caseId && lodgmentType?.lodgmentType)
        dispatch(
          setLodgmentTypeRoom(caseId, lodgmentType.lodgmentType, roomIndex, {
            [fieldName]: value || undefined
          })
        );
    },
    [dispatch, caseId, lodgmentType?.lodgmentType, roomIndex]
  );

  const handleClickClose = React.useCallback(() => {
    if (caseId && projectId && lodgmentType)
      dispatch(goToGranulometryLodgmentType(projectId, caseId, lodgmentType.lodgmentType));
  }, [dispatch, projectId, caseId, lodgmentType]);

  const handleClickPrev = React.useCallback(() => {
    if (caseId && projectId && lodgmentType)
      dispatch(
        goToGranulometryLodgmentTypeRoom(
          projectId,
          caseId,
          lodgmentType.lodgmentType,
          roomIndex - 1
        )
      );
  }, [dispatch, projectId, caseId, lodgmentType, roomIndex]);

  const handleClickNext = React.useCallback(() => {
    if (caseId && projectId && lodgmentType?.nextType)
      dispatch(
        goToGranulometryLodgmentTypeRoom(
          projectId,
          caseId,
          lodgmentType.lodgmentType,
          roomIndex + 1
        )
      );
  }, [dispatch, projectId, caseId, lodgmentType, roomIndex]);

  if (!caseLabel || !lodgmentType) return null;

  if (isNaN(roomIndex)) {
    return null;
  }
  const roomSpecification: RoomSpecification = lodgmentType.rooms[roomIndex];
  const title = `${t('Room')} : ${t(roomSpecification.title)}`;
  return (
    <Window
      title={title}
      topButtons={
        <>
          <IconObject
            iconName="arrowLeft"
            type="menu"
            disabled={!lodgmentType.rooms[roomIndex - 1]}
            onClick={handleClickPrev}
          />
          <IconObject
            iconName="arrowRight"
            type="menu"
            disabled={!lodgmentType.rooms[roomIndex + 1]}
            onClick={handleClickNext}
          />
          <IconObject iconName="close" type="menu" onClick={handleClickClose} />
        </>
      }
      hidden={false}
      triggerId={getLodgmentTypeRoomTriggerId(caseLabel, lodgmentType.lodgmentType, roomIndex)}>
      <RoomWindowInner
        roomSpecification={roomSpecification}
        handleSetLodgmentTypeRoom={handleSetLodgmentTypeRoom}
        lodgmentTypeSpecification={lodgmentType}
      />
    </Window>
  );
};

export default RoomWindow;
