import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormGroup } from '../../../../../ui/FormGroup';
import { useParams } from 'react-router';
import { IconObject } from '../../../../../ui/Icons/iconObject';
import { Input } from '../../../../../ui/input/Input';
import { selectCurrentCaseProjection } from '../../../../../../store/selectors/projection/currentCase/projection.selector';
import { getCaseDataProperty } from '../../../../../../domain/specification/cases/queries/get/properties/getCaseDataProperty';
import { casePropertyChanged } from '../../../../../../store/actions/casePropertyChanged.action';
import { SMOCK_EXTRACTION_DUCTS_ICON } from '../../../../../../domain/granulometry/sections/circulationSections/smokeExtractionDucts';
import { CaseGranulometryEditSuperstructureRouteParams } from '../../../../../../routes/toolboxPanels/cases';

export const SmokeExtractionDuctsCount = () => {
  const { caseId } = useParams<CaseGranulometryEditSuperstructureRouteParams>();
  const currentlyEditedCase = useSelector(selectCurrentCaseProjection);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const smokeExtractionDuctsShaftCountProperty =
    currentlyEditedCase && getCaseDataProperty(currentlyEditedCase, 'smokeExtractionDuctsCount');
  const handleSmokeExtractionDuctsCountChange = React.useCallback(
    (newSmokeExtractionDuctsCount: number | null) => {
      if (
        caseId &&
        smokeExtractionDuctsShaftCountProperty &&
        (typeof newSmokeExtractionDuctsCount === 'number' || newSmokeExtractionDuctsCount === null)
      ) {
        dispatch(
          casePropertyChanged(caseId, {
            ...smokeExtractionDuctsShaftCountProperty,
            value: newSmokeExtractionDuctsCount ?? undefined
          })
        );
      }
    },
    [dispatch, caseId, smokeExtractionDuctsShaftCountProperty]
  );

  if (!currentlyEditedCase || !smokeExtractionDuctsShaftCountProperty) return null;

  return (
    <FormGroup horizontal={true} wrap={true}>
      <Input
        style={{ width: '40%', flex: '0 0 40%' }}
        name={`case${caseId}SmokeExtractionDuctsCount`}
        label={t('Smoke extraction ducts')}
        // info={t('smokeExtractionDuctsShaftCount.infos')}
        type="number"
        min={smokeExtractionDuctsShaftCountProperty.min}
        max={smokeExtractionDuctsShaftCountProperty.max}
        minMax={{
          min: smokeExtractionDuctsShaftCountProperty.min,
          max: smokeExtractionDuctsShaftCountProperty.max,
          unit: smokeExtractionDuctsShaftCountProperty.unit
        }}
        placeholder={smokeExtractionDuctsShaftCountProperty.defaultValue}
        value={smokeExtractionDuctsShaftCountProperty.value}
        handleChange={handleSmokeExtractionDuctsCountChange}
        suffix={smokeExtractionDuctsShaftCountProperty.unit}
        icon={<IconObject {...SMOCK_EXTRACTION_DUCTS_ICON} clickable={false} />}
      />
    </FormGroup>
  );
};
