import { getCaseDataLodgmentDistribution } from './getCaseDataLodgmentDistribution';
import { getLevelRealBuiltSurface } from '../../granulometry/levels/queries/surfaces/getLevelRealBuiltSurface';
import { getTopLevelSurfaceForSale } from '../../granulometry/levels/queries/topLevels/surfaces/surfaceForSale/getTopLevelSurfaceForSale';
import { LevelSFSAdjusment } from './fillCaseLevels';
import { BasementLevelDataLegacy, CaseFormDataLegacy } from '../caseFormData';
import { LodgmentType } from '../../specification/lodgmentTypes/LodgmentType';
import { getSectionsTotalSurface } from '../../granulometry/sections/queries/surfaces/getSectionsTotalSurface';
import { LevelGranulometryInitialEntries } from '../../granulometry/levels/LevelGranulometry';

export interface InitialLevelsData {
  level: number;
  realBuiltSurface: number;
  surfaceForSale: number;
  remainingSurfaceForSale: number;
  filledSurface: number;
  lodgmentCount: number;
  lodgments: { [key in LodgmentType]: [] } | {};
}

/* Get the initial levels data in the given case */
export const getCaseDataInitialLevelsData = (
  caseData: CaseFormDataLegacy,
  levelsSFSAdjustments?: LevelSFSAdjusment[]
): (InitialLevelsData | BasementLevelDataLegacy)[] => {
  const distribution = getCaseDataLodgmentDistribution(caseData);
  const initialLevelsData = [];

  // If topLevelData is empty
  // TODO : How this can be possible ?
  if (!caseData.topLevelsData || !caseData.topLevelsData.length) {
    caseData.topLevelsData = [];
    for (let i = caseData.projectedTopLevelsCount - 1; i >= 0; i--) {
      caseData.topLevelsData.push({ level: i });
    }
  }

  // For each top level
  for (let i = 0; i < caseData.topLevelsData.length; i++) {
    const levelIndex = caseData.topLevelsData[i].level;
    const technicalPremiseSections = caseData.topLevelsData[i].technicalPremiseSections;
    const levelData: InitialLevelsData = {
      ...caseData.topLevelsData[i],
      realBuiltSurface: getLevelRealBuiltSurface({ level: levelIndex }, caseData), // TODO : To type for both contexts : before and after granulo
      surfaceForSale: getTopLevelSurfaceForSale(caseData, {
        level: levelIndex,
        technicalPremiseSections
      } as LevelGranulometryInitialEntries),
      remainingSurfaceForSale: 0,
      filledSurface: 0,
      lodgmentCount: 0,
      lodgments: {}
    };
    // SFS adjustment due of minimum bearing surface overriding (cf. fillCaseLevels.ts)
    if (levelsSFSAdjustments !== undefined) {
      const adjustment = levelsSFSAdjustments.find(
        (levelsSFSAdjustment) => levelsSFSAdjustment.level === levelData.level
      )?.adjustment;
      levelData.surfaceForSale += adjustment || 0;
    }
    const commonPremiseSectionsSurface = getSectionsTotalSurface(
      caseData.topLevelsData[i].commonPremiseSections || []
    );
    const shopSectionsSurface = getSectionsTotalSurface(
      caseData.topLevelsData[i].shopSections || []
    );
    const officeSectionsSurface = getSectionsTotalSurface(
      caseData.topLevelsData[i].officeSections || []
    );
    levelData.remainingSurfaceForSale =
      levelData.surfaceForSale -
      commonPremiseSectionsSurface -
      shopSectionsSurface -
      officeSectionsSurface;
    levelData.filledSurface = 0;
    levelData.lodgmentCount = 0;
    levelData.lodgments = distribution.reduce(
      (acc, distrib) => ({ ...acc, [distrib.lodgmentType]: [] }),
      {}
    );
    initialLevelsData[i] = levelData;
  }

  // For each basement level
  for (let i = 0; i < caseData.basementLevelsData.length; i++) {
    initialLevelsData.push({ ...caseData.basementLevelsData[i] });
  }

  return initialLevelsData;
};
