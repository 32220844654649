import mapboxgl, { EventData, MapLayerEventType, MapMouseEvent } from 'mapbox-gl';
import { selectParcel } from '../domain/parcel/selectParcel';
import removeOneParcel from '../domain/parcel/removeOneParcel';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Dispatch } from 'redux';
import { addOrRemoveParcelAction } from '../../../store/actions/parcels.actions';
import { FeatureParcel, ParcelProperties } from '../../../domain/Parcel';
import { fireDrawEvents, RotateMode } from '../mapboxDraw/draw.events';
import { MapSource } from '../types';
import { Polygon } from 'geojson';
import * as R from 'ramda';

const cursorSelect = (layer: string, map: mapboxgl.Map) => {
  map.on('mouseenter', layer, () => {
    map.getCanvas().style.cursor = 'pointer';
  });
  map.on('mouseleave', layer, () => {
    map.getCanvas().style.cursor = '';
  });
};
const reinitCursor = (map: mapboxgl.Map) => {
  map.on('mouseenter', 'parcelsFillLayer', () => {
    map.getCanvas().style.cursor = '';
  });
};

export const onKeyDown = (
  e: KeyboardEvent,
  map: mapboxgl.Map,
  addOrRemoveParcel: AddOrRemoveParcel
) => {
  if (e.repeat) {
    return;
  }
  switch (e.code) {
    case 'ShiftLeft':
      cursorSelect('parcelsFillLayer', map);
      map.on('click', 'parcelsFillLayer', addOrRemoveParcel);
      break;
    default:
      return null;
  }
};

export const onKeyUp = (
  e: KeyboardEvent,
  map: mapboxgl.Map,
  draw: MapboxDraw,
  addOrRemoveParcel: AddOrRemoveParcel
) => {
  switch (e.code) {
    case 'ShiftLeft':
      map.off('click', 'parcelsFillLayer', addOrRemoveParcel);
      reinitCursor(map);
      break;
    case 'KeyR':
      if (draw.getMode() !== RotateMode) {
        return draw.changeMode(RotateMode);
      }
      if (draw.getMode() === RotateMode) {
        draw.changeMode('simple_select');
        fireDrawEvents(map).updateAll();
      }
      break;
    case 'Enter':
      reinitCursor(map);
      draw.changeMode('simple_select');
      fireDrawEvents(map).updateAll();
      break;
    default:
      return null;
  }
};

const boundAddOrRemoveParcel =
  (map: mapboxgl.Map, dispatch: Dispatch) =>
  (e: MapMouseEvent & { features?: FeatureParcel[] } & EventData) => {
    const layers = map.getSource('selected_parcels') as MapSource<Polygon, ParcelProperties>;
    if (!e.features) return;
    let parcel = e.features[0];
    if (
      !layers?._data?.features ||
      layers._data.features.length < 1 ||
      !R.find(R.propEq('id', e.features[0].id))(layers._data.features as { id: string }[])
    ) {
      parcel = selectParcel(map, e.features[0]);
    } else {
      removeOneParcel(map, e.features[0].id, layers);
    }
    dispatch(addOrRemoveParcelAction(parcel));
  };

type AddOrRemoveParcel = <T extends keyof MapLayerEventType>(
  ev: MapLayerEventType[T] & EventData
) => void;

const keyboardControl = (map: mapboxgl.Map, draw: MapboxDraw, dispatch: Dispatch) => {
  const addOrRemoveParcel = boundAddOrRemoveParcel(map, dispatch); // map.off work only with same function
  map.getCanvas().addEventListener('keydown', (e) => onKeyDown(e, map, addOrRemoveParcel));
  map.getCanvas().addEventListener('keyup', (e) => onKeyUp(e, map, draw, addOrRemoveParcel));
};

export default keyboardControl;
