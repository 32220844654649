import { LevelGranulometryInitialEntries } from '../../../../LevelGranulometry';
import { CaseFormDataLegacy } from '../../../../../../legacy/caseFormData';
import { getLevelRealBuiltSurface } from '../../../surfaces/getLevelRealBuiltSurface';
import { getCaseDataCuttedBuiltSurfaceRate } from '../../../../../../legacy/methodsForCases/getCaseDataCuttedBuiltSurfaceRate';
import { getCaseDataSurfaceForSaleRate } from '../../../../../../legacy/methodsForCases/getCaseDataSurfaceForSaleRate';
import { getDefaultUnitConvertedPropertyValue } from '../../../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { getLevelMaxSurfaceForSaleFromCaseProjection } from './getLevelMaxSurfaceForSaleFromCaseProjection';
import { getSectionDisplayedSurface } from '../../../../../sections/queries/surfaces/getSectionDisplayedSurface';
import { getLevelTechnicalPermiseSections } from '../../../sections/getLevelTechnicalPermiseSections';
import { getCaseDataRealBuiltSurface } from '../../../../../../legacy/methodsForCases/getCaseDataRealBuiltSurface';

export const getGroundLevelSurfaceForSale = (
  caseData: CaseFormDataLegacy,
  groundLevel: LevelGranulometryInitialEntries
): number => {
  if (caseData.maxSurfaceForSaleHasBeenForced) {
    return getLevelMaxSurfaceForSaleFromCaseProjection(caseData, groundLevel);
  } else {
    const levelRealBuiltSurface = getLevelRealBuiltSurface(groundLevel, caseData);
    const cbsRate = getCaseDataCuttedBuiltSurfaceRate(caseData);
    const sfsRate = getCaseDataSurfaceForSaleRate(caseData);
    const caseRealBuiltSurface = getCaseDataRealBuiltSurface(caseData);

    let groundLevelSFS = levelRealBuiltSurface;
    groundLevelSFS *= cbsRate * sfsRate;

    // Hall : the hall surface is redistributed in the upperLevels
    if (caseData.hall && caseData.topLevelsData.length > 1) {
      groundLevelSFS -= getDefaultUnitConvertedPropertyValue(caseData, 'hallSurface') as number;
    }

    // Technical premise sections
    if (caseData.topLevelsData.length > 1) {
      groundLevelSFS -= getLevelTechnicalPermiseSections(groundLevel).reduce(
        (acc, technicalPremiseSection) => acc + getSectionDisplayedSurface(technicalPremiseSection),
        0
      );

      // TODO : check if level exist
      groundLevelSFS +=
        caseData.topLevelsData.reduce(
          (acc, level) =>
            acc +
            (level.technicalPremiseSections || []).reduce(
              (accTPS, technicalPremiseSection) =>
                accTPS + getSectionDisplayedSurface(technicalPremiseSection),
              0
            ),
          0
        ) *
        (levelRealBuiltSurface / (caseRealBuiltSurface || 1));
    }

    return groundLevelSFS;
  }
};
