import { CaseGranulometry } from '../../../../granulometry/cases/CaseGranulometry';
import { getLevelRealBuiltSurface } from '../../../../granulometry/levels/queries/surfaces/getLevelRealBuiltSurface';
import { TopLevelDataLegacy } from '../../../caseFormData';
import { getCaseTopLevels } from '../../../../granulometry/cases/queries/levels/getCaseTopLevels';

export const addGfsEffRelativeTopLevelsRbs = (
  caseGranulometry: CaseGranulometry,
  topLevelsCount: number
): CaseGranulometry => {
  // We simulate forced real built surfaces for each level to suit the calculated gross floor surface
  let gfsEffRelativeTopLevelsRBS: TopLevelDataLegacy[] = [];
  let levelIndex = topLevelsCount;
  while (levelIndex > 0) {
    levelIndex -= 1;
    const levelGranulometry = getCaseTopLevels(caseGranulometry).find(
      (l) => l.level === levelIndex
    );
    // undefined if roofing level
    if (levelGranulometry) {
      const realBuiltSurface = getLevelRealBuiltSurface(
        levelGranulometry,
        caseGranulometry.initialSpecifications,
        caseGranulometry
      ); // Will go to case 3 (see : getLevelRealBuiltSurface)
      gfsEffRelativeTopLevelsRBS = [
        ...gfsEffRelativeTopLevelsRBS,
        {
          level: levelIndex,
          realBuiltSurface
        }
      ];
    }
  }

  return {
    ...caseGranulometry,
    initialSpecifications: {
      ...caseGranulometry.initialSpecifications,
      gfsEffRelativeTopLevelsRBS
    }
  };
};
