import { getDefaultUnitConvertedPropertyValue } from '../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { CaseGranulometry } from '../../cases/CaseGranulometry';

export const getLevelFloorThickness = (caseGranulometry: CaseGranulometry): number =>
  ((getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'topLevelConcreteSlabThickness'
  ) as number) || 0) +
  ((getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'topLevelCementScreedThickness'
  ) as number) || 0);
