import { getCaseDataTheoricalRealBuiltSurface } from './getCaseDataTheoricalRealBuiltSurface';
import { DEFAULT_RBS_TO_CBS_RATIO } from '../../../constants/appConstants';
import { getCaseDataSurfaceForSaleRate } from './getCaseDataSurfaceForSaleRate';
import { CaseFormDataLegacy } from '../caseFormData';
import { getSectionDisplayedSurface } from '../../granulometry/sections/queries/surfaces/getSectionDisplayedSurface';

/* fr : retourne la surface de planché réelle d'une cage */
export const getCaseDataRealBuiltSurface = (caseData: CaseFormDataLegacy): number => {
  if (caseData.realBuiltSurface) {
    // If there is a realBuiltSurface forced value
    return caseData.realBuiltSurface;
  } else {
    // Otherwise : we calculate it
    return (
      getCaseDataTheoricalRealBuiltSurface(caseData) +
      caseData.topLevelsData.reduce(
        (accL, levelData) =>
          accL +
          (levelData.technicalPremiseSections || []).reduce(
            (accTPS, technicalPremiseSection) =>
              accTPS + getSectionDisplayedSurface(technicalPremiseSection),
            0
          ),
        0
      ) /
        (getCaseDataSurfaceForSaleRate(caseData) * DEFAULT_RBS_TO_CBS_RATIO)
    );
  }
};
