import { CaseProjection } from '../../projection/cases/CaseProjection';
import { CaseGranulometry } from '../../granulometry/cases/CaseGranulometry';
import { isSectionAHall } from '../../granulometry/sections/circulationSections/hall';
import { getCaseGroundLevel } from '../../granulometry/cases/queries/levels/getCaseGroundLevel';
import { getLevelFullFilledContent } from '../../granulometry/levels/queries/content/getLevelFullFilledContent';

export const caseHallSurfaceIsAdjusted = (
  caseProjection: CaseProjection,
  caseGranulometry: CaseGranulometry
): boolean => {
  let surfaceFromProjection = 0;
  let surfaceFromGranulo = 0;
  // If case has hall :
  if (caseProjection.hall) {
    surfaceFromProjection += caseProjection.projectedSurfaces.hallSurface.value;
    surfaceFromGranulo +=
      getLevelFullFilledContent(getCaseGroundLevel(caseGranulometry)).find(isSectionAHall)
        ?.displayedSurface || 0;
  }
  return surfaceFromProjection > surfaceFromGranulo;
};
