import { ProjectProjection } from '../../../../projection/project/ProjectProjection';
import { getProjectCasesProjections } from '../../../../projection/project/queries/cases/getProjectCasesProjections';

export const getProjectSumOfCasesCuttedBuiltSurfaces = (
  projectProjection: ProjectProjection
): number =>
  getProjectCasesProjections(projectProjection).reduce(
    (acc, eachCase) => acc + (eachCase.projectedSurfaces.cuttedBuiltSurface?.value || 0),
    0
  );
