import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { selectCurrentCaseParkingsCountByBasementLevelAndType } from '../../../store/selectors/granulometry/parkings/parkingsCountByBasementLevelAndType.selector';
import { selectCurrentCaseIdFromRoute } from '../../../store/selectors/navigation/caseIdFromRoute.selector';
import { goToCaseGranulometryEditLevel } from '../../../store/actions/navigations.actions';
import { selectProjectId } from '../../../store/selectors/project';
import { selectCurrentCaseRealBuiltSurfacesByLevel } from '../../../store/selectors/granulometry/currentCase/realBuiltSurfacesByLevel.selector';
import { editingBegan } from '../../../store/actions/editingStarted.action';
import { editingEnded } from '../../../store/actions/editingEnded.action';
import { selectCurrentCaseSpecifiedBasementLevels } from '../../../store/selectors/specification/currentCase/basementLevels.selector';
import { setBasementLevelsSpecifications } from '../../../store/actions/setBasementLevelsSpecifications.action';
import { BasementLevelLine } from './BasementLevelLine';
import { selectCurrentCaseBasementLevelsCount } from '../../../store/selectors/granulometry/currentCase/basementLevelsCount.selector';
import {
  BasementLevelSpecification,
  isBasementLevelsParkingRateRepartitionValid
} from '../../../domain/specification/levels/BasementLevelSpecification';

interface LocalBasementLevelsParkingRatesRepartition {
  levels: BasementLevelSpecification[];
  isValid: boolean;
}

const BasementLevelLines = () => {
  const parkingsCountByLevelAndType = useSelector(
    selectCurrentCaseParkingsCountByBasementLevelAndType
  );
  const projectId = useSelector(selectProjectId);
  const caseId = useSelector(selectCurrentCaseIdFromRoute);
  const realBuiltSurfaceByLevel = useSelector(selectCurrentCaseRealBuiltSurfacesByLevel);
  const basementLevels = useSelector(selectCurrentCaseSpecifiedBasementLevels);
  const basementLevelsCount = useSelector(selectCurrentCaseBasementLevelsCount);
  const dispatch = useDispatch();

  const [localBasementLevelsSpecification, setLocalBasementLevelsSpecification] = React.useState({
    levels: basementLevels,
    isValid: true
  } as LocalBasementLevelsParkingRatesRepartition);

  React.useEffect(() => {
    if (basementLevels) {
      setLocalBasementLevelsSpecification({
        levels: basementLevels,
        isValid: isBasementLevelsParkingRateRepartitionValid(basementLevels)
      });
    }
  }, [basementLevels]);

  const handleParkingRateChange = React.useCallback(
    (level: number | null, parkingRate: number | null) => {
      const levelIndex = localBasementLevelsSpecification.levels.findIndex(
        (l) => l.level === level
      );
      const newLevelsSpecifications = R.over(
        R.lensIndex(levelIndex),
        R.assoc('parkingRate', parkingRate || undefined),
        localBasementLevelsSpecification.levels
      );
      const isValid = isBasementLevelsParkingRateRepartitionValid(newLevelsSpecifications);

      setLocalBasementLevelsSpecification({ levels: newLevelsSpecifications, isValid });

      if (!isValid && localBasementLevelsSpecification.isValid) dispatch(editingBegan());
      if (isValid && !localBasementLevelsSpecification.isValid) dispatch(editingEnded());

      if (isValid) {
        if (caseId) {
          dispatch(setBasementLevelsSpecifications(caseId, newLevelsSpecifications));
        }
      }
    },
    [dispatch, localBasementLevelsSpecification, caseId]
  );

  const handleRealBuildSurfaceChange = React.useCallback(
    (level: number | null, realBuiltSurface: number | null) => {
      const levelIndex = localBasementLevelsSpecification.levels.findIndex(
        (l) => l.level === level
      );

      const newLevelsSpecifications = R.over(
        R.lensIndex(levelIndex),
        R.assoc('realBuiltSurface', realBuiltSurface !== null ? realBuiltSurface : undefined),
        localBasementLevelsSpecification.levels
      );

      if (caseId) {
        dispatch(setBasementLevelsSpecifications(caseId, newLevelsSpecifications));
      }
    },
    [dispatch, localBasementLevelsSpecification, caseId]
  );

  if (!parkingsCountByLevelAndType || !basementLevels || !realBuiltSurfaceByLevel) return null;

  const { levels } = localBasementLevelsSpecification;
  const specifiedParkingRate = levels.reduce((acc, level) => acc + (level.parkingRate || 0), 0);
  const unspecifiedRatesCount = levels.filter((l) => l.parkingRate === undefined).length;
  const defaultParkingRate =
    basementLevelsCount < 2 ? 100 : (100 - specifiedParkingRate) / unspecifiedRatesCount;

  return (
    <>
      {levels.map((levelSpecification) => {
        const parkingRate = basementLevelsCount < 2 ? undefined : levelSpecification.parkingRate;
        const parkingsCountByType =
          parkingsCountByLevelAndType[levelSpecification.level.toString(10)];

        return (
          <BasementLevelLine
            key={levelSpecification.level}
            levelSpecification={levelSpecification}
            onEditClick={() =>
              projectId &&
              caseId &&
              dispatch(
                goToCaseGranulometryEditLevel(
                  projectId,
                  caseId,
                  levelSpecification.level.toString()
                )
              )
            }
            handleParkingRateChange={(value: number | null) =>
              handleParkingRateChange(levelSpecification.level, value)
            }
            handleRealBuildSurfaceChange={(value: number | null) =>
              handleRealBuildSurfaceChange(levelSpecification.level, value)
            }
            parkingRate={parkingRate}
            parkingRatePH={defaultParkingRate}
            parkingsCountByType={parkingsCountByType}
            isValid={localBasementLevelsSpecification.isValid}
            calculatedRealBuiltSurface={realBuiltSurfaceByLevel[levelSpecification.level]}
          />
        );
      })}
    </>
  );
};

export default BasementLevelLines;
