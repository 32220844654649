import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { LevelGranulometry, LevelGranulometryFullFilled } from '../../../LevelGranulometry';
import { getLevelRealBuiltSurfaceEff } from '../../surfaces/getLevelRealBuiltSurfaceEff';
import { hasCaseGranulometryDrawnFloorSpaceFeature } from '../../../../cases/queries/has/hasCaseGranulometryDrawnFloorSpaceFeature';
import { getTopLevelGrossFloorSurfaceEff } from '../surfaces/grossFloorSurfaces/getTopLevelGrossFloorSurfaceEff';

export const LARGE_TOP_LEVEL_DEFAULT_WIDTH = 13.5; // m
export const SMALL_TOP_LEVEL_DEFAULT_WIDTH = 9; // m

export const getTopLevelDefaultWidth = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled | LevelGranulometry
): number => {
  if (hasCaseGranulometryDrawnFloorSpaceFeature(caseGranulometry)) {
    return getTopLevelGrossFloorSurfaceEff(caseGranulometry, levelGranulometry) <= 200
      ? SMALL_TOP_LEVEL_DEFAULT_WIDTH
      : LARGE_TOP_LEVEL_DEFAULT_WIDTH;
  } else {
    return getLevelRealBuiltSurfaceEff(caseGranulometry, levelGranulometry) <= 200
      ? SMALL_TOP_LEVEL_DEFAULT_WIDTH
      : LARGE_TOP_LEVEL_DEFAULT_WIDTH;
  }
};
