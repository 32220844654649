import { getCaseDataSurfaceForSaleRealRate } from './getCaseDataSurfaceForSaleRealRate';
import { DEFAULT_RBS_TO_CBS_RATIO } from '../../../constants/appConstants';
import { CaseFormDataLegacy } from '../caseFormData';

export const getCaseDataTheoricalRealBuiltSurface = (caseData: CaseFormDataLegacy): number => {
  let realBuiltSurface;
  if (caseData.realBuiltSurface) {
    realBuiltSurface = caseData.realBuiltSurface;
  } else if (caseData.cuttedBuiltSurface) {
    realBuiltSurface = caseData.cuttedBuiltSurface / DEFAULT_RBS_TO_CBS_RATIO;
  } else if (caseData.surfaceForSale) {
    realBuiltSurface = caseData.surfaceForSale / getCaseDataSurfaceForSaleRealRate(caseData);
  }
  return realBuiltSurface as number;
};
