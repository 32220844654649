import { getTopLevelRealBuiltForcedSurface } from '../topLevels/surfaces/getTopLevelRealBuiltForcedSurface';
import { getCaseDataLevelsForcedRealBuiltSurfaces } from '../../../../specification/cases/queries/levels/surfaces/getCaseDataLevelsForcedRealBuiltSurfaces';
import { getCaseDataProjectedTopLevelCount } from '../../../../specification/cases/queries/levels/counts/getCaseDataProjectedTopLevelCount';
import { getCaseDataGroundLevelForcedSurface } from '../../../../specification/cases/queries/levels/surfaces/getCaseDataGroundLevelForcedSurface';
import { getCaseDataRealBuiltSurface } from '../../../../legacy/methodsForCases/getCaseDataRealBuiltSurface';
import {
  LevelGranulometry,
  LevelGranulometryFullFilled,
  LevelGranulometryInitialEntries
} from '../../LevelGranulometry';
import { isGroundLevel } from '../is/isGroundLevel';
import { CaseFormDataLegacy, TopLevelDataLegacy } from '../../../../legacy/caseFormData';
import { getDefaultUnitConvertedPropertyValue } from '../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { getTopLevelGrossFloorSurfaceEff } from '../topLevels/surfaces/grossFloorSurfaces/getTopLevelGrossFloorSurfaceEff';
import { CaseGranulometry } from '../../../cases/CaseGranulometry';
import { isTopLevel } from '../is/isTopLevel';
import { hasCaseGranulometryDrawnFloorSpaceFeature } from '../../../cases/queries/has/hasCaseGranulometryDrawnFloorSpaceFeature';
import { getTopLevelHoppersSurface } from '../topLevels/surfaces/getTopLevelHoppersSurface';
import { getTopLevelRealOutsideWallsSurface } from '../topLevels/walls/realWalls/getTopLevelRealOutsideWallsSurface';

export const getLevelRealBuiltSurface = (
  levelGranulometry:
    | LevelGranulometryInitialEntries
    | TopLevelDataLegacy
    | LevelGranulometryFullFilled
    | LevelGranulometry,
  caseData: CaseFormDataLegacy,
  caseGranulometry?: CaseGranulometry
): number => {
  let levelRealBuiltSurface;
  // 1 : If there is a manualy forced real built surface :
  if (getTopLevelRealBuiltForcedSurface(levelGranulometry, caseData)) {
    return getTopLevelRealBuiltForcedSurface(levelGranulometry, caseData) as number;
  }
  // 2 : If there is a filled gfsEffRelativeTopLevelsRBS (see : addGfsEffRelativeTopLevelsRbs)
  else if (caseData.gfsEffRelativeTopLevelsRBS !== undefined) {
    return (
      caseData.gfsEffRelativeTopLevelsRBS.find((l) => l.level === levelGranulometry.level)
        ?.realBuiltSurface || 0
    );
  }
  // Otherwise :
  else {
    const caseTopLevelCount = getCaseDataProjectedTopLevelCount(caseData);
    const levelsForcedRealBuiltSurfaces = getCaseDataLevelsForcedRealBuiltSurfaces(caseData);
    const unforcedLevelCount = caseTopLevelCount - levelsForcedRealBuiltSurfaces.count;

    const floorSpaceFeatureMustBeTreated =
      caseGranulometry &&
      levelGranulometry &&
      hasCaseGranulometryDrawnFloorSpaceFeature(caseGranulometry) &&
      isTopLevel(caseGranulometry, levelGranulometry as LevelGranulometry);

    // 3 : If there is a filled floor space feature
    if (caseGranulometry && floorSpaceFeatureMustBeTreated) {
      const topLevelGrossFloorSurfaceEff = getTopLevelGrossFloorSurfaceEff(
        caseGranulometry,
        levelGranulometry as LevelGranulometryFullFilled,
        false
      );
      levelRealBuiltSurface =
        topLevelGrossFloorSurfaceEff -
        getTopLevelRealOutsideWallsSurface(
          caseGranulometry,
          levelGranulometry as LevelGranulometryFullFilled
        ) -
        getTopLevelHoppersSurface(
          caseGranulometry,
          levelGranulometry as LevelGranulometryFullFilled,
          true
        );
    }
    // 4 : Otherwise :
    else {
      const caseRealBuiltSurface = getCaseDataRealBuiltSurface(caseData);
      // Calculate the redistribued real built surface relatively to the forced ones
      levelRealBuiltSurface =
        (caseRealBuiltSurface - levelsForcedRealBuiltSurfaces.totalSurface) / unforcedLevelCount;
    }

    // 3 or 4 : ramp surface distribution
    if (
      caseData.builtInRamp && // If a ramp is built in the case
      caseData.basementLevelCount !== 0 && // their is at least one basement level
      !getCaseDataGroundLevelForcedSurface(caseData) // and the ground level real built surface is not forced :
    ) {
      // Get the ramp surface
      const rampLength = getDefaultUnitConvertedPropertyValue(caseData, 'rampLength') as number;
      const rampWidth = getDefaultUnitConvertedPropertyValue(caseData, 'rampWidth') as number;
      const rampSurface = rampLength * rampWidth;
      if (isGroundLevel(levelGranulometry)) {
        levelRealBuiltSurface -= rampSurface;
        if (!floorSpaceFeatureMustBeTreated) {
          levelRealBuiltSurface += rampSurface / unforcedLevelCount;
        }
      } else {
        // For any top level but the ground level :
        if (!floorSpaceFeatureMustBeTreated) {
          levelRealBuiltSurface += rampSurface / unforcedLevelCount;
        }
      }
    }

    return Math.max(levelRealBuiltSurface, 0);
  }
};
