import * as R from 'ramda';
import { Project } from '../../project/Project';
import { mapCases } from '../../project/map/mapCases';
import { CaseSpecification } from '../../specification/cases/CaseSpecification';
import {
  CarpentryOccultationsTypeName,
  DEFAULT_CARPENTRY_OCCULTATIONS_TYPE
} from '../../specification/cases/CarpentryOccultationsType';
import { CarpentryOccultationsMotorisationTypeName } from '../../specification/cases/CarpentryOccultationsMotorisationType';

export const migrateProject21To22 = (project: Project): Project =>
  mapCases((eachCase) =>
    R.pipe<
      [CaseSpecification],
      CaseSpecification,
      CaseSpecification,
      CaseSpecification,
      CaseSpecification,
      CaseSpecification
    >(
      R.assoc(
        'carpentryDayTimeOccultationsType',
        eachCase.carpentryDayTimeOccultationsType || DEFAULT_CARPENTRY_OCCULTATIONS_TYPE
      ),
      R.assoc(
        'carpentryDayTimeOccultationsTypeIsMonobloc',
        eachCase.carpentryDayTimeOccultationsTypeIsMonobloc !== false
      ),
      R.assoc(
        'carpentryNightTimeOccultationsType',
        eachCase.carpentryNightTimeOccultationsType || DEFAULT_CARPENTRY_OCCULTATIONS_TYPE
      ),
      R.assoc(
        'carpentryNightTimeOccultationsTypeIsMonobloc',
        eachCase.carpentryNightTimeOccultationsTypeIsMonobloc !== false
      ),
      R.assoc(
        'carpentryOccultationsMotorisationType',
        eachCase.carpentryOccultationsMotorisationType ||
          CarpentryOccultationsMotorisationTypeName.None
      )
    )(eachCase)
  )(project);
