import { uniqId } from '../../infra/uniqId';
import { Surface } from '../specification/Surface';
import { DEFAULT_PROJECT_SURFACE } from '../../constants/appConstants';
import { createBuildingSpecification } from '../specification/buildings/queries/createBuildingSpecification';
import { CURRENT_MIGRATION_VERSION, UnsavedProject } from './Project';

export const createProject = (): UnsavedProject => ({
  name: '',
  surface: undefined,
  projectedSurface: new Surface(DEFAULT_PROJECT_SURFACE),
  buildings: [createBuildingSpecification()],
  street: undefined,
  postalCode: undefined,
  city: undefined,
  country: undefined,
  longitude: undefined,
  latitude: undefined,
  revision: uniqId(),
  migrationVersion: CURRENT_MIGRATION_VERSION,
  reportRevision: '',
  customPriceListId: 'none'
});
