import {
  DEFAULT_CAR_BOX_COUNT_PER_LODGMENT,
  DEFAULT_CAR_PARK_COUNT_PER_SHAB,
  DEFAULT_LODGMENT_COUNT_PER_CAR_BOX,
  DEFAULT_SHAB_PER_CAR_PARK
} from '../../../constants/appConstants';

export interface ParkingsSpecification {
  carBoxCountPerLodgment?: number;
  carParkCountPerShab?: number;
  lodgmentCountPerCarBox?: number;
  shabPerCarPark?: number;
}

export const defaultParkingSpecification = {
  carBoxCountPerLodgment: undefined,
  carParkCountPerShab: undefined,
  lodgmentCountPerCarBox: undefined,
  shabPerCarPark: undefined
};

export const getTotalCarBoxes = (
  { carBoxCountPerLodgment, lodgmentCountPerCarBox }: ParkingsSpecification,
  lodgmentsCount: number
): number =>
  carBoxCountPerLodgment !== 0 && lodgmentCountPerCarBox !== 0
    ? Math.round(
        lodgmentsCount *
          ((carBoxCountPerLodgment || DEFAULT_CAR_BOX_COUNT_PER_LODGMENT) /
            (lodgmentCountPerCarBox || DEFAULT_LODGMENT_COUNT_PER_CAR_BOX))
      )
    : 0;

export const getTotalCarParks = (
  { carParkCountPerShab, shabPerCarPark }: ParkingsSpecification,
  caseSurfaceForSale: number
) =>
  carParkCountPerShab !== 0 && shabPerCarPark !== 0
    ? Math.round(
        caseSurfaceForSale *
          ((carParkCountPerShab || DEFAULT_CAR_PARK_COUNT_PER_SHAB) /
            (shabPerCarPark || DEFAULT_SHAB_PER_CAR_PARK))
      )
    : 0;
