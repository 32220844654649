import { CaseFormDataLegacy } from '../../../../../legacy/caseFormData';
import { getCaseDataProperty } from './getCaseDataProperty';
import { CaseSpecification } from '../../../CaseSpecification';

export const getCaseDataPropertyValue = (
  caseData: Partial<CaseFormDataLegacy> | CaseSpecification,
  propertyName: string
): number | string | undefined => {
  const caseProperty = getCaseDataProperty(caseData, propertyName);
  if (!caseProperty) return undefined;
  const { defaultValue, value } = caseProperty;
  return value ?? defaultValue;
};
