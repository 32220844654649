import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonGroup } from 'components/ui/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonProps } from '../../ui/Button';
import { Note } from '../../ui/Note';
import { SpecialLabel } from '../../ui/SpecialLabel';
import { Card } from '../../ui/Card';
import { selectCurrentCaseHasCustomDistribution } from '../../../store/selectors/specification/currentCase/hasCustomDistribution.selector';
import { selectCurrentCaseEditingStage } from '../../../store/selectors/specification/currentCase/editingStage.selector';
import {
  CaseEditingStage,
  CaseEditingStageName
} from '../../../domain/specification/cases/CaseSpecification';
import { selectCurrentCaseIdFromRoute } from '../../../store/selectors/navigation/caseIdFromRoute.selector';
import { caseEditingStageChanged } from '../../../store/actions/caseEditingStageChanged.action';

const EditingStage = () => {
  const { t } = useTranslation();
  const hasCustomDistribution = useSelector(selectCurrentCaseHasCustomDistribution);
  const editingStage = useSelector(selectCurrentCaseEditingStage);
  const caseId = useSelector(selectCurrentCaseIdFromRoute);
  const dispatch = useDispatch();

  const [openInfo, setOpenInfo] = React.useState<boolean>(false);

  const handleSetEditingStage = React.useCallback(
    (editingStageValue: CaseEditingStage) => {
      if (caseId) {
        dispatch(caseEditingStageChanged(caseId, editingStageValue));
      }
    },
    [dispatch, caseId]
  );

  const dataEntryStageButtons: ButtonProps[] = [
    {
      content: t('Granulometry'),
      handleClick: () => handleSetEditingStage(CaseEditingStageName.Granulometry),
      style: {
        fontSize: '11px',
        flexBasis: '37%',
        color: 'white',
        borderColor: 'rgb(var(--deep-blue))',
        backgroundColor:
          editingStage === CaseEditingStageName.Granulometry
            ? 'rgb(var(--blue))'
            : 'rgba(var(--deep-blue), 0.5)'

        // filter: editingStage !== CaseEditingStageName.Granulometry ? 'grayscale(1)' : null
      },
      appearance: hasCustomDistribution ? 'outline' : 'plain'
    },
    {
      content: t('Distribution'),
      handleClick: () => handleSetEditingStage(CaseEditingStageName.Distribution),
      style: {
        fontSize: '11px',
        flexBasis: '33%',
        color: hasCustomDistribution ? 'white' : undefined,
        borderColor: 'rgb(var(--deep-blue))',
        backgroundColor:
          editingStage === CaseEditingStageName.Distribution
            ? 'rgb(var(--blue))'
            : hasCustomDistribution
            ? 'rgba(var(--deep-blue), 0.5)'
            : undefined
        // filter: editingStage !== CaseEditingStageName.Distribution ? 'grayscale(1)' : null
      },
      appearance: !hasCustomDistribution ? 'outline' : 'plain'
    },
    {
      content: t('Completion'),
      // handleClick: () => handleSetEditingStage(CaseEditingStageName.Completion),
      style: {
        color: 'rgba(var(--deep-blue), 0.5)',
        fontSize: '11px',
        flexBasis: '30%',
        pointerEvents: 'none',
        filter: 'grayscale(1)'
      }
    }
  ];

  return (
    <Card style={{ position: 'relative' }}>
      <div
        className={'infoBtn' + (openInfo ? ' open' : '')}
        style={{ top: '8px', right: '8px' }}
        onClick={() => setOpenInfo(!openInfo)}>
        i
      </div>
      <SpecialLabel label={t('Data entry stage')} />
      <ButtonGroup
        buttons={dataEntryStageButtons}
        fullWidth={true}
        style={{ marginBottom: '1px', marginRight: '-2px' }}
        // initialSelectedKey={!hasCustomDistribution ? 0 : 1}
      />
      {openInfo && (
        <Note className="info">
          <p>
            <b>
              Étape <u>Granulométrie</u> (équivalent faisabilité) :
            </b>{' '}
            Distribution automatisée, calculée sur la base des pourcentages par type de logements et
            de la SHab disponible. Les modifications sur des types de logements sont opérés à
            l’échelle de la cage. À noter – un pourcentage faible de type de logement peut ne pas
            générer d’occurrence.
          </p>
          <p>
            <b>
              Étape <u>Distribution</u> (équivalent faisabilité+ ou permis de construire) :
            </b>{' '}
            Distribution semi-automatisée, calculée sur le base du stade précédent et du nombre de
            logements saisie, venant déroger à la distribution automatisée, pour un étage et un type
            de logement donné. À venir – Les modifications sur des types de logements seront opérés
            à l’échelle du niveau.
          </p>
          <p>
            <b>
              Étape <u>Complétion</u> (équivalent APD) :
            </b>{' '}
            À vénir – Distribution manuelle, calculée sur la base du stade précédent. Les
            modifications sur des types de logements seront opérés à l’échelle du logement.
          </p>
        </Note>
      )}
    </Card>
  );
};

export default EditingStage;
