import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from '../../../../ui/SpecialLabel';
import { FormGroup } from '../../../../ui/FormGroup';
import { Input } from '../../../../ui/input/Input';
import { IconObject } from '../../../../ui/Icons/iconObject';
import {
  DEFAULT_CAR_BOX_COUNT_PER_LODGMENT,
  DEFAULT_LODGMENT_COUNT_PER_CAR_BOX,
  MAXIMUM_CAR_BOX_COUNT_PER_LODGMENT,
  MINIMUM_LODGMENT_COUNT_PER_CAR_BOX,
  OTHERS_COLOR
} from '../../../../../constants/appConstants';
import { selectCarBoxCountPerLodgment } from '../../../../../store/selectors/specification/parkings/carBoxCountPerLodgment.selector';
import { selectLodgmentCountPerCarBox } from '../../../../../store/selectors/specification/parkings/lodgmentCountPerCarBox.selector';
import { selectCurrentCaseIdFromRoute } from '../../../../../store/selectors/navigation/caseIdFromRoute.selector';
import { selectCarBoxesCount } from '../../../../../store/selectors/specification/parkings/carBoxesCount.selector';
import { caseParkingSpecificationChanged } from '../../../../../store/actions/setCaseParkingsSpecification.action';
import { roundWith2Decimal } from '../../../../../utils/round/roundWith2Decimal';
import { secureInputChange } from '../../../../../utils/secureInputChange';

export const CarBoxFormGroup = () => {
  const dispatch = useDispatch();
  const caseId = useSelector(selectCurrentCaseIdFromRoute);

  // Car box count per lodgment
  const carBoxCountPerLodgment = useSelector(selectCarBoxCountPerLodgment);
  const [localCarBoxCountPerLodgment, setLocalCarBoxCountPerLodgment] =
    React.useState(carBoxCountPerLodgment);
  const getCarBoxCountPerLodgmentValidity = (carBoxCountPerLodgment: number | undefined) =>
    carBoxCountPerLodgment === undefined ||
    (typeof carBoxCountPerLodgment === 'number' && carBoxCountPerLodgment >= 0);
  const dispatchCarBoxCountPerLodgmentChanged = React.useCallback(
    (carBoxCountPerLodgment: number | undefined) => {
      setLocalCarBoxCountPerLodgment(carBoxCountPerLodgment);
      if (caseId && getCarBoxCountPerLodgmentValidity(carBoxCountPerLodgment)) {
        dispatch(caseParkingSpecificationChanged(caseId, { carBoxCountPerLodgment }));
      }
    },
    [dispatch, caseId]
  );

  // Lodgment count per car box
  const lodgmentCountPerCarBox = useSelector(selectLodgmentCountPerCarBox);
  const [localLodgmentCountPerCarBox, setLocalLodgmentCountPerCarBox] =
    React.useState(lodgmentCountPerCarBox);
  const getLodgmentCountPerCarBoxValidity = (lodgmentCountPerCarBox: number | undefined) =>
    lodgmentCountPerCarBox === undefined ||
    (typeof lodgmentCountPerCarBox === 'number' && lodgmentCountPerCarBox >= 0);
  const dispatchLodgmentCountPerCarBoxChanged = React.useCallback(
    (lodgmentCountPerCarBox: number | undefined) => {
      setLocalLodgmentCountPerCarBox(lodgmentCountPerCarBox);
      if (caseId && getLodgmentCountPerCarBoxValidity(lodgmentCountPerCarBox)) {
        dispatch(caseParkingSpecificationChanged(caseId, { lodgmentCountPerCarBox }));
      }
    },
    [dispatch, caseId]
  );

  // Car park count
  const carBoxesCount = useSelector(selectCarBoxesCount);

  const { t } = useTranslation();

  return (
    <div className="car-box-distribution">
      <SpecialLabel label={t('Residents parking spots')} small={true} />
      <FormGroup horizontal={true}>
        <div style={{ height: '32px', width: '65px', marginRight: '5px' }}>
          <Input
            name="carBoxCountPerLodgment"
            type="number"
            color={
              getCarBoxCountPerLodgmentValidity(
                carBoxCountPerLodgment === undefined ? DEFAULT_CAR_BOX_COUNT_PER_LODGMENT : 0
              )
                ? undefined
                : 'orange'
            }
            step={1}
            value={
              localCarBoxCountPerLodgment
                ? roundWith2Decimal(localCarBoxCountPerLodgment)
                : undefined
            }
            style={{ margin: '0' }}
            handleChange={(value) =>
              dispatchCarBoxCountPerLodgmentChanged(
                value === null
                  ? undefined
                  : secureInputChange(value, undefined, MAXIMUM_CAR_BOX_COUNT_PER_LODGMENT)
              )
            }
            placeholder={DEFAULT_CAR_BOX_COUNT_PER_LODGMENT}
          />
        </div>
        <div style={{ height: '32px', width: '32px', marginRight: '5px' }}>
          <IconObject
            color={OTHERS_COLOR}
            iconName="carBox"
            title="Car box"
            type="object"
            clickable={false}
          />
        </div>
        <div
          style={{
            height: '32px',
            display: 'flex',
            alignItems: 'center',
            fontSize: '12px',
            marginRight: '5px'
          }}>
          {t('for')}
        </div>
        <div
          style={{
            height: '32px',
            marginRight: '5px'
          }}>
          <Input
            name="lodgmentCountPerCarBox"
            type="number"
            color={
              getLodgmentCountPerCarBoxValidity(
                lodgmentCountPerCarBox === undefined ? DEFAULT_LODGMENT_COUNT_PER_CAR_BOX : 0
              )
                ? undefined
                : 'orange'
            }
            step={1}
            value={localLodgmentCountPerCarBox}
            suffix={t('lodgment(s)')}
            style={{ margin: '0' }}
            handleChange={(value) =>
              dispatchLodgmentCountPerCarBoxChanged(
                value === null
                  ? undefined
                  : secureInputChange(value, MINIMUM_LODGMENT_COUNT_PER_CAR_BOX)
              )
            }
            placeholder={DEFAULT_LODGMENT_COUNT_PER_CAR_BOX}
          />
        </div>
        <div
          style={{
            height: '32px',
            display: 'flex',
            alignItems: 'center',
            fontSize: '12px',
            marginRight: '5px'
          }}>
          =
        </div>
        <div
          style={{
            height: '32px',
            width: '45px'
          }}>
          <Input
            name="totalCarBoxes"
            type="number"
            step={1}
            value={carBoxesCount}
            style={{ margin: '0' }}
            inputStyle={{ textAlign: 'center' }}
            disabled={true}
          />
        </div>
      </FormGroup>
    </div>
  );
};
