import * as R from 'ramda';
import { CaseGranulometry } from '../../CaseGranulometry';
import { LevelGranulometry } from '../../../levels/LevelGranulometry';
import { getCaseLevels } from './getCaseLevels';
import { isUpperLevel } from '../../../levels/queries/is/isUpperLevel';

export const getCaseUpperLevels = (caseGranulometry: CaseGranulometry): LevelGranulometry[] =>
  R.pipe<[CaseGranulometry], LevelGranulometry[], LevelGranulometry[]>(
    getCaseLevels,
    R.filter<LevelGranulometry>((levelGranulometry) =>
      isUpperLevel(caseGranulometry, levelGranulometry)
    )
  )(caseGranulometry);
