import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { LevelGranulometry, LevelGranulometryFullFilled } from '../../../LevelGranulometry';
import { roundWith2Decimal } from '../../../../../../utils/round/roundWith2Decimal';
import { getTopLevelDefaultLength } from './getTopLevelDefaultLength';
import { getLevelRealBuiltSurfaceEff } from '../../surfaces/getLevelRealBuiltSurfaceEff';
import { getTopLevelDefaultWidth } from './getTopLevelDefaultWidth';

export const getTopLevelDefaultLengthDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled | LevelGranulometry
): string => {
  let details: string[] = [];
  details = [
    ...details,
    '<b>' +
      roundWith2Decimal(getLevelRealBuiltSurfaceEff(caseGranulometry, levelGranulometry)) +
      ' m\u00B2</b> de Sdp Réelle VPP'
  ];
  details = [
    ...details,
    '<b>/ ' +
      getTopLevelDefaultWidth(caseGranulometry, levelGranulometry) +
      ' m</b> de largeur par défaut'
  ];
  details = [
    ...details,
    '<b>= ' +
      roundWith2Decimal(getTopLevelDefaultLength(caseGranulometry, levelGranulometry)) +
      ' m</b>'
  ];
  return details.join('<br />');
};
