import { getDefaultUnitConvertedPropertyValue } from '../../../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getTopLevelOutsideStructuralWallsThickness = (
  caseGranulometry: CaseGranulometry
): number =>
  getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'outsideWallsThickness'
  ) as number;
