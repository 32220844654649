import * as R from 'ramda';
import { createBuildingGranulometry } from '../../granulometry/buildings/queries/createBuildingGranulometry';
import { createCaseGranulometry } from '../../granulometry/cases/queries/createCaseGranulometry';
import { fillCaseLevels } from './fillCaseLevels';
import { resizeSmallSections } from './resizeSmallSections';
import { CaseFormDataLegacy } from '../caseFormData';
import { BuildingGranulometry } from '../../granulometry/buildings/BuildingGranulometry';
import { getCaseFirstBasementLevel } from '../../granulometry/cases/queries/levels/getCaseFirstBasementLevel';
import { getBasementLevelOutsideWallsLinear2 } from '../../granulometry/levels/queries/basementLevels/getBasementLevelOutsideWallsLinear2';
import { updateCaseProperty } from '../../granulometry/cases/queries/properties/updateCaseProperty';
import { getCaseLowestBasementLevel } from '../../granulometry/cases/queries/levels/getCaseLowestBasementLevel';
import { redistributeCaseWithCustomDistribution } from '../../granulometry/queries/customDistribution/redistributeCaseWithCustomDistribution';
import { CaseEditingStageName } from '../../specification/cases/CaseSpecification';
import { getCaseBasementLevelsCount } from '../../granulometry/cases/queries/levels/counts/getCaseBasementLevelsCount';
import { distributeTechnicalSheathsInSectionsWithTS } from '../../granulometry/cases/queries/technicalSheaths/distributeTechnicalSheathsInSectionsWithTS';
import { hasCaseGranulometryDrawnFloorSpaceFeature } from '../../granulometry/cases/queries/has/hasCaseGranulometryDrawnFloorSpaceFeature';
import { refillCaseGranulometryRelativelyToFloorSpaceFeature } from './renderBuilding/refillCaseGranulometryRelativelyToFloorSpaceFeature';
import { createCaseRoofings } from '../../granulometry/cases/queries/createCaseRoofings';
import { BuildingProjection } from '../../projection/buildings/BuildingProjection';
import { mustHaveOutsideStairShaftSections } from '../../granulometry/levels/queries/mustHave/mustHaveOutsideStairShaftSections';
import { addAndFillOutsideLevels } from '../../granulometry/queries/addAndFillOutsideLevels';
import { RESIZE_SMALL_SECTION } from '../../../constants/appConstants';

export const renderBuilding = (
  buildingProjection: BuildingProjection,
  buildingIndex: number,
  casesFormData: CaseFormDataLegacy[]
): BuildingGranulometry => {
  // ## Part 1
  // Set a new building
  const building: BuildingGranulometry = createBuildingGranulometry(
    buildingProjection,
    buildingIndex
  );

  // For each case in form data
  for (let caseIndex = 0; caseIndex < casesFormData.length; caseIndex++) {
    // Set a new case in building
    let caseGranulometry = createCaseGranulometry(
      buildingIndex,
      casesFormData[caseIndex],
      caseIndex,
      casesFormData.length
    );

    // ## Part 2, 3, 4 : Fill the case object with adjusted levels data
    caseGranulometry = fillCaseLevels(caseGranulometry);

    // ## Part 5 : If a floor space feature is drawn for this case :
    const caseProjection = buildingProjection.cases[caseIndex];
    if (hasCaseGranulometryDrawnFloorSpaceFeature(caseGranulometry)) {
      caseGranulometry = refillCaseGranulometryRelativelyToFloorSpaceFeature(
        caseProjection,
        caseGranulometry
      );
    }

    // ## Part 6 : Manage the roofing sections
    caseGranulometry = createCaseRoofings(caseGranulometry);

    // ## Part 7 : Manage basement reinforcement
    if (getCaseBasementLevelsCount(caseGranulometry) >= 1) {
      caseGranulometry = updateCaseProperty('firstBasementReinforcementLength', {
        max: Math.floor(
          getBasementLevelOutsideWallsLinear2(getCaseFirstBasementLevel(caseGranulometry))
        )
      })(caseGranulometry);
    }
    if (getCaseBasementLevelsCount(caseGranulometry) === 2) {
      caseGranulometry = updateCaseProperty('secondBasementReinforcementLength', {
        max: Math.floor(
          getBasementLevelOutsideWallsLinear2(getCaseLowestBasementLevel(caseGranulometry))
        )
      })(caseGranulometry);
    }

    // ## Part 8 (Distribution step only) : Manage custom lodgment types distribution
    if (
      process.env.GB_FEAT_CUSTOM_DISTRIBUTION !== 'false' &&
      caseGranulometry.initialSpecifications.editingStage === CaseEditingStageName.Distribution &&
      !R.isEmpty(caseGranulometry.initialSpecifications.customDistribution)
    ) {
      caseGranulometry = redistributeCaseWithCustomDistribution(caseGranulometry);
    }

    // ## Part 9 : Add and fill outside levels
    if (mustHaveOutsideStairShaftSections(caseGranulometry)) {
      caseGranulometry = addAndFillOutsideLevels(caseGranulometry);
    }
    // ## Part 9 : Resize small sections
    if (RESIZE_SMALL_SECTION) {
      caseGranulometry = resizeSmallSections(caseGranulometry);
    }

    // ## Part 10 : Distribute technical ducts in lodgments
    caseGranulometry = distributeTechnicalSheathsInSectionsWithTS(caseGranulometry);

    // ## Part 11 : Add current case in the building
    building.cases = [...building.cases, caseGranulometry];
  }

  return building;
};
