import { getCaseDataRealBuiltSurface } from './getCaseDataRealBuiltSurface';
import { CaseFormDataLegacy } from '../caseFormData';
import { getSectionDisplayedSurface } from '../../granulometry/sections/queries/surfaces/getSectionDisplayedSurface';
import { DEFAULT_RBS_TO_SFS_RATIO_DEPRECATED } from '../../../constants/appConstants';

export const getCaseDataTheoricalRealBuiltSurfaceFromRBS = (caseData: CaseFormDataLegacy) => {
  let theoricalRealBuildSurface = getCaseDataRealBuiltSurface(caseData);

  const technicalPermiseSectionsSurface = caseData.topLevelsData.reduce(
    (accL, levelData) =>
      accL +
      (levelData.technicalPremiseSections || []).reduce(
        (accTp, technicalPremiseSection) =>
          accTp + getSectionDisplayedSurface(technicalPremiseSection),
        0
      ),
    0
  );
  theoricalRealBuildSurface -=
    technicalPermiseSectionsSurface / DEFAULT_RBS_TO_SFS_RATIO_DEPRECATED;

  return theoricalRealBuildSurface;
};
