import { filter, map, pairwise, withLatestFrom } from 'rxjs/operators';
import { Action } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { State } from '../reducers';
import { goToCase } from '../actions/navigations.actions';
import { selectProjectId } from '../selectors/project';
import { selectAllCasesProjections } from '../selectors/projection/allCasesProjections.selector';
import { CASE_REMOVED, CaseRemovedAction } from '../actions/caseRemoved.action';
import { CASE_ADDED } from '../actions/caseAdded.action';
import { BUILDING_ADDED } from '../actions/buildingAdded.action';
import { BUILDING_REMOVED } from '../actions/buildingRemoved.action';
import { CaseSpecification } from '../../domain/specification/cases/CaseSpecification';

type ActionWithState = [Action, State];

type Pair = [ActionWithState, ActionWithState];

export const moveToPreviousCaseAfterDeletionEpic: Epic<Action, Action, State> = (
  actions$,
  state$
) =>
  actions$.pipe(
    ofType(CASE_ADDED, CASE_REMOVED, BUILDING_ADDED, BUILDING_REMOVED),
    withLatestFrom(state$),
    pairwise(),
    filter(([, [action]]: Pair) => action.type === CASE_REMOVED),
    map(([[, stateBeforeRemoval], [action, state]]: Pair): Action => {
      const projectId = selectProjectId(state) as string;
      const casesBeforeRemoval = selectAllCasesProjections(
        stateBeforeRemoval
      ) as CaseSpecification[];

      const indexOfRemovedCase = casesBeforeRemoval?.findIndex(
        (b) => b.id === (action as CaseRemovedAction).payload.caseId
      ) as number;

      const caseIndexToGoTo = indexOfRemovedCase === 0 ? 1 : indexOfRemovedCase - 1;
      const caseIdToGoTo = casesBeforeRemoval[caseIndexToGoTo].id;
      return goToCase(projectId, caseIdToGoTo);
    })
  );
