import { LevelGranulometry } from '../../levels/LevelGranulometry';
import { CaseGranulometry } from '../../cases/CaseGranulometry';
import { getBasementLevelOutsideWallsLinear } from '../../levels/queries/basementLevels/getBasementLevelOutsideWallsLinear';
import { isSectionACarBoxInfra } from '../../sections/parkingSections/carBoxInfra';
import { isSectionACarParkInfra } from '../../sections/parkingSections/carParkInfra';
import { getLevelFullFilledContent } from '../../levels/queries/content/getLevelFullFilledContent';

export const updateBasementLevelOutsideWallsLinear = (
  levelGranulometry: LevelGranulometry,
  caseGranulometry: CaseGranulometry
): LevelGranulometry => ({
  ...levelGranulometry,
  outsideWallsLinear: getBasementLevelOutsideWallsLinear(
    caseGranulometry.initialSpecifications,
    getLevelFullFilledContent(levelGranulometry).filter(isSectionACarBoxInfra).length,
    getLevelFullFilledContent(levelGranulometry).filter(isSectionACarParkInfra).length,
    caseGranulometry.initialSpecifications.basementLevelCount
  )
});
