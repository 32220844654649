import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Fullscreen from 'react-full-screen';
import Modal from 'react-modal';
import classNames from 'classnames';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import * as Sentry from '@sentry/react';
import { BuildingMapViewPort } from './buildingMap/BuildingMapViewPort';
import NotificationBar from '../../components/layouts/notificationBar/NotificationBar';
import ActionBar from '../../components/layouts/actionBar/ActionBar';
import { StartUpWizardIntro } from '../../components/layouts/startUpWizard/StartUpWizardIntro';
import { moveOn } from '../../utils/buildingMap/moveOn';
import { getAdjustedZoomRate } from '../../utils/buildingMap/getAdjustedZoomRate';
import { selectIsInitializeProject } from '../../store/selectors/project/isInitializeProject.selector';
import { selectGranulometryObjectCount } from '../../store/selectors/granulometry/objectCount.selector';
import { selectUserLicenceType } from '../../store/selectors/user/userLicenceType.selector';
import {
  isFocusBuilding,
  isFocusCase,
  isFocusLevel,
  isFocusProject,
  isFocusSection
} from '../../domain/buildingMap/BuildingMapFocus';
import { DataBar } from '../../components/layouts/dataBar/DataBar';
import { selectBuildingMapFocus } from '../../store/selectors/buildingMap/buildingMapFocus.selector';
import { selectIsAppReady } from '../../store/selectors/isAppReady.selector';
import { selectIsGranulometryRendering } from '../../store/selectors/granulometry/isGranulometryRendering.selector';
import { MapPositionContextProvider } from '../../contexts/MapPositionContext';
import MainBarView from '../../components/layouts/mainBar/MainBarView';
import { NavBarView } from '../../components/layouts/navBar/NavBarView';
import { ToolBox } from '../../components/layouts/toolbox/ToolBox';
import { selectIsAnyWindowsOpened } from '../../store/selectors/navigation/windows/isAnyWindowsOpened.selector';
import { selectHasBeenCalculatedOnce } from '../../store/selectors/project/hasBeenCalculatedOnce.selector';
import { DefaultFallback } from '../../DefaultFallback';

interface LoadingState {
  text: string;
  time: number;
}

export const ProjectScreen: React.FC = () => {
  const currentFocus = useSelector(selectBuildingMapFocus);
  const isAppReady = useSelector(selectIsAppReady);
  const isGranulometryRendering = useSelector(selectIsGranulometryRendering);
  const isInitializeProject = useSelector(selectIsInitializeProject);
  const calculatedOnce = useSelector(selectHasBeenCalculatedOnce);
  const granulometryObjectCount = useSelector(selectGranulometryObjectCount);
  const licenseType = useSelector(selectUserLicenceType);
  const isAnyWindowsOpened = useSelector(selectIsAnyWindowsOpened);

  const [isFull, setIsFull] = React.useState(false);
  const [zoom, setZoom] = React.useState(0.6);
  const [element, setElement] = React.useState<string | undefined>(undefined);
  const [positionX, setPositionX] = React.useState(0);
  const [positionY, setPositionY] = React.useState(0);
  const [counter, setCounter] = React.useState(0);

  const dispatch = useDispatch();

  const secPerObj =
    granulometryObjectCount < 140 ? 0.3 : 0.3 + (granulometryObjectCount - 140) * (0.0014 * 1.66);
  const loadingTimeEstimated = granulometryObjectCount * secPerObj * 1000;
  const loadingStates: LoadingState[] = [
    { text: 'Envoi des données', time: 0 },
    { text: 'Enregistrement du projet', time: loadingTimeEstimated - loadingTimeEstimated * 0.9 },
    {
      text:
        'Calcul des quantités' +
        (licenseType === 'Advanced' ? ', prix' : '') +
        ', poids carbone, cubature bois',
      time: loadingTimeEstimated - loadingTimeEstimated * 0.8
    },
    { text: 'Génération du rapport', time: loadingTimeEstimated - loadingTimeEstimated * 0.4 },
    { text: 'Réception des données', time: loadingTimeEstimated - loadingTimeEstimated * 0.1 }
  ];
  const [currentLoadingState, setCurrentLoadingState] = React.useState<string>(
    loadingStates[0].text
  );

  React.useEffect(() => {
    if (granulometryObjectCount) {
      // TODO : ratio relative to number of section in granulometry
      const timeouts = loadingStates.map((state, index) =>
        setTimeout(
          () =>
            setCurrentLoadingState(
              state.text + '... [' + (index + 1) + '/' + loadingStates.length + ']'
            ),
          state.time
        )
      );
      return () => {
        timeouts.map((timeout) => clearTimeout(timeout));
        setCurrentLoadingState(loadingStates[0].text + '... [1/' + loadingStates.length + ']');
      };
    }
  }, [isAppReady]);

  React.useEffect(() => {
    Modal.setAppElement('#rootInner');
  }, []);

  const focusOn = React.useCallback(
    (element: string) => {
      const newZoom = getAdjustedZoomRate(element);
      setZoom(newZoom);
      setElement(element);
    },
    [setZoom, setElement]
  );

  React.useLayoutEffect(() => {
    const resizes$ = new BehaviorSubject<never | null>(null);
    const observer = new ResizeObserver(() => {
      resizes$.next(null);
    });
    const wrapper = document.querySelector('.buildingMapWrapper');
    if (wrapper) {
      observer.observe(wrapper);
      resizes$.pipe(debounceTime(500)).subscribe(() => {
        setCounter((c) => c + 1);
      });
      return () => {
        observer.unobserve(wrapper);
      };
    }
  }, [setCounter]);

  const refreshFocus = React.useCallback(() => {
    if (isFocusProject(currentFocus)) {
      focusOn('.buildings');
    } else if (isFocusBuilding(currentFocus)) {
      focusOn(`#building${currentFocus.building}`);
    } else if (isFocusCase(currentFocus)) {
      focusOn(`#case${currentFocus.case}`);
    } else if (isFocusLevel(currentFocus)) {
      focusOn(`#level${currentFocus.level}`);
    } else if (isFocusSection(currentFocus)) {
      focusOn(`#section${currentFocus.section}`);
    }
  }, [currentFocus, focusOn]);

  React.useLayoutEffect(refreshFocus, [refreshFocus]);

  const reMoveOn = () => {
    if (element) {
      const nextPosition = moveOn(element, zoom, calculatedOnce, dispatch);
      if (
        nextPosition &&
        (nextPosition.translateX !== positionX || nextPosition.translateY !== positionY)
      ) {
        setPositionX(nextPosition.translateX);
        setPositionY(nextPosition.translateY);
      }
    }
  };

  React.useLayoutEffect(reMoveOn, [element, zoom]);

  const toggleFullscreen = React.useCallback(() => {
    setIsFull(!isFull);
  }, [setIsFull, isFull]);

  const zoomAdd = React.useCallback(
    (value: number) => {
      setZoom(zoom + value);
    },
    [zoom, setZoom]
  );

  const zoomRemove = React.useCallback(
    (value: number) => {
      const newZoomValue = zoom - value;
      if (newZoomValue >= 0.1) {
        setZoom(newZoomValue);
      }
    },
    [zoom, setZoom]
  );

  const emptyPosition = React.useCallback(() => {
    setPositionX(0);
    setPositionY(0);
  }, [setPositionX, setPositionY]);

  const mapPositonContext = { focusOn };

  const handleFullScreenToggle = React.useCallback(
    (isFull) => {
      setIsFull(isFull);
      setTimeout(refreshFocus, 150);
    },
    [setIsFull, refreshFocus]
  );

  return (
    <Sentry.ErrorBoundary fallback={DefaultFallback} showDialog>
      <>
        <MapPositionContextProvider value={mapPositonContext}>
          <Fullscreen enabled={isFull} onChange={handleFullScreenToggle}>
            <div id="rootInner" className={classNames({ windowsOpened: isAnyWindowsOpened })}>
              <div
                className={classNames({
                  loader: true,
                  'building-map-only': isGranulometryRendering,
                  active: (!isAppReady || isGranulometryRendering) && isInitializeProject
                })}
                data-loading-state={currentLoadingState}
              />
              <MainBarView />
              <NotificationBar />
              <NavBarView
                iconsDisabled={false}
                isFull={isFull}
                toggleFullscreen={toggleFullscreen}
                zoomAdd={zoomAdd}
                zoomRemove={zoomRemove}
                refreshFocus={refreshFocus}
                zoom={zoom}
              />
              <BuildingMapViewPort
                refreshFocus={refreshFocus}
                reMoveOn={reMoveOn}
                opacity={isAppReady ? 1 : 0}
                positionX={positionX}
                positionY={positionY}
                emptyPosition={emptyPosition}
                zoom={zoom}
              />
              <DataBar />
              <ActionBar />
              <ToolBox />
              <StartUpWizardIntro />
            </div>
          </Fullscreen>
        </MapPositionContextProvider>
        <style>{`
            .buildingMap .tooltip { transform: translate(-50%, 12px) scale(${1 / zoom}); }
            .buildingMap .tooltipped:hover .tooltip { transform: translate(-50%, 0) scale(${
              1 / zoom
            }); }
          `}</style>
      </>
    </Sentry.ErrorBoundary>
  );
};
