import { roundWith2Decimal } from '../../../../../../utils/round/roundWith2Decimal';
import { CaseOutsideWallTypeRatesSpecifications } from '../../../../../granulometry/cases/CaseGranulometry';

export const getCaseOutsideWallWoodRateMaxValue = ({
  outsideWallMasonryRate,
  outsideWallConcreteRate
}: CaseOutsideWallTypeRatesSpecifications): number =>
  Math.max(
    0,
    roundWith2Decimal(100 - (outsideWallMasonryRate || 0) - (outsideWallConcreteRate || 0))
  );
