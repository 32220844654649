import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { LevelGranulometry, LevelGranulometryFullFilled } from '../../../../LevelGranulometry';

export const getTopLevelForcedGrossFloorSurfaceEff = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled | LevelGranulometry
): number | undefined =>
  caseGranulometry.initialSpecifications.topLevelsData.find(
    (l) => l.level === levelGranulometry.level
  )?.grossFloorSurfaceEff;
