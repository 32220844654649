import { ExistingProject } from '../project/Project';
import { ComEthReport } from '../../api/cstb/comEth/comEthReport/ComEthReport';
import { COM_ETH_IC_CONSTRUCTION_DEFAULT_TOLERANCE_RATE } from '../project/ProjectCstbIndicatorsParameters';
import { ComEthReportBuilding } from '../../api/cstb/comEth/comEthReport/ComEthReportBuilding';
import { ComEthReportCase } from '../../api/cstb/comEth/comEthReport/ComEthReportCase';

export interface EnvironmentalIndicators {
  icConstruction: number;
  icConstructionToleratedMax: number;
  icConstructionMax: number;
  icEnergy: number;
  icEnergyMax: number;
  bBio: number;
  bBioMax: number;
  cep: number;
  cepMax: number;
  cepNr: number;
  cepNrMax: number;
  dh: number;
  dhMax: number;
}

export const getEnvironmentalIndicators = (
  project: ExistingProject,
  {
    CO2DynamicImpacts: { IcConstruction, IcConstructionMax, IcEnergie, IcEnergieMax },
    Energetic_indicators: {
      Bbio_annuel,
      Bbio_max,
      Cep_annuel,
      Cep_max,
      Cep_nr_annuel,
      Cep_nr_max,
      DH,
      DH_max
    }
  }: ComEthReport | ComEthReportBuilding | ComEthReportCase
): EnvironmentalIndicators => ({
  icConstruction: IcConstruction,
  icConstructionToleratedMax:
    IcConstructionMax *
    (1 +
      (project.comEthIcConstructionToleranceRate ||
        COM_ETH_IC_CONSTRUCTION_DEFAULT_TOLERANCE_RATE)),
  icConstructionMax: IcConstructionMax,
  icEnergy: IcEnergie,
  icEnergyMax: IcEnergieMax,
  bBio: Bbio_annuel,
  bBioMax: Bbio_max,
  cep: Cep_annuel,
  cepMax: Cep_max,
  cepNr: Cep_nr_annuel,
  cepNrMax: Cep_nr_max,
  dh: DH,
  dhMax: DH_max
});
