import { createSelector, Selector } from 'reselect';
import { selectParkingsSpecifications } from './parkingsSpecifications.selector';
import { selectCurrentCaseProjection } from '../../projection/currentCase/projection.selector';
import { getTotalCarParks } from '../../../../domain/specification/lodgmentTypes/ParkingsSpecification';
import { State } from '../../../reducers';
import { Maybe } from '../../../../utils/Maybe';

export const selectCarParksCount: Selector<State, Maybe<number>> = createSelector(
  [selectParkingsSpecifications, selectCurrentCaseProjection],
  (parkingsSpecification, currentCase) =>
    parkingsSpecification && currentCase?.projectedSurfaces?.surfaceForSale
      ? getTotalCarParks(parkingsSpecification, currentCase.projectedSurfaces.surfaceForSale.value)
      : undefined
);
