import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { LevelGranulometry, LevelGranulometryFullFilled } from '../../../../LevelGranulometry';
import { mustHaveFacadeLinearsRelativeToDefaultWidthAndLength } from '../../../mustHave/mustHaveFacadeLinearsRelativeToDefaultWidthAndLength';
import { getTopLevelForcedGrossFloorSurfaceEff } from './getTopLevelForcedGrossFloorSurfaceEff';
import { TopLevelDataLegacy } from '../../../../../../legacy/caseFormData';

export const mustTopLevelGfsEffBeAdjusted = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled | LevelGranulometry
): boolean => {
  if (caseGranulometry.diffBetweenRBSWithoutFloorSpaceAndRBSWithFloorSpace) {
    // to prevent "too much recursion" if levelRBS.realBuiltSurface !== 0
    // undefined if roofing level
    const levelRbs = (
      caseGranulometry.initialSpecifications.gfsEffRelativeTopLevelsRBS as TopLevelDataLegacy[]
    ).find((l) => l.level === levelGranulometry.level) as TopLevelDataLegacy;

    return levelRbs
      ? (levelRbs.realBuiltSurface as number) !== 0 &&
          mustHaveFacadeLinearsRelativeToDefaultWidthAndLength(
            caseGranulometry,
            levelGranulometry as LevelGranulometry
          ) &&
          getTopLevelForcedGrossFloorSurfaceEff(caseGranulometry, levelGranulometry) === undefined
      : false;
  }
  return false;
};
