import { buildingRoute, buildingsRoute } from './toolboxPanels/buildings';
import {
  caseGranulometryEditEnergyAndNetworksRoute,
  caseGranulometryEditFifthFacadeRoute,
  caseGranulometryEditGroundAdaptationsRoute,
  caseGranulometryEditInfrastructureRoute,
  caseGranulometryEditLevelRoute,
  caseGranulometryEditLodgmentTypeRoomRoute,
  caseGranulometryEditLodgmentTypeRoute,
  caseGranulometryEditOtherPropertiesRoute,
  caseGranulometryEditOutdoorsRoute,
  caseGranulometryEditPeripheralReinforcementsRoute,
  caseGranulometryEditPropertyRoute,
  caseGranulometryEditSuperstructureRoute,
  caseGranulometryRoute,
  caseRoute,
  casesRoute
} from './toolboxPanels/cases';
import {
  deliverablesArchivesRoute,
  deliverablesExportRoute,
  deliverablesRoute
} from './toolboxPanels/deliverables';
import { projectDetailsRoute } from './toolboxPanels/projectDetails';
import {
  sectionsEditRoute,
  sectionsIndividualRoute,
  sectionsRoute
} from './toolboxPanels/sections';
import { smartRequestRoute, smartRequestsRoute } from './toolboxPanels/smartRequest';
import { getLevelLabel } from '../domain/granulometry/levels/LevelGranulometry';
import { CaseSpecification } from '../domain/specification/cases/CaseSpecification';
import { LodgmentTypeSpecification } from '../domain/specification/lodgmentTypes/LodgmentTypeSpecification';
import { Property } from '../domain/specification/cases/Property';

export const getBreadcrumb = (
  path: string,
  currentBuildingLabel?: string,
  currentCaseLabel?: string,
  currentCase?: CaseSpecification
): string[] => {
  const segments: string[] = [];
  if (projectDetailsRoute.matchesPath(path).matches) {
    segments.push('Details');
  }

  if (buildingsRoute.matchesPath(path).matches) {
    segments.push('BUILDINGS');

    const buildingMatch = buildingRoute.matchesPath(path);
    if (buildingMatch.matches && currentBuildingLabel) {
      segments.push(currentBuildingLabel);
    }
  }

  if (casesRoute.matchesPath(path).matches) {
    segments.push('CASES');

    const caseMatch = caseRoute.matchesPath(path);
    if (caseMatch.matches && currentCaseLabel) {
      segments.push(currentCaseLabel);

      const granulometryMatch = caseGranulometryRoute.matchesPath(path);
      if (granulometryMatch.matches) {
        segments.push('Granulo');

        const editLodgmentMatch = caseGranulometryEditLodgmentTypeRoute.matchesPath(path);
        if (editLodgmentMatch.matches && editLodgmentMatch.params) {
          segments.push(editLodgmentMatch.params.lodgmentType);

          const lodgmentType = currentCase?.lodgmentsTypesSpecifications.find(
            (lt) => lt.lodgmentType === editLodgmentMatch?.params?.lodgmentType
          ) as LodgmentTypeSpecification;

          const editLodgmentTypeRoomMatch =
            caseGranulometryEditLodgmentTypeRoomRoute.matchesPath(path);
          if (editLodgmentTypeRoomMatch.matches && editLodgmentTypeRoomMatch.params) {
            const { roomIndex } = editLodgmentTypeRoomMatch.params;
            segments.push('Rooms', lodgmentType.rooms[Number(roomIndex)].title);
          }
        }

        const editLevelMatch = caseGranulometryEditLevelRoute.matchesPath(path);
        if (editLevelMatch.matches && editLevelMatch.params) {
          segments.push(getLevelLabel(parseInt(editLevelMatch.params.levelId.toString())));
        }

        if (caseGranulometryEditFifthFacadeRoute.matchesPath(path).matches) {
          segments.push('Fifth facade');
        } else if (caseGranulometryEditSuperstructureRoute.matchesPath(path).matches) {
          segments.push('Superstructure');
        } else if (caseGranulometryEditEnergyAndNetworksRoute.matchesPath(path).matches) {
          segments.push('Energy and networks');
        } else if (caseGranulometryEditInfrastructureRoute.matchesPath(path).matches) {
          segments.push('Infrastructure');
        } else if (caseGranulometryEditGroundAdaptationsRoute.matchesPath(path).matches) {
          segments.push('Ground adaptations');
        } else if (caseGranulometryEditPeripheralReinforcementsRoute.matchesPath(path).matches) {
          segments.push('Peripheral reinforcements');
        } else if (caseGranulometryEditOutdoorsRoute.matchesPath(path).matches) {
          segments.push('Outdoors');
        } else if (caseGranulometryEditOtherPropertiesRoute.matchesPath(path).matches) {
          segments.push('Other properties');
        }

        const editPropertyMatch = caseGranulometryEditPropertyRoute.matchesPath(path);
        if (editPropertyMatch.matches && editPropertyMatch.params) {
          const { propertyName } = editPropertyMatch.params;
          const property = currentCase?.properties.find((p) => p.name === propertyName) as Property;
          segments.push(property.title);
        }
      }
    }
  }

  if (sectionsRoute.matchesPath(path).matches) {
    segments.push('SECTIONS');

    if (sectionsEditRoute.matchesPath(path).matches) {
      segments.push('Manage & insert');
    }
    if (sectionsIndividualRoute.matchesPath(path).matches) {
      segments.push('Individual section');
    }
  }

  if (smartRequestsRoute.matchesPath(path).matches) {
    segments.push('SMART REQUEST');

    const smartRequestMatch = smartRequestRoute.matchesPath(path);
    if (smartRequestMatch.matches && smartRequestMatch.params) {
      segments.push(`Results for « ${smartRequestMatch.params.request} »`);
    }
  }

  if (deliverablesRoute.matchesPath(path).matches) {
    segments.push('DELIVERABLES');

    if (deliverablesExportRoute.matchesPath(path).matches) {
      segments.push('Export data');
    }
    if (deliverablesArchivesRoute.matchesPath(path).matches) {
      segments.push('Export archives');
    }
  }

  return segments;
};
