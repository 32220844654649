import { MaybeLine } from '../../../domain/specification/floorSpace/FacadeSpecification';

const DEFAULT_CASE_DEFAULT_GEOMETRY_WIDTH = 14; // m

export const rectangle = (area: number): MaybeLine[] => {
  const width = DEFAULT_CASE_DEFAULT_GEOMETRY_WIDTH;
  const length = area / 2 / DEFAULT_CASE_DEFAULT_GEOMETRY_WIDTH;

  return [
    { distance: width, angle: -90 },
    { distance: length, angle: 0 },
    { distance: width, angle: 90 },
    { distance: null, angle: null }
  ];
};

/*
const losange = (area: number) => {
  const lg = Math.sqrt(area);

  return [
    { distance: lg, angle: -90 },
    { distance: lg, angle: -45 },
    { distance: lg, angle: 0 },
    { distance: lg, angle: 45 },
    { distance: lg, angle: 90 },
    { distance: lg, angle: 135 },
    { distance: lg, angle: 180 },
    { distance: lg, angle: null }
  ];
};
*/
