import { getCaseDataLodgmentDistribution } from './getCaseDataLodgmentDistribution';
import { getCaseDataLodgmentData } from './getCaseDataLodgmentData';
import { CaseFormDataLegacy } from '../caseFormData';
import { getSectionTheoreticalSurface } from '../../granulometry/sections/queries/surfaces/getSectionTheoreticalSurface';

export const getCaseDataAverageLodgmentSurface = (caseData: CaseFormDataLegacy) => {
  const distribution = getCaseDataLodgmentDistribution(caseData);
  const lodgmentData = getCaseDataLodgmentData(caseData);
  return distribution.reduce(
    (acc, distrib) =>
      acc + getSectionTheoreticalSurface(lodgmentData[distrib.lodgmentType]) * (distrib.rate / 100),
    0
  );
};
