import { CaseGranulometry } from '../../cases/CaseGranulometry';
import { LodgmentTypeSpecification } from '../../../specification/lodgmentTypes/LodgmentTypeSpecification';
import { LodgmentSection } from '../../sections/lodgmentSections/LodgmentSection';
import { setLodgmentTypeSpecificationTheoreticalSurface } from '../../../specification/lodgmentTypes/queries/set/setLodgmentTypeSpecificationTheoreticalSurface';

export const getDefaultLodgmentSectionsFromGranulometry = (
  caseGranulometry: CaseGranulometry
): LodgmentSection[] =>
  [...caseGranulometry.initialSpecifications.distribution].reduce(
    (acc, distributedLodgmentSection) => [
      ...acc,
      setLodgmentTypeSpecificationTheoreticalSurface()(
        distributedLodgmentSection.minimumDisplayedSurface || 0
      )({
        ...distributedLodgmentSection,
        surface: distributedLodgmentSection.minimumDisplayedSurface,
        displayedSurface: distributedLodgmentSection.minimumDisplayedSurface
      } as unknown as LodgmentTypeSpecification) as LodgmentSection
    ],
    [] as LodgmentSection[]
  );
