import { CaseProjection } from '../CaseProjection';
import { getDraftLodgmentsTypesDistributionRateDefaultValue } from '../../../specification/lodgmentTypes/LodgmentTypesDistribution';

export const setCaseProjectedDistribution = (caseProjection: CaseProjection): CaseProjection => {
  const distribution = caseProjection.distribution;

  const defaultRateValue = getDraftLodgmentsTypesDistributionRateDefaultValue(distribution);

  const projectedDistribution = [...distribution].map((lodgmentType) => {
    return {
      ...lodgmentType,
      rate: lodgmentType.rate !== 0 ? lodgmentType.rate : defaultRateValue
    };
  });

  return {
    ...caseProjection,
    projectedDistribution
  };
};
