import classNames from 'classnames';
import * as React from 'react';

interface SpecialLabelProps {
  label: string;
  className?: string;
  small?: boolean;
}

export const SpecialLabel: React.SFC<SpecialLabelProps> = (props: SpecialLabelProps) => {
  const { label, className, small } = props;

  const classes = classNames('gb-special-label', { 'gb-special-label--small': small }, className);

  return <label className={classes}>{label}</label>;
};
