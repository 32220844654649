import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SpecialLabel } from '../../ui/SpecialLabel';
import { Card } from '../../ui/Card';
import { Option, Select } from '../../ui/Select';
import { selectProject } from '../../../store/selectors/project';
import { selectBuildings } from '../../../store/selectors/specification/buildings.selector';
import { selectCurrentCaseIdFromRoute } from '../../../store/selectors/navigation/caseIdFromRoute.selector';
import { getCaseIndexInBuildingFromCaseId } from '../../../domain/core/queries/getCaseIndexInBuildingGivenCaseId';
import { caseMoved } from '../../../store/actions/caseMoved.action';
import { getBuildingIndexGivenBuildingId } from '../../../domain/core/queries/getBuildingIndexGivenBuildingId';
import { getBuildingIdFromCaseId } from '../../../domain/core/queries/getBuildingIdFromCaseId';
import {
  BuildingId,
  BuildingSpecification
} from '../../../domain/specification/buildings/BuildingSpecification';
import { Project } from '../../../domain/project/Project';
import { CaseId } from '../../../domain/specification/cases/CaseSpecification';
import { letterFromIndex } from '../../../utils/letterFromIndex';

const PositioningCaseCard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const project = useSelector(selectProject) as Project;
  const caseId = useSelector(selectCurrentCaseIdFromRoute) as CaseId;
  const caseIndex = getCaseIndexInBuildingFromCaseId(project, caseId);
  const caseBuildingId = getBuildingIdFromCaseId(project, caseId) as BuildingId;
  const caseBuildingIndex = getBuildingIndexGivenBuildingId(project, caseBuildingId);
  const buildings = useSelector(selectBuildings);

  if (
    !caseId ||
    caseIndex === undefined ||
    !caseBuildingId ||
    !caseBuildingIndex === undefined ||
    !buildings
  )
    return null;

  const buildingPositionToOption = (building: BuildingSpecification, index: number): Option => ({
    label: (index + 1).toString(),
    value: building.id
  });
  const buildingPositionsOptions = buildings.map(buildingPositionToOption);
  const selectedBuildingPosition = buildingPositionsOptions.find(
    (props) => props.value === caseBuildingId
  );

  const caseBuildingCasesCount = buildings[caseBuildingIndex].cases.length;
  const casePositions = Array.from(Array(caseBuildingCasesCount).keys());
  const casePositionToOption = (position: number): Option => ({
    label: letterFromIndex(position),
    value: position
  });
  const casePositionsOptions = casePositions.map(casePositionToOption);

  const selectedCasePosition = casePositionsOptions.find((option) => option.value === caseIndex);

  return (
    <Card>
      <SpecialLabel label={t('Positioning')} />
      <div className="line case-position-line">
        <div>
          <label htmlFor={'case' + caseId + 'Building'}>{t('Building')}</label>
          <Select
            id={'case' + caseId + 'BuildingPosition'}
            options={buildingPositionsOptions}
            handleChange={({ value }: Option) => {
              dispatch(caseMoved(caseId, value as BuildingId, caseIndex));
            }}
            value={selectedBuildingPosition}
            disabled={buildings.length === 1}
            style={{ marginTop: '3px' }}
          />
        </div>
        <div>
          <label htmlFor={'case' + caseId + 'Position'}>{t('Case position')}</label>
          <Select
            id={'case' + caseId + 'Position'}
            options={casePositionsOptions}
            handleChange={({ value }: Option) => {
              dispatch(caseMoved(caseId, caseBuildingId, value as number));
            }}
            value={selectedCasePosition}
            disabled={caseBuildingCasesCount === 1}
            style={{ marginTop: '3px' }}
          />
        </div>
      </div>
    </Card>
  );
};

export default PositioningCaseCard;
