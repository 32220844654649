import React from 'react';
import { useDispatch } from 'react-redux';
import { MaybeUiFloorSpace } from './FloorSpace';
import { floorSpaceChanged } from '../../../../store/actions/floorSpace.actions';
import { WindowScroll } from '../../../ui/WindowScroll';
import { FloorSpaceFeature } from '../../../../domain/specification/floorSpace/FloorSpaceFeature';
import { CardsList } from '../../../ui/CardsList';
import { FloorSpaceParcelsCard } from './FloorSpaceSideCards/FloorSpaceParcelsCard';
import { FloorSpaceCasesCard } from './FloorSpaceSideCards/FloorSpaceCasesCard';

interface FloorSpaceSidePanelProps {
  ui: MaybeUiFloorSpace;
}

export const FloorSpaceSideCards = ({ ui }: FloorSpaceSidePanelProps) => {
  const dispatch = useDispatch();

  const dispatchChange = (floorSpaceFeature: FloorSpaceFeature) =>
    dispatch(floorSpaceChanged(floorSpaceFeature));

  return (
    <CardsList>
      <WindowScroll>
        <FloorSpaceParcelsCard />
        <FloorSpaceCasesCard ui={ui} dispatchChange={dispatchChange} />
      </WindowScroll>
    </CardsList>
  );
};
