import { CaseFormDataLegacy } from '../../../../../legacy/caseFormData';
import { Property } from '../../../Property';
import { CaseSpecification } from '../../../CaseSpecification';

export const getCaseDataProperty = (
  caseData: Partial<CaseFormDataLegacy> | CaseSpecification,
  propertyName: string
): Property | undefined => {
  return caseData.properties?.find((property) => property?.name === propertyName);
};
