import { LevelGranulometryInitialEntries } from '../../../../LevelGranulometry';
import { CaseFormDataLegacy } from '../../../../../../legacy/caseFormData';

export const getLevelMaxSurfaceForSaleFromCaseProjection = (
  caseData: CaseFormDataLegacy,
  levelGranulometry: LevelGranulometryInitialEntries
): number =>
  caseData.maxSurfaceForSaleByLevels.find(
    (maxSurfaceForSaleByLevel) => levelGranulometry.level === maxSurfaceForSaleByLevel.levelIndex
  )?.maxSurfaceForSale as number;
