import { push } from 'connected-react-router';
import { orCreate } from '../../routes/panel';
import { buildingRoute, buildingsRoute } from '../../routes/toolboxPanels/buildings';
import {
  caseGranulometryEditEnergyAndNetworksRoute,
  caseGranulometryEditFifthFacadeRoute,
  caseGranulometryEditFloorSpaceRoute,
  caseGranulometryEditGroundAdaptationsRoute,
  caseGranulometryEditInfrastructureRoute,
  caseGranulometryEditLevelRoute,
  caseGranulometryEditLodgmentTypeRoomRoute,
  caseGranulometryEditLodgmentTypeRoute,
  caseGranulometryEditOtherPropertiesRoute,
  caseGranulometryEditOutdoorsRoute,
  caseGranulometryEditPeripheralReinforcementsRoute,
  caseGranulometryEditPropertyRoute,
  caseGranulometryEditSuperstructureRoute,
  caseRoute
} from '../../routes/toolboxPanels/cases';
import {
  deliverablesArchivesRoute,
  deliverablesExportReportRoute,
  deliverablesExportRoute,
  deliverablesRoute
} from '../../routes/toolboxPanels/deliverables';
import {
  projectDetailsRoute,
  projectDetailsEditGingerRoute,
  projectDetailsEditFloorSpaceRoute
} from '../../routes/toolboxPanels/projectDetails';
import {
  sectionsEditRoute,
  sectionsIndividualRoute,
  sectionsRoute
} from '../../routes/toolboxPanels/sections';
import { smartRequestRoute, smartRequestsRoute } from '../../routes/toolboxPanels/smartRequest';
import { LodgmentType } from '../../domain/specification/lodgmentTypes/LodgmentType';
import { Action } from 'redux';
import { appMenuEditUserRoute, appMenuRoute } from '../../routes/appMenu';

export const goToProject = (projectId?: string): Action => push(`/project/${orCreate(projectId)}`);
export const goToProjectDetails = (projectId?: string): Action =>
  push(projectDetailsRoute.makePath({ projectId: orCreate(projectId) }));
export const goToProjectGinger = (projectId: string): Action =>
  push(projectDetailsEditGingerRoute.makePath({ projectId: orCreate(projectId) }));
export const goToProjectFloorSpace = (projectId: string): Action =>
  push(projectDetailsEditFloorSpaceRoute.makePath({ projectId: orCreate(projectId) }));

export const goToBuildings = (projectId: string): Action =>
  push(buildingsRoute.makePath({ projectId }));
export const goToBuilding = (projectId: string, buildingId: string): Action =>
  push(buildingRoute.makePath({ projectId, buildingId }));

export const goToCase = (projectId: string, caseId: string): Action =>
  push(caseRoute.makePath({ projectId, caseId }));
export const goToCaseFloorSpace = (projectId: string, caseId: string): Action =>
  push(caseGranulometryEditFloorSpaceRoute.makePath({ projectId, caseId }));
export const goToCaseFifthFacade = (projectId: string, caseId: string): Action =>
  push(caseGranulometryEditFifthFacadeRoute.makePath({ projectId, caseId }));
export const goToCaseSuperstructure = (projectId: string, caseId: string): Action =>
  push(caseGranulometryEditSuperstructureRoute.makePath({ projectId, caseId }));
export const goToCaseEnergyAndNetworks = (projectId: string, caseId: string): Action =>
  push(caseGranulometryEditEnergyAndNetworksRoute.makePath({ projectId, caseId }));
export const goToCaseInfrastructure = (projectId: string, caseId: string): Action =>
  push(caseGranulometryEditInfrastructureRoute.makePath({ projectId, caseId }));
export const goToCaseGroundAdaptations = (projectId: string, caseId: string): Action =>
  push(caseGranulometryEditGroundAdaptationsRoute.makePath({ projectId, caseId }));
export const goToCasePeripheralReinforcements = (projectId: string, caseId: string): Action =>
  push(caseGranulometryEditPeripheralReinforcementsRoute.makePath({ projectId, caseId }));
export const goToCaseOutdoors = (projectId: string, caseId: string): Action =>
  push(caseGranulometryEditOutdoorsRoute.makePath({ projectId, caseId }));
export const goToCaseOtherProperties = (projectId: string, caseId: string): Action =>
  push(caseGranulometryEditOtherPropertiesRoute.makePath({ projectId, caseId }));
export const goToGranulometryLodgmentType = (
  projectId: string,
  caseId: string,
  lodgmentType: LodgmentType
): Action =>
  push(
    caseGranulometryEditLodgmentTypeRoute.makePath({
      projectId,
      caseId,
      lodgmentType
    })
  );
export const goToCaseGranulometryEditProperty = (
  projectId: string,
  caseId: string,
  propertyName: string
): Action => push(caseGranulometryEditPropertyRoute.makePath({ projectId, caseId, propertyName }));

export const goToCaseGranulometryEditLevel = (
  projectId: string,
  caseId: string,
  levelId: string
): Action => push(caseGranulometryEditLevelRoute.makePath({ projectId, caseId, levelId }));

export const goToGranulometryLodgmentTypeRoom = (
  projectId: string,
  caseId: string,
  lodgmentType: LodgmentType,
  roomIndex: number
): Action =>
  push(
    caseGranulometryEditLodgmentTypeRoomRoute.makePath({
      projectId,
      caseId,
      lodgmentType,
      roomIndex
    })
  );
export const goToSections = (projectId: string): Action =>
  push(sectionsRoute.makePath({ projectId }));
export const goToSectionsEdit = (projectId: string): Action =>
  push(sectionsEditRoute.makePath({ projectId }));
export const goToSectionsIndividual = (projectId: string, sectionId: string): Action =>
  push(sectionsIndividualRoute.makePath({ projectId, sectionId }));

export const goToSmartRequests = (projectId: string): Action =>
  push(smartRequestsRoute.makePath({ projectId }));
export const goToSmartRequest = (projectId: string, request: string): Action =>
  push(smartRequestRoute.makePath({ projectId, request }));

export const goToDeliverables = (projectId: string): Action =>
  push(deliverablesRoute.makePath({ projectId }));
export const goToDeliverablesExport = (projectId: string): Action =>
  push(deliverablesExportRoute.makePath({ projectId }));
export const goToDeliverablesExportReport = (
  projectId: string,
  reportId: string,
  reportDataType: string
): Action => push(deliverablesExportReportRoute.makePath({ projectId, reportId, reportDataType }));
export const goToDeliverablesArchives = (projectId: string): Action =>
  push(deliverablesArchivesRoute.makePath({ projectId }));

export const goToAppMenu = (projectId: string): Action =>
  push(appMenuRoute.makePath({ projectId }));
export const goToAppMenuEditUser = (projectId: string): Action =>
  push(appMenuEditUserRoute.makePath({ projectId }));
