import { ProjectProjection } from '../../projection/project/ProjectProjection';
import { CaseProjection } from '../../projection/cases/CaseProjection';
import { getCaseCuttedBuiltSurfaceMaximum } from '../../specification/cases/queries/get/surfaces/getCaseCuttedBuiltSurfaceMaximum';

export const caseCuttedBuiltSurfaceIsLargerThanTheMaximum = (
  projectProjection: ProjectProjection,
  caseProjection: CaseProjection
): boolean =>
  caseProjection.projectedSurfaces.cuttedBuiltSurface.value >
  getCaseCuttedBuiltSurfaceMaximum(projectProjection, caseProjection);
