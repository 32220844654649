import { matchesPath } from '../matchesPath';
import { RouteWithMakePath } from '../panel';

export type SectionsEditRouteParams = { projectId: string };
export const sectionsEditRoute: RouteWithMakePath<SectionsEditRouteParams> = {
  path: `/project/:projectId/sections/edit`,
  makePath: ({ projectId }) => `/project/${projectId}/sections/edit`,
  matchesPath: matchesPath<SectionsEditRouteParams>(/\/project\/([^/]+)\/sections\/edit/, {
    projectId: 1
  })
};

export type SectionsIndividualRouteParams = { projectId: string; sectionId: string };
export const sectionsIndividualRoute: RouteWithMakePath<SectionsIndividualRouteParams> = {
  path: `/project/:projectId/sections/individual/:sectionId`,
  makePath: ({ projectId, sectionId }) => `/project/${projectId}/sections/individual/${sectionId}`,
  matchesPath: matchesPath<SectionsIndividualRouteParams>(
    /\/project\/([^/]+)\/sections\/individual\/(\w+)/,
    {
      projectId: 1,
      sectionId: 2
    }
  )
};

export type SectionsRouteParams = { projectId: string };
export const sectionsRoute: RouteWithMakePath<SectionsRouteParams> = {
  path: `/project/:projectId/sections`,
  makePath: sectionsEditRoute.makePath,
  matchesPath: matchesPath<SectionsRouteParams>(/\/project\/([^/]+)\/sections/, {
    projectId: 1
  })
};
