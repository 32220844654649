import { createSelector, Selector } from 'reselect';
import * as R from 'ramda';
import { selectCurrentCaseIdFromRoute } from '../../navigation/caseIdFromRoute.selector';
import { selectAllCasesProjections } from '../allCasesProjections.selector';
import { State } from '../../../reducers';
import { CaseProjectionWithIndexes } from '../../../../domain/projection/cases/CaseProjection';

export const selectCurrentCaseProjection: Selector<State, CaseProjectionWithIndexes> =
  createSelector(
    [selectAllCasesProjections, selectCurrentCaseIdFromRoute],
    (allCases, caseId) => allCases?.find(R.propEq('id', caseId)) as CaseProjectionWithIndexes
  );
