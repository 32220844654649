import { Project } from '../../../../project/Project';
import { FloorSpaceFeatureId } from '../../FloorSpaceFeature';
import { getCaseIdFromFloorSpaceId } from '../../../../core/queries/getCaseIdFromFloorSpaceId';
import { CaseSpecification } from '../../../cases/CaseSpecification';
import * as R from 'ramda';
import { BuildingSpecification } from '../../../buildings/BuildingSpecification';
import { NonEmptyArray } from '../../../../../utils/NonEmptyArray';

type Fn<T> = (type: T) => T;

const mapOnBuildings =
  (fn: Fn<BuildingSpecification>) =>
  (project: Project): NonEmptyArray<BuildingSpecification> =>
    R.map(fn)(project.buildings) as NonEmptyArray<BuildingSpecification>;

const mapOnCases =
  (fn: Fn<CaseSpecification>) =>
  (building: BuildingSpecification): BuildingSpecification =>
    R.over(R.lensProp('cases'), R.map(fn), building);

const selectFloorSpace =
  (caseId?: string) =>
  (caseSpec: CaseSpecification): CaseSpecification => {
    return R.over(
      R.lensPath(['floorSpaceFeature', 'properties', 'shape', 'isSelected']),
      () => caseId === caseSpec.id,
      caseSpec
    );
  };

export const toggleSelectFloorSpaceFeature =
  (floorSpaceId: FloorSpaceFeatureId) =>
  (project: Project): Project => {
    const caseId = getCaseIdFromFloorSpaceId(project, floorSpaceId);

    return {
      ...project,
      buildings: mapOnBuildings(mapOnCases(selectFloorSpace(caseId)))(project)
    };
  };
