import { APP_COLOR } from '../../../../constants/appConstants';
import { getSectionName, Section, SectionDrawn } from '../Section';
import i18next from 'i18next';
import { getDefaultCasePropertyValue } from '../../../specification/cases/queries/get/properties/getDefaultCasePropertyValue';
import { CirculationName } from './CirculationSection';
import { SectionWithTheoreticalSurface } from '../SectionWithTheoreticalSurface';
import { IconObjectProps } from '../../../../components/ui/Icons/iconObject';
import { SectionWithWidth } from '../SectionWithWidth';
import { SectionWithLength } from '../SectionWithLength';

interface ElevatorShaftProps {
  surface: number;
  length: number;
  width: number;
  doorHeight: number;
  doorWidth: number;
  group: string;
}

export interface ElevatorShaftSection
  extends Section,
    SectionDrawn,
    SectionWithLength,
    SectionWithWidth,
    SectionWithTheoreticalSurface {
  name: CirculationName.ElevatorShaft;
  doorHeight: number;
  doorWidth: number;
}

export const ELEVATOR_SHAFT_ICON = {
  type: 'object',
  iconName: 'elevator',
  color: APP_COLOR
} as IconObjectProps;

const elevatorShaft = ({
  surface,
  length,
  width,
  doorHeight,
  doorWidth,
  group
}: ElevatorShaftProps): ElevatorShaftSection => ({
  // Section
  id: 'NOID',
  name: CirculationName.ElevatorShaft,
  title: i18next.t('elevatorShaft.title'),
  type: 'circulation',
  displayedSurface: surface,
  // SectionDrawn
  surface,
  color: APP_COLOR,
  icon: ELEVATOR_SHAFT_ICON,
  group,
  // SectionWithTheoreticalSurface
  theoreticalSurface: surface,
  defaultTheoreticalSurface:
    getDefaultCasePropertyValue('elevatorShaftWidth') *
    getDefaultCasePropertyValue('elevatorShaftLength'),
  // ElevatorShaftSection
  length,
  width,
  doorHeight,
  doorWidth
});

export default elevatorShaft;

export const isSectionAElevatorShaft = (section: Section): section is ElevatorShaftSection =>
  getSectionName(section) === CirculationName.ElevatorShaft;
