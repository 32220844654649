import { getDefaultUnitConvertedPropertyValue } from '../../../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { getHallSectionTheoreticalWallsLinear } from './getHallSectionTheoreticalWallsLinear';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { EXPONENT, MULTIPLIER_COEFFICIENT } from '../../getSectionWallsLinear';

export const getHallSectionTheoreticalWallsLinearDetails = (
  caseGranulometry: CaseGranulometry
): string => {
  let details: string[] = [];
  details = [
    ...details,
    '<b>' +
      roundWith2Decimal(
        getDefaultUnitConvertedPropertyValue(
          caseGranulometry.initialSpecifications,
          'hallSurface'
        ) as number
      ) +
      ' m\u00B2</b> de surface de hall'
  ];
  const hideNumbers = !(process.env.GB_ENV === 'dev' || process.env.GB_ENV === 'recette');
  details = [
    ...details,
    '<b>* ' +
      (hideNumbers
        ? 'coefficient multiplicateur'
        : MULTIPLIER_COEFFICIENT + '<sup>' + EXPONENT + '</sup>') +
      '</b>'
  ];
  details = [
    ...details,
    '<b>= ' + roundWith2Decimal(getHallSectionTheoreticalWallsLinear(caseGranulometry)) + ' mL</b>'
  ];
  return details.join('<br />');
};
