import { connect } from 'react-redux';
import { State } from '../../../../store/reducers';
import {
  goToBuilding,
  goToCase,
  goToDeliverables,
  goToProject,
  goToProjectDetails,
  goToSections,
  goToSmartRequests
} from '../../../../store/actions/navigations.actions';
import { selectProjectId } from '../../../../store/selectors/project';
import { selectFirstBuildingId } from '../../../../store/selectors/specification/firstBuildingId.selector';
import { selectFirstCaseId } from '../../../../store/selectors/specification/firstCaseId.selector';

const mapStateToProps = (state: State) => ({
  currentProjectId: selectProjectId(state),
  firstBuildingId: selectFirstBuildingId(state),
  firstCaseId: selectFirstCaseId(state)
});

const mapDispatchToProps = {
  goToProject,
  goToProjectDetails,
  goToBuilding,
  goToCase,
  goToSections,
  goToSmartRequests,
  goToDeliverables
};

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: object
) => ({
  ...ownProps,
  goToProject: () =>
    stateProps.currentProjectId && dispatchProps.goToProject(stateProps.currentProjectId),
  goToProjectDetails: () =>
    stateProps.currentProjectId && dispatchProps.goToProjectDetails(stateProps.currentProjectId),
  goToFirstBuilding: () =>
    stateProps.currentProjectId &&
    stateProps.firstBuildingId &&
    dispatchProps.goToBuilding(stateProps.currentProjectId, stateProps.firstBuildingId),
  goToFirstCase: () =>
    stateProps.currentProjectId &&
    stateProps.firstCaseId &&
    dispatchProps.goToCase(stateProps.currentProjectId, stateProps.firstCaseId),
  goToSections: () =>
    stateProps.currentProjectId && dispatchProps.goToSections(stateProps.currentProjectId),
  goToSmartRequests: () =>
    stateProps.currentProjectId && dispatchProps.goToSmartRequests(stateProps.currentProjectId),
  goToDeliverables: () =>
    stateProps.currentProjectId && dispatchProps.goToDeliverables(stateProps.currentProjectId)
});

export const withConnect = connect(mapStateToProps, mapDispatchToProps, mergeProps);
