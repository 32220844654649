import { ValidationRule } from '../ValidationsRule';
import {
  projectSurfaceIsEmpty,
  projectSurfaceIsLargerThanTheMaximum,
  projectSurfaceIsSmallerThanTheMinimum,
  projectSurfaceIsUndefined
} from '../../checks/project/projectSurfaceIsValid';
import { MAXIMUM_PROJECT_CBS, MINIMUM_PROJECT_CBS } from '../../../constants/appConstants';

export const projectSurfaceMustBeValid: ValidationRule = (project, isNewProject) => {
  const explanation =
    'Il est nécessaire de renseigner une surface pour le projet. Celle-ci doit être comprise entre ' +
    MINIMUM_PROJECT_CBS +
    ' m\u00B2 et ' +
    MAXIMUM_PROJECT_CBS +
    ' m\u00B2.';

  if (projectSurfaceIsUndefined(project.surface)) {
    if (isNewProject) {
      return {
        title: 'Merci de renseigner une surface du projet',
        explanation,
        type: 'info'
      };
    } else {
      return {
        title: 'La surface du projet n’est pas définie',
        explanation,
        type: 'error'
      };
    }
  }
  if (project.surface && projectSurfaceIsEmpty(project.surface)) {
    return {
      title: 'La surface du projet ne peut être égale à zéro',
      explanation,
      type: 'error'
    };
  }
  if (project.surface && projectSurfaceIsSmallerThanTheMinimum(project.surface)) {
    return {
      title: 'La surface du projet est trop petite',
      explanation,
      type: 'error'
    };
  }
  if (project.surface && projectSurfaceIsLargerThanTheMaximum(project.surface)) {
    return {
      title: 'La surface du projet est trop grande',
      explanation,
      type: 'error'
    };
  }
  return true;
};
