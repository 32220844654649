import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Window } from '../../../../ui/Window';
import LodgmentsTypesInner from './LodgmentsTypesInner';
import { getLodgmentTypeTriggerId } from '../../../../../routes/toolboxPanels/triggers';
import { IconObject } from '../../../../ui/Icons/iconObject';
import {
  goToCase,
  goToGranulometryLodgmentType
} from '../../../../../store/actions/navigations.actions';
import { selectCurrentlyEditedLodgmentType } from '../../../../../store/selectors/specification/currentCase/currentlyEditedLodgmentType.selector';
import { selectCurrentCaseLabel } from '../../../../../store/selectors/project/currentCaseLabel.selector';
import { CaseGranulometryEditLodgmentTypeRouteParams } from '../../../../../routes/toolboxPanels/cases';

export const LodgmentsTypes = () => {
  const { projectId, caseId } = useParams<CaseGranulometryEditLodgmentTypeRouteParams>();
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const lodgmentTypeSpecification = useSelector(selectCurrentlyEditedLodgmentType);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClickClose = React.useCallback(() => {
    if (caseId && projectId) dispatch(goToCase(projectId, caseId));
  }, [dispatch, projectId, caseId]);

  const handleClickPrev = React.useCallback(() => {
    if (caseId && projectId && lodgmentTypeSpecification?.prevType)
      dispatch(goToGranulometryLodgmentType(projectId, caseId, lodgmentTypeSpecification.prevType));
  }, [dispatch, projectId, caseId, lodgmentTypeSpecification]);

  const handleClickNext = React.useCallback(() => {
    if (caseId && projectId && lodgmentTypeSpecification?.nextType)
      dispatch(goToGranulometryLodgmentType(projectId, caseId, lodgmentTypeSpecification.nextType));
  }, [dispatch, projectId, caseId, lodgmentTypeSpecification]);

  if (!caseLabel || !lodgmentTypeSpecification) return null;

  return (
    <Window
      title={`${t('Lodgment type')} : ${lodgmentTypeSpecification.lodgmentType}`}
      topButtons={
        <>
          <IconObject
            iconName="arrowLeft"
            type="menu"
            disabled={!lodgmentTypeSpecification.prevType}
            onClick={handleClickPrev}
          />
          <IconObject
            iconName="arrowRight"
            type="menu"
            disabled={!lodgmentTypeSpecification.nextType}
            onClick={handleClickNext}
          />
          <IconObject iconName="close" type="menu" onClick={handleClickClose} />
        </>
      }
      triggerId={getLodgmentTypeTriggerId(caseLabel, lodgmentTypeSpecification.lodgmentType)}>
      <LodgmentsTypesInner />
    </Window>
  );
};

export default LodgmentsTypes;
