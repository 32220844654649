import { CaseGranulometry } from '../../../../domain/granulometry/cases/CaseGranulometry';
import getBuildingGeometry from '../../../../services/mapBox/domain/floorSpace/getBuildingGeometry';
import {
  PROJECT_LOCATION_DEFAULT_LATITUDE,
  PROJECT_LOCATION_DEFAULT_LONGITUDE
} from '../../../../domain/ProjectLocation';
import { rectangle } from '../../../../services/mapBox/utils/defaultForm';
import { getCaseGroundLevelGrossFloorSurfaceEff } from '../../../../domain/granulometry/cases/queries/levels/surfaces/getCaseGroundLevelGrossFloorSurfaceEff';

const getDefaultGeometry = (caseGranulometry: CaseGranulometry) =>
  getBuildingGeometry(
    [PROJECT_LOCATION_DEFAULT_LONGITUDE, PROJECT_LOCATION_DEFAULT_LATITUDE],
    rectangle(getCaseGroundLevelGrossFloorSurfaceEff(caseGranulometry).value)
  );

export interface ComEthJsonFeatureGeometry {
  type: 'Polygon'; // leave as it is
  coordinates: ComEthJsonGeometryCoordinate[][];
}

export type ComEthJsonGeometryCoordinate = number[];

export const getComEthJsonFeatureGeometry = (
  caseGranulometry: CaseGranulometry
): ComEthJsonFeatureGeometry | null =>
  caseGranulometry.initialSpecifications?.floorSpace?.geometry ||
  getDefaultGeometry(caseGranulometry);
