import * as R from 'ramda';
import { getLevelDisplayedWallThickness } from '../getLevelDisplayedWallThickness';
import { getLevelFloorThickness } from '../getLevelFloorThickness';
import { LodgmentTypeSpecification } from '../../../../specification/lodgmentTypes/LodgmentTypeSpecification';
import { CaseGranulometry } from '../../../cases/CaseGranulometry';
import { getDefaultUnitConvertedPropertyValue } from '../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { getTopLevelTheoreticalOutsideWallsLinear } from './walls/theoreticalWalls/getTopLevelTheoreticalOutsideWallsLinear';
import { LodgmentSection } from '../../../sections/lodgmentSections/LodgmentSection';
import { getTopLevelLodgmentsPartitionWallsLinear } from './walls/lodgmentWalls/getTopLevelLodgmentsPartitionWallsLinear';
import {
  LevelGranulometry,
  LevelGranulometryFullFilled,
  LevelGranulometryInitialEntries,
  LevelGranulometryWithBearingRelativeSections,
  LevelGranulometryWithLodgments
} from '../../LevelGranulometry';
import { createTopLevelLodgmentSections } from './sections/create/createTopLevelLodgmentSections';
import { getLevelTechnicalPermiseSections } from '../sections/getLevelTechnicalPermiseSections';
import { getTopLevelHallSection } from './sections/getTopLevelHallSection';
import { getTopLevelBearingSection } from './sections/getTopLevelBearingSection';
import { getTopLevelStairShaftSections } from './sections/getTopLevelStairShaftSections';
import { getTopLevelElevatorShaftSections } from './sections/getTopLevelElevatorShaftSections';
import { getTopLevelRampSections } from './sections/getTopLevelRampSections';
import { getTopLevelRbsRulerWidth } from './rulers/getTopLevelRbsRulerWidth';
import { getTopLevelRbsRulerLeft } from './rulers/getTopLevelRbsRulerLeft';
import { getTopLevelSfsRulerWidth } from './rulers/getTopLevelSfsRulerWidth';
import { getTopLevelSfsRulerLeft } from './rulers/getTopLevelSfsRulerLeft';
import { tryToAdjustGroundLevelTooSmallBearingSurface } from './surfaces/tryToAdjustGroundLevelTooSmallBearingSurface';
import { getTopLevelDrawnSurface } from '../surfaces/getLevelDrawnSurface';
import { setLevelSectionsIds } from '../sections/setLevelSectionIds';
import { getLevelId } from '../getLevelId';
import { getTopLevelSmokeExtractionDuctsSections } from './sections/getTopLevelSmokeExtractionDuctsSections';
import { getLevelCommonPremiseSections } from '../sections/getLevelCommonPremiseSections';
import { getLevelShopSections } from '../sections/getLevelShopSections';
import { getLevelOfficeSections } from '../sections/getLevelOfficeSections';
import { getLevelWithLodgmentsContent } from '../content/getLevelWithLodgmentsContent';

// TODO : type levelData
export const createTopLevel = (
  levelData: any,
  caseGranulometry: CaseGranulometry,
  withMinimumBearingSurface: boolean,
  customLodgments?: (LodgmentTypeSpecification | LodgmentSection)[] // depend on editingStage
): LevelGranulometry => {
  const levelGranulometryInitialEntries: LevelGranulometryInitialEntries = {
    level: levelData.level,
    realBuiltSurface: levelData.realBuiltSurface, // same as surface
    surfaceForSale: levelData.surfaceForSale,
    technicalPremiseSections: levelData.technicalPremiseSections || [],
    commonPremiseSections: levelData.commonPremiseSections || [],
    shopSections: levelData.shopSections || [],
    officeSections: levelData.officeSections || [],
    waterBearingTechnicalSheathEnabled: levelData.waterBearingTechnicalSheathEnabled !== false,
    generalBearingTechnicalSheathEnabled: levelData.generalBearingTechnicalSheathEnabled !== false,
    gasBearingTechnicalSheathEnabled: levelData.gasBearingTechnicalSheathEnabled !== false,
    electricityBearingTechnicalSheathEnabled:
      levelData.electricityBearingTechnicalSheathEnabled !== false,
    communicationBearingTechnicalSheathEnabled:
      levelData.communicationBearingTechnicalSheathEnabled !== false
  };

  const levelGranulometryWithLodgmentSections: LevelGranulometryWithLodgments = {
    ...levelGranulometryInitialEntries,
    content: createTopLevelLodgmentSections(caseGranulometry, levelData, customLodgments)
  };

  const levelGranulometryWithBearingRelativeSections: LevelGranulometryWithBearingRelativeSections =
    {
      ...levelGranulometryWithLodgmentSections,
      content: [
        ...getTopLevelSmokeExtractionDuctsSections(
          caseGranulometry,
          levelGranulometryInitialEntries
        ),
        ...getTopLevelStairShaftSections(caseGranulometry, levelGranulometryInitialEntries),
        ...getTopLevelElevatorShaftSections(caseGranulometry, levelGranulometryInitialEntries),
        ...getLevelWithLodgmentsContent(levelGranulometryWithLodgmentSections),
        ...getLevelCommonPremiseSections(levelGranulometryWithLodgmentSections),
        ...getLevelShopSections(levelGranulometryWithLodgmentSections),
        ...getLevelOfficeSections(levelGranulometryWithLodgmentSections),
        ...getLevelTechnicalPermiseSections(levelGranulometryWithLodgmentSections),
        ...getTopLevelHallSection(caseGranulometry, levelGranulometryWithLodgmentSections)
      ]
    };

  const levelGranulometryFullFilled: LevelGranulometryFullFilled = {
    ...levelGranulometryWithBearingRelativeSections,
    content: [
      ...getTopLevelSmokeExtractionDuctsSections(caseGranulometry, levelGranulometryInitialEntries),
      ...getTopLevelStairShaftSections(caseGranulometry, levelGranulometryInitialEntries),
      ...getTopLevelElevatorShaftSections(caseGranulometry, levelGranulometryInitialEntries),
      ...getTopLevelRampSections(caseGranulometry, levelGranulometryInitialEntries),
      ...getTopLevelBearingSection(
        caseGranulometry,
        levelGranulometryWithBearingRelativeSections,
        withMinimumBearingSurface
      ),
      ...getLevelWithLodgmentsContent(levelGranulometryWithLodgmentSections),
      ...getLevelCommonPremiseSections(levelGranulometryWithLodgmentSections),
      ...getLevelShopSections(levelGranulometryWithLodgmentSections),
      ...getLevelOfficeSections(levelGranulometryWithLodgmentSections),
      ...getLevelTechnicalPermiseSections(levelGranulometryWithLodgmentSections),
      ...getTopLevelHallSection(caseGranulometry, levelGranulometryWithLodgmentSections)
    ]
  };

  const finalLevelGranulometry: LevelGranulometry = {
    ...levelGranulometryFullFilled,
    id: getLevelId(caseGranulometry, levelGranulometryFullFilled),
    surface: getTopLevelDrawnSurface(levelGranulometryFullFilled),
    displayedSurface: getTopLevelDrawnSurface(levelGranulometryFullFilled),
    ceilingHeight:
      levelData.ceilingHeight ||
      getDefaultUnitConvertedPropertyValue(
        caseGranulometry.initialSpecifications,
        'topLevelCeilingHeight'
      ),
    wallThickness: 0,
    floorThickness: getLevelFloorThickness(caseGranulometry),
    concreteSlabThickness: getDefaultUnitConvertedPropertyValue(
      caseGranulometry.initialSpecifications,
      'topLevelConcreteSlabThickness'
    ) as number,
    cementScreedThickness: getDefaultUnitConvertedPropertyValue(
      caseGranulometry.initialSpecifications,
      'topLevelCementScreedThickness'
    ) as number,
    partitionWallsLinear: getTopLevelLodgmentsPartitionWallsLinear(
      caseGranulometry,
      levelGranulometryWithLodgmentSections
    ),
    outsideWallsLinear: getTopLevelTheoreticalOutsideWallsLinear(
      caseGranulometry,
      levelGranulometryFullFilled
    ),
    displayedWallThickness: getLevelDisplayedWallThickness(levelGranulometryFullFilled),
    rbsRulerWidth: getTopLevelRbsRulerWidth(levelGranulometryFullFilled),
    rbsRulerLeft: getTopLevelRbsRulerLeft(levelGranulometryFullFilled),
    sfsRulerWidth: getTopLevelSfsRulerWidth(levelGranulometryFullFilled),
    sfsRulerLeft: getTopLevelSfsRulerLeft(levelGranulometryFullFilled)
  };

  return R.pipe<[LevelGranulometry], LevelGranulometry, LevelGranulometry>(
    () => tryToAdjustGroundLevelTooSmallBearingSurface(caseGranulometry, finalLevelGranulometry),
    () => setLevelSectionsIds(caseGranulometry, finalLevelGranulometry)
  )(finalLevelGranulometry);
};
