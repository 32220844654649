import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from 'components/ui/SpecialLabel';
import { FormGroup } from 'components/ui/FormGroup';
import { Card } from '../../../../ui/Card';
import { Input } from '../../../../ui/input/Input';
import { selectProjectComEthIcConstructionToleranceRate } from '../../../../../store/selectors/project/projectComEthIcConstructionToleranceRate.selector';
import { projectComEthIcConstructionToleranceRateChanged } from '../../../../../store/actions/projectComEthIcConstructionToleranceRateChanged.action';
import { COM_ETH_IC_CONSTRUCTION_DEFAULT_TOLERANCE_RATE } from '../../../../../domain/project/ProjectCstbIndicatorsParameters';

export const ProjectCstbIndicatorsParametersCard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const comEthIcConstructionToleranceRate = useSelector(
    selectProjectComEthIcConstructionToleranceRate
  );

  const handleProjectComEthIcConstructionToleranceRateChange = (rate?: number) =>
    dispatch(projectComEthIcConstructionToleranceRateChanged(rate));

  return (
    <Card>
      <SpecialLabel label={t('projectCstbIndicatorsParametersCard.label')} />
      <FormGroup horizontal={true} wrap={true}>
        <Input
          label={t('projectCstbIndicatorsParametersCard.inputLabel')}
          name="projectComEthIcConstructionToleranceRate"
          type="number"
          style={{ width: '40%', flex: '0 0 40%' }}
          value={
            comEthIcConstructionToleranceRate ? comEthIcConstructionToleranceRate * 100 : undefined
          }
          placeholder={COM_ETH_IC_CONSTRUCTION_DEFAULT_TOLERANCE_RATE * 100}
          handleChange={(value) => {
            handleProjectComEthIcConstructionToleranceRateChange(
              value === null || value < -100 ? undefined : value / 100
            );
          }}
          suffix="%"
          minMax={{
            min: -100,
            max: 100,
            unit: '%'
          }}
          info={t('projectCstbIndicatorsParametersCard.inputInfo')}
        />
      </FormGroup>
    </Card>
  );
};
