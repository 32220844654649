import { LevelGranulometryInitialEntries } from '../../../../LevelGranulometry';
import { getLevelRealBuiltSurface } from '../../../surfaces/getLevelRealBuiltSurface';
import { getCaseDataCuttedBuiltSurfaceRate } from '../../../../../../legacy/methodsForCases/getCaseDataCuttedBuiltSurfaceRate';
import { getCaseDataSurfaceForSaleRate } from '../../../../../../legacy/methodsForCases/getCaseDataSurfaceForSaleRate';
import { getDefaultUnitConvertedPropertyValue } from '../../../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { getUpperLevelSurfaceForSale } from './getUpperLevelSurfaceForSale';
import { getCaseUpperLevelsRealBuiltSurface } from '../../../../../../specification/cases/queries/levels/surfaces/getCaseDataUpperLevelsRealBuiltSurfaces';
import { getGroundLevelSurfaceForSale } from './getGroundLevelSurfaceForSale';
import { getCaseDataProjectedUpperLevelCount } from '../../../../../../specification/cases/queries/levels/counts/getCaseDataProjectedUpperLevelCount';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { roundWith3Decimal } from '../../../../../../../utils/round/roundWith3Decimal';
import { getSectionDisplayedSurface } from '../../../../../sections/queries/surfaces/getSectionDisplayedSurface';
import { getLevelTechnicalPermiseSections } from '../../../sections/getLevelTechnicalPermiseSections';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getUpperLevelSurfaceForSaleDetails = (
  caseGranulometry: CaseGranulometry,
  upperLevel: LevelGranulometryInitialEntries
): string => {
  const levelRealBuiltSurface = roundWith2Decimal(
    getLevelRealBuiltSurface(upperLevel, caseGranulometry.initialSpecifications)
  );
  const cbsRate = getCaseDataCuttedBuiltSurfaceRate(caseGranulometry.initialSpecifications);
  const sfsRate = getCaseDataSurfaceForSaleRate(caseGranulometry.initialSpecifications);
  const caseUpperLevelsRealBuiltSurface = roundWith2Decimal(
    getCaseUpperLevelsRealBuiltSurface(caseGranulometry.initialSpecifications)
  );
  const groundLevelSurfaceForSale = roundWith2Decimal(
    getGroundLevelSurfaceForSale(caseGranulometry.initialSpecifications, {
      level: 0
    } as LevelGranulometryInitialEntries)
  );

  let details: string[] = [];

  if (caseGranulometry.initialSpecifications.maxSurfaceForSaleHasBeenForced) {
    details = [
      ...details,
      '<b>Note : Cette SHab est issue du calcul de la SHab maximale car le rendement par défaut de 90% ne permettait pas d’intégrer toutes les sections dans la cage.</b><br />'
    ];
  }

  details = [...details, '<b>' + levelRealBuiltSurface + ' m\u00B2</b> de SdP réelle'];
  details = [
    ...details,
    '<b>* ' +
      roundWith2Decimal(cbsRate * sfsRate * 100) +
      '%</b> <i>(= ' +
      roundWith2Decimal(cbsRate * 100) +
      '% d’abattement règlementaire [SdP PC/SdP réelle] * ' +
      roundWith2Decimal(sfsRate * 100) +
      '% de rendement [Shab/SdP PC])</i>'
  ];

  // Hall
  if (caseGranulometry.initialSpecifications.hall) {
    details = [
      ...details,
      '<b>+ ' +
        (getDefaultUnitConvertedPropertyValue(
          caseGranulometry.initialSpecifications,
          'hallSurface'
        ) as number) +
        ' m\u00B2</b> de hall' +
        ' <b>* ' +
        roundWith3Decimal(levelRealBuiltSurface / (caseUpperLevelsRealBuiltSurface || 1)) +
        '</b> <i>(= ' +
        levelRealBuiltSurface +
        ' m\u00B2 de SdP réelle' +
        (caseUpperLevelsRealBuiltSurface !== 0
          ? ' / ' +
            caseUpperLevelsRealBuiltSurface +
            ' m\u00B2 de SdP réelle aux étages courants non forcés'
          : '') +
        ')</i>'
    ];
  }

  // Technical premises
  details = [
    ...details,
    (upperLevel.technicalPremiseSections ? '(' : '') +
      getLevelTechnicalPermiseSections(upperLevel).reduce(
        (acc, technicalPremiseSection) =>
          acc +
          '<b>+ ' +
          getSectionDisplayedSurface(technicalPremiseSection) +
          ' m\u00B2</b> de ' +
          technicalPremiseSection.title,
        ''
      ) +
      (upperLevel.technicalPremiseSections ? ')' : '') +
      ' <b>* ' +
      roundWith3Decimal(levelRealBuiltSurface / (caseUpperLevelsRealBuiltSurface || 1)) +
      '</b> <i>(= ' +
      levelRealBuiltSurface +
      ' m\u00B2 de SdP réelle' +
      (caseUpperLevelsRealBuiltSurface !== 0
        ? ' / ' +
          caseUpperLevelsRealBuiltSurface +
          ' m\u00B2 de SdP réelle aux étages courants non forcés'
        : '') +
      ')</i>'
  ];

  // Redistribution of the negative SFS in the upper levels
  if (groundLevelSurfaceForSale < 0) {
    details = [
      ...details,
      '<b>+ ' +
        groundLevelSurfaceForSale +
        ' m\u00B2</b> de SHab négative au RC <b>/ ' +
        getCaseDataProjectedUpperLevelCount(caseGranulometry.initialSpecifications) +
        '</b> étages courants'
    ];
  }

  const calculatedUpperLevelSFS = getUpperLevelSurfaceForSale(
    caseGranulometry.initialSpecifications,
    upperLevel
  );
  details = [...details, '<b>= ' + roundWith2Decimal(calculatedUpperLevelSFS) + ' m\u00B2</b>'];

  if (upperLevel.surfaceForSale && upperLevel.surfaceForSale !== calculatedUpperLevelSFS) {
    details = [...details, ''];
    details = [
      ...details,
      '<b>' +
        (upperLevel.surfaceForSale - calculatedUpperLevelSFS >= 0 ? '+ ' : '') +
        roundWith2Decimal(upperLevel.surfaceForSale - calculatedUpperLevelSFS)
          .toString()
          .replace('-', '- ') +
        ' m\u00B2</b> d’ajustement <i>(pour laisser suffisament de place à la surface minimale de pallier)</i>'
    ];
    details = [...details, '<b>= ' + roundWith2Decimal(upperLevel.surfaceForSale) + ' m\u00B2</b>'];
  }

  return details.join('<br />');
};
