import { getCaseDataLevelsForcedRealBuiltSurfaces } from '../../../../specification/cases/queries/levels/surfaces/getCaseDataLevelsForcedRealBuiltSurfaces';
import { getCaseDataProjectedTopLevelCount } from '../../../../specification/cases/queries/levels/counts/getCaseDataProjectedTopLevelCount';
import { getCaseDataGroundLevelForcedSurface } from '../../../../specification/cases/queries/levels/surfaces/getCaseDataGroundLevelForcedSurface';
import { getCaseDataRealBuiltSurface } from '../../../../legacy/methodsForCases/getCaseDataRealBuiltSurface';
import { LevelGranulometry } from '../../LevelGranulometry';
import { isGroundLevel } from '../is/isGroundLevel';
import { getDefaultUnitConvertedPropertyValue } from '../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { roundWith2Decimal } from '../../../../../utils/round/roundWith2Decimal';
import { getLevelRealBuiltSurface } from './getLevelRealBuiltSurface';
import { getTopLevelRealBuiltForcedSurface } from '../topLevels/surfaces/getTopLevelRealBuiltForcedSurface';
import { hasCaseGranulometryDrawnFloorSpaceFeature } from '../../../cases/queries/has/hasCaseGranulometryDrawnFloorSpaceFeature';
import { CaseGranulometry } from '../../../cases/CaseGranulometry';
import { isTopLevel } from '../is/isTopLevel';
import { getTopLevelGrossFloorSurfaceEff } from '../topLevels/surfaces/grossFloorSurfaces/getTopLevelGrossFloorSurfaceEff';
import { getTopLevelHoppersSurface } from '../topLevels/surfaces/getTopLevelHoppersSurface';
import { getTopLevelRealOutsideWallsSurface } from '../topLevels/walls/realWalls/getTopLevelRealOutsideWallsSurface';
import { mustTopLevelGfsEffBeAdjusted } from '../topLevels/surfaces/grossFloorSurfaces/mustTopLevelGfsEffBeAdjusted';
import { getCaseUpperLevelsWithAdjustedGfsEffCount } from '../../../cases/queries/levels/counts/getCaseUpperLevelsWithAdjustedGfsEffCount';

// Note : the details is not folowwing perfectly the getLevelRealBuiltSurface to make it work's
export const getLevelRealBuiltSurfaceDetails = (
  levelGranulometry: LevelGranulometry,
  caseGranulometry: CaseGranulometry
): string => {
  let details: string[] = [];
  const caseTopLevelCount = getCaseDataProjectedTopLevelCount(
    caseGranulometry.initialSpecifications
  );
  const levelsForcedRealBuiltSurfaces = getCaseDataLevelsForcedRealBuiltSurfaces(
    caseGranulometry.initialSpecifications
  );
  const unforcedLevelCount = caseTopLevelCount - levelsForcedRealBuiltSurfaces.count;

  const floorSpaceFeatureMustBeTreated =
    caseGranulometry &&
    hasCaseGranulometryDrawnFloorSpaceFeature(caseGranulometry) &&
    isTopLevel(caseGranulometry, levelGranulometry);

  // 1 : If there is a manualy forced real built surface :
  if (
    getTopLevelRealBuiltForcedSurface(levelGranulometry, caseGranulometry.initialSpecifications)
  ) {
    details = [
      ...details,
      '<b>(' +
        getTopLevelRealBuiltForcedSurface(
          levelGranulometry,
          caseGranulometry.initialSpecifications
        ) +
        ' m\u00B2</b> de Sdp Réelle saisie pour ce niveau par l’opérateur'
    ];
  }
  // 2 : If there is a filled gfsEffRelativeTopLevelsRBS (saved from a previous pass)
  else if (caseGranulometry.initialSpecifications.gfsEffRelativeTopLevelsRBS !== undefined) {
    const topLevelGrossFloorSurfaceEff = getTopLevelGrossFloorSurfaceEff(
      caseGranulometry,
      levelGranulometry,
      false
    );
    details = [
      ...details,
      '<b>' + roundWith2Decimal(topLevelGrossFloorSurfaceEff) + ' m\u00B2</b> de SHOB VPP'
    ];
    details = [
      ...details,
      '<b>- ' +
        roundWith2Decimal(getTopLevelRealOutsideWallsSurface(caseGranulometry, levelGranulometry)) +
        ' m\u00B2</b> d’emprise réelle de voiles exposés'
    ];
    details = [
      ...details,
      '<b>- ' +
        getTopLevelHoppersSurface(caseGranulometry, levelGranulometry, true) +
        ' m\u00B2</b> de surface de trémies (rampe exlue)'
    ];
  }
  // Otherwise :
  else {
    // 3 : In case of floor space filled :
    if (floorSpaceFeatureMustBeTreated) {
      // Details will go to 2
    }
    // 4 : Otherwise :
    else {
      const caseRealBuiltSurface = getCaseDataRealBuiltSurface(
        caseGranulometry.initialSpecifications
      );
      details = [
        ...details,
        '<b>(' +
          roundWith2Decimal(caseRealBuiltSurface) +
          ' m\u00B2</b> de Sdp Réelle à la cage <b>- ' +
          roundWith2Decimal(levelsForcedRealBuiltSurfaces.totalSurface) +
          ' m\u00B2</b> de Sdp Réelle forcée '
      ];
      details = [
        ...details,
        '<b>/ ' +
          unforcedLevelCount +
          '</b> étage' +
          (unforcedLevelCount > 1 ? 's' : '') +
          ' non forcé' +
          (unforcedLevelCount > 1 ? 's' : '')
      ];
    }
  }

  // 3 or 4 : ramp surface distribution
  if (
    caseGranulometry.initialSpecifications.builtInRamp && // If a ramp is built in the case
    caseGranulometry.initialSpecifications.basementLevelCount !== 0 && // their is at least one basement level
    !getCaseDataGroundLevelForcedSurface(caseGranulometry.initialSpecifications) // and the ground level real built surface is not forced :
  ) {
    // Get the ramp surface
    const rampLength = getDefaultUnitConvertedPropertyValue(
      caseGranulometry.initialSpecifications,
      'rampLength'
    ) as number;
    const rampWidth = getDefaultUnitConvertedPropertyValue(
      caseGranulometry.initialSpecifications,
      'rampWidth'
    ) as number;
    const rampSurface = rampLength * rampWidth;
    if (isGroundLevel(levelGranulometry)) {
      // For the ground level only
      details = [...details, '- ' + rampSurface + ' m\u00B2 de rampe'];
      if (!floorSpaceFeatureMustBeTreated) {
        details = [
          ...details,
          '<b>+ ' +
            rampSurface +
            ' m\u00B2</b> de rampe <b>/ ' +
            unforcedLevelCount +
            '</b> étage' +
            (unforcedLevelCount > 1 ? 's' : '') +
            ' non forcé' +
            (unforcedLevelCount > 1 ? 's' : '')
        ];
      }
    } else {
      if (!floorSpaceFeatureMustBeTreated) {
        details = [
          ...details,
          '<b>+ ' +
            rampSurface +
            ' m\u00B2</b> de rampe <b>/ ' +
            unforcedLevelCount +
            '</b> étage' +
            (unforcedLevelCount > 1 ? 's' : '') +
            ' non forcé' +
            (unforcedLevelCount > 1 ? 's' : '')
        ];
      }
    }
  }

  if (mustTopLevelGfsEffBeAdjusted(caseGranulometry, levelGranulometry as LevelGranulometry)) {
    details = [
      ...details,
      '<b>- (' +
        roundWith2Decimal(
          caseGranulometry.diffBetweenRBSWithoutFloorSpaceAndRBSWithFloorSpace as number
        ) +
        ') m\u00B2</b> de différence entre la SdP réelle de la cage avant et après saisie d’une SHOB VPP' +
        '<b>/ ' +
        getCaseUpperLevelsWithAdjustedGfsEffCount(caseGranulometry) +
        ' étages</b> à la SHOB VPP nécessitant un ajustement)'
    ];
  }

  details = [
    ...details,
    '<b>= ' +
      roundWith2Decimal(
        getLevelRealBuiltSurface(levelGranulometry, caseGranulometry.initialSpecifications)
      ) +
      'm\u00B2</b>'
  ];

  return details.join('<br />');
};
