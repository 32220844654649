import { CaseGranulometry } from '../../../../granulometry/cases/CaseGranulometry';
import { TopLevelDataLegacy } from '../../../caseFormData';
import { CaseProjection } from '../../../../projection/cases/CaseProjection';

export const updateCaseGranulometryTopLevelsData = (
  caseProjection: CaseProjection,
  caseGranulometry: CaseGranulometry,
  topLevelsCount: number
): CaseGranulometry => {
  let updatedTopLevelsData: TopLevelDataLegacy[] = [];
  let levelIndex = topLevelsCount;
  while (levelIndex > 0) {
    levelIndex -= 1;
    const initialLevelProjection = caseProjection.levels.find((l) => l.level === levelIndex);
    const initialLevelData = caseGranulometry.initialSpecifications.topLevelsData.find(
      (l) => l.level === levelIndex
    );
    updatedTopLevelsData = [
      ...updatedTopLevelsData,
      {
        level: levelIndex,
        realBuiltSurface: initialLevelData?.realBuiltSurface,
        ceilingHeight: initialLevelData?.ceilingHeight,
        grossFloorSurfaceEff: initialLevelData?.grossFloorSurfaceEff,
        technicalPremiseSections: initialLevelProjection?.technicalPremiseSections,
        commonPremiseSections: initialLevelProjection?.commonPremiseSections,
        shopSections: initialLevelProjection?.shopSections,
        officeSections: initialLevelProjection?.officeSections,
        waterBearingTechnicalSheathEnabled: initialLevelData?.waterBearingTechnicalSheathEnabled,
        generalBearingTechnicalSheathEnabled:
          initialLevelData?.generalBearingTechnicalSheathEnabled,
        gasBearingTechnicalSheathEnabled: initialLevelData?.gasBearingTechnicalSheathEnabled,
        electricityBearingTechnicalSheathEnabled:
          initialLevelData?.electricityBearingTechnicalSheathEnabled,
        communicationBearingTechnicalSheathEnabled:
          initialLevelData?.communicationBearingTechnicalSheathEnabled
      }
    ];
  }

  return {
    ...caseGranulometry,
    initialSpecifications: {
      ...caseGranulometry.initialSpecifications,
      topLevelsData: updatedTopLevelsData
    }
  };
};
