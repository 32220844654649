import { MaybeFloorSpaceFeature } from '../../FloorSpaceFeature';
import { Project } from '../../../../project/Project';
import { CaseSpecification } from '../../../cases/CaseSpecification';
import { getCaseLabelGivenCaseId } from '../../../../core/queries/getCaseLabelGivenCaseId';
import * as R from 'ramda';

export const setFloorSpaceFeature =
  (floorSpace: MaybeFloorSpaceFeature) =>
  (project: Project) =>
  (caseSpecification: CaseSpecification): CaseSpecification => {
    const floorSpaceName = getCaseLabelGivenCaseId(project, caseSpecification.id);

    if (floorSpace.properties) {
      floorSpace.properties.facades = floorSpace.properties.facades?.map((f) => ({
        ...f,
        angle: f.angle || 0,
        distance: f.distance || 0
      }));
    }

    return {
      ...caseSpecification,
      floorSpaceFeature: R.over(
        R.lensProp('properties'),
        (floorSpaceProperties) =>
          floorSpaceProperties
            ? R.set(R.lensProp('name'), floorSpaceName, floorSpaceProperties)
            : undefined,
        floorSpace
      )
    };
  };
