import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../ui/Button';
import { getLevelTriggerId } from '../../../../routes/toolboxPanels/triggers';
import { goToCaseGranulometryEditLevel } from '../../../../store/actions/navigations.actions';
import { LevelSpecification } from '../../../../domain/specification/levels/LevelSpecification';
import { selectCurrentCaseLabel } from '../../../../store/selectors/project/currentCaseLabel.selector';
import { selectCurrentCaseIdFromRoute } from '../../../../store/selectors/navigation/caseIdFromRoute.selector';
import { selectProjectId } from '../../../../store/selectors/project';
import { getLabel } from '../../../../domain/specification/levels/queries/get/getLabel';
import { selectCurrentlyEditedLevel } from '../../../../store/selectors/navigation/currentlyEditedLevel.selector';

interface Column1Props {
  levelSpecification: LevelSpecification;
}

export const Column1 = ({ levelSpecification }: Column1Props) => {
  const dispatch = useDispatch();

  const projectId = useSelector(selectProjectId);
  const caseId = useSelector(selectCurrentCaseIdFromRoute);
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const currentlyEditedLevel = useSelector(selectCurrentlyEditedLevel);

  if (!caseLabel) return null;

  return (
    <div className="column-1">
      <div className="cell">
        <Button
          id={getLevelTriggerId(caseLabel, levelSpecification.level)}
          selected={currentlyEditedLevel?.level === levelSpecification.level}
          fullWidth
          size="small"
          content={getLabel(levelSpecification)}
          appearance="outline"
          handleClick={() => {
            if (projectId && caseId)
              dispatch(
                goToCaseGranulometryEditLevel(
                  projectId,
                  caseId,
                  levelSpecification.level.toString()
                )
              );
          }}
        />
      </div>
    </div>
  );
};
