import * as React from 'react';
import { Bracket } from '../../ui/Bracket';

const UnitSwitchersLine = () => {
  /* const unit1Buttons = [
    {
      content: 'm%u00B2',
      handleClick: () => {
        console.log('unit clicked !');
      },
      style: { textTransform: 'none' }
    },
    {
      content: '%',
      handleClick: () => {
        console.log('unit clicked !');
      },
      disabled: true
    }
  ]; */

  /* const unit2Buttons = [
    {
      content: 'm%u00B2',
      handleClick: () => {
        console.log('unit clicked !');
      },
      style: { textTransform: 'none' },
      disabled: true
    },
    {
      content: '%',
      handleClick: () => {
        console.log('unit clicked !');
      },
      disabled: true
    },
    {
      content: 'Nb',
      handleClick: () => {
        console.log('unit clicked !');
      }
    }
  ]; */

  return (
    <div className="line switchers-line">
      <div className="column-1">&nbsp;</div>
      <div className="column-2 withBracket">
        <div className="nbArrow">
          <Bracket />
          <label>Nb</label>
        </div>
        <div className="percentArrow">
          <div>
            <Bracket />
          </div>
          <label>%</label>
          {/* <ButtonGroup buttons={unit2Buttons} initialSelectedKey={2} color="grey" size="small" /> */}
        </div>
      </div>
      <div className="column-3">
        <div className="squareMeterArrow">
          <Bracket />
          <label>m²</label>
          {/* <ButtonGroup buttons={unit1Buttons} initialSelectedKey={0} color="grey" size="small" /> */}
        </div>
      </div>
    </div>
  );
};

export default UnitSwitchersLine;
