import * as R from 'ramda';
import { Project } from '../../project/Project';
import { mapCases } from '../../project/map/mapCases';
import { CaseSpecification } from '../../specification/cases/CaseSpecification';
import { removeCaseProperty } from '../utils/removeCaseProperty';

export const migrateProject30To31 = (project: Project): Project =>
  mapCases((eachCase) =>
    R.pipe<
      [CaseSpecification],
      CaseSpecification,
      CaseSpecification,
      CaseSpecification,
      CaseSpecification,
      CaseSpecification,
      CaseSpecification
    >(
      (cS) => removeCaseProperty(cS, 'bearingTechnicalSheathWidth'),
      (cS) => removeCaseProperty(cS, 'bearingTechnicalSheathDepth'),
      (cS) => removeCaseProperty(cS, 'bearingTechnicalSheathCount'),
      (cS) => removeCaseProperty(cS, 'technicalSectionWidth'), // Redo cause defaultProperties hasn't been clean properly
      (cS) => removeCaseProperty(cS, 'technicalSectionSurface'), // Redo cause defaultProperties hasn't been clean properly
      (cS) => removeCaseProperty(cS, 'technicalSectionExposureRate') // Redo cause defaultProperties hasn't been clean properly
    )(eachCase)
  )(project);
