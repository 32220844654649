import { getDefaultUnitConvertedPropertyValue } from '../../../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import smokeExtractionDucts, {
  SmokeExtractionDuctsSection
} from '../../../../../sections/circulationSections/smokeExtractionDucts';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const createTopLevelSmokeExtractionDuctsSection = (
  caseGranulometry: CaseGranulometry,
  group: string
): SmokeExtractionDuctsSection => {
  const depth = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'smokeExtractionDuctsDepth'
  ) as number;
  const width = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'smokeExtractionDuctsWidth'
  ) as number;
  const surface = depth * width;
  return smokeExtractionDucts({
    surface,
    depth,
    width,
    group
  });
};
