import { createSelector, Selector } from 'reselect';
import { State } from '../../../reducers';
import { selectProject } from '../../project';
import { selectIsExistingProject } from '../../project/isExistingProject.selector';
import { ExistingProject } from '../../../../domain/project/Project';

export const selectIsProjectDetailsGingerAvailable: Selector<State, boolean> = createSelector(
  [selectIsExistingProject, selectProject],
  (isExistingProject, project) =>
    !!(
      isExistingProject &&
      (project as ExistingProject).id &&
      (project as ExistingProject).street &&
      (project as ExistingProject).city &&
      (project as ExistingProject).postalCode &&
      (project as ExistingProject).latitude &&
      (project as ExistingProject).longitude
    )
);
