import { LevelGranulometry } from '../../../LevelGranulometry';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { getTotalCarBoxes } from '../../../../../specification/lodgmentTypes/ParkingsSpecification';
import { getBasementLevelParkingRate } from '../getBasementLevelParkingRate';
import { getLodgmentCountFromDistribution } from '../../../../queries/customDistribution/getLodgmentCountFromDistribution';
import { getCaseDistributionWithCustomDistribution } from '../../../../queries/customDistribution/getCaseDistributionWithCustomDistribution';

export const getBasementLevelCarBoxesCount = (
  levelGranulometry: LevelGranulometry,
  caseGranulometry: CaseGranulometry
): number => {
  const levelCarBoxesCount =
    (getTotalCarBoxes(
      caseGranulometry.initialSpecifications.parkingData,
      getLodgmentCountFromDistribution(getCaseDistributionWithCustomDistribution(caseGranulometry))
    ) *
      getBasementLevelParkingRate(
        levelGranulometry.level,
        caseGranulometry.initialSpecifications.basementLevelsData
      )) /
    100;
  // Note : It works like this only because there is a max of 2 basement level
  return levelGranulometry.level === -1
    ? Math.ceil(levelCarBoxesCount)
    : Math.floor(levelCarBoxesCount);
};
