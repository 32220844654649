import * as turf from '@turf/turf';
import { getAxis } from '../../utils/geometry/getAxis';
import { getCenter } from '../../utils/geometry/getCenter';
import { getAngle } from '../../utils/geometry/getAngle';
import { FacadeSpecification } from '../../../../domain/specification/floorSpace/FacadeSpecification';
import { getLineDistance } from '../../utils/geometry/lineDistance';

export const updateFacadesProperties = (facades: FacadeSpecification[]): FacadeSpecification[] => {
  const computed = facades.find((f) => f.computed);

  const facadesToSplice = [...facades];
  const orderedFacadesByOrigin = [
    ...facadesToSplice.splice(computed.order, facades.length - computed.order),
    ...facadesToSplice
  ];

  const newFacadeOrigin = {
    ...orderedFacadesByOrigin[0],
    point2: turf.destination(
      turf.point(orderedFacadesByOrigin[0].point1),
      orderedFacadesByOrigin[0].distance / 1000,
      orderedFacadesByOrigin[0].angle
    ).geometry.coordinates
  };
  const newFacades = orderedFacadesByOrigin.reduce((acc, facadeSpecification, index) => {
    if (index === 0) return [newFacadeOrigin];
    if (facadeSpecification.computed)
      return [
        ...acc,
        {
          ...facadeSpecification,
          point1: acc[index - 1].point2,
          point2: newFacadeOrigin.point1,
          axis: getAxis(getAngle(acc[index - 1].point2, newFacadeOrigin.point1)),
          center: getCenter([acc[index - 1].point2, newFacadeOrigin.point1]),
          angle: getAngle(acc[index - 1].point2, newFacadeOrigin.point1),
          distance: getLineDistance(acc[index - 1].point2, newFacadeOrigin.point1)
        }
      ];
    const point1 = turf.point(acc[index - 1].point2);
    const point2 = turf.destination(
      point1,
      facadeSpecification.distance / 1000,
      facadeSpecification.angle
    );

    return [
      ...acc,
      {
        ...facadeSpecification,
        point1: point1.geometry.coordinates,
        point2: point2.geometry.coordinates,
        axis: getAxis(facadeSpecification.angle),
        center: getCenter([point1.geometry.coordinates, point2.geometry.coordinates])
      }
    ];
  }, [] as FacadeSpecification[]);

  newFacades.sort((a, b) => a.order - b.order);

  return newFacades;
};
