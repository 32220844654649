import { ValidationRule } from '../ValidationsRule';
import { Notification } from '../Notification';
import { getProjectCasesProjections } from '../../projection/project/queries/cases/getProjectCasesProjections';
import { ProjectProjection } from '../../projection/project/ProjectProjection';
import { roundWith2Decimal } from '../../../utils/round/roundWith2Decimal';
import { makeCaseProjectionTopLevels } from '../../projection/cases/queries/levels/makeCaseProjectionTopLevels';
import { Granulometry } from '../../granulometry/Granulometry';
import { getProjectCasesGranulometries } from '../../specification/project/queries/cases/getProjectCasesGranulometries';
import { getCaseGrossFloorSurfaceEff } from '../../granulometry/cases/queries/surfaces/getCaseGrossFloorSurfaceEff';
import { CaseProjection } from '../../projection/cases/CaseProjection';
import { caseTopLevelsSpecificationsRepartitionIsValid } from '../../checks/cases/caseTopLevelsSpecificationsRepartitionIsValid';
import { hasCaseGranulometryDrawnFloorSpaceFeature } from '../../granulometry/cases/queries/has/hasCaseGranulometryDrawnFloorSpaceFeature';

export const projectCasesTopLevelsSurfacesRepartitionMustBeValid: ValidationRule = (
  projectProjection: ProjectProjection,
  isNewProject?: boolean,
  granulometry?: Granulometry
) => {
  if (granulometry) {
    const caseProjectionWithIndexes = getProjectCasesProjections(projectProjection);
    const notifications: Notification[] = getProjectCasesGranulometries(granulometry).reduce(
      (accNotifications, caseGranulometry) => {
        const caseProjection = caseProjectionWithIndexes.find(
          (cP) => cP.id === caseGranulometry.id
        ) as CaseProjection | undefined;
        if (!caseProjection) return accNotifications; // To prevent bug

        const caseRealBuildSurface = caseProjection.projectedSurfaces.realBuiltSurface.value;
        const caseGrossFloorSurfaceEff = getCaseGrossFloorSurfaceEff(caseGranulometry, true).value;

        if (
          !caseTopLevelsSpecificationsRepartitionIsValid(
            caseRealBuildSurface,
            caseGrossFloorSurfaceEff,
            makeCaseProjectionTopLevels(caseProjection)
          )
        ) {
          return [
            {
              title:
                'La répartition des surfaces de niveaux en superstructure de la cage ' +
                caseGranulometry.name +
                ' n’est pas valide',
              explanation: hasCaseGranulometryDrawnFloorSpaceFeature(caseGranulometry)
                ? 'La somme des surfaces de niveaux en superstructure doit être égale à la SHOB VPP de la cage, soit pour cette cage : ' +
                  roundWith2Decimal(caseGrossFloorSurfaceEff) +
                  ' m\u00B2.'
                : 'La somme des surfaces de niveaux en superstructure doit être égale à la SdP réelle de la cage, soit pour cette cage : ' +
                  roundWith2Decimal(caseRealBuildSurface) +
                  ' m\u00B2.',
              type: 'error'
            },
            ...accNotifications
          ];
        }
        return accNotifications;
      },
      [] as Notification[]
    );
    return notifications.length !== 0 ? notifications : true;
  }
  return true;
};
