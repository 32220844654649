import classNames from 'classnames';
import * as React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Card } from '../../../ui/Card';
import { CardsList } from '../../../ui/CardsList';
import { Note } from '../../../ui/Note';
import PanelTitle from '../PanelTitle';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentSectionIdFromRoute } from '../../../../store/selectors/navigation/sectionIdFromRoute.selector';
import { selectCurrentSectionGranulometry } from '../../../../store/selectors/granulometry/currentSection/currentSection.selector';
import {
  goToSectionsEdit,
  goToSectionsIndividual
} from '../../../../store/actions/navigations.actions';
import { selectProjectId } from '../../../../store/selectors/project';
import { SectionDetails } from './SectionDetails';
import SectionMap from '../../../ui/SectionMap';
import { SpecialLabel } from '../../../ui/SpecialLabel';
import { isSectionALodgment } from '../../../../domain/granulometry/sections/lodgmentSections/LodgmentSection';

export const SectionsPanelView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const projectId = useSelector(selectProjectId);
  const currentSectionId = useSelector(selectCurrentSectionIdFromRoute);
  const currentSection = useSelector(selectCurrentSectionGranulometry);

  if (!projectId) return null;

  return (
    <div className="panel panel-sections">
      <CardsList className={classNames('sections-panel')}>
        <PanelTitle title={t('Sections')} />
        <Tabs
          selectedIndex={currentSectionId ? 1 : 0}
          onSelect={(index) => {
            index
              ? dispatch(goToSectionsIndividual(projectId, 'NONE'))
              : dispatch(goToSectionsEdit(projectId));
          }}>
          <Card>
            <TabList>
              <Tab key={0}>{t('Manage & insert')}</Tab>
              <Tab key={1}>{currentSection ? currentSection.title : t('Individual section')}</Tab>
            </TabList>
          </Card>
          <TabPanel>
            {' '}
            <Scrollbars
              autoHide={true}
              autoHideTimeout={1000}
              autoHideDuration={200}
              autoHeight={true}
              autoHeightMin="100%"
              autoHeightMax="100%"
              style={{ width: '100%' }}
              renderTrackVertical={(properties: any) => (
                <div {...properties} className="track-vertical" />
              )}>
              <Card>
                <Note>
                  Cet espace permettra d’insérer des sections par glisser-déposer (drag & drop) dans
                  les différents niveaux de la modélisation (building map). Il sera aussi possible
                  d’éditer la palette de modèle de sections disponibles.
                </Note>
              </Card>
            </Scrollbars>
          </TabPanel>
          <TabPanel>
            {' '}
            <Scrollbars
              autoHide={true}
              autoHideTimeout={1000}
              autoHideDuration={200}
              autoHeight={true}
              autoHeightMin="100%"
              autoHeightMax="100%"
              style={{ width: '100%' }}
              renderTrackVertical={(properties: any) => (
                <div {...properties} className="track-vertical" />
              )}>
              {currentSection && (
                <Card>
                  <SpecialLabel label={t('Section map')} />
                  <SectionMap
                    id={currentSection.id}
                    sectionIconProps={currentSection.icon}
                    rooms={isSectionALodgment(currentSection) ? currentSection.rooms : undefined}
                    // infoList={['', '', roundWith2Decimal(currentSection.displayedSurface) + 'm2']}
                  />
                </Card>
              )}
              {currentSection && process.env.GB_FEAT_GFS === 'true' && <SectionDetails />}
              <Card>
                <Note>
                  Cet espace permettra d’éditer une section en particulier soit en la recherchant
                  soit en la selectionnant directement sur la modélisation (building map).
                </Note>
              </Card>
            </Scrollbars>
          </TabPanel>
        </Tabs>
      </CardsList>
    </div>
  );
};
