import * as R from 'ramda';
import { Project } from '../../project/Project';
import { mapCases } from '../../project/map/mapCases';
import { CaseSpecification } from '../../specification/cases/CaseSpecification';
import { removeCaseProperty } from '../utils/removeCaseProperty';
import { getDefaultUnitConvertedPropertyValue } from '../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import technicalSection from '../../granulometry/sections/technicalPremiseSections/technicalSection';

export const migrateProject22To23 = (project: Project): Project =>
  mapCases((eachCase) =>
    R.pipe<
      [CaseSpecification],
      CaseSpecification,
      CaseSpecification,
      CaseSpecification,
      CaseSpecification,
      CaseSpecification
    >(
      R.ifElse(
        R.propSatisfies((value: boolean) => value === true, 'technicalSection'),
        R.over(R.lensProp('levels'), (levels) => {
          const groundLevelIndex = levels.findIndex((lS) => lS.level === 0);
          if (groundLevelIndex >= 0) {
            levels[groundLevelIndex] = {
              ...levels[groundLevelIndex],
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              // @ts-ignore
              technicalPremises: [technicalSection({})]
            };
            return levels;
          } else {
            return [
              ...levels,
              {
                level: 0,
                technicalPremises: [
                  technicalSection({
                    surface:
                      (getDefaultUnitConvertedPropertyValue(
                        eachCase,
                        'technicalSectionSurface'
                      ) as number) || undefined,
                    width:
                      (getDefaultUnitConvertedPropertyValue(
                        eachCase,
                        'technicalSectionWidth'
                      ) as number) || undefined,
                    exposureRate: getDefaultUnitConvertedPropertyValue(
                      eachCase,
                      'technicalSectionExposureRate'
                    )
                      ? (getDefaultUnitConvertedPropertyValue(
                          eachCase,
                          'technicalSectionExposureRate'
                        ) as number) * 4
                      : undefined
                  })
                ]
              }
            ];
          }
        }),
        R.identity
      ),
      R.dissoc('technicalSection'),
      (cS) => removeCaseProperty(cS, 'technicalSectionSurface'),
      (cS) => removeCaseProperty(cS, 'technicalSectionWidth'),
      (cS) => removeCaseProperty(cS, 'technicalSectionExposureRate')
    )(eachCase)
  )(project);
