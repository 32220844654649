import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { LevelGranulometry, LevelGranulometryFullFilled } from '../../../../LevelGranulometry';
import { getCaseUpperLevelsGrossFloorSurfaceDistribution } from '../../../../../cases/queries/getCaseUpperLevelsGrossFloorSurfaceDistribution';
import { getTopLevelAdjustedGrossFloorSurfaceEff } from './getTopLevelAdjustedGrossFloorSurfaceEff';
import { mustTopLevelGfsEffBeAdjusted } from './mustTopLevelGfsEffBeAdjusted';

export const getTopLevelDistributedGrossFloorSurfaceEff = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled | LevelGranulometry
): number | undefined => {
  if (mustTopLevelGfsEffBeAdjusted(caseGranulometry, levelGranulometry)) {
    return getTopLevelAdjustedGrossFloorSurfaceEff(caseGranulometry, levelGranulometry);
  } else {
    return getCaseUpperLevelsGrossFloorSurfaceDistribution(caseGranulometry).find(
      (l) => l.level === levelGranulometry.level
    )?.gfsEff;
  }
};
