import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  goToCase,
  goToCaseFifthFacade,
  goToCaseInfrastructure
} from '../../../../../store/actions/navigations.actions';
import { useParams } from 'react-router';
import { IconObject } from '../../../../ui/Icons/iconObject';
import { selectCurrentCaseLabel } from '../../../../../store/selectors/project/currentCaseLabel.selector';
import { InsulationRates } from './InsulationRates';
import { OutsideWallTypeRates } from './OutsideWallTypeRates';
import { VerticalDistribution } from './VerticalDistribution';
import { GroundLevel } from './GroundLevel';
import { CarpentrySpecifications } from './CarpentrySpecifications';
import { getCaseSuperstructureTriggerId } from '../../../../../routes/toolboxPanels/triggers';
import { Form } from '../../../../ui/Form';
import { Window } from '../../../../ui/Window';
import { CaseGranulometryEditSuperstructureRouteParams } from '../../../../../routes/toolboxPanels/cases';

export const CaseSuperstructureWindow = () => {
  const { projectId, caseId } = useParams<CaseGranulometryEditSuperstructureRouteParams>();
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClickClose = React.useCallback(() => {
    if (caseId && projectId) dispatch(goToCase(projectId, caseId));
  }, [dispatch, projectId, caseId]);

  const handleClickPrev = React.useCallback(() => {
    caseId && projectId && dispatch(goToCaseFifthFacade(projectId, caseId));
  }, [dispatch, projectId, caseId]);

  const handleClickNext = React.useCallback(() => {
    caseId && projectId && dispatch(goToCaseInfrastructure(projectId, caseId));
  }, [dispatch, projectId, caseId]);

  if (!caseId || !caseLabel) return null;

  return (
    <Window
      title={t('Superstructure')}
      triggerId={getCaseSuperstructureTriggerId(caseLabel)}
      topButtons={
        <>
          <IconObject iconName="arrowTop" type="menu" onClick={handleClickPrev} />
          <IconObject iconName="arrowBottom" type="menu" onClick={handleClickNext} />
          <IconObject iconName="close" type="menu" onClick={handleClickClose} />
        </>
      }>
      <Form>
        <VerticalDistribution />
        <GroundLevel />
        <OutsideWallTypeRates />
        <InsulationRates />
        <CarpentrySpecifications />
      </Form>
    </Window>
  );
};

export default CaseSuperstructureWindow;
