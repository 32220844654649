import { createSelector, Selector } from 'reselect';
import { buildingRoute } from '../../../routes/toolboxPanels/buildings';
import { selectPath } from '../navigation/getPath.selector';
import { BuildingId } from '../../../domain/specification/buildings/BuildingSpecification';
import { State } from '../../reducers';
import { Maybe } from '../../../utils/Maybe';

export const selectBuildingIdFromRoute: Selector<State, Maybe<BuildingId>> = createSelector(
  [selectPath],
  (path) => buildingRoute.matchesPath(path).params?.buildingId
);
