import { getCaseDataSurfaceForSale } from '../../../../legacy/methodsForCases/getCaseDataSurfaceForSale';
import { createLevelFundationsSection } from '../sections/create/createLevelFundationsSection';
import { getBasementLevelParkingRate } from './getBasementLevelParkingRate';
import {
  LevelGranulometry,
  SectionInLevelGranulometryFullFilledContent
} from '../../LevelGranulometry';
import { createBasementLevelCarParkInfra } from './sections/create/createBasementLevelCarParkInfra';
import { createBasementLevelCarBoxInfra } from './sections/create/createBasementLevelCarBoxInfra';
import { createBasementLevelCirculationLaneInfra } from './sections/create/createBasementLevelCirculationLaneInfra';
import { getLevelFloorThickness } from '../getLevelFloorThickness';
import { createBasementLevelSasInfra } from './sections/create/createBasementLevelSasInfra';
import {
  getTotalCarBoxes,
  getTotalCarParks
} from '../../../../specification/lodgmentTypes/ParkingsSpecification';
import { isGroundLevel } from '../is/isGroundLevel';
import { CaseGranulometry } from '../../../cases/CaseGranulometry';
import { getDefaultUnitConvertedPropertyValue } from '../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { getBasementLevelOutsideWallsLinear } from './getBasementLevelOutsideWallsLinear';
import bearingInfra from '../../../sections/circulationSections/bearingInfra';
import { createBasementLevelVentilationGroupInfra } from './sections/create/createBasementLevelVentilationGroupInfra';
import { createBasementLevelStairsShaftInfra } from './sections/create/createBasementLevelStairsShaftInfra';
import { createBasementLevelElevatorShaftInfra } from './sections/create/createBasementLevelElevatorShaftInfra';
import { createBasementLevelRampInfra } from './sections/create/createBasementLevelRampInfra';
import * as R from 'ramda';
import { getCaseStairShaftSectionsCount } from '../../../cases/queries/sections/counts/getCaseStairShaftSectionsCount';
import { getCaseElevatorShaftSectionsCount } from '../../../cases/queries/sections/counts/getCaseElevatorShaftSectionsCount';
import { getLevelId } from '../getLevelId';
import { createBasementLevelSmokeExtractionDuctsInfra } from './sections/create/createBasementLevelSmokeExtractionDuctsInfra';
import { getCaseSmokeExtractionDuctSectionsCount } from '../../../cases/queries/sections/counts/getCaseSmokeExtractionDuctSectionsCount';
import { setLevelSectionsIds } from '../sections/setLevelSectionIds';
import { getCaseLodgmentSectionsCount } from '../../../cases/queries/sections/counts/getCaseLodgmentSectionsCount';
import { getSectionDisplayedSurface } from '../../../sections/queries/surfaces/getSectionDisplayedSurface';
import { ParkingSection } from '../../../sections/parkingSections/ParkingSection';
import { getCaseOutsideStairShaftSectionsCount } from '../../../cases/queries/sections/counts/getCaseOutsideStairShaftSectionsCount';
import { OutsideStairsShaftInfraSection } from '../../../sections/outsideSections/outsideStairShaftInfra';
import { createBasementLevelOutsideStairsShaftInfraSection } from './sections/create/createBasementLevelOutsideStairsShaftInfraSection';
import { Surface } from '../../../../specification/Surface';

export const createBasementLevel = (
  levelData: any,
  caseGranulometry: CaseGranulometry,
  lowestLevel: LevelGranulometry
): LevelGranulometry => {
  // Initiate parkings level content
  let basementContent = [] as SectionInLevelGranulometryFullFilledContent[];
  // Initiate parkings surface
  let basementSurface = 0;
  // Initiate parkings current level level (position)
  const basementLevelIndex = lowestLevel.level - 1;

  // Add smoke extraction ducts (it's the easier way to align the draw of building map)
  if (process.env.GB_FEAT_SMOKE_EXTRACTION !== 'false') {
    R.times((i) => {
      const smokeExtractionDucts = createBasementLevelSmokeExtractionDuctsInfra(
        caseGranulometry,
        'smokeExtractionDucts' + i + caseGranulometry.labelIndex
      );
      smokeExtractionDucts.openedAbove = true;
      if (basementLevelIndex !== caseGranulometry.initialSpecifications.basementLevelCount * -1) {
        smokeExtractionDucts.openedBelow = true;
      }
      basementSurface += smokeExtractionDucts.displayedSurface;
      basementContent.push(smokeExtractionDucts);
    })(getCaseSmokeExtractionDuctSectionsCount(caseGranulometry));
  }

  // Add stairs shafts
  R.times((i) => {
    const stairs = createBasementLevelStairsShaftInfra(
      caseGranulometry,
      'stairs' + i + caseGranulometry.labelIndex
    );
    stairs.openedAbove = true;
    if (basementLevelIndex !== caseGranulometry.initialSpecifications.basementLevelCount * -1) {
      stairs.openedBelow = true;
    }
    basementSurface += stairs.displayedSurface;
    basementContent.push(stairs);
  })(getCaseStairShaftSectionsCount(caseGranulometry));

  // Add elevator shafts
  R.times((i) => {
    const elevator = createBasementLevelElevatorShaftInfra(
      caseGranulometry,
      'elevator' + i + caseGranulometry.labelIndex
    );
    elevator.openedAbove = true;
    if (basementLevelIndex !== caseGranulometry.initialSpecifications.basementLevelCount * -1) {
      elevator.openedBelow = true;
    }
    basementSurface += elevator.displayedSurface;
    basementContent.push(elevator);
  })(getCaseElevatorShaftSectionsCount(caseGranulometry));

  // Add ramp
  if (
    caseGranulometry.initialSpecifications.builtInRamp &&
    caseGranulometry.initialSpecifications.basementLevelCount !== 0
  ) {
    const ramp = createBasementLevelRampInfra(
      caseGranulometry,
      'ramp' + caseGranulometry.labelIndex
    );
    ramp.openedAbove = true;
    if (basementLevelIndex !== caseGranulometry.initialSpecifications.basementLevelCount * -1) {
      ramp.openedBelow = true;
    }
    basementSurface += ramp.displayedSurface;
    basementContent.push(ramp);
  }

  // Add ventilation group infra
  if (caseGranulometry.initialSpecifications.basementLevelCount > 1) {
    const ventilationGroupInfra = {
      ...createBasementLevelVentilationGroupInfra(
        caseGranulometry,
        'ventilationGroupInfra' + caseGranulometry.labelIndex
      ),
      openedAbove: lowestLevel.level !== 0,
      openedBelow:
        lowestLevel.level - 1 !== caseGranulometry.initialSpecifications.basementLevelCount * -1
    };
    basementContent.push(ventilationGroupInfra);
    basementSurface += ventilationGroupInfra.displayedSurface;
  }

  basementContent = [
    ...basementContent,
    ...R.times(R.identity, getCaseOutsideStairShaftSectionsCount(caseGranulometry)).reduce(
      (acc, l, sectionIndex) => {
        const outsideStairsShaftInfra = {
          ...createBasementLevelOutsideStairsShaftInfraSection(
            caseGranulometry,
            'outsideStairsShaft' + sectionIndex + caseGranulometry.labelIndex,
            'C' + caseGranulometry.labelIndex + 'BL' + lowestLevel.level + 'S' + sectionIndex
          ),
          // firstInGroup: isHighestLevel(caseGranulometry, levelGranulometry),
          // lastInGroup: isGroundLevel(levelGranulometry),
          openedAbove: lowestLevel.level !== 0,
          openedBelow:
            lowestLevel.level - 1 !== caseGranulometry.initialSpecifications.basementLevelCount * -1
        };
        basementSurface += outsideStairsShaftInfra.displayedSurface;
        return [...acc, outsideStairsShaftInfra];
      },
      [] as OutsideStairsShaftInfraSection[]
    )
  ];

  // Add bearing
  const bearingInfraSurface = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'bearingInfraSurface'
  ) as number;
  const bearingInfraWidth = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'bearingInfraWidth'
  ) as number;
  const bearingInfraExposureRate = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'bearingInfraExposureRate'
  ) as number;
  const newBearing = bearingInfra({
    surface: bearingInfraSurface,
    width: bearingInfraWidth,
    exposureRate: bearingInfraExposureRate
  });
  basementSurface += bearingInfraSurface;
  basementContent.push(newBearing);

  // Add sas
  const sas = createBasementLevelSasInfra(caseGranulometry);
  basementSurface += sas.surface;
  basementContent.push(sas);
  // Add parking sections
  const basementParkingSections = [] as ParkingSection[];
  const basementParkingRate = getBasementLevelParkingRate(
    basementLevelIndex,
    caseGranulometry.initialSpecifications.basementLevelsData
  );

  // Add technical premises
  const basementTechnicalPremiseSections =
    caseGranulometry.initialSpecifications.basementLevelsData.find(
      (l) => l.level === basementLevelIndex
    )?.technicalPremiseSections || [];
  basementContent = [...basementContent, ...basementTechnicalPremiseSections];
  basementSurface += basementTechnicalPremiseSections.reduce(
    (acc, technicalPremiseSection) => acc + getSectionDisplayedSurface(technicalPremiseSection),
    0
  );

  // Add car boxes :
  const caseCarBoxesCount = getTotalCarBoxes(
    caseGranulometry.initialSpecifications.parkingData,
    getCaseLodgmentSectionsCount(caseGranulometry)
  );
  let levelCarBoxesCount = 0;
  if (caseCarBoxesCount !== 0) {
    levelCarBoxesCount = (caseCarBoxesCount * basementParkingRate) / 100;
    // Note : It works like this only because there is a max of 2 basement level
    levelCarBoxesCount = isGroundLevel(lowestLevel)
      ? Math.ceil(levelCarBoxesCount)
      : Math.floor(levelCarBoxesCount);
    for (let i = 0; i < levelCarBoxesCount; i++) {
      const carBox = createBasementLevelCarBoxInfra(caseGranulometry);
      if (!isGroundLevel(lowestLevel)) {
        carBox.beamHeight = 0;
        carBox.beamWidth = 0;
      }
      basementSurface += carBox.displayedSurface;
      basementParkingSections.push(carBox);
    }
  }

  // Add car parks :
  const caseCarParksCount = getTotalCarParks(
    caseGranulometry.initialSpecifications.parkingData,
    getCaseDataSurfaceForSale(caseGranulometry.initialSpecifications)
  );
  let levelCarParksCount = 0;
  if (caseCarParksCount !== 0) {
    levelCarParksCount = (caseCarParksCount * basementParkingRate) / 100;
    // Note : It works like this only because there is a max of 2 basement level
    levelCarParksCount = isGroundLevel(lowestLevel)
      ? Math.ceil(levelCarParksCount)
      : Math.floor(levelCarParksCount);
    for (let i = 0; i < levelCarParksCount; i++) {
      const carPark = createBasementLevelCarParkInfra(caseGranulometry);
      if (!isGroundLevel(lowestLevel)) {
        carPark.beamHeight = 0;
        carPark.beamWidth = 0;
      }
      carPark.openedPrev = i !== 0;
      carPark.openedNext = levelCarParksCount !== i + 1;
      basementSurface += carPark.displayedSurface;
      basementParkingSections.push(carPark);
    }
  }

  // Add circulation lane
  const circulationLaneBeforeSpecifiedRBS = createBasementLevelCirculationLaneInfra(
    caseGranulometry,
    basementParkingSections,
    bearingInfraSurface,
    sas.displayedSurface,
    undefined,
    undefined
  );
  const basementSurfaceBeforeSpecifiedRBS = new Surface(
    basementSurface +
      (circulationLaneBeforeSpecifiedRBS !== false
        ? circulationLaneBeforeSpecifiedRBS.displayedSurface
        : 0)
  );
  if (levelCarBoxesCount + levelCarParksCount > 0) {
    const lane = createBasementLevelCirculationLaneInfra(
      caseGranulometry,
      basementParkingSections,
      bearingInfraSurface,
      sas.displayedSurface,
      levelData.realBuiltSurface,
      basementSurfaceBeforeSpecifiedRBS
    );
    if (lane !== false && lane.displayedSurface > 0) {
      // Is there the parking count is not 0
      basementSurface += lane.displayedSurface;
      basementContent.push(lane);
    }
  }

  // Merge with parking sections
  basementContent = basementContent.concat(basementParkingSections);

  // Create basement level
  const basementLevel = {
    level: lowestLevel.level - 1,
    surface: basementSurface,
    displayedSurface: basementSurface,
    realBuiltSurface: basementSurface,
    basementSurfaceBeforeSpecifiedRBS
  } as LevelGranulometry;
  basementLevel.id = getLevelId(caseGranulometry, basementLevel);
  basementLevel.ceilingHeight =
    levelData.ceilingHeight ||
    (getDefaultUnitConvertedPropertyValue(
      caseGranulometry.initialSpecifications,
      'basementLevelCeilingHeight'
    ) as number);
  if (isGroundLevel(lowestLevel) && basementLevel.ceilingHeight) {
    basementLevel.ceilingHeight +=
      Math.max(
        getDefaultUnitConvertedPropertyValue(
          caseGranulometry.initialSpecifications,
          'carBoxBeamHeight'
        ) as number,
        getDefaultUnitConvertedPropertyValue(
          caseGranulometry.initialSpecifications,
          'carParkBeamHeight'
        ) as number
      ) / 100;
  }

  // Add properties
  basementLevel.concreteSlabThickness = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'basementLevelConcreteSlabThickness'
  ) as number;
  basementLevel.cementScreedThickness = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'basementLevelCementScreedThickness'
  ) as number;
  basementLevel.floorThickness = getLevelFloorThickness(caseGranulometry);
  basementLevel.wallThickness = 0;
  basementLevel.content = basementContent;
  basementLevel.outsideWallsLinear = getBasementLevelOutsideWallsLinear(
    caseGranulometry.initialSpecifications,
    levelCarBoxesCount,
    levelCarParksCount,
    caseGranulometry.initialSpecifications.basementLevelCount
  );
  basementLevel.formworkBenchLength =
    levelData.formworkBenchLength !== undefined ? levelData.formworkBenchLength : 1;
  basementLevel.contiguousAgainstBuiltLinear =
    levelData.contiguousAgainstBuiltLinear !== undefined
      ? levelData.contiguousAgainstBuiltLinear
      : 0;
  basementLevel.contiguousUnderBuiltLinear =
    levelData.contiguousUnderBuiltLinear !== undefined ? levelData.contiguousUnderBuiltLinear : 0;
  basementLevel.contiguousNotBuiltLinear =
    levelData.contiguousNotBuiltLinear !== undefined ? levelData.contiguousNotBuiltLinear : 0;

  // Create fundations if necessary
  if (lowestLevel.surface && lowestLevel.surface > basementSurface) {
    basementLevel.fundations = [
      createLevelFundationsSection(lowestLevel.surface - basementSurface, 'superficial')
    ];
  }

  return setLevelSectionsIds(caseGranulometry, basementLevel);
};
