import * as R from 'ramda';
import { Granulometry } from '../Granulometry';
import { BuildingGranulometry } from '../buildings/BuildingGranulometry';
import {
  CaseGranulometry,
  SurfaceByLevel,
  getCaseRealBuiltSurfaceByLevel
} from '../cases/CaseGranulometry';
import { getBuildingId } from '../buildings/queries/getBuildingId';
import { getCaseId } from '../cases/queries/get/getCaseId';
import { CaseId } from '../../specification/cases/CaseSpecification';
import { BuildingId } from '../../specification/buildings/BuildingSpecification';

const doCaseGranulometry = (eachCase: CaseGranulometry): SurfaceByLevel =>
  getCaseRealBuiltSurfaceByLevel(eachCase);

const extractFromCases = (
  cases: Record<string, CaseGranulometry>
): Record<string, SurfaceByLevel> => R.mapObjIndexed(doCaseGranulometry, cases);

const indexCases = (cases: CaseGranulometry[]): Record<CaseId, CaseGranulometry> =>
  R.indexBy(getCaseId, cases);

export type SurfaceByBuildingCaseAndLevel = Record<BuildingId, Record<CaseId, SurfaceByLevel>>;

const extractFromBuildings = (
  buildings: Record<BuildingId, BuildingGranulometry>
): SurfaceByBuildingCaseAndLevel =>
  R.mapObjIndexed(({ cases }) =>
    R.pipe<[CaseGranulometry[]], Record<CaseId, CaseGranulometry>, Record<CaseId, SurfaceByLevel>>(
      indexCases,
      extractFromCases
    )(cases)
  )(buildings);

const indexBuildings = R.indexBy(getBuildingId);

export const getRealBuiltSurfaceByBuildingCaseAndLevel = (granulometry: Granulometry) =>
  R.pipe(indexBuildings, extractFromBuildings)(granulometry);
