import { getSectionWallsLinear } from '../../getSectionWallsLinear';
import { getSectionTheoreticalOutsideWallsLinear } from './getSectionTheoreticalOutsideWallsLinear';
import { SectionWithExposureRate } from '../../../../SectionWithExposureRate';
import {
  LevelGranulometry,
  LevelGranulometryWithBearingRelativeSections
} from '../../../../../levels/LevelGranulometry';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { getSectionTheoreticalExposureRate } from '../../../exposureRate/getSectionTheoreticalExposureRate';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getSectionTheoreticalOutsideWallsLinearDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryWithBearingRelativeSections | LevelGranulometry,
  section: SectionWithExposureRate
): string => {
  let details: string[] = [];
  details = [
    ...details,
    '<b>' +
      roundWith2Decimal(getSectionWallsLinear(caseGranulometry, levelGranulometry, section)) +
      ' m</b> de linéaire de voiles exposés et non-exposés'
  ];
  details = [
    ...details,
    '<b>* ' +
      roundWith2Decimal(getSectionTheoreticalExposureRate(section) * 100) +
      ' %</b> de taux d’exposition'
  ];
  details = [
    ...details,
    '<b>= ' +
      roundWith2Decimal(
        getSectionTheoreticalOutsideWallsLinear(caseGranulometry, levelGranulometry, section)
      ) +
      ' mL</b>'
  ];
  return details.join('<br />');
};
