import { LevelGranulometry, LevelGranulometryFullFilled } from '../../../../LevelGranulometry';
import { getLevelRealBuiltSurfaceEff } from '../../../surfaces/getLevelRealBuiltSurfaceEff';
import { getTopLevelRealOutsideWallsSurface } from '../../walls/realWalls/getTopLevelRealOutsideWallsSurface';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { isGroundLevel } from '../../../is/isGroundLevel';
import { getCaseGranulometryDrawnFloorSpaceFeatureArea } from '../../../../../cases/queries/get/getCaseGranulometryDrawnFloorSpaceFeatureArea';
import { getTopLevelForcedGrossFloorSurfaceEff } from './getTopLevelForcedGrossFloorSurfaceEff';
import { getTopLevelDistributedGrossFloorSurfaceEff } from './getTopLevelDistributedGrossFloorSurfaceEff';

export const getTopLevelGrossFloorSurfaceEff = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled | LevelGranulometry,
  forceCalculationBasedOnRBS?: boolean
): number => {
  const caseFilledFloorSpace = getCaseGranulometryDrawnFloorSpaceFeatureArea(caseGranulometry);
  const floorSpaceIsFilledForThisCase =
    forceCalculationBasedOnRBS !== true && caseFilledFloorSpace !== undefined;
  if (floorSpaceIsFilledForThisCase) {
    if (isGroundLevel(levelGranulometry)) {
      return caseFilledFloorSpace as number;
    } else {
      return (
        getTopLevelForcedGrossFloorSurfaceEff(caseGranulometry, levelGranulometry) ||
        getTopLevelDistributedGrossFloorSurfaceEff(caseGranulometry, levelGranulometry) ||
        0
      );
    }
  }
  return (
    getLevelRealBuiltSurfaceEff(caseGranulometry, levelGranulometry) +
    getTopLevelRealOutsideWallsSurface(caseGranulometry, levelGranulometry)
  );
};
