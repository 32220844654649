import * as R from 'ramda';
import {
  ComEthJsonFeatureDwellingComposition,
  getComEthDwellingTypeInhabitant,
  getComEthJsonFeatureDwellingComposition
} from './getComEthJsonFeatureDwellingComposition';
import {
  ComEthJsonFeatureSuperstructure,
  getComEthJsonFeatureSuperstructure
} from './getComEthJsonFeatureSuperstructure';
import {
  ComEthJsonFeatureInfrastructure,
  getComEthJsonFeatureInfrastructure
} from './getComEthJsonFeatureInfrastructure';
import {
  ComEthJsonFeatureEnergeticSystems,
  getComEthJsonFeatureEnergeticSystems
} from './getComEthJsonFeatureEnergeticSystems';
import {
  ComEthJsonFeatureOutputs,
  getComEthJsonFeatureOutputs
} from './getComEthJsonFeatureOutputs';
import { BuildingGranulometry } from '../../../../domain/granulometry/buildings/BuildingGranulometry';
import { CaseGranulometry } from '../../../../domain/granulometry/cases/CaseGranulometry';
import { getCaseTopLevelsHeight } from '../../../../domain/granulometry/cases/queries/levels/getCaseTopLevelsHeight';
import { getCaseTopLevelsAverageHeight } from '../../../../domain/granulometry/cases/queries/levels/getCaseTopLevelsAverageHeight';
import { roundWith2Decimal } from '../../../../utils/round/roundWith2Decimal';
import { getCaseGroundLevelGrossFloorSurface } from '../../../../domain/granulometry/cases/queries/levels/surfaces/getCaseGroundLevelGrossFloorSurface';
import { BuildingId } from '../../../../domain/specification/buildings/BuildingSpecification';
import { CaseId } from '../../../../domain/specification/cases/CaseSpecification';
import { getCaseDataSurfaceForSale } from '../../../../domain/legacy/methodsForCases/getCaseDataSurfaceForSale';

export interface ComEthJsonFeatureProperties {
  featureType: 'Building'; // leave as it is (start TyPy structure)
  id: CaseId;
  gobuildCaseId: CaseId;
  gobuildBuildingId: BuildingId;
  building_group_name: string; // if groups are needed (ex: "bat1")
  building_type: 'new'; // set 'new' for now
  building_program: 'none'; // set 'none' for now
  main_building_use:
    | 'Individual_housing'
    | 'Collective_housing'
    | 'Hostel'
    | 'Office'
    | 'Shop'
    | 'Supermarket'
    | 'Public_facilities'
    | 'Primary_school'
    | 'Secondary_school'
    | 'University'
    | 'Sport_center'
    | 'Hospital'
    | 'Parking'
    | 'Other';
  ground_floor_retail: boolean; // set false for now
  dwelling_composition: ComEthJsonFeatureDwellingComposition; // Composition des logements (T1, T2, ...)
  total_floor_area: number; // total built surface (SHOB)
  height: number; // building total height (roofing included) in meters
  altitude: number | null; // in meters // TODO Antoine : Mettre à jour avec l’emprise au sol
  is_heated: boolean; // set true for now
  is_cooled: boolean; // /!\ IMPORTANT: par déaut false?
  is_dhw: boolean; // set true for now (central heating for sanitary water)
  number_of_inhabitants: number; // TODO Antoine: Vérifier règle avec Edouard
  level_height: number; // average celling height (excluding basement)
  superstructure: ComEthJsonFeatureSuperstructure;
  infrastructure: ComEthJsonFeatureInfrastructure;
  energetic_systems: ComEthJsonFeatureEnergeticSystems;
  name_building: string;
  ground_area: number; // surface au sol du RDC – TODO Antoine : Mettre à jour avec l’emprise au sol
  building_outputs: ComEthJsonFeatureOutputs; // On laisse car utilisé dans le code CSTB (laissé à null partout: ok)
  id_typy_building: string | null;
  is_restaurant: boolean; // set false for now
  is_valid: boolean; // set true for now
  construction_year: number; // set current year for now
}

const getComEthNumberOfInhabitants = (caseGranulometry: CaseGranulometry): number =>
  R.sum(getComEthDwellingTypeInhabitant(caseGranulometry));

export const getComEthJsonFeatureProperties = (
  buildingGranulometry: BuildingGranulometry,
  caseGranulometry: CaseGranulometry
): ComEthJsonFeatureProperties => ({
  featureType: 'Building',
  id: caseGranulometry.id,
  gobuildCaseId: caseGranulometry.id,
  gobuildBuildingId: buildingGranulometry.id,
  building_group_name: buildingGranulometry.name,
  building_type: 'new',
  building_program: 'none',
  main_building_use: 'Collective_housing',
  ground_floor_retail: false,
  dwelling_composition: getComEthJsonFeatureDwellingComposition(caseGranulometry),
  total_floor_area: roundWith2Decimal(
    getCaseDataSurfaceForSale(caseGranulometry.initialSpecifications)
  ),
  height: getCaseTopLevelsHeight(caseGranulometry),
  altitude: 100, // TODO
  is_heated: true,
  is_cooled: true,
  is_dhw: true,
  number_of_inhabitants: getComEthNumberOfInhabitants(caseGranulometry),
  level_height: getCaseTopLevelsAverageHeight(caseGranulometry),
  superstructure: getComEthJsonFeatureSuperstructure(caseGranulometry),
  infrastructure: getComEthJsonFeatureInfrastructure(caseGranulometry),
  energetic_systems: getComEthJsonFeatureEnergeticSystems(caseGranulometry),
  name_building: caseGranulometry.name,
  ground_area: roundWith2Decimal(getCaseGroundLevelGrossFloorSurface(caseGranulometry).value),
  building_outputs: getComEthJsonFeatureOutputs(),
  id_typy_building: null,
  is_restaurant: false,
  is_valid: true,
  construction_year: new Date().getFullYear()
});
