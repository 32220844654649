import { getDefaultUnitConvertedPropertyValue } from '../../../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getTopLevelOutsideStructuralWallsThicknessDetails = (
  caseGranulometry: CaseGranulometry
): string => {
  const outsideWallsThickness = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'outsideWallsThickness'
  ) as number;
  return (
    '<b>' +
    outsideWallsThickness * 100 +
    ' cm</b> d’épaisseur paramétrés dans les propriétés de la cage.'
  );
};
