import * as React from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import * as Sentry from '@sentry/react';
import { DefaultFallback } from 'DefaultFallback';
import { Card } from '../../../../components/ui/Card';
import { CardsList } from '../../../../components/ui/CardsList';
import { GranulometryView } from '../../../../components/business/granulometry/GranulometryView';
import { IconObject } from '../../../../components/ui/Icons/iconObject';
import { selectCanAddACase } from '../../../../store/selectors/core/canAddACase.selector';
import { caseAdded } from '../../../../store/actions/caseAdded.action';
import { caseRemoved } from '../../../../store/actions/caseRemoved.action';
import { selectCurrentCaseLabel } from '../../../../store/selectors/project/currentCaseLabel.selector';
import { goToCase } from '../../../../store/actions/navigations.actions';
import { selectProjectId } from '../../../../store/selectors/project/currentProjectId.selector';
import { selectCurrentBuildingId } from '../../../../store/selectors/project/currentBuildingId.selector';
import { selectCurrentBuildingLabel } from '../../../../store/selectors/project/currentBuildingLabel.selector';
import { selectIsGranulometryFresh } from '../../../../store/selectors/granulometry/isGranulometryFresh.selector';
import { selectIsGranulometryRendering } from '../../../../store/selectors/granulometry/isGranulometryRendering.selector';
import PanelTitle from '../PanelTitle';
import { CaseId } from '../../../../domain/specification/cases/CaseSpecification';
import { BuildingId } from '../../../../domain/specification/buildings/BuildingSpecification';
import { letterFromIndex } from '../../../../utils/letterFromIndex';
import { selectAllCasesProjections } from '../../../../store/selectors/projection/allCasesProjections.selector';
import { CaseGranulometryRouteParams } from '../../../../routes/toolboxPanels/cases';

interface CasesPanelProps {
  isToolboxEnlarged: boolean;
  setIsToolboxEnlarged: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CasesPanel = ({ isToolboxEnlarged, setIsToolboxEnlarged }: CasesPanelProps) => {
  const { caseId } = useParams<CaseGranulometryRouteParams>();

  const projectId = useSelector(selectProjectId);
  const allCases = useSelector(selectAllCasesProjections);
  const canAddACase = useSelector(selectCanAddACase);
  const currentBuildingId = useSelector(selectCurrentBuildingId) as BuildingId;
  const selectedCaseLabel = useSelector(selectCurrentCaseLabel);
  const selectedBuildingLabel = useSelector(selectCurrentBuildingLabel);
  const isGranulometryFresh = useSelector(selectIsGranulometryFresh);
  const isGranulometryRendering = useSelector(selectIsGranulometryRendering);

  const dispatch = useDispatch();
  const onCaseAdded = React.useCallback(
    () => currentBuildingId && dispatch(caseAdded(currentBuildingId)),
    [dispatch, currentBuildingId]
  );
  const onCaseRemoved = React.useCallback(
    () => dispatch(caseRemoved(caseId as string)),
    [dispatch, caseId]
  );
  const onCaseSelection = React.useCallback(
    (id: CaseId) => {
      if (projectId) {
        dispatch(goToCase(projectId, id));
      }
    },
    [dispatch, projectId]
  );

  const { t } = useTranslation();

  if (!allCases || !caseId) return null;

  return (
    <Sentry.ErrorBoundary fallback={DefaultFallback} showDialog>
      <div className="panel panel-cases">
        <CardsList className={'cases-panel'}>
          <PanelTitle title={t('Cases')} />
          <Tabs
            selectedIndex={caseId ? allCases.findIndex((c) => c.id === caseId) : undefined}
            onSelect={(index) => onCaseSelection(allCases[index].id)}>
            <Card>
              <TabList>
                {allCases &&
                  allCases.map((eachCase) => (
                    <Tab
                      key={eachCase.id}
                      disabled={!isGranulometryFresh || isGranulometryRendering}>
                      {eachCase.buildingIndex + 1 + letterFromIndex(eachCase.indexInBuilding)}
                    </Tab>
                  ))}
              </TabList>
              {caseId && (
                <div className="add-remove-buttons">
                  <IconObject
                    iconName="remove"
                    type="menu"
                    disabled={allCases && allCases.length === 1}
                    onClick={() => {
                      if (
                        confirm(
                          t('Are you sure you want to delete case {{caseLabel}}?', {
                            caseLabel: selectedCaseLabel
                          })
                        )
                      ) {
                        setImmediate(onCaseRemoved);
                      }
                    }}
                    title={t('Remove case {{caseLabel}}', { caseLabel: selectedCaseLabel })}
                  />
                  <IconObject
                    iconName="add"
                    type="menu"
                    disabled={!canAddACase}
                    onClick={() => {
                      setImmediate(onCaseAdded);
                    }}
                    title={t('Add a case in building {{buildingLabel}}', {
                      buildingLabel: selectedBuildingLabel
                    })}
                  />
                </div>
              )}
            </Card>
            {allCases &&
              allCases.map((caseSpecification) => (
                <TabPanel key={caseSpecification.id}>
                  <GranulometryView
                    isToolboxEnlarged={isToolboxEnlarged}
                    setIsToolboxEnlarged={setIsToolboxEnlarged}
                  />
                </TabPanel>
              ))}
          </Tabs>
        </CardsList>
      </div>
    </Sentry.ErrorBoundary>
  );
};
