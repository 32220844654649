import { Position } from '@turf/helpers/dist/js/lib/geojson';
import { FacadeSpecification } from './FacadeSpecification';
import { Feature, FeatureCollection, Polygon } from 'geojson';

export interface FloorSpaceGeometry {
  area?: number;
  perimeter: number;
}

export interface FloorSpaceProperties extends FloorSpaceGeometry {
  name: string;
  origin: Position;
  facades: FacadeSpecification[];
  center: Position;
  shape?: {
    isSelected?: boolean;
    lock?: boolean;
  };
  drawHelper?: {
    id: string;
  };
}

export type FloorSpaceFeatureId = string;

export interface MaybeFloorSpaceFeature
  extends Partial<Feature<Polygon, Partial<FloorSpaceProperties>>> {
  id: FloorSpaceFeatureId;
}
export interface FloorSpaceFeature extends Feature<Polygon, FloorSpaceProperties> {
  id: FloorSpaceFeatureId;
}

export interface FloorSpaceCollection extends FeatureCollection {
  features: Array<FloorSpaceFeature>;
}

export const isFloorSpaceFeature = (feature?: Feature): feature is FloorSpaceFeature => {
  return !!(feature?.properties?.facades && feature.geometry);
};

export const isFeatureCollectionOfFloorSpaces = (
  featureCollection?: FeatureCollection
): featureCollection is {
  type: 'FeatureCollection';
  features: Array<FloorSpaceFeature>;
} => {
  if (!featureCollection?.features || featureCollection.features.length === 0) return false;
  return isFloorSpaceFeature(featureCollection.features[0]);
};

export const hasFloorSpaceDrawHelper = (
  featureCollection: FeatureCollection,
  drawHelperLayerName: string
): boolean =>
  isFeatureCollectionOfFloorSpaces(featureCollection) &&
  featureCollection.features
    .map((feature: FloorSpaceFeature) => feature.properties.drawHelper?.id)
    .filter(Boolean)
    .includes(drawHelperLayerName);

export const getFloorSpaceByDrawHelperId = (
  drawHelperLayerName: string,
  featureCollection?: FeatureCollection
) => {
  return (
    isFeatureCollectionOfFloorSpaces(featureCollection) &&
    featureCollection.features.find(
      (feature: FloorSpaceFeature) => feature.properties.drawHelper?.id === drawHelperLayerName
    )
  );
};
