import React from 'react';
import { MaybeFloorSpaceFeature } from '../../../../../../domain/specification/floorSpace/FloorSpaceFeature';
import { Input } from '../../../../../ui/input/Input';
import { FormGroup } from '../../../../../ui/FormGroup';
import { roundWith2Decimal } from '../../../../../../utils/round/roundWith2Decimal';
import { useTranslation } from 'react-i18next';
import { roundForSurfacesInputs } from '../../../../../../utils/round/roundForSurfacesInputs';

interface FloorSpaceFeatureFormProps {
  floorSpaceFeature: MaybeFloorSpaceFeature;
}

export const FloorSpaceFeatureForm = ({ floorSpaceFeature }: FloorSpaceFeatureFormProps) => {
  const { t } = useTranslation();

  return (
    <FormGroup horizontal={true}>
      <div>
        <Input
          label={t('floorSpaceWindow.featureForm.gfsEff.label')}
          name={`floorSpaceFeature${floorSpaceFeature.properties?.name || 'UNKNOWN'}GfsEff`}
          type="number"
          suffix="m&#178;"
          value={
            floorSpaceFeature.properties?.area
              ? roundForSurfacesInputs(floorSpaceFeature.properties.area)
              : undefined
          }
          className="highlighted"
          asCustomValue={true}
          disabled={true}
        />
      </div>
      <div>
        <Input
          label={t('floorSpaceWindow.featureForm.perimeter.label')}
          name={`floorSpaceFeature${floorSpaceFeature.properties?.name || 'UNKNOWN'}Perimeter`}
          type="number"
          suffix="mL"
          value={
            floorSpaceFeature.properties?.perimeter
              ? roundWith2Decimal(floorSpaceFeature.properties?.perimeter)
              : undefined
          }
          disabled={true}
        />
      </div>
    </FormGroup>
  );
};
