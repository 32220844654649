import i18next from 'i18next';
import { LodgmentSection } from '../sections/lodgmentSections/LodgmentSection';
import { HallSection } from '../sections/circulationSections/hall';
import { ElevatorShaftSection } from '../sections/circulationSections/elevatorShaft';
import { StairsShaftSection } from '../sections/circulationSections/stairsShaft';
import { RampSection } from '../sections/circulationSections/ramp';
import { BearingSection } from '../sections/circulationSections/bearing';
import { FundationsSection } from '../sections/fundationsSections/FundationsSection';
import { OffsetSlabSection } from '../sections/slabSections/offsetSlab';
import { OutsideSlabSection } from '../sections/slabSections/outsideSlab';
import { RoofingSection } from '../sections/roofingSections/RoofingSection';
import { CirculationLaneInfraSection } from '../sections/circulationSections/circulationLaneInfra';
import { BearingInfraSection } from '../sections/circulationSections/bearingInfra';
import { SasInfraSection } from '../sections/circulationSections/sasInfra';
import { CarParkInfraSection } from '../sections/parkingSections/carParkInfra';
import { CarBoxInfraSection } from '../sections/parkingSections/carBoxInfra';
import { RampInfraSection } from '../sections/circulationSections/rampInfra';
import { ElevatorShaftInfraSection } from '../sections/circulationSections/elevatorShaftInfra';
import { StairsShaftInfraSection } from '../sections/circulationSections/stairsShaftInfra';
import { SmokeExtractionDuctsSection } from '../sections/circulationSections/smokeExtractionDucts';
import { TechnicalPremiseSection } from '../sections/technicalPremiseSections/TechnicalPremiseSection';
import { CommonPremiseSection } from '../sections/commonPremiseSections/CommonPremiseSection';
import { ShopSection } from '../sections/shopSection/shopSection';
import { OfficeSection } from '../sections/officeSection/officeSection';
import { SmokeExtractionDuctsInfraSection } from '../sections/circulationSections/smokeExtractionDuctsInfra';
import { VentilationGroupInfraSection } from '../sections/circulationSections/ventilationGroupInfra';
import { OutsideStairsShaftSection } from '../sections/outsideSections/outsideStairShaft';
import { Surface } from '../../specification/Surface';

export interface LevelGranulometryInitialEntries {
  level: number;
  realBuiltSurface?: number;
  surfaceForSale?: number;
  technicalPremiseSections?: TechnicalPremiseSection[];
  commonPremiseSections?: CommonPremiseSection[];
  shopSections?: ShopSection[];
  officeSections?: OfficeSection[];
  waterBearingTechnicalSheathEnabled: boolean;
  generalBearingTechnicalSheathEnabled: boolean;
  gasBearingTechnicalSheathEnabled: boolean;
  electricityBearingTechnicalSheathEnabled: boolean;
  communicationBearingTechnicalSheathEnabled: boolean;
}

export interface LevelPeripheralReinforcementsSpecifications {
  outsideWallsLinear?: number;
  formworkBenchLength?: number;
  contiguousAgainstBuiltLinear?: number;
  contiguousUnderBuiltLinear?: number;
  contiguousNotBuiltLinear?: number;
}

export interface SectionWrapper {
  theoricalWidth: number; // the surface to wrap divided by 2
  firstWrapperInnerWidth: number; // incrementer to get the real width of the first inner wrapper
  secondWrapperInnerWidth: number; // incrementer to get the real width of the second inner wrapper
  realWidth: number; // the real width of the wrapper
  overflow: number; // the overflow width of the last section of the first wrapper
}

export interface LevelGranulometryRulersEntries {
  rbsRulerWidth?: number;
  rbsRulerLeft?: number;
  sfsRulerWidth?: number;
  sfsRulerLeft?: number;
  outsideRulerWidth?: number;
  outsideRulerLeft?: number;
  outsideSurface?: number;
}

export interface LevelGranulometryWithLodgments extends LevelGranulometryInitialEntries {
  content: LodgmentSection[];
}

export type SectionInLevelGranulometryWithBearingRelativeSectionsContent =
  | SmokeExtractionDuctsSection
  | StairsShaftSection
  | ElevatorShaftSection
  | LodgmentSection
  | CommonPremiseSection
  | ShopSection
  | OfficeSection
  | TechnicalPremiseSection
  | HallSection;

/* Note : contains sections with inside walls */
export interface LevelGranulometryWithBearingRelativeSections
  extends LevelGranulometryInitialEntries {
  content: SectionInLevelGranulometryWithBearingRelativeSectionsContent[];
}

export type SectionInLevelGranulometryFullFilledContent =
  | // Top levels
  ElevatorShaftSection
  | StairsShaftSection
  | SmokeExtractionDuctsSection
  | RampSection
  | BearingSection
  | LodgmentSection
  | CommonPremiseSection
  | ShopSection
  | OfficeSection
  | TechnicalPremiseSection
  | HallSection
  // Basement levels
  | ElevatorShaftInfraSection
  | StairsShaftInfraSection
  | RampInfraSection
  | BearingInfraSection
  | SasInfraSection
  | CirculationLaneInfraSection
  | SmokeExtractionDuctsInfraSection
  | CarParkInfraSection
  | CarBoxInfraSection
  | VentilationGroupInfraSection
  | OutsideStairsShaftSection;

export interface LevelGranulometryFullFilled extends LevelGranulometryInitialEntries {
  content: SectionInLevelGranulometryFullFilledContent[]; // TODO : remove ?
}

export type LevelId = string;

// TODO We should differentiate top levels, parkings and fundations typings
export interface LevelGranulometry
  extends LevelGranulometryInitialEntries,
    LevelGranulometryFullFilled,
    LevelGranulometryRulersEntries,
    LevelPeripheralReinforcementsSpecifications {
  id: LevelId;
  surface: number;
  displayedSurface: number;
  ceilingHeight: number;
  wallThickness: number;
  floorThickness: number;
  concreteSlabThickness?: number;
  cementScreedThickness?: number;
  partitionWallsLinear?: number;
  outsideWallsLinear?: number;
  displayedWallThickness?: number;
  roofing?: RoofingSection[];
  fundations?: FundationsSection[];
  offsetSlab?: OffsetSlabSection;
  outsideSlab?: OutsideSlabSection;
  // Basement levels
  sectionWrapper?: SectionWrapper;
  basementSurfaceBeforeSpecifiedRBS: Surface;
}

// FIXME Find a better name
export const getLevelLevel = (level: LevelGranulometry): number => level.level;

export const getLevelLabel = (levelIndex: number): string =>
  levelIndex === 0
    ? i18next.t('GF')
    : (levelIndex > 0 ? i18next.t('G+') : i18next.t('G-')) +
      (levelIndex > 0 ? levelIndex : levelIndex * -1);
