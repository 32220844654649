import * as R from 'ramda';
import { isBasementLevel } from '../../granulometry/levels/queries/is/isBasementLevel';
import { isRoofingLevel } from '../../granulometry/levels/queries/is/isRoofingLevel';
import { isTopLevel } from '../../granulometry/levels/queries/is/isTopLevel';
import { isFoundationLevel } from '../../granulometry/levels/queries/is/isFoundationLevel';
import { isSectionASmallSection } from '../../granulometry/sections/SmallSection';
import { CaseGranulometry } from '../../granulometry/cases/CaseGranulometry';
import { FundationsSection } from '../../granulometry/sections/fundationsSections/FundationsSection';
import { RoofingSection } from '../../granulometry/sections/roofingSections/RoofingSection';
import { getCaseLevels } from '../../granulometry/cases/queries/levels/getCaseLevels';
import { Section, SectionDrawn } from '../../granulometry/sections/Section';
import { getLevelFullFilledContent } from '../../granulometry/levels/queries/content/getLevelFullFilledContent';
import { SMALL_SECTION_SURFACE, SQUARE_METER_SCALE } from '../../../constants/appConstants';

/* Get the displayed level's data : integration of displaying adjustments */
export const resizeSmallSections = (caseGranulometry: CaseGranulometry) => {
  const caseLevels = getCaseLevels(caseGranulometry);
  const addedDisplayedSurfacesInTopLevels: number[] = Array(caseLevels.length).fill(0);
  const addedDisplayedSurfacesInBasementLevels: number[] = Array(caseLevels.length).fill(0);

  // For each level
  let i = -1;
  caseLevels.forEach((levelGranulometry, levelIndex) => {
    i++;
    getLevelFullFilledContent(levelGranulometry).forEach((section, sectionIndex) => {
      if (section.displayedSurface < SMALL_SECTION_SURFACE) {
        const addedSurface = SMALL_SECTION_SURFACE - section.displayedSurface;
        if (isSectionASmallSection(section)) {
          if (isTopLevel(caseGranulometry, levelGranulometry)) {
            addedDisplayedSurfacesInTopLevels[i] += addedSurface;
          } else if (isBasementLevel(caseGranulometry, levelGranulometry)) {
            addedDisplayedSurfacesInBasementLevels[i] += addedSurface;
          }
          (caseGranulometry.levels[levelIndex].content as (Section & SectionDrawn)[])[
            sectionIndex
          ].displayedSurface = section.displayedSurface;
          (caseGranulometry.levels[levelIndex].content as (Section & SectionDrawn)[])[
            sectionIndex
          ].surface = SMALL_SECTION_SURFACE;
        }
      }
    });
  });

  // Get the maximum added surface
  const maxAddedSurfaceInTopLevels = R.apply(Math.max, addedDisplayedSurfacesInTopLevels);
  // Get the maximum of surface to remove on top levels
  const maxAddedSurfaceInBasementLevels = R.apply(Math.max, addedDisplayedSurfacesInBasementLevels);

  // For each level (again)
  caseGranulometry.levels.forEach((levelGranulometry, levelIndex) => {
    if (isRoofingLevel(caseGranulometry, levelGranulometry)) {
      if (levelGranulometry.roofing) {
        levelGranulometry.roofing.forEach((section, sectionIndex) => {
          (caseGranulometry.levels[levelIndex].roofing as RoofingSection[])[sectionIndex].surface +=
            maxAddedSurfaceInTopLevels / (levelGranulometry.roofing as RoofingSection[]).length;
        });
      }
    } else if (isFoundationLevel(caseGranulometry, levelGranulometry)) {
      if (levelGranulometry.fundations) {
        levelGranulometry.fundations.forEach((section, sectionIndex) => {
          (caseGranulometry.levels[levelIndex].fundations as FundationsSection[])[
            sectionIndex
          ].surface +=
            (caseGranulometry.initialSpecifications.basementLevelCount === 0
              ? maxAddedSurfaceInTopLevels
              : maxAddedSurfaceInBasementLevels) /
            (levelGranulometry.fundations as FundationsSection[]).length;
        });
      }
    }
    // Update levels surface
    caseGranulometry.levels[levelIndex].surface +=
      isTopLevel(caseGranulometry, levelGranulometry) ||
      isRoofingLevel(caseGranulometry, levelGranulometry)
        ? maxAddedSurfaceInTopLevels
        : maxAddedSurfaceInBasementLevels;
    // Update levels rulers
    (caseGranulometry.levels[levelIndex].rbsRulerLeft as number) +=
      maxAddedSurfaceInTopLevels * SQUARE_METER_SCALE;
    (caseGranulometry.levels[levelIndex].sfsRulerLeft as number) +=
      maxAddedSurfaceInTopLevels * SQUARE_METER_SCALE;
    if (caseGranulometry.levels[levelIndex].outsideRulerLeft !== undefined) {
      (caseGranulometry.levels[levelIndex].outsideRulerLeft as number) +=
        maxAddedSurfaceInTopLevels * SQUARE_METER_SCALE;
    }
  });

  return caseGranulometry;
};
