import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ScrollSync } from 'react-scroll-sync';
import { useTranslation } from 'react-i18next';
import { Card } from 'components/ui/Card';
import { SpecialLabel } from 'components/ui/SpecialLabel';
import { HighlightOverlay } from 'components/layouts/HighlightOverlay';
import {
  getCaseEnergyAndNetworksTriggerId,
  getCaseFifthFacadeTriggerId,
  getCaseFloorSpaceTriggerId,
  getCaseGroundAdaptationsTriggerId,
  getCaseInfrastructureTriggerId,
  getCaseOtherPropertiesTriggerId,
  getCaseOutdoorsTriggerId,
  getCaseSuperstructureTriggerId,
  getLevelTriggerId,
  getLodgmentTypeTriggerId
} from '../../../routes/toolboxPanels/triggers';
import {
  goToCase,
  goToCaseEnergyAndNetworks,
  goToCaseFifthFacade,
  goToCaseGroundAdaptations,
  goToCaseInfrastructure,
  goToCaseOtherProperties,
  goToCaseOutdoors,
  goToCaseSuperstructure
} from 'store/actions/navigations.actions';
import { selectCurrentCaseIdFromRoute } from 'store/selectors/navigation/caseIdFromRoute.selector';
import { selectProjectId } from 'store/selectors/project';
import { selectCurrentlyEditedLevel } from 'store/selectors/navigation/currentlyEditedLevel.selector';
import { selectCurrentlyEditedLodgmentType } from 'store/selectors/specification/currentCase/currentlyEditedLodgmentType.selector';
import EditingStage from './EditingStage';
import TopAndBasementLevelsCount from './TopAndBasementLevelsCount';
import HeaderLine from './HeaderLine';
import DistributionLine from './DistributionLine';
import { TopLevelLines } from './TopLevelLines';
import BasementLevelLines from './BasementLevelLines';
import UnitSwitchersLine from './UnitSwitchersLine';
import { TotalTopLevelLines } from './TotalTopLevelLines';
import TotalBasementLevelLines from './TotalBasementLevelLines';
import PositioningCaseCard from './PositioningCaseCard';
import { ButtonGroup } from '../../ui/ButtonGroup';
import { selectIsCaseFifthFacadeOpened } from '../../../store/selectors/navigation/windows/isCaseFifthFacadeOpened.selector';
import { selectIsCaseSuperstructureOpened } from '../../../store/selectors/navigation/windows/isCaseSuperstructureOpened.selector';
import { selectIsCaseEnergyAndNetworksOpened } from '../../../store/selectors/navigation/windows/isCaseEnergyAndNetworksOpened.selector';
import { selectIsCaseInfrastructureOpened } from '../../../store/selectors/navigation/windows/isCaseInfrastructureOpened.selector';
import { selectIsCaseGroundAdaptationsOpened } from '../../../store/selectors/navigation/windows/isCaseGroundAdaptationsOpened.selector';
import { selectIsCaseOutdoorsOpened } from '../../../store/selectors/navigation/windows/isCaseOutdoorsOpened.selector';
import { selectIsCaseOtherPropertiesOpened } from '../../../store/selectors/navigation/windows/isCaseOtherPropertiesOpened.selector';
import { selectCurrentCaseLabel } from '../../../store/selectors/project/currentCaseLabel.selector';
import { TopLevelSurfaceName } from '../../../domain/granulometry/cases/queries/surfaces/getCaseTopLevelsSurfaces';
import { selectIsCaseFloorSpaceOpened } from '../../../store/selectors/navigation/windows/isCaseFloorSpaceOpened.selector';
import { SurfacesCalculation } from './SurfacesCalculation/SurfacesCalculation';

export const CELL_WIDTH = 34;

interface CardToScrollProps {
  isToolboxEnlarged: boolean;
  setIsToolboxEnlarged: React.Dispatch<React.SetStateAction<boolean>>;
  selectedLevelSurface: TopLevelSurfaceName;
  setSelectedLevelSurface: React.Dispatch<React.SetStateAction<TopLevelSurfaceName>>;
}

export const CardToScroll = ({
  isToolboxEnlarged,
  setIsToolboxEnlarged,
  selectedLevelSurface,
  setSelectedLevelSurface
}: CardToScrollProps) => {
  const { t } = useTranslation();

  const projectId = useSelector(selectProjectId);
  const caseId = useSelector(selectCurrentCaseIdFromRoute);
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const currentlyEditedLodgmentType = useSelector(selectCurrentlyEditedLodgmentType);
  const currentlyEditedLevel = useSelector(selectCurrentlyEditedLevel);
  const isCaseFloorSpaceOpened = useSelector(selectIsCaseFloorSpaceOpened);
  const isCaseFifthFacadeOpened = useSelector(selectIsCaseFifthFacadeOpened);
  const isCaseSuperstructureOpened = useSelector(selectIsCaseSuperstructureOpened);
  const isCaseEnergyAndNetworksOpened = useSelector(selectIsCaseEnergyAndNetworksOpened);
  const isCaseInfrastructureOpened = useSelector(selectIsCaseInfrastructureOpened);
  const isCaseGroundAdaptationsOpened = useSelector(selectIsCaseGroundAdaptationsOpened);
  const isCaseOutdoorsOpened = useSelector(selectIsCaseOutdoorsOpened);
  const isCaseOtherPropertiesOpened = useSelector(selectIsCaseOtherPropertiesOpened);

  const dispatch = useDispatch();

  const handleGoToCase = React.useCallback(() => {
    if (projectId && caseId) dispatch(goToCase(projectId, caseId));
  }, [dispatch, projectId, caseId]);

  if (!caseLabel) return null;

  const isHighlighted =
    Boolean(currentlyEditedLodgmentType) ||
    Boolean(currentlyEditedLevel) ||
    isCaseFloorSpaceOpened ||
    isCaseFifthFacadeOpened ||
    isCaseSuperstructureOpened ||
    isCaseEnergyAndNetworksOpened ||
    isCaseInfrastructureOpened ||
    isCaseGroundAdaptationsOpened ||
    isCaseOutdoorsOpened ||
    isCaseOtherPropertiesOpened;

  const triggerId = isHighlighted
    ? currentlyEditedLodgmentType
      ? getLodgmentTypeTriggerId(caseLabel, currentlyEditedLodgmentType.lodgmentType)
      : currentlyEditedLevel
      ? getLevelTriggerId(caseLabel, currentlyEditedLevel.level)
      : isCaseFloorSpaceOpened
      ? getCaseFloorSpaceTriggerId(caseLabel)
      : isCaseFifthFacadeOpened
      ? getCaseFifthFacadeTriggerId(caseLabel)
      : isCaseSuperstructureOpened
      ? getCaseSuperstructureTriggerId(caseLabel)
      : isCaseEnergyAndNetworksOpened
      ? getCaseEnergyAndNetworksTriggerId(caseLabel)
      : isCaseInfrastructureOpened
      ? getCaseInfrastructureTriggerId(caseLabel)
      : isCaseGroundAdaptationsOpened
      ? getCaseGroundAdaptationsTriggerId(caseLabel)
      : isCaseOutdoorsOpened
      ? getCaseOutdoorsTriggerId(caseLabel)
      : isCaseOtherPropertiesOpened
      ? getCaseOtherPropertiesTriggerId(caseLabel)
      : undefined
    : undefined;

  const selectedKey = isCaseFifthFacadeOpened
    ? 0
    : isCaseSuperstructureOpened
    ? 1
    : isCaseInfrastructureOpened
    ? 2
    : isCaseGroundAdaptationsOpened
    ? 3
    : isCaseEnergyAndNetworksOpened
    ? 4
    : isCaseOutdoorsOpened
    ? 5
    : isCaseOtherPropertiesOpened
    ? 6
    : -1;

  const optionAndParamsButtons = [
    {
      id: getCaseFifthFacadeTriggerId(caseLabel),
      content: t('Fifth facade'),
      details: t('Roofing'),
      handleClick: () => {
        projectId && caseId && dispatch(goToCaseFifthFacade(projectId, caseId));
      }
    },
    {
      id: getCaseSuperstructureTriggerId(caseLabel),
      content: t('Superstructure'),
      details: `${t('Stairs')}, ${t('Elevator')}, ${t('Hall')},...`,
      handleClick: () => {
        projectId && caseId && dispatch(goToCaseSuperstructure(projectId, caseId));
      }
    },
    {
      id: getCaseInfrastructureTriggerId(caseLabel),
      content: t('Infrastructure'),
      details: t('Basements'),
      handleClick: () => {
        projectId && caseId && dispatch(goToCaseInfrastructure(projectId, caseId));
      }
    },
    {
      id: getCaseGroundAdaptationsTriggerId(caseLabel),
      content: t('Ground adaptations'),
      details: t('Floors and foundations'),
      handleClick: () => {
        projectId && caseId && dispatch(goToCaseGroundAdaptations(projectId, caseId));
      }
    },
    {
      id: getCaseEnergyAndNetworksTriggerId(caseLabel),
      content: t('Energy and networks'),
      details: `${t('Heating')}, ${t('Technical sheaths')},...`,
      handleClick: () => {
        projectId && caseId && dispatch(goToCaseEnergyAndNetworks(projectId, caseId));
      }
    },
    {
      id: getCaseOutdoorsTriggerId(caseLabel),
      content: t('Outdoors'),
      handleClick: () => {
        projectId && caseId && dispatch(goToCaseOutdoors(projectId, caseId));
      }
    },
    {
      id: getCaseOtherPropertiesTriggerId(caseLabel),
      content: t('Other properties'),
      handleClick: () => {
        projectId && caseId && dispatch(goToCaseOtherProperties(projectId, caseId));
      }
    }
  ];

  return (
    <HighlightOverlay highlighted={isHighlighted} triggerId={triggerId} onReturn={handleGoToCase}>
      <PositioningCaseCard />
      <EditingStage />
      <Card>
        <SpecialLabel label={t('Surfaces calculation')} />
        <SurfacesCalculation />
      </Card>
      <Card>
        <SpecialLabel label={t('Levels and basements number')} />
        <TopAndBasementLevelsCount />
      </Card>
      <Card>
        <div className="level-distribution">
          <SpecialLabel label={t('Level distribution')} />
          <div style={{ position: 'relative' }}>
            <ScrollSync>
              <>
                <HeaderLine
                  isToolboxEnlarged={isToolboxEnlarged}
                  setIsToolboxEnlarged={setIsToolboxEnlarged}
                  selectedLevelSurface={selectedLevelSurface}
                  setSelectedLevelSurface={setSelectedLevelSurface}
                />
                <DistributionLine />
                <TopLevelLines
                  isToolboxEnlarged={isToolboxEnlarged}
                  selectedLevelSurface={selectedLevelSurface}
                />
                <TotalTopLevelLines
                  isToolboxEnlarged={isToolboxEnlarged}
                  selectedLevelSurface={selectedLevelSurface}
                />
              </>
            </ScrollSync>
            <BasementLevelLines />
            <TotalBasementLevelLines />
            <div className="column-separator column-separator-1" />
            <div className="column-separator column-separator-2" />
            <div className="column-separator column-separator-3" />
            {isToolboxEnlarged && (
              <>
                {process.env.GB_FEAT_GFS === 'true' && (
                  <div className="column-separator column-separator-4" />
                )}
                <div className="column-separator column-separator-5" />
                <div className="column-separator column-separator-6" />
              </>
            )}
          </div>
          <UnitSwitchersLine />
        </div>
      </Card>
      <Card className="to-scroll">
        <SpecialLabel label={t('Options and settings')} />
        <ButtonGroup
          buttons={optionAndParamsButtons}
          forcedSelectedKey={selectedKey}
          vertical={true}
        />
      </Card>
    </HighlightOverlay>
  );
};
