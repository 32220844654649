import { APP_COLOR, OTHER_RBS_SECTIONS_COLOR } from '../../../../constants/appConstants';
import { getSectionName, Section, SectionDrawn } from '../Section';
import i18next from 'i18next';
import { getDefaultCasePropertyValue } from '../../../specification/cases/queries/get/properties/getDefaultCasePropertyValue';
import { CirculationName } from './CirculationSection';
import { SectionWithTheoreticalSurface } from '../SectionWithTheoreticalSurface';
import { SectionWithExposureRate } from '../SectionWithExposureRate';
import { IconObjectProps } from '../../../../components/ui/Icons/iconObject';
import { SectionWithWidth } from '../SectionWithWidth';

interface HallProps {
  surface: number;
  exposureRate: number;
  width: number;
  entranceHeight: number;
  entranceLength: number;
}

export interface HallSection
  extends Section,
    SectionDrawn,
    SectionWithWidth,
    SectionWithTheoreticalSurface,
    SectionWithExposureRate {
  name: CirculationName.Hall;
  entranceHeight: number;
  entranceLength: number;
}

export const HALL_ICON = {
  type: 'object',
  iconName: 'elevator',
  color: APP_COLOR
} as IconObjectProps;

const hall = ({
  surface,
  exposureRate,
  width,
  entranceHeight,
  entranceLength
}: HallProps): HallSection => ({
  // Section
  id: 'NOID',
  name: CirculationName.Hall,
  title: i18next.t('hall.title'),
  type: 'circulation',
  displayedSurface: surface,
  // SectionDrawn
  surface,
  color: OTHER_RBS_SECTIONS_COLOR,
  icon: HALL_ICON,
  // SectionWithTheoreticalSurface
  theoreticalSurface: surface,
  defaultTheoreticalSurface: getDefaultCasePropertyValue('hallSurface'),
  // SectionWithExposureRate
  exposureRate,
  defaultExposureRate: getDefaultCasePropertyValue('hallExposureRate'),
  // HallSection
  width,
  entranceHeight,
  entranceLength
});

export default hall;

export const isSectionAHall = (section: Section): section is HallSection =>
  getSectionName(section) === CirculationName.Hall;

export const filterHalls = (sections: Section[]): HallSection[] => sections.filter(isSectionAHall);
