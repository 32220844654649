import { getCaseSurfaceWeightingByType } from './getCaseSurfaceWeightingByType';
import { CaseFormDataLegacy } from '../caseFormData';

/* Get the eligible lodgment real total count in the given case */
export const getCaseDataTotalSurfaceWeighting = (caseData: CaseFormDataLegacy) => {
  const caseSurfaceWeightingByType = getCaseSurfaceWeightingByType(caseData);
  let caseTotalSurfaceWeighting = 0;
  caseSurfaceWeightingByType.forEach((item) => {
    caseTotalSurfaceWeighting += item.surfaceWeighting;
  });
  return caseTotalSurfaceWeighting;
};
