import * as React from 'react';
import { APP_COLOR } from '../../../../../constants/appConstants';
import { DataTable } from '../../../../ui/DataTable';
import { useTranslation } from 'react-i18next';
import { roundWith2Decimal } from '../../../../../utils/round/roundWith2Decimal';
import { CellInfo, Column, ComponentPropsGetterR, ComponentPropsGetterRC } from 'react-table';
import { LevelDetail } from './LevelDetails';

interface DetailsLineProps {
  cellInfo: CellInfo;
}

const DetailsLine = ({ cellInfo }: DetailsLineProps) => {
  const [isOpen, setOpen] = React.useState<boolean>(false);
  return cellInfo.original.details ? (
    <>
      <span
        style={{
          textDecoration: 'underline',
          textDecorationStyle: 'dotted'
        }}
        onClick={() => {
          setOpen(!isOpen);
        }}>
        {cellInfo.row.label}
      </span>
      <div
        className={!isOpen ? 'hide' : ''}
        style={{
          width: '284px',
          whiteSpace: 'normal',
          backgroundColor: 'transparent',
          marginTop: '5px',
          paddingTop: '5px',
          borderTop: '1px dotted rgb(var(--darkGrey))',
          fontSize: 'inherit',
          color: 'rgb(var(--darkGrey))'
        }}
        dangerouslySetInnerHTML={{ __html: cellInfo.original.details }}
      />
    </>
  ) : (
    cellInfo.row.label
  );
};

interface DetailsTableProps {
  infos: LevelDetail[];
}
export const DetailsTable = ({ infos }: DetailsTableProps) => {
  const { t } = useTranslation();

  const getTdProps: ComponentPropsGetterRC = (state, rowInfo, column) => {
    return {
      style: {
        overflow:
          rowInfo && rowInfo.original.details && column?.id === 'label' ? 'visible' : 'initial',
        fontWeight: rowInfo && rowInfo.original.bold && column?.id === 'value' ? `bold` : null
      }
    };
  };

  const getTrProps: ComponentPropsGetterR = (state, rowInfo) => {
    return {
      style: {
        background: 'white',
        color: APP_COLOR,
        fontStyle: 'normal',
        borderTop: rowInfo && rowInfo.original.separator ? `1px ${APP_COLOR} solid` : null
      }
    };
  };

  const getTrGroupProps = () => {
    return {
      style: {
        minHeight: 'auto'
      }
    };
  };

  // Set columns
  const columns: Column[] = [
    {
      Header: t('Details'),
      headerStyle: { textAlign: 'left' },
      style: { textAlign: 'left' },
      accessor: 'label',
      Cell: (cellInfo) => <DetailsLine cellInfo={cellInfo} />
    },
    {
      Header: t('Values'),
      headerStyle: { textAlign: 'right', paddingLeft: 0 },
      style: { textAlign: 'right', paddingLeft: 0 },
      accessor: 'value',
      width: 48,
      Cell: (cellInfo) => (
        <span>{cellInfo.row.value ? roundWith2Decimal(cellInfo.row.value) : '-'}</span>
      )
    },
    {
      Header: t('U.'),
      headerStyle: { textAlign: 'right' },
      style: { textAlign: 'right' },
      accessor: 'unit',
      width: 26,
      Cell: (cellInfo) => <span>{cellInfo.row.unit ?? '-'}</span>
    }
  ];
  // Return data table
  return (
    <DataTable
      data={infos}
      columns={columns}
      defaultPageSize={100}
      filterable={false}
      getTdProps={getTdProps}
      getTrProps={getTrProps}
      getTrGroupProps={getTrGroupProps}
      showPaginationTop={false}
      showPaginationBottom={false}
    />
  );
};
