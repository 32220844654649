import { CaseGranulometry } from '../CaseGranulometry';
import { CaseFormDataLegacy } from '../../../legacy/caseFormData';
import { letterFromIndex } from '../../../../utils/letterFromIndex';

export const createCaseGranulometry = (
  buildingIndex: number,
  caseData: CaseFormDataLegacy,
  caseIndex: number,
  buildingCaseCount: number
): CaseGranulometry => {
  const letter = letterFromIndex(caseIndex);
  const labelIndex = '' + (buildingIndex + 1) + letter;
  return {
    id: caseData.id,
    labelIndex,
    name: 'case' + labelIndex,
    title: 'Cage ' + labelIndex, // !!! Must be traduce with react-i18next
    levels: [],
    allBasementsHeight: 0,
    properties: caseData.properties,
    initialSpecifications: caseData, // Save the case form data inside the case object
    carpentryType: caseData.carpentryType,
    carpentryDayTimeOccultationsType: caseData.carpentryDayTimeOccultationsType,
    carpentryDayTimeOccultationsTypeIsMonobloc: caseData.carpentryDayTimeOccultationsTypeIsMonobloc,
    carpentryNightTimeOccultationsType: caseData.carpentryNightTimeOccultationsType,
    carpentryNightTimeOccultationsTypeIsMonobloc:
      caseData.carpentryNightTimeOccultationsTypeIsMonobloc,
    carpentryOccultationsMotorisationType: caseData.carpentryOccultationsMotorisationType,
    masonryType: caseData.masonryType,
    woodEnvelopeType: caseData.woodEnvelopeType,
    heatingDistribution: caseData.heatingDistribution,
    direction: caseIndex === 0 ? (buildingCaseCount > 1 ? 'rtl' : 'ltr') : 'ltr',
    technicalSheathCountByLevel: []
  };
};
