import { BuildingId } from '../../domain/specification/buildings/BuildingSpecification';

export const CASE_ADDED = 'core/caseAdded';

/**
 * Action déclenchée lorsque l’utilisateur demande une cage supplémentaire dans un bâtiment
 * @param buildingId
 */
export const caseAdded = (buildingId: BuildingId) => ({
  type: CASE_ADDED,
  payload: { buildingId }
});

export type CaseAddedAction = ReturnType<typeof caseAdded>;
