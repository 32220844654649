import * as React from 'react';
import { Route } from 'react-router';
import { ProjectDetailsGingerWindow } from './ProjectDetailsGingerWindow/ProjectDetailsGingerWindow';
import { ToolboxWindows } from './ToolboxWindows';
import {
  projectDetailsEditFloorSpaceRoute,
  projectDetailsEditGingerRoute
} from '../../../routes/toolboxPanels/projectDetails';
import { FloorSpaceWindow } from './FloorSpaceWindow/FloorSpaceWindow';

export const ProjectPanelWindows = () => (
  <ToolboxWindows>
    <Route path={projectDetailsEditGingerRoute.path} component={ProjectDetailsGingerWindow} />
    <Route path={projectDetailsEditFloorSpaceRoute.path} component={FloorSpaceWindow} />
  </ToolboxWindows>
);
