import * as R from 'ramda';
import { getSectionName, Section } from '../Section';
import bicycleParkingSection, { BicycleParkingSection } from './bicycleParkingSection';
import technicalSection, { TechnicalSection } from './/technicalSection';
import trashcanSection, { TrashcanSection } from './/trashcanSection';
import opticalFiberSection, { OpticalFiberSection } from './/opticalFiberSection';
import electricalTransformerSection, {
  ElectricalTransformerSection
} from './/electricalTransformerSection';

export enum TechnicalPremiseSectionName {
  BicycleParkingSection = 'bicycleParkingSection',
  TechnicalSection = 'technicalSection',
  TrashcanSection = 'trashcanSection',
  OpticalFiberSection = 'opticalFiberSection',
  ElectricalTransformerSection = 'electricalTransformerSection'
}

export type TechnicalPremiseSection =
  | BicycleParkingSection
  | TechnicalSection
  | TrashcanSection
  | OpticalFiberSection
  | ElectricalTransformerSection;

type GetTechnicalPremiseName = (
  otherSection: TechnicalPremiseSection
) => TechnicalPremiseSectionName;
export const getTechnicalPremiseSectionName: GetTechnicalPremiseName = R.prop('name');

export const isSectionAnTechnicalPremise = (section: Section): section is TechnicalPremiseSection =>
  (R.values(TechnicalPremiseSectionName) as string[]).includes(getSectionName(section));

export const filterTechnicalPremiseSections = (sections: Section[]): TechnicalPremiseSection[] =>
  sections.filter(isSectionAnTechnicalPremise);

export const getTechnicalPremiseSectionsList = (): TechnicalPremiseSection[] => [
  technicalSection({}),
  bicycleParkingSection({}),
  opticalFiberSection({}),
  trashcanSection({}),
  electricalTransformerSection({})
];
