import * as React from 'react';
import { LevelSpecification } from '../../../../../domain/specification/levels/LevelSpecification';
import { Column4Inner } from '../Column4';

interface Column4EnlargedProps {
  levelSpecification: LevelSpecification;
}

export const Column4Enlarged = ({ levelSpecification }: Column4EnlargedProps) => {
  return (
    <div className="column-4">
      <div className="cell">
        {process.env.GB_FEAT_GFS === 'true' && (
          <Column4Inner
            levelSpecification={levelSpecification}
            selectedLevelSurface={'grossFloorSurfaceEff'}
          />
        )}
      </div>
      <div className="cell">
        {process.env.GB_FEAT_GFS === 'true' && (
          <Column4Inner
            levelSpecification={levelSpecification}
            selectedLevelSurface={'grossFloorSurface'}
          />
        )}
      </div>
      <div className="cell">
        <Column4Inner
          levelSpecification={levelSpecification}
          selectedLevelSurface={'realBuiltSurface'}
        />
      </div>
      <div className="cell">
        <Column4Inner
          levelSpecification={levelSpecification}
          selectedLevelSurface={'surfaceForSale'}
        />
      </div>
    </div>
  );
};
