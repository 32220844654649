import * as React from 'react';
import { useSelector } from 'react-redux';
import { LodgmentTypeSpecification } from '../../../../domain/specification/lodgmentTypes/LodgmentTypeSpecification';
import { CaseEditingStageName } from '../../../../domain/specification/cases/CaseSpecification';
import { selectCurrentCaseLabel } from '../../../../store/selectors/project/currentCaseLabel.selector';
import { selectCurrentCaseEditingStage } from '../../../../store/selectors/specification/currentCase/editingStage.selector';
import { selectCurrentCaseLodgmentsTypesSpecifications } from '../../../../store/selectors/specification/currentCase/lodgementsTypesSpecifications.selector';
import { CELL_WIDTH } from '../CardToScroll';
import { selectCurrentCaseLodgmentsCountByType } from '../../../../store/selectors/granulometry/currentCase/lodgmentsCountByType.selector';
import { selectCurrentCaseLodgmentsCountByTypeWithCustomDistribution } from '../../../../store/selectors/granulometry/currentCase/lodgmentsCountByTypeWithCustomDistribution.selector';
import { Input } from '../../../ui/input/Input';

export const Column2 = () => {
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const editingStage = useSelector(selectCurrentCaseEditingStage);
  const lodgmentsCountByTypeFromGranulometryStage = useSelector(
    selectCurrentCaseLodgmentsCountByType
  );
  const lodgmentsCountByTypeFromDistributionStage = useSelector(
    selectCurrentCaseLodgmentsCountByTypeWithCustomDistribution
  );
  const lodgmentsCountByType =
    editingStage === CaseEditingStageName.Granulometry
      ? lodgmentsCountByTypeFromGranulometryStage
      : lodgmentsCountByTypeFromDistributionStage;
  const lodgmentsTypesSpecifications = useSelector(selectCurrentCaseLodgmentsTypesSpecifications);

  if (!caseLabel || !lodgmentsCountByType || !lodgmentsTypesSpecifications) return null;

  return (
    <div className="column-2" tabIndex={-1}>
      <div style={{ width: `${CELL_WIDTH * lodgmentsTypesSpecifications.length}px` }}>
        {lodgmentsTypesSpecifications.map((logmentType: LodgmentTypeSpecification) => (
          <div className="cell" key={logmentType.lodgmentType}>
            <Input
              name={`${caseLabel}TotalTopLevel${logmentType.lodgmentType}Count`}
              type="number"
              size="small"
              disabled
              value={lodgmentsCountByType[logmentType.lodgmentType] || 0}
            />
          </div>
        ))}
        <div className="clear" />
      </div>
    </div>
  );
};
