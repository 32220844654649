import { ProjectProjection } from '../../projection/project/ProjectProjection';
import { CaseProjection } from '../../projection/cases/CaseProjection';
import { roundWithNoDecimal } from '../../../utils/round/roundWithNoDecimal';
import { getCaseRealBuiltSurfaceMinimum } from '../../specification/cases/queries/get/surfaces/getCaseRealBuiltSurfaceMinimum';

export const caseRealBuiltSurfaceIsSmallerThanTheMinimum = (
  projectProjection: ProjectProjection,
  caseProjection: CaseProjection
): boolean =>
  roundWithNoDecimal(caseProjection.projectedSurfaces.realBuiltSurface.value) <
  roundWithNoDecimal(getCaseRealBuiltSurfaceMinimum(caseProjection));
