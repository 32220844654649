import { createSelector, Selector } from 'reselect';
import * as R from 'ramda';
import { selectCurrentCaseIdFromRoute } from '../../navigation/caseIdFromRoute.selector';
import { State } from '../../../reducers';
import { CaseSpecificationWithIndexes } from '../../../../domain/specification/cases/CaseSpecification';
import { selectAllCasesSpecifications } from '../allCasesSpecifications.selector';

export const selectCurrentCaseSpecification: Selector<State, CaseSpecificationWithIndexes> =
  createSelector(
    [selectAllCasesSpecifications, selectCurrentCaseIdFromRoute],
    (allCases, caseId) => allCases?.find(R.propEq('id', caseId)) as CaseSpecificationWithIndexes
  );
