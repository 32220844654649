import {
  GRAY_COLOR,
  HARDROCKS_COLOR,
  INFO_COLOR,
  OTHERS_COLOR,
  VALID_COLOR,
  WARNING_COLOR
} from '../../../constants/appConstants';
import mapboxgl, { CircleLayer, FillLayer, LineLayer, SymbolLayer } from 'mapbox-gl';
import {
  DRAW_HELPER_MOVING_FEATURE,
  DRAW_HELPER_MOVING_FEATURE_STYLE_LAYER
} from '../domain/drawHelper/drawHelper.model';

const forMovingLayer = ['==', 'user_type', DRAW_HELPER_MOVING_FEATURE];
const notMovingLayer = ['!=', 'user_type', DRAW_HELPER_MOVING_FEATURE];

const vertexFilter = [
  'all',
  ['==', 'meta', 'vertex'],
  ['==', '$type', 'Point'],
  ['!=', 'mode', 'static']
];
const midPointFilter = [
  'all',
  ['==', 'meta', 'midpoint'],
  ['==', '$type', 'Point'],
  ['!=', 'mode', 'static']
];

const angleCircles = [
  {
    id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
    type: 'circle',
    filter: vertexFilter,
    paint: {
      'circle-radius': 10,
      'circle-color': '#fff'
    }
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-inactive',
    type: 'circle',
    filter: vertexFilter,
    paint: {
      'circle-radius': 8,
      'circle-color': '#fbb03b'
    }
  },
  {
    id: 'gl-draw-polygon-rotate-point-stroke',
    type: 'circle',
    filter: midPointFilter,
    paint: {
      'circle-radius': 9,
      'circle-color': '#fff'
    }
  },
  {
    id: 'gl-draw-polygon-rotate-point',
    type: 'circle',
    filter: midPointFilter,
    paint: {
      'circle-radius': 7,
      'circle-color': '#4d4d4d'
    }
  }
];

export const removeAngleCircles = (featureId: string, setFilter: mapboxgl.Map['setFilter']) => {
  const filter = ['!=', 'parent', featureId];
  setFilter('gl-draw-polygon-and-line-vertex-stroke-inactive.cold', [...vertexFilter, filter]);
  setFilter('gl-draw-polygon-and-line-vertex-stroke-inactive.hot', [...vertexFilter, filter]);
  setFilter('gl-draw-polygon-and-line-vertex-inactive.cold', [...vertexFilter, filter]);
  setFilter('gl-draw-polygon-and-line-vertex-inactive.hot', [...vertexFilter, filter]);
  setFilter('gl-draw-polygon-rotate-point-stroke.cold', [...midPointFilter, filter]);
  setFilter('gl-draw-polygon-rotate-point-stroke.hot', [...midPointFilter, filter]);
  setFilter('gl-draw-polygon-rotate-point.cold', [...midPointFilter, filter]);
  setFilter('gl-draw-polygon-rotate-point.hot', [...midPointFilter, filter]);
};

const circles = [
  ...angleCircles,
  {
    id: 'gl-draw-point-point-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      notMovingLayer,
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static']
    ],
    paint: {
      'circle-radius': 5,
      'circle-opacity': 1,
      'circle-color': '#fff'
    }
  },
  {
    id: 'gl-draw-point-inactive',
    type: 'circle',
    filter: [
      'all',
      notMovingLayer,
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static']
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': '#3bb2d0'
    }
  },
  {
    id: 'gl-draw-point-stroke-active',
    type: 'circle',
    filter: [
      'all',
      notMovingLayer,
      ['==', '$type', 'Point'],
      ['==', 'active', 'true'],
      ['!=', 'meta', 'midpoint']
    ],
    paint: {
      'circle-radius': 16,
      'circle-color': '#ffffff'
    }
  },
  {
    id: 'gl-draw-point-active',
    type: 'circle',
    filter: [
      'all',
      notMovingLayer,
      ['==', '$type', 'Point'],
      ['!=', 'meta', 'midpoint'],
      ['==', 'active', 'true']
    ],
    paint: {
      'circle-radius': 14,
      'circle-color': '#75c900',
      'circle-opacity': 0.3
    }
  },
  {
    id: 'gl-draw-point-static',
    type: 'circle',
    filter: ['all', notMovingLayer, ['==', 'mode', 'static'], ['==', '$type', 'Point']],
    paint: {
      'circle-radius': 5,
      'circle-color': '#404040'
    }
  }
];

export const movingLayerFilterActive = [
  'all',
  forMovingLayer,
  ['==', 'active', 'true'],
  ['==', '$type', 'Polygon'],
  ['!=', 'user_type', 'overlay']
];
export const movingLayerFilterInActive = [
  'all',
  forMovingLayer,
  ['==', 'active', 'false'],
  ['==', '$type', 'Polygon'],
  ['!=', 'user_type', 'overlay'],
  ['!=', 'mode', 'static']
];

const drawHelperMovingLayerStyle: (FillLayer | LineLayer | SymbolLayer | CircleLayer)[] = [
  {
    id: DRAW_HELPER_MOVING_FEATURE_STYLE_LAYER + '-inactive',
    type: 'fill',
    filter: movingLayerFilterInActive,
    paint: {
      'fill-color': ['case', ['==', ['get', 'mode'], 'RotateMode'], VALID_COLOR, '#fff'],
      'fill-outline-color': [
        'case',
        ['==', ['get', 'mode'], 'RotateMode'],
        VALID_COLOR,
        INFO_COLOR
      ],
      'fill-opacity': 0.4
    }
  },
  {
    id: DRAW_HELPER_MOVING_FEATURE_STYLE_LAYER + '-active',
    type: 'fill',
    filter: movingLayerFilterActive,
    paint: {
      'fill-color': INFO_COLOR,
      'fill-outline-color': INFO_COLOR,
      'fill-opacity': 0.1
    }
  }
];

export const drawStyle: (FillLayer | LineLayer | SymbolLayer | CircleLayer)[] = [
  {
    id: 'gl-draw-polygon-fill-inactive',
    type: 'fill',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      notMovingLayer,
      ['!=', 'user_type', 'overlay'],
      ['!=', 'mode', 'static']
    ],
    paint: {
      'fill-color': [
        'case',
        ['==', ['get', 'user_deactivate'], true],
        GRAY_COLOR,
        ['==', ['get', 'mode'], 'RotateMode'],
        '#00ff00',
        OTHERS_COLOR
      ],
      'fill-outline-color': [
        'case',
        ['==', ['get', 'user_deactivate'], true],
        HARDROCKS_COLOR,
        ['==', ['get', 'mode'], 'RotateMode'],
        '#005c00',
        OTHERS_COLOR
      ],
      'fill-opacity': 0.5
    }
  },
  {
    id: 'gl-draw-polygon-fill-active',
    type: 'fill',
    filter: [
      'all',
      notMovingLayer,
      ['==', 'active', 'true'],
      ['==', '$type', 'Polygon'],
      ['!=', 'user_type', 'overlay']
    ],
    paint: {
      'fill-color': WARNING_COLOR,
      'fill-outline-color': WARNING_COLOR,
      'fill-opacity': 0.7
    }
  },
  {
    id: 'gl-draw-overlay-polygon-fill-inactive',
    type: 'fill',
    filter: [
      'all',
      notMovingLayer,
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['==', 'user_type', 'overlay'],
      ['!=', 'mode', 'static']
    ],
    paint: {
      'fill-color': '#3bb2d0',
      'fill-outline-color': '#3bb2d0',
      'fill-opacity': 0.01
    }
  },
  {
    id: 'gl-draw-overlay-polygon-fill-active',
    type: 'fill',
    filter: [
      'all',
      notMovingLayer,
      ['==', 'active', 'true'],
      ['==', '$type', 'Polygon'],
      ['==', 'user_type', 'overlay']
    ],
    paint: {
      'fill-color': '#fbb03b',
      'fill-outline-color': '#fbb03b',
      'fill-opacity': 0.01
    }
  },
  {
    id: 'gl-draw-polygon-stroke-inactive',
    type: 'line',
    filter: [
      'all',
      notMovingLayer,
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'user_type', 'overlay'],
      ['!=', 'mode', 'static']
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': [
        'case',
        ['==', ['get', 'user_deactivate'], true],
        HARDROCKS_COLOR,
        ['==', ['get', 'mode'], 'RotateMode'],
        '#008000',
        OTHERS_COLOR
      ],
      'line-width': 2
    }
  },
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', notMovingLayer, ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#fbb03b',
      'line-dasharray': [0.2, 2],
      'line-width': 2
    }
  },

  // {
  //     'id': 'gl-draw-polygon-midpoint',
  //     'type': 'circle',
  //     'filter': ['all',
  //         ['==', '$type', 'Point'],
  //         ['==', 'meta', 'midpoint']],
  //     'paint': {
  //         'circle-radius': 3,
  //         'circle-color': '#fbb03b'
  //     }
  // },

  {
    id: 'gl-draw-line-inactive',
    type: 'line',
    filter: [
      'all',
      notMovingLayer,
      ['==', 'active', 'false'],
      ['==', '$type', 'LineString'],
      ['!=', 'mode', 'static']
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#3bb2d0',
      'line-width': 2
    }
  },
  {
    id: 'gl-draw-line-active',
    type: 'line',
    filter: ['all', notMovingLayer, ['==', '$type', 'LineString'], ['==', 'active', 'true']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#fbb03b',
      'line-dasharray': [0.2, 2],
      'line-width': 2
    }
  },

  {
    id: 'gl-draw-polygon-and-line-vertex-scale-icon',
    type: 'symbol',
    filter: [
      'all',
      notMovingLayer,
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
      ['has', 'heading']
    ],
    layout: {
      'icon-image': 'scale',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      'icon-rotation-alignment': 'map',
      'icon-rotate': ['get', 'heading']
    },
    paint: {
      'icon-opacity': 1.0,
      'icon-opacity-transition': {
        delay: 0,
        duration: 0
      }
    }
  },

  {
    id: 'gl-draw-polygon-fill-static',
    type: 'fill',
    filter: ['all', notMovingLayer, ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': '#404040',
      'fill-outline-color': '#404040',
      'fill-opacity': 0.1
    }
  },
  {
    id: 'gl-draw-polygon-stroke-static',
    type: 'line',
    filter: ['all', notMovingLayer, ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#404040',
      'line-width': 2
    }
  },
  {
    id: 'gl-draw-line-static',
    type: 'line',
    filter: ['all', notMovingLayer, ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#404040',
      'line-width': 2
    }
  },
  {
    id: 'gl-draw-line-rotate-point',
    type: 'line',
    filter: [
      'all',
      notMovingLayer,
      ['==', 'meta', 'midpoint'],
      ['==', '$type', 'LineString'],
      ['!=', 'mode', 'static']
      // ['==', 'active', 'true']
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#fbb03b',
      'line-dasharray': [0.2, 2],
      'line-width': 2
    }
  },
  {
    id: 'gl-draw-polygon-rotate-point-icon',
    type: 'symbol',
    filter: [
      'all',
      notMovingLayer,
      ['==', 'meta', 'midpoint'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static']
    ],
    layout: {
      'icon-image': 'rotate',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      'icon-rotation-alignment': 'map',
      'icon-rotate': ['get', 'heading']
    },
    paint: {
      'icon-opacity': 1.0,
      'icon-opacity-transition': {
        delay: 0,
        duration: 0
      }
    }
  },
  ...circles,
  ...drawHelperMovingLayerStyle
];
