import * as React from 'react';
import { Route } from 'react-router-dom';
import { caseGranulometryEditLodgmentTypeRoomRoute } from '../../../../../routes/toolboxPanels/cases';
import { WindowsGroup } from '../../../../ui/WindowsGroup';
import RoomWindow from './roomWindow/RoomWindow';
import LodgmentsTypes from './LodgmentsTypes';

export const LodgmentsTypesWindow: React.FC = () => (
  <WindowsGroup>
    <LodgmentsTypes />
    <Route path={caseGranulometryEditLodgmentTypeRoomRoute.path} component={RoomWindow} />
  </WindowsGroup>
);

export default LodgmentsTypesWindow;
