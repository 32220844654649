import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormGroup } from '../../../../../ui/FormGroup';
import { useParams } from 'react-router';
import { IconObject } from '../../../../../ui/Icons/iconObject';
import { Input } from '../../../../../ui/input/Input';
import { selectCurrentCaseProjection } from '../../../../../../store/selectors/projection/currentCase/projection.selector';
import { getCaseDataProperty } from '../../../../../../domain/specification/cases/queries/get/properties/getCaseDataProperty';
import { casePropertyChanged } from '../../../../../../store/actions/casePropertyChanged.action';
import { OUTSIDE_STAIR_SHAFT_ICON } from '../../../../../../domain/granulometry/sections/outsideSections/outsideStairShaft';
import { CaseGranulometryEditSuperstructureRouteParams } from '../../../../../../routes/toolboxPanels/cases';

export const OutsideStairShaftCount = () => {
  const { caseId } = useParams<CaseGranulometryEditSuperstructureRouteParams>();
  const currentlyEditedCase = useSelector(selectCurrentCaseProjection);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const outsideStairShaftCountProperty =
    currentlyEditedCase && getCaseDataProperty(currentlyEditedCase, 'outsideStairShaftCount');
  const handleStairsShaftCountChange = React.useCallback(
    (newOutsideStairShaftCount: number | null) => {
      if (
        caseId &&
        outsideStairShaftCountProperty &&
        (typeof newOutsideStairShaftCount === 'number' || newOutsideStairShaftCount === null)
      ) {
        dispatch(
          casePropertyChanged(caseId, {
            ...outsideStairShaftCountProperty,
            value: newOutsideStairShaftCount ?? undefined
          })
        );
      }
    },
    [dispatch, caseId, outsideStairShaftCountProperty]
  );

  if (!currentlyEditedCase || !outsideStairShaftCountProperty) return null;

  return (
    <FormGroup horizontal={true} wrap={true}>
      <Input
        style={{ width: '40%', flex: '0 0 40%' }}
        name={`case${caseId}outsideStairShaftCount`}
        label={t('Outside stair shafts')}
        // info={t('stairsShaftCount.infos')}
        type="number"
        min={outsideStairShaftCountProperty.min}
        max={outsideStairShaftCountProperty.max}
        minMax={{
          min: outsideStairShaftCountProperty.min,
          max: outsideStairShaftCountProperty.max,
          unit: outsideStairShaftCountProperty.unit
        }}
        placeholder={outsideStairShaftCountProperty.defaultValue}
        value={outsideStairShaftCountProperty.value}
        handleChange={handleStairsShaftCountChange}
        suffix={outsideStairShaftCountProperty.unit}
        icon={<IconObject {...OUTSIDE_STAIR_SHAFT_ICON} clickable={false} />}
      />
    </FormGroup>
  );
};
