import circulationLaneInfra, {
  CirculationLaneInfraSection
} from '../../../../../sections/circulationSections/circulationLaneInfra';
import { CarBoxInfraSection } from '../../../../../sections/parkingSections/carBoxInfra';
import { CarParkInfraSection } from '../../../../../sections/parkingSections/carParkInfra';
import { getDefaultUnitConvertedPropertyValue } from '../../../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { Surface } from '../../../../../../specification/Surface';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { ERROR_COLOR } from '../../../../../../../constants/appConstants';

export const createBasementLevelCirculationLaneInfra = (
  caseGranulometry: CaseGranulometry,
  basementParkingSections: (CarBoxInfraSection | CarParkInfraSection)[],
  bearingSurface: number,
  sasSurface: number,
  specifiedRealBuiltSurface?: number,
  basementSurfaceBeforeSpecifiedRBS?: Surface
): CirculationLaneInfraSection | false => {
  const circulationLaneWidth = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'circulationLaneWidth'
  ) as number;
  const parkingCount = basementParkingSections.length;
  if (parkingCount !== 0) {
    const parkingTotalWidth = basementParkingSections.reduce(
      (acc, parking) => acc + (parking.width || 0),
      0
    );
    const averageParkingWidth = parkingTotalWidth / parkingCount;
    const parkingTotalDepth = basementParkingSections.reduce(
      (acc, parking) => acc + (parking.depth || 0),
      0
    );
    const averageParkingDepth = parkingTotalDepth / parkingCount;
    const averageParkingSurface = averageParkingWidth * averageParkingDepth;
    const fixedSurfaceOnParkingCountCoefficient = 0.0823;
    let circulationLaneSurface =
      parkingCount *
      (averageParkingWidth * (averageParkingDepth + circulationLaneWidth / 2) -
        averageParkingSurface) *
      (1 +
        (bearingSurface + sasSurface) *
          (fixedSurfaceOnParkingCountCoefficient * Math.pow(parkingCount, -1)));
    let isValid = true;
    if (
      specifiedRealBuiltSurface !== undefined &&
      basementSurfaceBeforeSpecifiedRBS !== undefined
    ) {
      const surfaceToAddInCirculationLane =
        specifiedRealBuiltSurface - basementSurfaceBeforeSpecifiedRBS.value;
      isValid = surfaceToAddInCirculationLane >= 0;
      circulationLaneSurface += surfaceToAddInCirculationLane;
    }
    return circulationLaneInfra({
      surface: roundWith2Decimal(circulationLaneSurface),
      width: circulationLaneWidth,
      color: isValid ? undefined : ERROR_COLOR
    });
  } else {
    return false;
  }
};
