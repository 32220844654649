import * as R from 'ramda';
import { flow, pipe } from 'fp-ts/function';
import { Reducer } from 'redux';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { Project } from '../../domain/project/Project';
import { addABuilding } from '../../domain/useCases/addABuilding';
import { removeABuilding } from '../../domain/useCases/removeABuilding';
import { getBuildingIdFromCaseId } from '../../domain/core/queries/getBuildingIdFromCaseId';
import { getBuildingIndexGivenBuildingId } from '../../domain/core/queries/getBuildingIndexGivenBuildingId';
import { RENAME_PROJECT, RenameProjectAction } from '../actions/renameProject.action';
import { CREATE_NEW_PROJECT } from '../actions/createNewProject.action';
import { PROJECT_FETCHED, ProjectFetchedAction } from '../actions/projectFetched.action';
import { PROJECT_CREATED, ProjectCreatedAction } from '../actions/projectCreated.action';
import {
  SET_CASE_LODGMENT_TYPES_DISTRIBUTION,
  SetCaseLodgmentsTypesDistributionAction
} from '../actions/setCaseLodgmentsTypesDistribution.action';
import {
  SET_CASE_TOP_LEVELS_COUNT,
  SetCaseTopLevelsCountAction
} from '../actions/setCaseTopLevelsCount.action';
import {
  SET_CASE_BASEMENT_LEVELS_COUNT,
  SetCaseBasementLevelsCountAction
} from '../actions/setCaseBasementLevelsCount.action';
import {
  SET_TOP_LEVELS_SPECIFICATIONS,
  SetTopLevelsSpecificationsAction
} from '../actions/setTopLevelsSpecifications.action';
import {
  CASE_SPECIFICATION_CHANGED,
  CaseSpecificationChangedAction
} from '../actions/changeCaseSpecification.action';
import {
  CASE_PARKINGS_SPECIFICATION_CHANGED,
  SetCaseParkingsSpecificationAction
} from '../actions/setCaseParkingsSpecification.action';
import {
  SET_LODGMENT_TYPE_ROOM,
  SetLodgmentTypeRoomAction
} from '../actions/setLodgmentTypeRoom.action';
import {
  SET_LODGMENT_TYPE_THEORETICAL_SURFACE,
  SetLodgmentTypeSurfaceAction
} from '../actions/setLodgmentTypeSurface.action';
import {
  SET_LODGMENT_TYPE_EXPOSURE_RATE,
  SetLodgmentTypeExposureRateAction
} from '../actions/setLodgmentTypeExposureRate.action';
import { saveProject } from '../actions/saveProject.action';
import { UNDO } from '../actions/undo.action';
import {
  CASE_SURFACE_CHANGED,
  CaseSurfaceChangedAction
} from '../actions/caseSurfaceChanged.action';
import { PROJECT_SAVED } from '../actions/projectSaved.action';
import {
  SET_BASEMENT_LEVELS_SPECIFICATIONS,
  SetBasementLevelsSpecificationsAction
} from '../actions/setBasementLevelsSpecifications.action';
import {
  CasePropertyChangedAction,
  SET_CASE_PROPERTY
} from '../actions/casePropertyChanged.action';
import { CLOSE_PROJECT } from '../actions/closeProject.action';
import { LOAD_PROJECT } from '../actions/loadProject.action';
import { PROJECT_SAVE_FAILED } from '../actions/projectSaveFailed.action';
import { PROJECT_FETCH_FAILED } from '../actions/projectsFetchFailed.action';
import {
  SET_CASE_LEVEL_SPECIFICATION,
  SetCaseLevelSpecificationAction
} from '../actions/setCaseLevelSpecification.action';
import { BUILDING_ADDED } from '../actions/buildingAdded.action';
import { BUILDING_REMOVED, BuildingRemovedAction } from '../actions/buildingRemoved.action';
import { CASE_ADDED, CaseAddedAction } from '../actions/caseAdded.action';
import { addACase } from '../../domain/useCases/addACase';
import { CASE_REMOVED, CaseRemovedAction } from '../actions/caseRemoved.action';
import { removeACase } from '../../domain/useCases/removeACase';
import {
  PROJECT_SURFACE_CHANGED,
  ProjectSurfaceChangedAction
} from '../actions/projectSurfaceChanged.action';
import {
  BUILDING_SPECIFICATION_CHANGED,
  BuildingSpecificationChangedAction
} from '../actions/changeBuildingSpecification.action';
import {
  BuildingId,
  BuildingSpecification
} from '../../domain/specification/buildings/BuildingSpecification';
import { BUILDING_MOVED, BuildingMovedAction } from '../actions/buildingMoved.action';
import { moveABuilding } from '../../domain/useCases/moveABuilding';
import { CASE_MOVED, CaseMovedAction } from '../actions/caseMoved.action';
import { moveACase } from '../../domain/useCases/moveACase';
import privateRoutes from '../../routes/privateRoutes';
import { PROJECT_COPIED, ProjectCopiedAction } from '../actions/projectCopied.action';
import {
  GB_PROJECT_LOCATION_CHANGED,
  ProjectLocationChangedAction
} from '../actions/projectLocationChanged.action';
import {
  CASE_CUSTOM_DISTRIBUTION_CHANGED,
  CaseCustomDistributionChangedAction
} from '../actions/caseCustomDistributionChanged.action';
import {
  CASE_EDITING_STAGE_CHANGED,
  CaseEditingStageChangedAction
} from '../actions/caseEditingStageChanged.action';
import {
  LocationCoordinatesFetched,
  PROJECT_LOCATION_COORDINATES_FETCHED
} from '../actions/projectLocationCoordinatesFetched';
import {
  FLOOR_SPACE_ADDED,
  FLOOR_SPACE_CHANGED,
  FLOOR_SPACE_REMOVED,
  FLOOR_SPACE_SELECTED,
  FloorSpaceAddedAction,
  FloorSpaceChangedAction,
  FloorSpacesSelectAction,
  RemoveFloorSpacesAction
} from '../actions/floorSpace.actions';
import { FloorSpaceFeatureId } from '../../domain/specification/floorSpace/FloorSpaceFeature';
import { getCaseIdFromFloorSpaceId } from '../../domain/core/queries/getCaseIdFromFloorSpaceId';
import { ADD_OR_REMOVE_PARCEL, RemoveParcelAction } from '../actions/parcels.actions';
import { CaseSpecification } from '../../domain/specification/cases/CaseSpecification';
import {
  setCaseCustomDistribution,
  setCaseEditingStage,
  setCaseSurface
} from '../../domain/specification/cases/CaseSurfaceSpecification';
import { SET_BUILDING_TITLE, SetBuildingTitleAction } from '../actions/setBuildingTitle.action';
import { setBuildingTitle } from '../../domain/specification/buildings/queries/setBuildingTitle';
import {
  SET_LODGMENT_TYPES_HEATING_SYSTEM,
  SetLodgmentTypesHeatingSystemAction
} from '../actions/setLodgmentTypesHeatingSystem.action';
import { setCaseTopLevelsCount } from '../../domain/specification/cases/queries/set/levels/setCaseTopLevelsCount';
import { setCaseBasementsLevelsCount } from '../../domain/specification/cases/queries/set/levels/setCaseBasementsLevelsCount';
import { setCaseTopLevelsSpecifications } from '../../domain/specification/cases/queries/set/levels/setCaseTopLevelsSpecifications';
import { setCaseBasementLevelsSpecifications } from '../../domain/specification/cases/queries/set/levels/setCaseBasementLevelsSpecifications';
import { setCaseLevelSpecification } from '../../domain/specification/cases/queries/set/levels/setCaseLevelSpecification';
import { setCaseLodgmentTypeSpecificationRoom } from '../../domain/specification/cases/queries/set/lodgmentTypes/setCaseLodgmentTypeSpecificationRoom';
import { setCaseLodgmentTypeSpecificationTheoreticalSurface } from '../../domain/specification/cases/queries/set/lodgmentTypes/setCaseLodgmentTypeSpecificationTheoreticalSurface';
import { setCaseLodgmentTypeSpecificationExposureRate } from '../../domain/specification/cases/queries/set/lodgmentTypes/setCaseLodgmentTypeSpecificationExposureRate';
import { setCaseLodgmentTypesDistribution } from '../../domain/specification/cases/queries/set/lodgmentTypes/setCaseLodgmentTypesDistribution';
import { setCaseProperty } from '../../domain/specification/cases/queries/set/properties/setCaseProperty';
import { setCaseLodgmentTypesSpecificationHeatingSystem } from '../../domain/specification/cases/queries/set/lodgmentTypes/setCaseLodgmentTypesSpecificationHeatingSystem';
import { createProject } from '../../domain/project/createProject';
import { renameProject } from '../../domain/project/renameProject';
import { markSaved } from '../../domain/project/markSaved';
import { getFloorSpaceFeaturesWithoutGeometry } from '../../domain/specification/floorSpace/queries/floorSpaceFeature/getFloorSpaceFeaturesWithoutGeometry';
import { getProjectFloorSpaceFeatures } from '../../domain/specification/floorSpace/queries/floorSpaceFeature/getProjectFloorSpaceFeatures';
import { setFloorSpaceFeature } from '../../domain/specification/floorSpace/queries/floorSpaceFeature/setFloorSpaceFeature';
import { removeFloorSpaceFeature } from '../../domain/specification/floorSpace/queries/floorSpaceFeature/removeFloorSpaceFeature';
import {
  PROJECT_COM_ETH_IC_CONSTRUCTION_DEFAULT_TOLERANCE_RATE_CHANGED,
  ProjectComEthIcConstructionToleranceRateChangedAction
} from '../actions/projectComEthIcConstructionToleranceRateChanged.action';
import { getProjectLastCaseId } from '../../domain/specification/project/queries/cases/getProjectLastCaseId';
import { overCaseId } from '../../domain/project/over/overCase';
import { reportFetched, ReportFetchedAction } from '../actions/report.actions';
import {
  PROJECT_CUSTOM_PRICE_LIST_ID_CHANGED,
  SetProjectCustomPriceListIdAction
} from '../actions/setProjectCustomPriceListId.action';
import { toggleSelectFloorSpaceFeature } from '../../domain/specification/floorSpace/queries/floorSpaceFeature/selectFloorSpaceFeature';
import { uniqId } from '../../infra/uniqId';
import { FeatureParcel } from '../../domain/Parcel';

export interface ProjectState {
  current: Project | null;
  history: Project[];
  state:
    | 'CLOSED'
    | 'CREATING'
    | 'IDLE'
    | 'EDITING'
    | 'SAVING'
    | 'LOADING'
    | 'FAILED_TO_SAVE'
    | 'FAILED_TO_FETCH';
}

export interface OpenedProjectState extends ProjectState {
  current: Project;
}

const initialState: ProjectState = {
  current: null,
  history: [],
  state: 'CLOSED'
};

const isOpened = (state: ProjectState): state is OpenedProjectState => !!state.current;

const whenOpened = (fn: (s: OpenedProjectState) => OpenedProjectState) => R.when(isOpened, fn);

const addToHistory = (state: OpenedProjectState): OpenedProjectState => ({
  ...state,
  history: [...state.history, state.current],
  state: 'EDITING'
});

const updateRevision = (p: Project): Project => ({ ...p, revision: uniqId() });

const overCurrent = (fn: (p: Project) => Project, revisionMustBeUpdated: boolean) =>
  R.over(
    R.lensProp<OpenedProjectState, 'current'>('current'),
    revisionMustBeUpdated ? flow(fn, updateRevision) : fn
  );

const updateCurrent = (
  fn: (p: Project) => Project,
  {
    appendHistory,
    revisionMustBeUpdated
  }: { appendHistory?: boolean; revisionMustBeUpdated?: boolean } = {}
) =>
  whenOpened(
    R.pipe(
      appendHistory ? addToHistory : R.identity,
      overCurrent(fn, revisionMustBeUpdated !== false)
    )
  );

const markIsSaving = R.assoc<'SAVING', 'state'>('state', 'SAVING');

const overBuilding =
  (buildingIndex: number) => (fn: (target: BuildingSpecification) => BuildingSpecification) =>
    R.over(R.lensPath(['buildings', buildingIndex]), fn);

const overBuildingId =
  (buildingId: BuildingId) =>
  (fn: (target: BuildingSpecification) => BuildingSpecification) =>
  (project: Project) => {
    return R.pipe(
      getBuildingIndexGivenBuildingId,
      overBuilding,
      R.applyTo(fn),
      R.applyTo(project)
    )(project, buildingId);
  };

const overFloorSpaceId =
  (floorSpaceId: FloorSpaceFeatureId) =>
  (fn: (target: CaseSpecification) => CaseSpecification) =>
  (project: Project): Project => {
    const caseId = getCaseIdFromFloorSpaceId(project, floorSpaceId);
    if (!caseId) return project;

    return overCaseId(caseId)(fn)(project);
  };

export const projectReducer: Reducer<ProjectState> = (state = initialState, action) => {
  if (action.type === CREATE_NEW_PROJECT) {
    return {
      state: 'CREATING',
      current: createProject(),
      history: []
    };
  }

  if (action.type === RENAME_PROJECT) {
    return updateCurrent(renameProject((action as RenameProjectAction).payload), {
      appendHistory: true
    })(state);
  }

  if (action.type === PROJECT_CUSTOM_PRICE_LIST_ID_CHANGED) {
    const newRevision = uniqId(); // necessary to trigger a project saving with revision = reportRevision

    return updateCurrent(
      (p) => ({
        ...p,
        customPriceListId: (action as SetProjectCustomPriceListIdAction).payload
          .newCustomPriceListId,
        revision: newRevision,
        reportRevision: newRevision
      }),
      {
        appendHistory: false,
        revisionMustBeUpdated: false
      }
    )({ ...state, state: 'SAVING' });
  }

  if (action.type === GB_PROJECT_LOCATION_CHANGED) {
    return updateCurrent(
      (p) => ({
        ...p,
        street: (action as ProjectLocationChangedAction).payload.street,
        postalCode: (action as ProjectLocationChangedAction).payload.postalCode,
        city: (action as ProjectLocationChangedAction).payload.city,
        country: (action as ProjectLocationChangedAction).payload.country,
        longitude: (action as ProjectLocationChangedAction).payload.longitude,
        latitude: (action as ProjectLocationChangedAction).payload.latitude
      }),
      { appendHistory: true }
    )(state);
  }

  if (action.type === PROJECT_LOCATION_COORDINATES_FETCHED) {
    return updateCurrent((p) => ({
      ...p,
      longitude: (action as LocationCoordinatesFetched).payload.longitude,
      latitude: (action as LocationCoordinatesFetched).payload.latitude
    }))(state);
  }

  if (action.type === PROJECT_CREATED) {
    const {
      payload: { projectId }
    } = action as ProjectCreatedAction;

    return pipe(state, updateCurrent(markSaved(projectId)), (state) => ({
      ...state,
      history: state.history.map(markSaved(projectId)),
      state: 'IDLE'
    }));
  }

  if (action.type === PROJECT_COPIED) {
    const {
      payload: { project }
    } = action as ProjectCopiedAction;

    return {
      state: 'CREATING',
      current: project,
      history: []
    };
  }

  if (action.type === LOCATION_CHANGE) {
    const {
      payload: {
        location: { pathname }
      }
    } = action as LocationChangeAction;

    if (pathname === privateRoutes.Projects.path) {
      return initialState;
    }
    return state;
  }

  if (saveProject.match(action)) {
    return whenOpened(markIsSaving)(state);
  }

  if (action.type === PROJECT_SAVED) {
    return { ...state, state: 'IDLE' };
  }

  if (action.type === PROJECT_SAVE_FAILED) {
    return { ...state, state: 'FAILED_TO_SAVE' };
  }

  if (action.type === LOAD_PROJECT) {
    return { ...state, state: 'LOADING' };
  }

  if (action.type === PROJECT_FETCHED) {
    return {
      state: 'IDLE',
      current: (action as ProjectFetchedAction).payload.project,
      history: []
    };
  }

  if (action.type === PROJECT_FETCH_FAILED) {
    return { ...state, state: 'FAILED_TO_FETCH' };
  }

  if (action.type === UNDO) {
    return state.history.length
      ? {
          ...state,
          current: R.last(state.history) as Project,
          history: R.init(state.history)
        }
      : state;
  }

  if (action.type === CLOSE_PROJECT) {
    return initialState;
  }

  if (action.type === PROJECT_SURFACE_CHANGED) {
    return updateCurrent(
      (p) => ({
        ...p,
        surface: (action as ProjectSurfaceChangedAction).payload.surface
      }),
      {
        appendHistory: true
      }
    )(state);
  }

  if (action.type === PROJECT_COM_ETH_IC_CONSTRUCTION_DEFAULT_TOLERANCE_RATE_CHANGED) {
    return updateCurrent(
      (p) => ({
        ...p,
        comEthIcConstructionToleranceRate: (
          action as ProjectComEthIcConstructionToleranceRateChangedAction
        ).payload.comEthIcConstructionToleranceRate
      }),
      {
        appendHistory: true
      }
    )(state);
  }

  if (action.type === BUILDING_ADDED) {
    return updateCurrent(addABuilding, {
      appendHistory: true
    })(state);
  }

  if (action.type === BUILDING_MOVED) {
    return updateCurrent(
      moveABuilding(
        (action as BuildingMovedAction).payload.buildingId,
        (action as BuildingMovedAction).payload.newBuildingIndex
      ),
      {
        appendHistory: true
      }
    )(state);
  }

  if (action.type === BUILDING_REMOVED) {
    return updateCurrent(removeABuilding((action as BuildingRemovedAction).payload.buildingId), {
      appendHistory: true
    })(state);
  }

  if (action.type === SET_BUILDING_TITLE) {
    const {
      payload: { buildingId, buildingTitle }
    } = action as SetBuildingTitleAction;

    return updateCurrent(overBuildingId(buildingId)(setBuildingTitle(buildingTitle)), {
      appendHistory: true
    })(state);
  }

  if (action.type === CASE_ADDED) {
    return updateCurrent(addACase((action as CaseAddedAction).payload.buildingId), {
      appendHistory: true
    })(state);
  }

  if (action.type === CASE_MOVED) {
    return updateCurrent(
      moveACase(
        (action as CaseMovedAction).payload.caseId,
        (action as CaseMovedAction).payload.buildingId,
        (action as CaseMovedAction).payload.newCaseIndex
      ),
      { appendHistory: true }
    )(state);
  }

  if (action.type === CASE_REMOVED) {
    return updateCurrent(
      (project: Project) => {
        const caseId = (action as CaseRemovedAction).payload.caseId;
        const buildingId = getBuildingIdFromCaseId(project, caseId);
        if (!buildingId) {
          return project;
        }
        return removeACase(buildingId)(caseId)(project);
      },
      { appendHistory: true }
    )(state);
  }

  // if (action.type === EDITING_BEGAN) {
  //   return R.assoc<boolean, ProjectState, 'editing'>('editing', true, state);
  // }

  // if (action.type === EDITING_ENDED) {
  //   return R.assoc<boolean, ProjectState, 'editing'>('editing', false, state);
  // }

  if (action.type === CASE_SURFACE_CHANGED) {
    const {
      payload: { caseId, surface }
    } = action as CaseSurfaceChangedAction;

    return updateCurrent(overCaseId(caseId)(setCaseSurface(surface)), { appendHistory: true })(
      state
    );
  }

  if (action.type === CASE_CUSTOM_DISTRIBUTION_CHANGED) {
    const {
      payload: { caseId, customDistribution }
    } = action as CaseCustomDistributionChangedAction;

    return updateCurrent(overCaseId(caseId)(setCaseCustomDistribution(customDistribution)), {
      appendHistory: true
    })(state);
  }

  if (action.type === CASE_EDITING_STAGE_CHANGED) {
    const {
      payload: { caseId, editingStage }
    } = action as CaseEditingStageChangedAction;

    return updateCurrent(overCaseId(caseId)(setCaseEditingStage(editingStage)), {
      appendHistory: true
    })(state);
  }

  if (action.type === SET_CASE_LODGMENT_TYPES_DISTRIBUTION) {
    const {
      payload: { caseId, distribution }
    } = action as SetCaseLodgmentsTypesDistributionAction;

    return updateCurrent(overCaseId(caseId)(setCaseLodgmentTypesDistribution(distribution)), {
      appendHistory: true
    })(state);
  }

  if (action.type === SET_CASE_TOP_LEVELS_COUNT) {
    const {
      payload: { caseId, count }
    } = action as SetCaseTopLevelsCountAction;

    return updateCurrent(overCaseId(caseId)(setCaseTopLevelsCount(count)), { appendHistory: true })(
      state
    );
  }
  if (action.type === SET_CASE_BASEMENT_LEVELS_COUNT) {
    const {
      payload: { caseId, basementLevelsCount }
    } = action as SetCaseBasementLevelsCountAction;

    return updateCurrent(overCaseId(caseId)(setCaseBasementsLevelsCount(basementLevelsCount)), {
      appendHistory: true
    })(state);
  }
  if (action.type === SET_TOP_LEVELS_SPECIFICATIONS) {
    const {
      payload: { caseId, levels }
    } = action as SetTopLevelsSpecificationsAction;

    return updateCurrent(overCaseId(caseId)(setCaseTopLevelsSpecifications(levels)), {
      appendHistory: true
    })(state);
  }
  if (action.type === SET_BASEMENT_LEVELS_SPECIFICATIONS) {
    const {
      payload: { caseId, levels }
    } = action as SetBasementLevelsSpecificationsAction;

    return updateCurrent(overCaseId(caseId)(setCaseBasementLevelsSpecifications(levels)), {
      appendHistory: true
    })(state);
  }
  if (action.type === SET_CASE_LEVEL_SPECIFICATION) {
    const {
      payload: { caseId, level }
    } = action as SetCaseLevelSpecificationAction;

    return updateCurrent(overCaseId(caseId)(setCaseLevelSpecification(level)), {
      appendHistory: true
    })(state);
  }
  if (action.type === CASE_PARKINGS_SPECIFICATION_CHANGED) {
    const {
      payload: { caseId, parkingsSpecification }
    } = action as SetCaseParkingsSpecificationAction;

    return updateCurrent(
      overCaseId(caseId)(R.over(R.lensProp('parking'), R.mergeLeft(parkingsSpecification))),
      {
        appendHistory: true
      }
    )(state);
  }

  if (action.type === SET_LODGMENT_TYPE_ROOM) {
    const {
      payload: { caseId, lodgmentType, roomIndex, roomSpecification }
    } = action as SetLodgmentTypeRoomAction;

    return updateCurrent(
      overCaseId(caseId)(
        setCaseLodgmentTypeSpecificationRoom(lodgmentType, roomIndex, roomSpecification)
      ),
      {
        appendHistory: true
      }
    )(state);
  }
  if (action.type === SET_LODGMENT_TYPE_THEORETICAL_SURFACE) {
    const {
      payload: { caseId, lodgmentType, surface }
    } = action as SetLodgmentTypeSurfaceAction;

    return updateCurrent(
      overCaseId(caseId)(setCaseLodgmentTypeSpecificationTheoreticalSurface(lodgmentType, surface)),
      {
        appendHistory: true
      }
    )(state);
  }
  if (action.type === SET_LODGMENT_TYPE_EXPOSURE_RATE) {
    const {
      payload: { caseId, lodgmentType, exposureRate }
    } = action as SetLodgmentTypeExposureRateAction;

    return updateCurrent(
      overCaseId(caseId)(setCaseLodgmentTypeSpecificationExposureRate(lodgmentType, exposureRate)),
      {
        appendHistory: true
      }
    )(state);
  }
  if (action.type === SET_LODGMENT_TYPES_HEATING_SYSTEM) {
    const {
      payload: { caseId, lodgmentTypes, heatingSystem }
    } = action as SetLodgmentTypesHeatingSystemAction;

    return updateCurrent(
      overCaseId(caseId)(
        setCaseLodgmentTypesSpecificationHeatingSystem(lodgmentTypes, heatingSystem)
      ),
      {
        appendHistory: true
      }
    )(state);
  }

  if (action.type === SET_CASE_PROPERTY) {
    const {
      payload: { caseId, property }
    } = action as CasePropertyChangedAction;

    return updateCurrent(overCaseId(caseId)(setCaseProperty(property)), {
      appendHistory: true
    })(state);
  }

  if (action.type === BUILDING_SPECIFICATION_CHANGED) {
    const {
      payload: { buildingId, buildingSpecification }
    } = action as BuildingSpecificationChangedAction;

    return updateCurrent(
      overBuildingId(buildingId)(
        (theBuilding) =>
          R.mergeDeepLeft(buildingSpecification, theBuilding) as BuildingSpecification
      ),
      {
        appendHistory: true
      }
    )(state);
  }

  if (action.type === CASE_SPECIFICATION_CHANGED) {
    const {
      payload: { caseId, caseSpecification }
    } = action as CaseSpecificationChangedAction;

    return updateCurrent(
      overCaseId(caseId)(
        (theCase) => R.mergeDeepLeft(caseSpecification, theCase) as CaseSpecification
      ),
      {
        appendHistory: true
      }
    )(state);
  }

  // Floor Space
  if (action.type === FLOOR_SPACE_ADDED) {
    const { payload } = action as FloorSpaceAddedAction;
    if (!state.current?.buildings) return state;

    const buildingSpecifications = state.current?.buildings as BuildingSpecification[];
    const floorSpacesWithoutGeometry = getFloorSpaceFeaturesWithoutGeometry(
      getProjectFloorSpaceFeatures(buildingSpecifications)
    );
    if (floorSpacesWithoutGeometry.length === 0) {
      return updateCurrent(
        R.pipe(
          (project) => addABuilding(project),
          (projectWithNewBuilding) =>
            overCaseId(getProjectLastCaseId(projectWithNewBuilding))(
              setFloorSpaceFeature(payload)(projectWithNewBuilding)
            )(projectWithNewBuilding)
        ),
        { appendHistory: true }
      )(state);
    } else {
      return updateCurrent(
        (current) =>
          toggleSelectFloorSpaceFeature(payload.id)(
            overFloorSpaceId(floorSpacesWithoutGeometry[0].id as string)(
              setFloorSpaceFeature(payload)(current)
            )(current)
          ),
        { appendHistory: true }
      )(state);
    }
  }
  if (action.type === FLOOR_SPACE_CHANGED) {
    const { payload } = action as FloorSpaceChangedAction;

    return updateCurrent(
      (current) => overFloorSpaceId(payload.id)(setFloorSpaceFeature(payload)(current))(current),
      { appendHistory: true }
    )(state);
  }
  if (action.type === FLOOR_SPACE_REMOVED) {
    const { payload } = action as RemoveFloorSpacesAction;
    return updateCurrent(overFloorSpaceId(payload)(removeFloorSpaceFeature()), {
      appendHistory: true
    })(state);
  }
  if (action.type === ADD_OR_REMOVE_PARCEL) {
    const { payload } = action as RemoveParcelAction;
    return updateCurrent(
      (project) => {
        const isParcel = R.find(R.propEq('id', payload.id));
        const parcels = project.parcels || [];
        return {
          ...project,
          parcels: isParcel(parcels)
            ? R.reject((parcel: FeatureParcel) => parcel.id === payload.id, parcels)
            : R.prepend(payload, parcels)
        };
      },
      { appendHistory: true }
    )(state);
  }
  if (action.type === FLOOR_SPACE_SELECTED) {
    const { payload: id } = action as FloorSpacesSelectAction;
    return updateCurrent(toggleSelectFloorSpaceFeature(id), {
      appendHistory: true
    })(state);
  }

  if (reportFetched.match(action)) {
    const { payload } = action as ReportFetchedAction;
    return updateCurrent(
      (p) => {
        const isFromFetchReportAfterLoad = payload.projectRevision === p.reportRevision;
        if (isFromFetchReportAfterLoad) {
          return p;
        } else {
          const newRevision = uniqId(); // necessary to trigger a project saving with revision = reportRevision
          return {
            ...p,
            revision: newRevision,
            reportRevision: newRevision
          };
        }
      },
      { appendHistory: true, revisionMustBeUpdated: false }
    )(state);
  }

  return state;
};

export const projectStateReconciler = (saved: ProjectState): ProjectState => ({
  ...saved,
  state:
    saved.state === 'CREATING' ||
    saved.state === 'SAVING' ||
    saved.state === 'LOADING' ||
    saved.state === 'FAILED_TO_SAVE' ||
    saved.state === 'FAILED_TO_FETCH'
      ? 'IDLE'
      : saved.state
});
