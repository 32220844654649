import { LevelGranulometryInitialEntries } from '../../../../LevelGranulometry';
import { CaseFormDataLegacy } from '../../../../../../legacy/caseFormData';
import { isGroundLevel } from '../../../is/isGroundLevel';
import { getUpperLevelSurfaceForSale } from './getUpperLevelSurfaceForSale';
import { getGroundLevelSurfaceForSale } from './getGroundLevelSurfaceForSale';

export const getTopLevelSurfaceForSale = (
  caseData: CaseFormDataLegacy,
  levelGranulometry: LevelGranulometryInitialEntries
): number =>
  isGroundLevel(levelGranulometry)
    ? getGroundLevelSurfaceForSale(caseData, levelGranulometry)
    : getUpperLevelSurfaceForSale(caseData, levelGranulometry);
