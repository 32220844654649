import { getDefaultUnitConvertedPropertyValue } from '../../../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getTopLevelExternalInsulationThickness = (
  caseGranulometry: CaseGranulometry
): number => {
  const externalInsulationRate = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'externalInsulationRate'
  ) as number;
  const externalInsulationThickness = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'externalInsulationThickness'
  ) as number;
  return externalInsulationRate * externalInsulationThickness;
};
