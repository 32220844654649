import * as R from 'ramda';
import { Project } from '../../project/Project';
import { LodgmentTypeSpecification } from '../../specification/lodgmentTypes/LodgmentTypeSpecification';
import { mapCases } from '../../project/map/mapCases';
import { LodgmentType } from '../../specification/lodgmentTypes/LodgmentType';
import { HeatingSystem } from '../../specification/lodgmentTypes/HeatingSystem';

export const migrateProject11To12 = (project: Project): Project => {
  return mapCases((eachCase) => ({
    ...eachCase,
    lodgmentsTypesSpecifications: R.map((lodgmentTypeSpecification: LodgmentTypeSpecification) =>
      lodgmentTypeSpecification.defaultHeatingSystem === undefined
        ? R.pipe<[LodgmentTypeSpecification], LodgmentTypeSpecification>(
            R.assoc(
              'defaultHeatingSystem',
              lodgmentTypeSpecification.lodgmentType === LodgmentType.T1
                ? HeatingSystem.IndividualElectricHeating
                : HeatingSystem.IndividualGasHeating
            )
          )(lodgmentTypeSpecification)
        : lodgmentTypeSpecification
    )(eachCase.lodgmentsTypesSpecifications)
  }))(project);
};
