import { EMPTY, from, Observable, of } from 'rxjs';
import { Action } from 'redux';
import { catchError, mergeMap, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { State } from '../reducers';
import { selectUserToken } from '../selectors/user/userToken.selector';
import { selectUserId } from '../selectors/user/userId.selector';
import { selectRoute } from '../selectors/navigation/getRoute.selector';
import { selectProject, selectProjectId } from '../selectors/project';
import { projectFetched } from '../actions/projectFetched.action';
import { selectIsExistingProject } from '../selectors/project/isExistingProject.selector';
import { loadProject } from '../actions/loadProject.action';
import { fetchProjectState } from '../../api/v1/fetchProjectState.api';
import { ApiAuthError } from '../../api/v1/errors/auth.error';
import { ApiRedirectError } from '../../api/v1/errors/redirect.error';
import userState from '../reducers/user.slice';
import { projectFetchFailed } from '../actions/projectsFetchFailed.action';
import { ExistingProject } from '../../domain/project/Project';
import { goToProjectDetails } from '../actions/navigations.actions';

type LocationActionWithState = [LocationChangeAction, State];

export const fetchProjectEpic: Epic<Action, Action, State> = (actions$, state$) =>
  actions$.pipe(
    ofType(LOCATION_CHANGE),
    withLatestFrom<LocationChangeAction, Observable<State>>(state$),
    switchMap(([_, state]: LocationActionWithState) => {
      if (selectIsExistingProject(state)) {
        const route = selectRoute(state);
        const projectId = selectProjectId(state);
        const currentProject = selectProject(state);

        if (
          route.isProject &&
          projectId &&
          (!currentProject || (currentProject as ExistingProject).id !== projectId)
        ) {
          const userId = selectUserId(state);
          const userToken = selectUserToken(state);

          return from(fetchProjectState(projectId, userId, userToken)).pipe(
            mergeMap((project) => from([projectFetched(project), goToProjectDetails(project.id)])),
            startWith(loadProject(projectId)),
            catchError((error: Error) => {
              if (error instanceof ApiAuthError || error instanceof ApiRedirectError)
                return of(userState.actions.sessionExpired());

              // eslint-disable-next-line no-console
              console.error(error);
              alert(
                'Impossible de récupérer votre projet. Notre équipe a été informée du problème. Veuillez réessayer ultérieurement.'
              );

              return of(projectFetchFailed());
            })
          );
        }
      }
      return EMPTY;
    })
  );
