import { Project } from '../../project/Project';
import { mapCases } from '../../project/map/mapCases';
import * as R from 'ramda';
import { CaseSpecification } from '../../specification/cases/CaseSpecification';
import { Property } from '../../specification/cases/Property';
import { removeCaseProperty } from '../utils/removeCaseProperty';
import { addCaseProperty } from '../utils/addCaseProperty';
import { defaultCaseProperties } from '../../../resources/defaultCaseProperties';

export const migrateProject28To29 = (project: Project): Project =>
  mapCases((eachCase) =>
    R.pipe<
      [CaseSpecification],
      CaseSpecification,
      CaseSpecification,
      CaseSpecification,
      CaseSpecification,
      CaseSpecification,
      CaseSpecification,
      CaseSpecification
    >(
      // Remove stairsShaftConcreteStairCount case properties
      (cS) => removeCaseProperty(cS, 'stairsShaftConcreteStairCount'),
      // Add 5 outsideStairShaft relative case properties
      (cS) =>
        addCaseProperty(
          cS,
          defaultCaseProperties.find(
            (property) => property.name === 'outsideStairShaftCount'
          ) as Property,
          cS.properties.findIndex((property) => property.name === 'elevatorShaftCount')
        ),
      (cS) =>
        addCaseProperty(
          cS,
          defaultCaseProperties.find(
            (property) => property.name === 'outsideStairShaftLength'
          ) as Property,
          cS.properties.findIndex(
            (property) => property.name === 'lodgmentInsideWallsPartitionRate'
          )
        ),
      (cS) =>
        addCaseProperty(
          cS,
          defaultCaseProperties.find(
            (property) => property.name === 'outsideStairShaftWidth'
          ) as Property,
          cS.properties.findIndex(
            (property) => property.name === 'lodgmentInsideWallsPartitionRate'
          )
        ),
      (cS) =>
        addCaseProperty(
          cS,
          defaultCaseProperties.find(
            (property) => property.name === 'outsideStairsShaftBearingLength'
          ) as Property,
          cS.properties.findIndex(
            (property) => property.name === 'lodgmentInsideWallsPartitionRate'
          )
        ),
      (cS) =>
        addCaseProperty(
          cS,
          defaultCaseProperties.find(
            (property) => property.name === 'outsideStairsShaftBearingWidth'
          ) as Property,
          cS.properties.findIndex(
            (property) => property.name === 'lodgmentInsideWallsPartitionRate'
          )
        ),
      // Update 4 stairShaft relative case properties
      (cS) =>
        R.over(
          R.lensPath(['properties']),
          R.map((property: Property) =>
            R.pipe<[Property], Property, Property, Property, Property>(
              (p) =>
                p.name === 'stairsShaftLength'
                  ? { ...p, title: 'Longueur de la cage d’escalier interne' }
                  : p,
              (p) =>
                p.name === 'stairsShaftWidth'
                  ? { ...p, title: 'Largeur de la cage d’escalier interne' }
                  : p,
              (p) =>
                p.name === 'stairsShaftBearingLength'
                  ? { ...p, title: 'Longueur du palier d’escalier interne' }
                  : p,
              (p) =>
                p.name === 'stairsShaftBearingWidth'
                  ? { ...p, title: 'Largeur du palier d’escalier interne' }
                  : p
            )(property)
          ),
          cS
        )
    )(eachCase)
  )(project);
