import * as R from 'ramda';
import { createSelector, Selector } from 'reselect';
import { selectPath } from '../navigation/getPath.selector';
import { selectCurrentCaseProperties } from '../specification/currentCase/properties.selector';
import { Property } from '../../../domain/specification/cases/Property';
import { State } from '../../reducers';
import { Maybe } from '../../../utils/Maybe';
import { caseGranulometryEditPropertyRoute } from '../../../routes/toolboxPanels/cases';

export const selectCurrentlyEditedProperty: Selector<State, Maybe<Property>> = createSelector(
  [selectCurrentCaseProperties, selectPath],
  (currentCaseProperties, path) =>
    currentCaseProperties?.find(
      R.propEq('name', caseGranulometryEditPropertyRoute.matchesPath(path).params?.propertyName)
    )
);
