import * as React from 'react';
import { LodgmentTypeSpecification } from '../../../../domain/specification/lodgmentTypes/LodgmentTypeSpecification';
import { IconObject } from '../../../ui/Icons/iconObject';
import { getLodgmentTypeTriggerId } from '../../../../routes/toolboxPanels/triggers';
import { goToGranulometryLodgmentType } from '../../../../store/actions/navigations.actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCaseLodgmentsTypesDistributionWithSpecifications } from '../../../../store/selectors/specification/currentCase/lodgmentsTypesDistributionWithSpecifications.selector';
import { selectCurrentCaseLabel } from '../../../../store/selectors/project/currentCaseLabel.selector';
import { selectCurrentCaseIdFromRoute } from '../../../../store/selectors/navigation/caseIdFromRoute.selector';
import { selectProjectId } from '../../../../store/selectors/project';
import { CELL_WIDTH } from '../CardToScroll';

export const Column2 = () => {
  const projectId = useSelector(selectProjectId);
  const caseId = useSelector(selectCurrentCaseIdFromRoute);
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const distribution = useSelector(selectCurrentCaseLodgmentsTypesDistributionWithSpecifications);
  const dispatch = useDispatch();

  if (!caseLabel) return null;

  return (
    <div className="column-2 with-scrollbar" tabIndex={-1}>
      <div style={{ width: CELL_WIDTH * (distribution?.length || 0) + 1 + 'px' }}>
        {distribution?.map((typeData: LodgmentTypeSpecification) => (
          <div className="cell" key={typeData.lodgmentType}>
            <IconObject
              id={getLodgmentTypeTriggerId(caseLabel, typeData.lodgmentType)}
              type="object"
              iconName="lodgment"
              color={typeData.color}
              customText={typeData.lodgmentType}
              customTextPosition="bottom right"
              onClick={() =>
                projectId &&
                caseId &&
                dispatch(goToGranulometryLodgmentType(projectId, caseId, typeData.lodgmentType))
              }
            />
          </div>
        ))}
        <div className="clear" />
      </div>
    </div>
  );
};
