import * as React from 'react';
import * as R from 'ramda';
import { ScrollSyncPane } from 'react-scroll-sync';
import { useSelector } from 'react-redux';
import { Column1 } from './TopLevelLines/Column1';
import { Column2 } from './TopLevelLines/Column2';
import { Column3 } from './TopLevelLines/Column3';
import { Column4 } from './TopLevelLines/Column4';
import { TopLevelSurfaceName } from '../../../domain/granulometry/cases/queries/surfaces/getCaseTopLevelsSurfaces';
import { Column4Enlarged } from './TopLevelLines/Column4/Column4Enlarged';
import { selectCurrentCaseGranulometryTopLevelsCount } from '../../../store/selectors/granulometry/currentCase/currentCaseGranulometryTopLevelsCount.selector';

interface TopLevelLinesProps {
  isToolboxEnlarged: boolean;
  selectedLevelSurface: TopLevelSurfaceName;
}

export const TopLevelLines = ({ isToolboxEnlarged, selectedLevelSurface }: TopLevelLinesProps) => {
  const currentCaseGranulometryTopLevelsCount = useSelector(
    selectCurrentCaseGranulometryTopLevelsCount
  );

  if (!currentCaseGranulometryTopLevelsCount) return null;

  return (
    <>
      {R.times((i) => {
        const levelSpecification = { level: i };
        return (
          <div key={levelSpecification.level} className="line top-level-line">
            <Column1 levelSpecification={levelSpecification} />
            <ScrollSyncPane>
              <Column2 levelSpecification={levelSpecification} />
            </ScrollSyncPane>
            <Column3 levelSpecification={levelSpecification} />
            {isToolboxEnlarged ? (
              <Column4Enlarged levelSpecification={levelSpecification} />
            ) : (
              <Column4
                levelSpecification={levelSpecification}
                selectedLevelSurface={selectedLevelSurface}
              />
            )}
          </div>
        );
      }, currentCaseGranulometryTopLevelsCount).reverse()}
    </>
  );
};
