import * as R from 'ramda';
import { BuildingSpecification } from '../specification/buildings/BuildingSpecification';
import { Surface } from '../specification/Surface';
import { NonEmptyArray } from '../../utils/NonEmptyArray';
import { ProjectLocation, ProjectWithLocation } from '../ProjectLocation';
import { ProjectCstbIndicatorsParameters } from './ProjectCstbIndicatorsParameters';
import { CustomPriceList } from '../Account';

export const CURRENT_MIGRATION_VERSION = 37;

export interface UnsavedProject extends ProjectLocation, ProjectCstbIndicatorsParameters {
  name: string;
  description?: string;
  surface?: Surface;
  projectedSurface: Surface;
  buildings: NonEmptyArray<BuildingSpecification>;
  migrationVersion: number;
  revision: string;
  reportRevision: string;
  customPriceListId: CustomPriceList['id'] | 'none';
}

export interface ExistingProjectBasicInfo {
  name: string;
  description?: string;
  id: string;
  createdAt: string;
  modifiedAt: string;
}

export interface ExistingProject extends UnsavedProject, ExistingProjectBasicInfo {}

export type Project = UnsavedProject | ExistingProject;

type IsNewProject = (project: Project) => project is UnsavedProject;
export const isNewProject = R.compose(R.isNil, R.propOr(null, 'id')) as IsNewProject;

type IsExistingProject = (project: Project) => project is ExistingProject;
export const isExistingProject = R.complement(isNewProject) as IsExistingProject;

export const toString = (project: Project): string =>
  isNewProject(project) ? 'new project' : `project ${(project as ExistingProject).id}`;

export const projectHasLocation = (project: Project): project is ProjectWithLocation =>
  typeof project.latitude === 'number' && typeof project.longitude === 'number';
