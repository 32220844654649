import * as R from 'ramda';
import { createSelector, Selector } from 'reselect';
import { selectPath } from '../navigation/getPath.selector';
import { selectCurrentCaseProjection } from '../projection/currentCase/projection.selector';
import { LevelSpecification } from '../../../domain/specification/levels/LevelSpecification';
import { State } from '../../reducers';
import { caseGranulometryEditLevelRoute } from '../../../routes/toolboxPanels/cases';
import { Maybe } from '../../../utils/Maybe';

export const selectCurrentlyEditedLevel: Selector<
  State,
  Maybe<LevelSpecification>
> = createSelector([selectCurrentCaseProjection, selectPath], (caseSpecification, path) => {
  const levelParam = caseGranulometryEditLevelRoute.matchesPath(path).params?.levelId;
  if (!levelParam) return;
  const levelNumber = parseFloat(levelParam);

  return (
    caseSpecification?.levels?.find(R.propEq('level', levelNumber)) ??
    ({ level: levelNumber } as LevelSpecification)
  );
});
