import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from 'components/ui/SpecialLabel';
import { FormGroup } from 'components/ui/FormGroup';
import { Card } from '../../../../ui/Card';
import { Input } from '../../../../ui/input/Input';
import { Surface } from '../../../../../domain/specification/Surface';
import { projectSurfaceIsValid } from '../../../../../domain/checks/project/projectSurfaceIsValid';
import {
  DEFAULT_PROJECT_SURFACE,
  MAXIMUM_PROJECT_CBS,
  MINIMUM_PROJECT_CBS
} from '../../../../../constants/appConstants';
import { projectSurfaceChanged } from '../../../../../store/actions/projectSurfaceChanged.action';
import { selectProjectSurface } from '../../../../../store/selectors/project/projectSurface.selector';

export const ProjectSurfaceCard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const projectSurface = useSelector(selectProjectSurface);

  const handleProjectSurfaceChange = (surface: Surface | undefined) => {
    if (surface !== undefined && surface.value < MINIMUM_PROJECT_CBS) {
      return false;
    }
    dispatch(projectSurfaceChanged(surface));
  };

  return (
    <Card>
      <SpecialLabel label={t('Areas')} />
      <FormGroup horizontal={true} wrap={true}>
        <Input
          label={`${t('Project surface')} (${t('CBS')})`}
          style={{ width: '40%', flex: '0 0 40%' }}
          name="projectSurface"
          type="number"
          value={projectSurface ? projectSurface.value : undefined}
          step={10}
          handleChange={(value) => {
            handleProjectSurfaceChange(
              value === null || value === 0 ? undefined : new Surface(value)
            );
          }}
          color={projectSurfaceIsValid(projectSurface) ? undefined : 'orange'}
          suffix="m<sup>2</sup>"
          placeholder={DEFAULT_PROJECT_SURFACE}
          minMax={{
            min: MINIMUM_PROJECT_CBS,
            max: MAXIMUM_PROJECT_CBS,
            unit: 'm\u00B2'
          }}
        />
      </FormGroup>
    </Card>
  );
};
