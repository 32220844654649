import { Surface } from '../../specification/Surface';
import { MAXIMUM_PROJECT_CBS, MINIMUM_PROJECT_CBS } from '../../../constants/appConstants';

export const projectSurfaceIsUndefined = (projectSurface?: Surface): boolean =>
  projectSurface === undefined;
export const projectSurfaceIsEmpty = (projectSurface: Surface): boolean =>
  projectSurface === Surface.EMPTY;
export const projectSurfaceIsSmallerThanTheMinimum = (projectSurface: Surface): boolean =>
  projectSurface.value < MINIMUM_PROJECT_CBS;
export const projectSurfaceIsLargerThanTheMaximum = (projectSurface: Surface): boolean =>
  projectSurface.value > MAXIMUM_PROJECT_CBS;

export const projectSurfaceIsValid = (projectSurface?: Surface): boolean =>
  !projectSurfaceIsUndefined(projectSurface) &&
  !projectSurfaceIsEmpty(projectSurface as Surface) &&
  !projectSurfaceIsSmallerThanTheMinimum(projectSurface as Surface) &&
  !projectSurfaceIsLargerThanTheMaximum(projectSurface as Surface);
