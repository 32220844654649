import { LevelSpecification } from '../../domain/specification/levels/LevelSpecification';
import { CaseId } from '../../domain/specification/cases/CaseSpecification';

export const SET_CASE_LEVEL_SPECIFICATION = 'SET_CASE_LEVEL_SPECIFICATION';

export const setCaseLevelSpecification = (caseId: CaseId, level: LevelSpecification) => ({
  type: SET_CASE_LEVEL_SPECIFICATION,
  payload: { caseId, level }
});

export type SetCaseLevelSpecificationAction = ReturnType<typeof setCaseLevelSpecification>;
