import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormGroup } from '../../../../../ui/FormGroup';
import { useParams } from 'react-router';
import { IconObject } from '../../../../../ui/Icons/iconObject';
import { Input } from '../../../../../ui/input/Input';
import { selectCurrentCaseProjection } from '../../../../../../store/selectors/projection/currentCase/projection.selector';
import { getCaseDataProperty } from '../../../../../../domain/specification/cases/queries/get/properties/getCaseDataProperty';
import { casePropertyChanged } from '../../../../../../store/actions/casePropertyChanged.action';
import { STAIR_SHAFT_ICON } from '../../../../../../domain/granulometry/sections/circulationSections/stairsShaft';
import { CaseGranulometryEditSuperstructureRouteParams } from '../../../../../../routes/toolboxPanels/cases';

export const StairShaftCount = () => {
  const { caseId } = useParams<CaseGranulometryEditSuperstructureRouteParams>();
  const currentlyEditedCase = useSelector(selectCurrentCaseProjection);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const stairShaftCountProperty =
    currentlyEditedCase && getCaseDataProperty(currentlyEditedCase, 'stairsShaftCount');
  const handleStairShaftCountChange = React.useCallback(
    (newStairsShaftCount: number | null) => {
      if (
        caseId &&
        stairShaftCountProperty &&
        (typeof newStairsShaftCount === 'number' || newStairsShaftCount === null)
      ) {
        dispatch(
          casePropertyChanged(caseId, {
            ...stairShaftCountProperty,
            value: newStairsShaftCount ?? undefined
          })
        );
      }
    },
    [dispatch, caseId, stairShaftCountProperty]
  );

  if (!currentlyEditedCase || !stairShaftCountProperty) return null;

  return (
    <FormGroup horizontal={true} wrap={true}>
      <Input
        style={{ width: '40%', flex: '0 0 40%' }}
        name={`case${caseId}StairShaftCount`}
        label={t('Stair shafts')}
        // info={t('stairsShaftCount.infos')}
        type="number"
        min={stairShaftCountProperty.min}
        max={stairShaftCountProperty.max}
        minMax={{
          min: stairShaftCountProperty.min,
          max: stairShaftCountProperty.max,
          unit: stairShaftCountProperty.unit
        }}
        placeholder={stairShaftCountProperty.defaultValue}
        value={stairShaftCountProperty.value}
        handleChange={handleStairShaftCountChange}
        suffix={stairShaftCountProperty.unit}
        icon={<IconObject {...STAIR_SHAFT_ICON} clickable={false} />}
      />
    </FormGroup>
  );
};
