import { createSelector, Selector } from 'reselect';
import { projectInitRoute } from '../../../routes/toolboxPanels/projectDetails';
import { State } from '../../reducers';
import { selectPath } from '../navigation';
import { selectProjectName } from './projectName.selector';
import { selectProjectSurface } from './projectSurface.selector';

export const selectIsInitializeProject: Selector<State, boolean> = createSelector(
  [selectPath, selectProjectName, selectProjectSurface],
  (path, projectName, projectSurface) =>
    !(
      projectInitRoute.matchesPath(path).matchesExact &&
      projectName === undefined &&
      projectSurface === undefined
    )
);
