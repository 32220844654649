import * as R from 'ramda';
import { Project } from '../../project/Project';
import { mapRoomsSpecifications } from '../../project/map/mapRoomsSpecifications';
import {
  BodyguardType,
  isRoomSpecificationABalcony
} from '../../specification/rooms/BalconySpecification';

export const migrateProject12To13 = (project: Project): Project => {
  return mapRoomsSpecifications((eachRoom) =>
    isRoomSpecificationABalcony(eachRoom)
      ? R.assoc('bodyguardType', BodyguardType.Steel)(eachRoom)
      : eachRoom
  )(project);
};
