import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { MaybeFloorSpaceFeature } from '../../../domain/specification/floorSpace/FloorSpaceFeature';

export const setDraw = (floorSpaceFeature: MaybeFloorSpaceFeature, draw: MapboxDraw) => {
  const allFeatures = draw.getAll();
  return draw.set({
    type: 'FeatureCollection',
    features: allFeatures.features.map((f) => {
      if (f.id === floorSpaceFeature.id) {
        return floorSpaceFeature;
      }
      return f;
    })
  });
};
