import { LevelGranulometryInitialEntries } from '../../../../LevelGranulometry';
import { CaseFormDataLegacy } from '../../../../../../legacy/caseFormData';
import { getLevelRealBuiltSurface } from '../../../surfaces/getLevelRealBuiltSurface';
import { getCaseDataCuttedBuiltSurfaceRate } from '../../../../../../legacy/methodsForCases/getCaseDataCuttedBuiltSurfaceRate';
import { getCaseDataSurfaceForSaleRate } from '../../../../../../legacy/methodsForCases/getCaseDataSurfaceForSaleRate';
import { getDefaultUnitConvertedPropertyValue } from '../../../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { getCaseUpperLevelsRealBuiltSurface } from '../../../../../../specification/cases/queries/levels/surfaces/getCaseDataUpperLevelsRealBuiltSurfaces';
import { getLevelMaxSurfaceForSaleFromCaseProjection } from './getLevelMaxSurfaceForSaleFromCaseProjection';
import { getSectionDisplayedSurface } from '../../../../../sections/queries/surfaces/getSectionDisplayedSurface';
import { getLevelTechnicalPermiseSections } from '../../../sections/getLevelTechnicalPermiseSections';
import { getCaseDataRealBuiltSurface } from '../../../../../../legacy/methodsForCases/getCaseDataRealBuiltSurface';

export const getUpperLevelSurfaceForSale = (
  caseData: CaseFormDataLegacy,
  upperLevel: LevelGranulometryInitialEntries
): number => {
  if (caseData.maxSurfaceForSaleHasBeenForced) {
    return getLevelMaxSurfaceForSaleFromCaseProjection(caseData, upperLevel);
  } else {
    const levelRealBuiltSurface = getLevelRealBuiltSurface(upperLevel, caseData);
    const cbsRate = getCaseDataCuttedBuiltSurfaceRate(caseData);
    const sfsRate = getCaseDataSurfaceForSaleRate(caseData);
    const caseUpperLevelsRealBuiltSurface = getCaseUpperLevelsRealBuiltSurface(caseData);
    const caseRealBuiltSurface = getCaseDataRealBuiltSurface(caseData);

    // Redistribution of the negative SFS in the upper levels
    /*
    const groundLevelData = caseData.topLevelsData.find(l => l.level === 0) as TopLevelDataLegacy;
    const groundLevelSurfaceForSale = getGroundLevelSurfaceForSale(caseData, {
      level: 0,
      technicalPremiseSections: groundLevelData.technicalPremiseSections
    });
    */

    let upperLevelSFS = levelRealBuiltSurface;

    upperLevelSFS *= cbsRate * sfsRate;

    // Hall : the hall surface is redistributed in the upperLevels
    if (caseData.hall) {
      upperLevelSFS +=
        (getDefaultUnitConvertedPropertyValue(caseData, 'hallSurface') as number) *
        (levelRealBuiltSurface / (caseUpperLevelsRealBuiltSurface || 1));
    }

    // Technical premise sections
    upperLevelSFS -= getLevelTechnicalPermiseSections(upperLevel).reduce(
      (acc, technicalPremiseSection) => acc + getSectionDisplayedSurface(technicalPremiseSection),
      0
    );

    upperLevelSFS +=
      caseData.topLevelsData.reduce(
        (acc, level) =>
          acc +
          (level.technicalPremiseSections || []).reduce(
            (accTP, technicalPremiseSection) =>
              accTP + getSectionDisplayedSurface(technicalPremiseSection),
            0
          ),
        0
      ) *
      (levelRealBuiltSurface / (caseRealBuiltSurface || 1));

    // Redistribution of the negative SFS in the upper levels
    /* if (groundLevelSurfaceForSale < 0) {
      upperLevelSFS += groundLevelSurfaceForSale / getCaseDataProjectedUpperLevelCount(caseData);
    } */

    return upperLevelSFS;
  }
};
