import { ReportEntry } from '../../domain/report/ReportEntry';
import { roundWith2Decimal } from '../../utils/round/roundWith2Decimal';
import * as R from 'ramda';
import { sumCo2QuantitiesInReportBy } from '../utils/sumCo2QuantitiesInReportBy';
import { sortReportByRefSsLot2 } from '../utils/sortReportByRefSsLot2';

export interface VizcabJsonEntry {
  building: string;
  case: string;
  projectName: string;
  element: string;
  refLot: string;
  refSsLot1: string;
  co2Title: string;
  co2Id: string;
  co2Quantity: string;
  unit: string;
  dve: string;
  deType: string;
  comments: string;
}

export type VizcabJson = Partial<VizcabJsonEntry>[];

const columns: (keyof VizcabJsonEntry)[] = [
  'building',
  'case',
  'projectName',
  'element',
  'refLot',
  'refSsLot1',
  'co2Title',
  'co2Id',
  'co2Quantity',
  'unit',
  'dve',
  'deType',
  'comments'
];

export const makeVizcabJson = (reportEntries: ReportEntry[]): VizcabJson =>
  R.pipe<[ReportEntry[]], ReportEntry[], ReportEntry[], VizcabJson>(
    sumCo2QuantitiesInReportBy(['building', 'ssLot2', 'co2Id', 'co2Title']),
    sortReportByRefSsLot2,
    R.reduce<ReportEntry, VizcabJson>((acc, reportEntry) => {
      const hasToBeSkipped = reportEntry.co2Quantity === 0 || !!reportEntry.co2Id.match(/[a-zA-Z]/);
      return !hasToBeSkipped
        ? [
            ...acc,
            columns.reduce((obj: Partial<VizcabJsonEntry>, key) => {
              let value: string | number;
              if (key === 'element') {
                value = reportEntry.product;
              } else if (key === 'unit') {
                value = reportEntry.co2QuantityUnit;
              } else if (key === 'co2Quantity') {
                value = roundWith2Decimal(reportEntry.co2Quantity);
              } else if (
                key === 'projectName' ||
                key === 'case' ||
                key === 'dve' ||
                key === 'deType' ||
                key === 'comments'
              ) {
                value = '';
              } else {
                value = reportEntry[key];
              }
              // Cleaning string values to preserve csv format
              obj[key] = value
                .toString()
                .replace(/;|,|:|\s\s/gm, ' ')
                .replace('  ', ' ')
                .replace('  ', ' ')
                .replace('  ', ' ');
              return obj;
            }, {})
          ]
        : acc;
    }, [])
  )(reportEntries);
