import * as R from 'ramda';
import { Char } from '../../Char';
import { getLevelLevel, LevelGranulometry } from '../levels/LevelGranulometry';
import { CaseId } from '../../specification/cases/CaseSpecification';
import { CarpentryType } from '../../specification/cases/CarpentryType';
import { CaseFormDataLegacy } from '../../legacy/caseFormData';
import { getLevelAlreadyCalculatedRealBuiltSurface } from '../levels/queries/surfaces/getLevelAlreadyCalculatedRealBuiltSurface';
import { Property } from '../../specification/cases/Property';
import { MaybeFloorSpaceFeature } from '../../specification/floorSpace/FloorSpaceFeature';
import { HeatingDistribution } from '../../specification/cases/HeatingDistribution';
import { BuildingId } from '../../specification/buildings/BuildingSpecification';
import { RealTechnicalSheathCountByLevel } from './queries/technicalSheaths/getCaseRealTechnicalSheathsCountByLevel';
import { MasonryType } from '../../specification/cases/MasonryType';
import { WoodEnvelopeType } from '../../specification/cases/WoodEnvelopeType';
import { GrossFloorSurfaceEffByLevel } from './queries/getCaseUpperLevelsGrossFloorSurfaceDistribution';
import { CarpentryOccultationsType } from '../../specification/cases/CarpentryOccultationsType';
import { CarpentryOccultationsMotorisationType } from '../../specification/cases/CarpentryOccultationsMotorisationType';
import { OutsideLevelGranulometry } from '../levels/OutsideLevelGranulometry';

export interface CaseOutsideWallTypeRatesSpecifications {
  outsideWallConcreteRate?: number;
  outsideWallMasonryRate?: number;
  outsideWallWoodRate?: number;
}

export interface CaseGranulometry {
  id: CaseId;
  labelIndex: Char;
  name: string;
  title: string;
  levels: LevelGranulometry[];
  outsideLevels?: OutsideLevelGranulometry[];
  floorSpaceFeature?: MaybeFloorSpaceFeature;
  allBasementsHeight: number;
  properties: Property[];
  initialSpecifications: CaseFormDataLegacy;
  carpentryType: CarpentryType;
  carpentryDayTimeOccultationsType: CarpentryOccultationsType;
  carpentryDayTimeOccultationsTypeIsMonobloc: boolean;
  carpentryNightTimeOccultationsType: CarpentryOccultationsType;
  carpentryNightTimeOccultationsTypeIsMonobloc: boolean;
  carpentryOccultationsMotorisationType: CarpentryOccultationsMotorisationType;
  masonryType: MasonryType;
  woodEnvelopeType: WoodEnvelopeType;
  heatingDistribution: HeatingDistribution;
  direction: 'rtl' | 'ltr';
  technicalSheathCountByLevel: RealTechnicalSheathCountByLevel[];
  diffBetweenRBSWithoutFloorSpaceAndRBSWithFloorSpace?: number;
  savedTopLevelsGfsEffFirstDistribution?: GrossFloorSurfaceEffByLevel;
}

export type SurfaceByLevel = Record<string, number>;

export const getCaseRealBuiltSurfaceByLevel = (
  caseGranulometry: CaseGranulometry
): SurfaceByLevel =>
  R.pipe<
    [CaseGranulometry],
    LevelGranulometry[],
    Record<string, LevelGranulometry>,
    SurfaceByLevel
  >(R.prop('levels'), R.indexBy(R.compose(R.toString, getLevelLevel)), (levels) =>
    R.map(
      (level: LevelGranulometry) => getLevelAlreadyCalculatedRealBuiltSurface(level).value,
      levels
    )
  )(caseGranulometry);

export interface CaseGranulometryWithIndexes extends CaseGranulometry {
  buildingIndex: number;
  buildingId: BuildingId;
  indexInBuilding: number;
}
