import { createSelector, Selector } from 'reselect';
import { selectPath } from '../navigation';
import { deliverablesExportReportRoute } from '../../../routes/toolboxPanels/deliverables';
import { State } from '../../reducers';
import { Maybe } from '../../../utils/Maybe';

export const selectReportNomenclatureIdFromRoute: Selector<State, Maybe<string>> = createSelector(
  [selectPath],
  (path) => deliverablesExportReportRoute.matchesPath(path).params?.reportId
);
