import { Granulometry } from '../../../../granulometry/Granulometry';
import { CaseGranulometryWithIndexes } from '../../../../granulometry/cases/CaseGranulometry';

export const getProjectCasesGranulometries = (
  granulometry: Granulometry
): CaseGranulometryWithIndexes[] =>
  granulometry.reduce(
    (casesGranulometries, buildingGranulometry, buildingIndex) =>
      buildingGranulometry.cases.reduce(
        (acc, eachCase, indexInBuilding) => [
          ...casesGranulometries,
          {
            ...eachCase,
            buildingIndex,
            buildingId: buildingGranulometry.id,
            indexInBuilding
          } as CaseGranulometryWithIndexes
        ],
        casesGranulometries
      ),
    [] as CaseGranulometryWithIndexes[]
  );
