import { createSelector, Selector } from 'reselect';
import { State } from '../../../reducers';
import { selectIsBasementsOpened } from './isBasementsOpened.selector';
import { selectIsCaseEnergyAndNetworksOpened } from './isCaseEnergyAndNetworksOpened.selector';
import { selectIsCaseFifthFacadeOpened } from './isCaseFifthFacadeOpened.selector';
import { selectIsCaseGroundAdaptationsOpened } from './isCaseGroundAdaptationsOpened.selector';
import { selectIsCaseInfrastructureOpened } from './isCaseInfrastructureOpened.selector';
import { selectIsCaseOtherPropertiesOpened } from './isCaseOtherPropertiesOpened.selector';
import { selectIsCaseSuperstructureOpened } from './isCaseSuperstructureOpened.selector';
import { selectIsProjectDetailsFloorSpaceOpened } from './isProjectDetailsFloorSpaceOpened.selector';
import { selectIsProjectDetailsGingerOpened } from './isProjectDetailsGingerOpened.selector';
import { selectIsCaseFloorSpaceOpened } from './isCaseFloorSpaceOpened.selector';

export const selectIsAnyWindowsOpened: Selector<State, boolean> = createSelector(
  [
    selectIsBasementsOpened,
    selectIsCaseFloorSpaceOpened,
    selectIsCaseEnergyAndNetworksOpened,
    selectIsCaseFifthFacadeOpened,
    selectIsCaseGroundAdaptationsOpened,
    selectIsCaseInfrastructureOpened,
    selectIsCaseOtherPropertiesOpened,
    selectIsCaseSuperstructureOpened,
    selectIsProjectDetailsFloorSpaceOpened,
    selectIsProjectDetailsGingerOpened
  ],
  (
    isBasementsOpened,
    isCaseFloorSpaceOpened,
    isCaseEnergyAndNetworksOpened,
    isCaseFifthFacadeOpened,
    isCaseGroundAdaptationsOpened,
    isCaseInfrastructureOpened,
    isCaseOtherPropertiesOpened,
    isCaseSuperstructureOpened,
    isProjectDetailsFloorSpaceOpened,
    isProjectDetailsGingerOpened
  ) =>
    isBasementsOpened ||
    isCaseFloorSpaceOpened ||
    isCaseEnergyAndNetworksOpened ||
    isCaseFifthFacadeOpened ||
    isCaseGroundAdaptationsOpened ||
    isCaseInfrastructureOpened ||
    isCaseOtherPropertiesOpened ||
    isCaseSuperstructureOpened ||
    isProjectDetailsFloorSpaceOpened ||
    isProjectDetailsGingerOpened
);
