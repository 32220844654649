import { BasementLevelSpecification } from '../../BasementLevelSpecification';
import * as R from 'ramda';

export const makeBasementLevels = (
  basementLevelsCount: number,
  basementLevelsSpecifications: BasementLevelSpecification[]
) => {
  return R.times((i) => {
    const found = basementLevelsSpecifications.find((l) => l.level === -(i + 1));
    const def = { level: -(i + 1) };
    return found || def;
  }, basementLevelsCount);
};
