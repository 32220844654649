import * as R from 'ramda';
import { Project } from '../../project/Project';
import { LodgmentTypeSpecification } from '../../specification/lodgmentTypes/LodgmentTypeSpecification';
import { mapCases } from '../../project/map/mapCases';
import { HeatingSystem } from '../../specification/lodgmentTypes/HeatingSystem';

export const migrateProject16To17 = (project: Project): Project => {
  return mapCases((eachCase) => ({
    ...eachCase,
    lodgmentsTypesSpecifications: R.map((lodgmentTypeSpecification: LodgmentTypeSpecification) =>
      R.pipe<[LodgmentTypeSpecification], LodgmentTypeSpecification>(
        R.assoc('defaultHeatingSystem', HeatingSystem.CollectiveHeatPumpOnGroundWaterHeating)
      )(lodgmentTypeSpecification)
    )(eachCase.lodgmentsTypesSpecifications)
  }))(project);
};
