import hall, { HallSection } from '../../../../../sections/circulationSections/hall';
import { getDefaultUnitConvertedPropertyValue } from '../../../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const createTopLevelHallSection = (
  caseGranulometry: CaseGranulometry
  // levelGranulometryWithLodgments: LevelGranulometryWithLodgments
): HallSection => {
  const theoricalHallSurface = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'hallSurface'
  ) as number;
  // Hall surface adjustment if there is no lodgment in ground level
  /* const surface =
    getTopLevelLodgmentSectionCount(levelGranulometryWithLodgments) === 0
      ? Math.max(
          theoricalHallSurface +
            (getTopLevelAlreadyCalculatedSurfaceForSale(levelGranulometryWithLodgments).value *
              theoricalHallSurface) /
              theoricalHallSurface,
          0
        )
      : theoricalHallSurface; */
  return hall({
    surface: theoricalHallSurface,
    exposureRate:
      (getDefaultUnitConvertedPropertyValue(
        caseGranulometry.initialSpecifications,
        'hallExposureRate'
      ) as number) * 4,
    width: getDefaultUnitConvertedPropertyValue(
      caseGranulometry.initialSpecifications,
      'hallWidth'
    ) as number,
    entranceHeight: getDefaultUnitConvertedPropertyValue(
      caseGranulometry.initialSpecifications,
      'hallEntranceHeight'
    ) as number,
    entranceLength: getDefaultUnitConvertedPropertyValue(
      caseGranulometry.initialSpecifications,
      'hallEntranceLength'
    ) as number
  });
};
