import { getCaseDataLodgmentDistribution } from './getCaseDataLodgmentDistribution';
import { getCaseDataLodgmentData } from './getCaseDataLodgmentData';
import { getCaseDataEligibleLodgmentRealCountByType } from './getCaseDataEligibleLodgmentRealCountByType';
import { CaseFormDataLegacy } from '../caseFormData';
import { LodgmentType } from '../../specification/lodgmentTypes/LodgmentType';
import { LodgmentSection } from '../../granulometry/sections/lodgmentSections/LodgmentSection';
import { LodgmentTypeSpecification } from '../../specification/lodgmentTypes/LodgmentTypeSpecification';

export interface InitialLodgmentsData {
  type: LodgmentType;
  count: number;
  remaining: number;
  defaultData: LodgmentSection & LodgmentTypeSpecification;
}

/* Get the initial lodgements data in the given case */
export const getCaseDataInitialLodgmentsData = (
  caseData: CaseFormDataLegacy
): InitialLodgmentsData[] => {
  const distribution = getCaseDataLodgmentDistribution(caseData);
  const lodgmentData = getCaseDataLodgmentData(caseData);
  const caseEligibleLodgmentRealCountByType = getCaseDataEligibleLodgmentRealCountByType(caseData);
  const initialLodgmentsData = [];
  // For each lodgement types
  for (let i = 0; i < distribution.length; i++) {
    const lodgmentType = distribution[i].lodgmentType;
    const realCountByType = { ...caseEligibleLodgmentRealCountByType[i] };
    initialLodgmentsData[i] = {
      type: lodgmentType,
      count: realCountByType.count,
      remaining: realCountByType.count,
      defaultData: lodgmentData[lodgmentType]
    };
  }

  return initialLodgmentsData;
};
