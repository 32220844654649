import { getCaseDataLodgmentDistribution } from './getCaseDataLodgmentDistribution';
import { FilledLevelsData, getCaseFilledLevelsData } from './getCaseFilledLevelsData';
import { CaseFormDataLegacy } from '../caseFormData';
import { LevelSFSAdjusment } from './fillCaseLevels';
import * as R from 'ramda';
import { LodgmentTypeSpecification } from '../../specification/lodgmentTypes/LodgmentTypeSpecification';
import { LodgmentSection } from '../../granulometry/sections/lodgmentSections/LodgmentSection';
import { LodgmentType } from '../../specification/lodgmentTypes/LodgmentType';
import { getSectionTheoreticalSurface } from '../../granulometry/sections/queries/surfaces/getSectionTheoreticalSurface';

/* Get the adjusted levels data and lodgments by level in the given case */
export const getCaseDataAdjustedLevelsData = (
  caseData: CaseFormDataLegacy,
  levelsSFSAdjustments?: LevelSFSAdjusment[]
) => {
  const distribution = getCaseDataLodgmentDistribution(caseData);
  const filledLevelsData = getCaseFilledLevelsData(caseData, levelsSFSAdjustments);

  return filledLevelsData.reduce((accL, level) => {
    // If there is remaining surface for sale :
    let remainingSurfaceForSale = level.remainingSurfaceForSale;
    if (remainingSurfaceForSale) {
      const levelLodgments = level.lodgments;
      let remainingLodgmentCount = R.pipe(
        R.valuesIn,
        R.reduce<(LodgmentSection & LodgmentTypeSpecification)[], number>(
          (count, lodgment) => count + lodgment.length,
          0
        )
      )(levelLodgments);
      // For each lodgment types
      const adjustedLodgments = R.pipe(
        R.valuesIn,
        R.reduce((accD, distribEntry: LodgmentSection & LodgmentTypeSpecification) => {
          // For each lodgment of this type in the current level
          const adjustedLodgmentType = R.pipe(
            R.valuesIn,
            R.reduce((accLL, lodgment: LodgmentSection & LodgmentTypeSpecification) => {
              // Calculation of the added surface
              const addedSurface = remainingSurfaceForSale / remainingLodgmentCount--;
              // Calculation of the adjusted surface
              const adjustedSurface = getSectionTheoreticalSurface(lodgment) + addedSurface;
              // Update remaining surface for sale
              remainingSurfaceForSale -= addedSurface;
              // To prevent negative zero javascript bug (ex : -0.00004)...
              if (remainingSurfaceForSale < 0) remainingSurfaceForSale = 0;
              return [
                ...accLL,
                {
                  ...lodgment,
                  surface: adjustedSurface,
                  displayedSurface: adjustedSurface
                }
              ];
            }, [] as (LodgmentSection & LodgmentTypeSpecification)[])
          )(levelLodgments[distribEntry.lodgmentType]);
          return { ...accD, [distribEntry.lodgmentType]: adjustedLodgmentType };
        }, {} as { [key in LodgmentType]: (LodgmentSection & LodgmentTypeSpecification)[] })
      )(distribution);
      const adjustedLevel = { ...level, lodgments: adjustedLodgments };
      return [...accL, adjustedLevel];
    } else {
      return [...accL, level];
    }
  }, [] as FilledLevelsData[]);
};
