import i18next from 'i18next';
import { APP_COLOR } from '../../../../constants/appConstants';
import { getSectionName, Section, SectionDrawn } from '../Section';
import { getDefaultCasePropertyValue } from '../../../specification/cases/queries/get/properties/getDefaultCasePropertyValue';
import { SectionWithTheoreticalSurface } from '../SectionWithTheoreticalSurface';
import { OutsideSectionName } from './OutsideSection';
import { IconObjectProps } from '../../../../components/ui/Icons/iconObject';
import { SectionWithWidth } from '../SectionWithWidth';

interface OutsideStairsShaftProps {
  id: string;
  surface: number;
  length: number;
  width: number;
  bearingLength: number;
  bearingWidth: number;
  group: string;
}

export interface OutsideStairsShaftSection
  extends Section,
    SectionDrawn,
    SectionWithWidth,
    SectionWithTheoreticalSurface {
  name: OutsideSectionName.OutsideStairShaft;
  length: number;
  bearingLength: number;
  bearingWidth: number;
}

export const OUTSIDE_STAIR_SHAFT_ICON = {
  type: 'object',
  iconName: 'stairs',
  color: APP_COLOR,
  strokeStyle: 'dashed'
} as IconObjectProps;

const outsideStairShaft = ({
  id,
  surface,
  length,
  width,
  bearingLength,
  bearingWidth,
  group
}: OutsideStairsShaftProps): OutsideStairsShaftSection => ({
  // Section
  id,
  name: OutsideSectionName.OutsideStairShaft,
  title: i18next.t('outsideStairShaft.title'),
  type: 'outside',
  displayedSurface: surface,
  // SectionDrawn
  surface,
  color: APP_COLOR,
  icon: OUTSIDE_STAIR_SHAFT_ICON,
  group,
  // SectionWithTheoreticalSurface
  theoreticalSurface: undefined,
  defaultTheoreticalSurface:
    getDefaultCasePropertyValue('outsideStairShaftWidth') *
    getDefaultCasePropertyValue('outsideStairShaftLength'),
  // StairsShaftSection
  length,
  width,
  bearingLength,
  bearingWidth
});

export default outsideStairShaft;

export const isSectionAnOutsideStairsShaft = (
  section: Section
): section is OutsideStairsShaftSection =>
  getSectionName(section) === OutsideSectionName.OutsideStairShaft;
