import { getCaseDataPropertyValue } from '../../../cases/queries/get/properties/getCaseDataPropertyValue';
import { LevelSpecification } from '../../LevelSpecification';
import { isTopLevel } from '../is/isTopLevel';
import { CaseProjection } from '../../../../projection/cases/CaseProjection';

export const getLevelDefaultCeilingHeight = (
  currentlyEditedCase: CaseProjection,
  levelSpecification: LevelSpecification
): number =>
  isTopLevel(levelSpecification)
    ? (getCaseDataPropertyValue(currentlyEditedCase, 'topLevelCeilingHeight') as number)
    : (getCaseDataPropertyValue(currentlyEditedCase, 'basementLevelCeilingHeight') as number);
