import { ProjectProjection } from '../../projection/project/ProjectProjection';
import { CaseProjection } from '../../projection/cases/CaseProjection';
import { Surface } from '../../specification/Surface';
import { getCaseSurfaceForSaleMinimum } from '../../specification/cases/queries/get/surfaces/getCaseSurfaceForSaleMinimum';

export const caseSurfaceForSaleIsSmallerThanTheMinimum = (
  projectProjection: ProjectProjection,
  caseProjection: CaseProjection
): boolean =>
  (caseProjection.projectedSurfaces.surfaceForSale as Surface).value <
  getCaseSurfaceForSaleMinimum();
