import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { LodgmentTypeSpecification } from '../../../../../../specification/lodgmentTypes/LodgmentTypeSpecification';
import { getCaseDataLodgmentDistribution } from '../../../../../../legacy/methodsForGranulo/getCaseDataLodgmentDistribution';
import { LodgmentSection } from '../../../../../sections/lodgmentSections/LodgmentSection';

export const createTopLevelLodgmentSections = (
  caseGranulometry: CaseGranulometry,
  levelData: any,
  customLodgments?: (LodgmentTypeSpecification | LodgmentSection)[] // depend of editingStage
): LodgmentSection[] => {
  if (customLodgments) {
    return customLodgments as LodgmentSection[];
  } else {
    const distribution = getCaseDataLodgmentDistribution(caseGranulometry.initialSpecifications);

    let lodgments: LodgmentSection[] = [];
    distribution.forEach((distrib) => {
      const lodgmentType = distrib.lodgmentType;
      levelData.lodgments[lodgmentType].forEach((lodgmentData: any) => {
        const lodgment = { ...lodgmentData };
        // TODO : Are we using all this ?
        /* lodgment.insideWallsPartitionRate = getDefaultUnitConvertedPropertyValue(
          caseGranulometry.initialSpecifications,
          'lodgmentInsideWallsPartitionRate'
        );
        lodgment.concreteSlabConcreteRatio = getDefaultUnitConvertedPropertyValue(
          caseGranulometry.initialSpecifications,
          'lodgmentConcreteSlabConcreteRatio'
        );
        lodgment.concreteSlabFormworkRate = getDefaultUnitConvertedPropertyValue(
          caseGranulometry.initialSpecifications,
          'lodgmentConcreteSlabFormworkRate'
        );
        lodgment.outsideWallsConcreteRatio = getDefaultUnitConvertedPropertyValue(
          caseGranulometry.initialSpecifications,
          'lodgmentOutsideWallsConcreteRatio'
        );
        lodgment.outsideWallsThickness = getDefaultUnitConvertedPropertyValue(
          caseGranulometry.initialSpecifications,
          'outsideWallsThickness'
        ); */
        lodgments = [...lodgments, lodgment] as LodgmentSection[];
      });
    });
    return lodgments;
  }
};
