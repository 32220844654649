import mapbox from '../config/mapboxgl';
import keyboardControl from './keyboardControl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import mapboxgl, { IControl } from 'mapbox-gl';
import { Dispatch } from 'redux';

export const initMapControl = (map: mapboxgl.Map, draw: MapboxDraw, dispatch: Dispatch) => {
  map.addControl(draw as IControl, 'top-right');
  map.addControl(new mapbox.NavigationControl(), 'top-right');
  keyboardControl(map, draw, dispatch);
};
