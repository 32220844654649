import { createSelector, Selector } from 'reselect';
import { selectPath } from '../navigation/getPath.selector';
import { State } from '../../reducers';
import { Maybe } from '../../../utils/Maybe';
import { caseGranulometryEditLodgmentTypeRoomRoute } from '../../../routes/toolboxPanels/cases';

export const selectCurrentlyEditedLodgmentTypeRoom: Selector<State, Maybe<number>> = createSelector(
  [selectPath],
  (path) => Number(caseGranulometryEditLodgmentTypeRoomRoute.matchesPath(path).params?.roomIndex)
);
