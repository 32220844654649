import { getCaseDataCuttedBuiltSurfaceRate } from './getCaseDataCuttedBuiltSurfaceRate';
import { getCaseDataSurfaceForSaleRealRate } from './getCaseDataSurfaceForSaleRealRate';
import { getCaseDataSurfaceForSaleRate } from './getCaseDataSurfaceForSaleRate';
import { getCaseDataCuttedBuiltSurface } from './getCaseDataCuttedBuiltSurface';
import { CaseFormDataLegacy } from '../caseFormData';

export const getCaseDataSurfaceForSale = (caseData: CaseFormDataLegacy): number => {
  let surfaceForSale;
  if (caseData?.surfaceForSale) {
    surfaceForSale = caseData.surfaceForSale;
  } else if (caseData?.cuttedBuiltSurface) {
    surfaceForSale =
      (caseData.cuttedBuiltSurface / getCaseDataCuttedBuiltSurfaceRate(caseData)) *
      getCaseDataSurfaceForSaleRealRate(caseData);
  } else if (caseData?.realBuiltSurface) {
    surfaceForSale =
      getCaseDataCuttedBuiltSurface(caseData) * getCaseDataSurfaceForSaleRate(caseData);
  }
  return surfaceForSale || 0;
};
