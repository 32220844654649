import * as R from 'ramda';
import { createSelector, Selector } from 'reselect';
import { selectFeaturesFloorSpaces } from '../specification/floorSpaces.selector';
import { selectParcels } from '../specification/parcels.selector';
import { FeatureParcel } from '../../../domain/Parcel';
import { FloorSpaceFeature } from '../../../domain/specification/floorSpace/FloorSpaceFeature';
import { State } from '../../reducers';
import { getFloorSpaceFeaturesWithGeometry } from '../../../domain/specification/floorSpace/queries/floorSpaceFeature/getFloorSpaceFeaturesWithGeometry';

export interface MapElementsToDraw {
  parcels: FeatureParcel[] | undefined;
  floorSpaceFeatures: FloorSpaceFeature[];
  isMapInitialized: boolean | undefined;
}

export const selectMapElementsToDraw: Selector<State, MapElementsToDraw> = createSelector(
  [selectFeaturesFloorSpaces, selectParcels],
  (featuresFloorSpaces, parcels) => {
    const onlyFloorSpaceFeatures = getFloorSpaceFeaturesWithGeometry(featuresFloorSpaces) || [];
    return {
      parcels,
      floorSpaceFeatures: onlyFloorSpaceFeatures,
      isMapInitialized:
        onlyFloorSpaceFeatures.length > 0 ||
        (parcels && parcels.length > 0 && parcels.some(R.has('geometry')))
    };
  }
);
