import { CaseGranulometry } from '../../../CaseGranulometry';
import { HeatingSystem } from '../../../../../specification/lodgmentTypes/HeatingSystem';
import { getLodgmentSectionHeatingSystem } from '../../../../sections/queries/heatingSystem/getLodgmentSectionHeatingSystem';

export const getCaseLodgmentSectionsWithIndividualGasHeatingCount = (
  caseGranulometry: CaseGranulometry
): number =>
  caseGranulometry.initialSpecifications.distribution.reduce(
    (acc, distributedLodgmentSection) =>
      getLodgmentSectionHeatingSystem(distributedLodgmentSection) ===
      HeatingSystem.IndividualGasHeating
        ? acc + 1
        : acc,
    0
  );
