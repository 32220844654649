import { CaseGranulometry } from '../CaseGranulometry';
import { getTheoreticalCaseGrossFloorSurfaceEff } from './surfaces/getTheoreticalCaseGrossFloorSurfaceEff';
import { getCaseGranulometryDrawnFloorSpaceFeatureArea } from './get/getCaseGranulometryDrawnFloorSpaceFeatureArea';
import { MAXIMUM_TOP_LEVEL_NUMBER } from '../../../../constants/appConstants';
import { getCaseDataSpecifiedTopLevelCount } from '../../../specification/cases/queries/levels/counts/getCaseDataSpecifiedTopLevelCount';
import { getTopLevelsSpecificationsTotalForcedGrossFloorSurfaceEff } from '../../../specification/cases/queries/levels/surfaces/getTopLevelsSpecificationsTotalForcedGrossFloorSurfaceEff';
import { getTopLevelsSpecificationsTotalForcedGrossFloorSurfaceEffCount } from '../../../specification/cases/queries/levels/counts/getTopLevelsSpecificationsTotalForcedGrossFloorSurfaceEffCount';
import * as R from 'ramda';
import {
  GrossFloorSurfaceEffByLevel,
  MINIMUM_LEVEL_GFSEFF
} from './getCaseUpperLevelsGrossFloorSurfaceDistribution';
import { roundWith2Decimal } from '../../../../utils/round/roundWith2Decimal';
import { LevelGranulometry } from '../../levels/LevelGranulometry';

export const getCaseUpperLevelsGrossFloorSurfaceDistributionDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry // see : getTopLevelGrossFloorSurfaceEffDetails
): string[] => {
  let details: string[] = [];
  const theoreticalCaseGrossFloorSurface =
    getTheoreticalCaseGrossFloorSurfaceEff(caseGranulometry).value;
  const floorSpaceArea = getCaseGranulometryDrawnFloorSpaceFeatureArea(caseGranulometry) as number;

  // DETAILS ADDED PART --------------------------------------------------------------------------------------------
  details = [
    ...details,
    '<i>Distribution de la SHOB VPP dans les étages courrants effectuée sur la base d’une SHOB VPP théorique calculée de la cage de <b>' +
      roundWith2Decimal(theoreticalCaseGrossFloorSurface) +
      ' m\u00B2</b> (- ' +
      roundWith2Decimal(floorSpaceArea) +
      ' m\u00B2 saisis au RC) :</i><br />'
  ];
  // DETAILS ADDED PART --------------------------------------------------------------------------------------------

  let remainingGfsEff = theoreticalCaseGrossFloorSurface;
  let levelIndex = 0;
  let upperLevelsGfsDistribution: GrossFloorSurfaceEffByLevel = [];

  const specifiedTopLevelsCount = getCaseDataSpecifiedTopLevelCount(
    caseGranulometry.initialSpecifications
  );
  const theoreticalResultingUpperLevelsCount = Math.ceil(
    (remainingGfsEff - floorSpaceArea) / floorSpaceArea
  );

  // If there is no specified top levels count
  // or if the specified top levels count is equal or under than the theoretical one :
  if (
    specifiedTopLevelsCount === undefined ||
    specifiedTopLevelsCount === theoreticalResultingUpperLevelsCount - 1 // -1 = RC
  ) {
    const maxTopLevelsCount = (specifiedTopLevelsCount || MAXIMUM_TOP_LEVEL_NUMBER) - 1; // -1 = RC

    while (remainingGfsEff > 0 && levelIndex < maxTopLevelsCount) {
      const theoreticalRemainingGfsEff = remainingGfsEff - floorSpaceArea;
      const upperLevelGfsEff =
        theoreticalRemainingGfsEff > 0
          ? theoreticalRemainingGfsEff > floorSpaceArea
            ? floorSpaceArea
            : theoreticalRemainingGfsEff
          : 0;

      remainingGfsEff =
        theoreticalRemainingGfsEff > floorSpaceArea ? remainingGfsEff - upperLevelGfsEff : 0;

      levelIndex++;

      upperLevelsGfsDistribution = [
        ...upperLevelsGfsDistribution,
        { level: levelIndex, gfsEff: upperLevelGfsEff }
      ];

      // DETAILS ADDED PART --------------------------------------------------------------------------------------------
      const detailToAdd =
        '<span style="display:inline-block;width:130px;">' +
        '· Niveau R+' +
        levelIndex +
        ' = ' +
        roundWith2Decimal(upperLevelGfsEff) +
        ' m\u00B2 ' +
        '</span><i>-> reste : ' +
        roundWith2Decimal(Math.max(remainingGfsEff, 0)) +
        ' m\u00B2</i>';

      details = [
        ...details,
        levelGranulometry.level === levelIndex ? '<b>' + detailToAdd + '</b>' : detailToAdd
      ];
      // DETAILS ADDED PART --------------------------------------------------------------------------------------------
    }

    if (remainingGfsEff > 0) {
      upperLevelsGfsDistribution.map(
        (l) => (l.gfsEff += remainingGfsEff / upperLevelsGfsDistribution.length)
      );

      // DETAILS ADDED PART --------------------------------------------------------------------------------------------
      details = [...details, '<br />Reste ' + remainingGfsEff + 'm\u00B2 à distribuer :'];
      upperLevelsGfsDistribution.reduce((acc, l) => {
        const detailToAdd =
          '· Niveau R+' +
          levelIndex +
          ' = +' +
          roundWith2Decimal(remainingGfsEff) +
          ' / ' +
          upperLevelsGfsDistribution.length +
          '???' +
          ' m\u00B2 <i>-> soit : ' +
          roundWith2Decimal(l.gfsEff + remainingGfsEff / upperLevelsGfsDistribution.length) +
          ' m\u00B2</i>';
        return [...details, detailToAdd];
      }, details);
      // DETAILS ADDED PART --------------------------------------------------------------------------------------------
    }
  }
  // If the specified top levels count is upper than the theoretical one :
  else {
    while (levelIndex < specifiedTopLevelsCount - 1) {
      // -1 = RC
      levelIndex++;
      upperLevelsGfsDistribution = [
        ...upperLevelsGfsDistribution,
        {
          level: levelIndex,
          gfsEff: (remainingGfsEff - floorSpaceArea) / (specifiedTopLevelsCount - 1)
        }
      ];

      // DETAILS ADDED PART --------------------------------------------------------------------------------------------
      const detailToAdd =
        '· Niveau R+' +
        levelIndex +
        ' = (' +
        roundWith2Decimal(remainingGfsEff) +
        ' m\u00B2 de SHOB VPP cage - ' +
        roundWith2Decimal(floorSpaceArea) +
        ' m\u00B2 saisis au RC) / ' +
        (specifiedTopLevelsCount - 1) +
        ' étages courrants<i> -> soit : ' +
        roundWith2Decimal((remainingGfsEff - floorSpaceArea) / (specifiedTopLevelsCount - 1)) +
        ' m\u00B2</i>';
      details = [
        ...details,
        levelGranulometry.level === levelIndex ? '<b>' + detailToAdd + '</b>' : detailToAdd
      ];
      // DETAILS ADDED PART --------------------------------------------------------------------------------------------
    }
  }

  // Manage forced levels :
  const totalForcedLevelCount = getTopLevelsSpecificationsTotalForcedGrossFloorSurfaceEffCount(
    caseGranulometry.initialSpecifications.topLevelsData
  );
  if (totalForcedLevelCount !== 0) {
    details = [
      ...details,
      '<br /><i>Redistribution pour intégrer la gestion des étages forcés :</i><br />'
    ];
    const totalForcedLevelSurface = getTopLevelsSpecificationsTotalForcedGrossFloorSurfaceEff(
      caseGranulometry.initialSpecifications.topLevelsData
    );
    const totalUnforcedLevelSurface = upperLevelsGfsDistribution.reduce((acc, gfsEffByLevel) => {
      const forcedGfsEff = caseGranulometry.initialSpecifications.topLevelsData.find(
        (l) => l.level === gfsEffByLevel.level
      )?.grossFloorSurfaceEff;
      return acc + (forcedGfsEff ? 0 : gfsEffByLevel.gfsEff);
    }, 0);
    const totalUnforcedLevelCount = upperLevelsGfsDistribution.length - totalForcedLevelCount;
    const gfsEffToRedistribute =
      theoreticalCaseGrossFloorSurface -
      floorSpaceArea -
      totalUnforcedLevelSurface -
      totalForcedLevelSurface;
    upperLevelsGfsDistribution = upperLevelsGfsDistribution.reduce((acc, gfsEffByLevel) => {
      const forcedGfsEff = caseGranulometry.initialSpecifications.topLevelsData.find(
        (l) => l.level === gfsEffByLevel.level
      )?.grossFloorSurfaceEff;

      // DETAILS ADDED PART --------------------------------------------------------------------------------------------
      const detailToAdd =
        '· Niveau R+' +
        gfsEffByLevel.level +
        ' = ' +
        (forcedGfsEff
          ? roundWith2Decimal(forcedGfsEff) + ' m\u00B2 de SHOB VPP saisie '
          : roundWith2Decimal(gfsEffByLevel.gfsEff) +
            ' m\u00B2 distribués ' +
            (gfsEffToRedistribute >= 0 ? '+' : '') +
            ' ' +
            roundWith2Decimal(gfsEffToRedistribute) +
            '  m\u00B2 à redistribuer / ' +
            totalUnforcedLevelCount +
            ' étages non-forcés -> soit ' +
            roundWith2Decimal(
              gfsEffByLevel.gfsEff + gfsEffToRedistribute / totalUnforcedLevelCount
            ) +
            ' m\u00B2');
      details = [
        ...details,
        levelGranulometry.level === gfsEffByLevel.level ? '<b>' + detailToAdd + '</b>' : detailToAdd
      ];
      // DETAILS ADDED PART --------------------------------------------------------------------------------------------
      return [
        ...acc,
        forcedGfsEff
          ? { ...gfsEffByLevel, gfsEff: forcedGfsEff }
          : {
              ...gfsEffByLevel,
              gfsEff: gfsEffByLevel.gfsEff + gfsEffToRedistribute / totalUnforcedLevelCount
            }
      ];
    }, [] as GrossFloorSurfaceEffByLevel);
  }

  // Remove too empty highest levels (only if top level count hasn't been forced)
  if (specifiedTopLevelsCount === undefined) {
    const gfsEffToBeRedistributed = upperLevelsGfsDistribution.reduce((acc, gfsEffByLevel) => {
      return gfsEffByLevel.gfsEff < MINIMUM_LEVEL_GFSEFF ? acc + gfsEffByLevel.gfsEff : 0;
    }, 0);
    if (gfsEffToBeRedistributed !== 0) {
      details = [
        ...details,
        '<br /><i>Suppression des étages trop petits pour intégrer un logement (< 73 m\u00B2) et redistribution de la surface récupérée :</i><br />'
      ];
      upperLevelsGfsDistribution = R.reject(
        (gfsEffByLevel: { level: number; gfsEff: number }) =>
          gfsEffByLevel.gfsEff < MINIMUM_LEVEL_GFSEFF
      )(upperLevelsGfsDistribution);
      upperLevelsGfsDistribution = upperLevelsGfsDistribution.reduce((acc, gfsEffByLevel) => {
        // DETAILS ADDED PART --------------------------------------------------------------------------------------------
        const detailToAdd =
          '· Niveau R+' +
          gfsEffByLevel.level +
          ' = ' +
          roundWith2Decimal(gfsEffByLevel.gfsEff) +
          ' m\u00B2 distribués ' +
          (gfsEffToBeRedistributed >= 0 ? '+' : '') +
          ' ' +
          roundWith2Decimal(gfsEffToBeRedistributed) +
          '  m\u00B2 à redistribuer / ' +
          upperLevelsGfsDistribution.length +
          ' étages restants -> soit ' +
          roundWith2Decimal(
            gfsEffByLevel.gfsEff + gfsEffToBeRedistributed / upperLevelsGfsDistribution.length
          ) +
          ' m\u00B2';
        details = [
          ...details,
          levelGranulometry.level === gfsEffByLevel.level
            ? '<b>' + detailToAdd + '</b>'
            : detailToAdd
        ];
        // DETAILS ADDED PART --------------------------------------------------------------------------------------------
        return [
          ...acc,
          {
            ...gfsEffByLevel,
            gfsEff:
              gfsEffByLevel.gfsEff + gfsEffToBeRedistributed / upperLevelsGfsDistribution.length
          }
        ];
      }, [] as GrossFloorSurfaceEffByLevel);
    }
  }

  return details;
};
