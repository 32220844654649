import { createSelector, Selector } from 'reselect';
import { State } from '../../../reducers';
import { selectCurrentCaseSpecification } from './specification.selector';
import { Maybe } from '../../../../utils/Maybe';

export const selectCurrentCaseSpecifiedTopLevelsCount: Selector<
  State,
  Maybe<number>
> = createSelector(
  [selectCurrentCaseSpecification],
  (caseSpecification) => caseSpecification.topLevelsCount
);
