import { LevelGranulometryInitialEntries } from '../../LevelGranulometry';
import { CaseGranulometry } from '../../../cases/CaseGranulometry';
import { isUpperLevel } from '../is/isUpperLevel';

export const mustHaveARampSection = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryInitialEntries
): boolean =>
  !isUpperLevel(caseGranulometry, levelGranulometry) &&
  caseGranulometry.initialSpecifications.builtInRamp &&
  caseGranulometry.initialSpecifications.basementLevelCount > 0;
