import * as MapboxDraw from '@mapbox/mapbox-gl-draw';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import RotateMode from 'mapbox-gl-draw-rotate-mode';
import {
  FloorSpaceFeature,
  MaybeFloorSpaceFeature
} from '../../../../domain/specification/floorSpace/FloorSpaceFeature';
import { MovingLayer } from '../../domain/drawHelper/drawHelper.model';
import { Feature } from 'geojson';
import { FeaturePolygonWithId, FeatureTarget } from '../draw.repository';

interface ExtendedModes extends MapboxDraw.DrawModes {
  RotateMode: any;
}

export enum PHASE_MODE {
  Rotatend = 'ROTATE_END'
}

export interface ModePhase {
  phase?: PHASE_MODE;
}

export const getCustomModes = (
  selectFloorSpaceInStore: (id: string) => void,
  modes: MapboxDraw.DrawModes
): ExtendedModes => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const originalClickOnFeature = modes.simple_select.clickOnFeature;
  const allModes = { ...modes, RotateMode };
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  allModes.simple_select.clickOnFeature = function (
    state: object,
    e: { featureTarget: FeatureTarget }
  ) {
    if (e.featureTarget.properties.user_deactivate) return null;
    selectFloorSpaceInStore(e.featureTarget.properties.id);
    return originalClickOnFeature.call(this, state, e);
  };
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  allModes.direct_select.onTrash = function (state: {
    feature: MaybeFloorSpaceFeature;
    featureId: MaybeFloorSpaceFeature['id'];
  }) {
    if (state.feature) {
      /*
      this.deleteFeature(state.featureId);
*/
      this.map.fire('draw.delete', {
        features: [state.feature]
      });
    }
    return state.feature;
  };
  const originalOnDrag = allModes.RotateMode.onDrag;
  allModes.RotateMode.onDrag = function (state: { originalFeature: FeatureTarget }, e: any) {
    console.log(state, e);
    if (state.originalFeature?.properties?.user_deactivate) return null;
    return originalOnDrag.call(this, state, e);
  };

  allModes.RotateMode.rotateend = function (
    feature: FeatureTarget,
    lastMouseDownLngLat: { lng: number; lat: number }
  ) {
    if (!feature) return;
    this.map.fire('draw.update', {
      features: [feature],
      position: lastMouseDownLngLat,
      phase: PHASE_MODE.Rotatend
    });
    return feature;
  };
  return allModes;
};
