import { getCaseDataCuttedBuiltSurfaceRate } from './getCaseDataCuttedBuiltSurfaceRate';
import { getCaseDataSurfaceForSaleRealRate } from './getCaseDataSurfaceForSaleRealRate';
import { getCaseDataTheoricalRealBuiltSurfaceFromRBS } from './getCaseDataTheoricalRealBuiltSurfaceFromRBS';
import { CaseFormDataLegacy } from '../caseFormData';

export const getCaseDataCuttedBuiltSurface = (caseData: CaseFormDataLegacy) => {
  let cuttedBuiltSurface = 0;
  if (caseData.cuttedBuiltSurface) {
    cuttedBuiltSurface = caseData.cuttedBuiltSurface;
  } else if (caseData.realBuiltSurface) {
    cuttedBuiltSurface =
      getCaseDataTheoricalRealBuiltSurfaceFromRBS(caseData) *
      getCaseDataCuttedBuiltSurfaceRate(caseData);
  } else if (caseData.surfaceForSale) {
    cuttedBuiltSurface = caseData.surfaceForSale / getCaseDataSurfaceForSaleRealRate(caseData);
  }
  return cuttedBuiltSurface;
};
