import elevatorShaft, {
  ElevatorShaftSection
} from '../../../../../sections/circulationSections/elevatorShaft';
import { getDefaultUnitConvertedPropertyValue } from '../../../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const createTopLevelElevatorShaftSection = (
  caseGranulometry: CaseGranulometry,
  group: string
): ElevatorShaftSection => {
  const length = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'elevatorShaftLength'
  ) as number;
  const width = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'elevatorShaftWidth'
  ) as number;
  const surface = length * width;
  const doorHeight = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'elevatorDoorHeight'
  ) as number;
  const doorWidth = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'elevatorDoorWidth'
  ) as number;
  return elevatorShaft({
    surface,
    length,
    width,
    doorHeight,
    doorWidth,
    group
  });
};
