import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormGroup } from '../../../../../ui/FormGroup';
import { useParams } from 'react-router';
import { IconObject } from '../../../../../ui/Icons/iconObject';
import { Input } from '../../../../../ui/input/Input';
import { selectCurrentCaseProjection } from '../../../../../../store/selectors/projection/currentCase/projection.selector';
import { getCaseDataProperty } from '../../../../../../domain/specification/cases/queries/get/properties/getCaseDataProperty';
import { casePropertyChanged } from '../../../../../../store/actions/casePropertyChanged.action';
import { ELEVATOR_SHAFT_ICON } from '../../../../../../domain/granulometry/sections/circulationSections/elevatorShaft';
import { CaseGranulometryEditSuperstructureRouteParams } from '../../../../../../routes/toolboxPanels/cases';

export const ElevatorShaftCount = () => {
  const { caseId } = useParams<CaseGranulometryEditSuperstructureRouteParams>();
  const currentlyEditedCase = useSelector(selectCurrentCaseProjection);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const elevatorShaftCountProperty =
    currentlyEditedCase && getCaseDataProperty(currentlyEditedCase, 'elevatorShaftCount');
  const handleElevatorShaftCountChange = React.useCallback(
    (newElevatorShaftCount: number | null) => {
      if (
        caseId &&
        elevatorShaftCountProperty &&
        (typeof newElevatorShaftCount === 'number' || newElevatorShaftCount === null)
      ) {
        dispatch(
          casePropertyChanged(caseId, {
            ...elevatorShaftCountProperty,
            value: newElevatorShaftCount ?? undefined
          })
        );
      }
    },
    [dispatch, caseId, elevatorShaftCountProperty]
  );

  if (!currentlyEditedCase || !elevatorShaftCountProperty) return null;

  return (
    <FormGroup horizontal={true} wrap={true}>
      <Input
        style={{ width: '40%', flex: '0 0 40%' }}
        name={`case${caseId}ElevatorShaftCount`}
        label={t('Elevator shaft')}
        // info={t('elevatorShaftCount.infos')}
        type="number"
        min={elevatorShaftCountProperty.min}
        max={elevatorShaftCountProperty.max}
        minMax={{
          min: elevatorShaftCountProperty.min,
          max: elevatorShaftCountProperty.max,
          unit: elevatorShaftCountProperty.unit
        }}
        placeholder={elevatorShaftCountProperty.defaultValue}
        value={elevatorShaftCountProperty.value}
        handleChange={handleElevatorShaftCountChange}
        suffix={elevatorShaftCountProperty.unit}
        icon={<IconObject {...ELEVATOR_SHAFT_ICON} clickable={false} />}
      />
    </FormGroup>
  );
};
