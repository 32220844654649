import React from 'react';
import { Card } from '../../../ui/Card';
import { IconObject } from '../../../ui/Icons/iconObject';
import { UiFloorSpace } from './FloorSpace';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Note } from '../../../ui/Note';
import { useTranslation } from 'react-i18next';
import { isDrawHelperFeatureEnabled } from '../../../../services/mapBox/mapboxDraw/draw.model';

interface FloorSpaceMapCardProps {
  mapContainer: React.MutableRefObject<HTMLDivElement>;
  ui: UiFloorSpace;
  setMode: () => void;
  drawHelperMode: boolean;
}

const DrawingButton = (draw: MapboxDraw) => [
  <IconObject
    key={'1'}
    iconName="polygon"
    type="menu"
    className="polygon"
    onClick={() => (draw as MapboxDraw).changeMode('draw_polygon')}
  />,
  <IconObject
    key={'2'}
    iconName="rotate"
    type="menu"
    className="rotate"
    /* eslint-disable-next-line @typescript-eslint/ban-ts-ignore */
    // @ts-ignore
    onClick={() => (draw as MapboxDraw).changeMode('RotateMode')}
  />,
  <IconObject
    key={'3'}
    iconName="remove"
    type="menu"
    className="remove"
    onClick={() => (draw as MapboxDraw).trash()}
  />
];

export const FloorSpaceMapCard = ({
  mapContainer,
  ui,
  setMode,
  drawHelperMode
}: FloorSpaceMapCardProps) => {
  const { t } = useTranslation();
  const currentDrawMode = ui.draw ? (ui.draw as MapboxDraw).getMode() : undefined;

  return (
    <Card>
      <div className="mapContainer" ref={mapContainer}>
        {ui.map && ui.draw && (
          <>
            <div className="mapControls">
              <IconObject
                iconName="add"
                type="menu"
                className="add"
                onClick={() => (ui.map as mapboxgl.Map).zoomIn()}
              />
              <IconObject
                iconName="minus"
                type="menu"
                className="minus"
                onClick={() => (ui.map as mapboxgl.Map).zoomOut()}
              />
              {!drawHelperMode && DrawingButton(ui.draw)}
              {isDrawHelperFeatureEnabled() && (
                <IconObject
                  iconName="interface"
                  type="menu"
                  className="add-remove-buttons"
                  onClick={setMode}
                />
              )}
            </div>
            {(currentDrawMode === 'draw_polygon' ||
              // @ts-ignore
              currentDrawMode === 'RotateMode' ||
              currentDrawMode === 'direct_select') && (
              <div className="mapNotes">
                <Note>{t('floorSpaceWindow.drawModesNote')}</Note>
              </div>
            )}
          </>
        )}
      </div>
    </Card>
  );
};
