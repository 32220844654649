import { Position } from '@turf/helpers/dist/js/lib/geojson';
import { MaybeLine } from '../../../../domain/specification/floorSpace/FacadeSpecification';
import * as turf from '@turf/turf';

export const getCoordinatesFromLines = (origin: Position, lines: MaybeLine[]): Position[] => {
  return lines.reduce(
    (p, c, i) => {
      if (i + 1 === lines.length) {
        return [...p, origin];
      }
      const lastPoint = turf.point(p[i]);
      const point = turf.destination(lastPoint, (c.distance || 0) / 1000, c.angle || 0); // 0 if distance or angle === null
      return [...p, point.geometry.coordinates];
    },
    [origin]
  );
};

export const normalizeSourceCoordinates = (coordinates: Position[]) => {
  if (
    coordinates[0][0] === coordinates[coordinates.length - 1][0] &&
    coordinates[0][1] === coordinates[coordinates.length - 1][1]
  )
    return coordinates;
  return [...coordinates, coordinates[0]];
};
