import * as R from 'ramda';
import { LevelGranulometryInitialEntries } from '../../../LevelGranulometry';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { isGroundLevel } from '../../is/isGroundLevel';
import { isUpperLevel } from '../../is/isUpperLevel';
import { mustHaveElevatorShaftSections } from '../../mustHave/mustHaveElevatorShaftSections';
import { createTopLevelElevatorShaftSection } from './create/createTopLevelElevatorShaftSection';
import { ElevatorShaftSection } from '../../../../sections/circulationSections/elevatorShaft';
import { getCaseElevatorShaftSectionsCount } from '../../../../cases/queries/sections/counts/getCaseElevatorShaftSectionsCount';
import { isHighestLevel } from '../../is/isHighestLevel';

export const getTopLevelElevatorShaftSections = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryInitialEntries
): ElevatorShaftSection[] | [] => {
  if (mustHaveElevatorShaftSections(caseGranulometry)) {
    let elevatorShafts: ElevatorShaftSection[] = [];
    R.times((i) => {
      const elevatorShaft = createTopLevelElevatorShaftSection(
        caseGranulometry,
        'elevator' + i + caseGranulometry.labelIndex
      );
      elevatorShaft.openedAbove = !isHighestLevel(caseGranulometry, levelGranulometry);
      if (
        isUpperLevel(caseGranulometry, levelGranulometry) ||
        (isGroundLevel(levelGranulometry) &&
          caseGranulometry.initialSpecifications.basementLevelCount > 0)
      ) {
        elevatorShaft.openedBelow = true;
      }
      elevatorShafts = [...elevatorShafts, elevatorShaft];
    })(getCaseElevatorShaftSectionsCount(caseGranulometry));
    return elevatorShafts;
  }
  return [];
};
