export const getProjectDetailsGingerTriggerId = () => `projectDetailsGingerTools`;
export const getProjectDetailsFloorSpaceTriggerId = () => `projectDetailsFloorSpace`;
export const getLodgmentTypeTriggerId = (caseLabel: string, lodgmentType: string) =>
  `case${caseLabel}DistributionIcon${lodgmentType}`;

export const getLodgmentTypeRoomTriggerId = (
  caseLabel: string,
  lodgmentType: string,
  roomIndex: number
) => `case${caseLabel}SectionMap${lodgmentType}Room${roomIndex}`;

export const getCasePropertyTriggerId = (caseLabel: string, propertyName: string) =>
  `case${caseLabel}PropertyLine-${propertyName}`;

export const getLevelTriggerId = (caseLabel: string, levelIndex: number | string) =>
  `case${caseLabel}LevelButton${levelIndex.toString()}`;

export const getEditUserTriggerId = () => `editUser`;

export const getCaseFloorSpaceTriggerId = (caseLabel: string) => `case${caseLabel}FloorSpace`;
export const getCaseFifthFacadeTriggerId = (caseLabel: string) => `case${caseLabel}FifthFacade`;
export const getCaseSuperstructureTriggerId = (caseLabel: string) =>
  `case${caseLabel}Superstructure`;
export const getCaseEnergyAndNetworksTriggerId = (caseLabel: string) =>
  `case${caseLabel}EnergyAndNetworks`;
export const getCaseInfrastructureTriggerId = (caseLabel: string) =>
  `case${caseLabel}Infrastructure`;
export const getCaseGroundAdaptationsTriggerId = (caseLabel: string) =>
  `case${caseLabel}GroundAdaptations`;
export const getCaseOutdoorsTriggerId = (caseLabel: string) => `case${caseLabel}Outdoors`;
export const getCaseOtherPropertiesTriggerId = (caseLabel: string) =>
  `case${caseLabel}OtherProperties`;
