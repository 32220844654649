import { getCaseDataLodgmentDistribution } from './getCaseDataLodgmentDistribution';
import { getCaseDataLodgmentData } from './getCaseDataLodgmentData';
import { getCaseDataAverageLodgmentSurface } from './getCaseDataAverageLodgmentSurface';
import { CaseFormDataLegacy } from '../caseFormData';
import { LodgmentType } from '../../specification/lodgmentTypes/LodgmentType';
import { getSectionTheoreticalSurface } from '../../granulometry/sections/queries/surfaces/getSectionTheoreticalSurface';

export interface CaseSurfaceWeightingByType {
  type: LodgmentType;
  surfaceWeighting: number;
}
export type CaseSurfaceWeightingsByType = CaseSurfaceWeightingByType[];

/* fr : Calcul de la pondération de surface par type de logement */
/* Get the surface weighting by lodgment type */
export const getCaseSurfaceWeightingByType = (
  caseData: CaseFormDataLegacy
): CaseSurfaceWeightingsByType => {
  const distribution = getCaseDataLodgmentDistribution(caseData);
  const lodgmentData = getCaseDataLodgmentData(caseData);
  const caseAverageLodgmentSurface = getCaseDataAverageLodgmentSurface(caseData);
  let caseSurfaceWeightingsByType: CaseSurfaceWeightingsByType = [];
  distribution.forEach((distrib) => {
    const lodgmentType = distrib.lodgmentType;
    const lodgmentTypeRate = distrib.rate;
    const lodgmentTypeTheoreticalSurface = getSectionTheoreticalSurface(lodgmentData[lodgmentType]);
    const lodgmentTypeWeightedSurface = lodgmentTypeTheoreticalSurface * (lodgmentTypeRate / 100);
    const lodgmentTypeWeightedRate = lodgmentTypeWeightedSurface / caseAverageLodgmentSurface;
    const lodgmentTypeDeltaRate = lodgmentTypeRate / 100 - lodgmentTypeWeightedRate;
    const lodgmentTypeSurfaceWeighting = lodgmentTypeDeltaRate * lodgmentTypeTheoreticalSurface;
    caseSurfaceWeightingsByType = [
      ...caseSurfaceWeightingsByType,
      {
        type: lodgmentType,
        surfaceWeighting: lodgmentTypeSurfaceWeighting
      }
    ];
  });

  return caseSurfaceWeightingsByType;
};
