import * as React from 'react';
import { Dispatch } from 'react';
import { getCaseFloorSpaceTriggerId } from '../../../../../routes/toolboxPanels/triggers';
import { goToCaseFloorSpace } from '../../../../../store/actions/navigations.actions';
import { Button } from '../../../../ui/Button';
import { CaseId } from '../../../../../domain/specification/cases/CaseSpecification';
import { roundForSurfacesInputs } from '../../../../../utils/round/roundForSurfacesInputs';

interface GroundLevelGrossFloorSurfaceEffButtonProps {
  projectId: string | undefined;
  caseId: CaseId;
  caseLabel: string;
  isCaseFloorSpaceOpened: boolean;
  grossFloorSurfaceEff: number | undefined;
  dispatch: Dispatch<any>;
  isFilled: boolean;
}

export const GroundLevelGrossFloorSurfaceEffButton = ({
  projectId,
  caseId,
  caseLabel,
  isCaseFloorSpaceOpened,
  grossFloorSurfaceEff,
  dispatch,
  isFilled
}: GroundLevelGrossFloorSurfaceEffButtonProps) => (
  <Button
    id={getCaseFloorSpaceTriggerId(caseLabel)}
    selected={isCaseFloorSpaceOpened}
    fullWidth
    size="small"
    content={grossFloorSurfaceEff ? roundForSurfacesInputs(grossFloorSurfaceEff) : 'ERR!'}
    appearance="outline"
    asCustomValue={isFilled}
    style={!isFilled ? { color: 'rgb(var(--darkGrey))' } : null}
    handleClick={() => {
      if (projectId) {
        dispatch(goToCaseFloorSpace(projectId, caseId));
      }
    }}
  />
);
