import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { LevelSpecification } from '../../../../domain/specification/levels/LevelSpecification';
import { selectCurrentCaseLabel } from '../../../../store/selectors/project/currentCaseLabel.selector';
import { selectCurrentCaseEditingStage } from '../../../../store/selectors/specification/currentCase/editingStage.selector';
import { TopLevelSpecification } from '../../../../domain/specification/levels/TopLevelSpecification';
import { setTopLevelsSpecifications } from '../../../../store/actions/setTopLevelsSpecifications.action';
import { selectCurrentCaseIdFromRoute } from '../../../../store/selectors/navigation/caseIdFromRoute.selector';
import { selectCurrentCaseProjection } from '../../../../store/selectors/projection/currentCase/projection.selector';
import { selectCurrentCaseTopLevelsSurfaces } from '../../../../store/selectors/granulometry/currentCase/topLevelsSurfaces.selector';
import { TopLevelSurfaceName } from '../../../../domain/granulometry/cases/queries/surfaces/getCaseTopLevelsSurfaces';
import { selectProjectId } from '../../../../store/selectors/project';
import { selectIsCaseFloorSpaceOpened } from '../../../../store/selectors/navigation/windows/isCaseFloorSpaceOpened.selector';
import { GroundLevelGrossFloorSurfaceEffButton } from './Column4/GroundLevelGrossFloorSurfaceEffButton';
import { TopLevelSurfaceInput } from './Column4/TopLevelSurfaceInput';
import { caseTopLevelsSpecificationsRepartitionIsValid } from '../../../../domain/checks/cases/caseTopLevelsSpecificationsRepartitionIsValid';
import { selectCurrentCaseGrossFloorSurfaceEff } from '../../../../store/selectors/granulometry/currentCase/grossFloorSurfaceEff.selector';
import { selectCurrentCaseGranulometry } from '../../../../store/selectors/granulometry/currentCase/currentCaseGranulometry.selector';
import { CaseGranulometry } from '../../../../domain/granulometry/cases/CaseGranulometry';
import { hasCaseProjectionDrawnFloorSpaceFeature } from '../../../../domain/projection/cases/queries/has/hasCaseProjectionDrawnFloorSpaceFeature';

export interface LocalLevelsSurfaceRepartition {
  levels: TopLevelSpecification[];
  isValid: boolean;
}

interface Column4Props {
  levelSpecification: LevelSpecification;
  selectedLevelSurface: TopLevelSurfaceName;
}

export const Column4Inner = ({ levelSpecification, selectedLevelSurface }: Column4Props) => {
  const projectId = useSelector(selectProjectId);
  const caseId = useSelector(selectCurrentCaseIdFromRoute);
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const caseProjection = useSelector(selectCurrentCaseProjection);
  const caseRealBuiltSurface = caseProjection.projectedSurfaces.realBuiltSurface.value;
  const grossFloorSurfaceEff = useSelector(selectCurrentCaseGrossFloorSurfaceEff);
  const caseGranulometry = useSelector(selectCurrentCaseGranulometry) as CaseGranulometry;
  const topLevelsSpecifications = caseGranulometry.initialSpecifications.topLevelsData; // useSelector(selectCurrentCaseSpecifiedTopLevels);
  const editingStage = useSelector(selectCurrentCaseEditingStage);
  const topLevelsSurfaces = useSelector(selectCurrentCaseTopLevelsSurfaces);
  const isCaseFloorSpaceOpened = useSelector(selectIsCaseFloorSpaceOpened);

  const dispatch = useDispatch();

  const [localLevelsSpecification, setLocalLevelsSpecification] = React.useState({
    levels: topLevelsSpecifications,
    isValid:
      !!grossFloorSurfaceEff &&
      caseTopLevelsSpecificationsRepartitionIsValid(
        caseRealBuiltSurface,
        grossFloorSurfaceEff.value,
        topLevelsSpecifications
      )
  } as LocalLevelsSurfaceRepartition);

  const handleLevelSurfaceChange = React.useCallback(
    (level: number | string, surfaceName: TopLevelSurfaceName, surface: number | null) => {
      if (caseId) {
        // To prevent infinity bug
        if (surface === null || surface > 10) {
          const levelIndex = localLevelsSpecification.levels.findIndex((l) => l.level === level);
          const newRepartition = R.over(
            R.lensIndex(levelIndex),
            R.assoc(surfaceName, surface || undefined),
            localLevelsSpecification.levels
          );
          const isValid =
            !!grossFloorSurfaceEff &&
            caseTopLevelsSpecificationsRepartitionIsValid(
              caseRealBuiltSurface,
              grossFloorSurfaceEff.value,
              newRepartition
            );
          setLocalLevelsSpecification({ levels: newRepartition, isValid });
          dispatch(setTopLevelsSpecifications(caseId, newRepartition));
        }
      }
    },
    [dispatch, localLevelsSpecification, caseRealBuiltSurface, setLocalLevelsSpecification, caseId]
  );

  React.useEffect(() => {
    setLocalLevelsSpecification({
      levels: topLevelsSpecifications,
      isValid:
        !!grossFloorSurfaceEff &&
        caseTopLevelsSpecificationsRepartitionIsValid(
          caseRealBuiltSurface,
          grossFloorSurfaceEff.value,
          topLevelsSpecifications
        )
    });
  }, [topLevelsSpecifications]);

  if (!caseLabel || !topLevelsSurfaces) return null;

  const levelIndex = levelSpecification.level;

  const isRbsSelected = selectedLevelSurface === 'realBuiltSurface';
  const isGfsEffSelected = selectedLevelSurface === 'grossFloorSurfaceEff';
  /* const isSfsSelected = selectedLevelSurface === 'surfaceForSale'; */

  const hasFloorSpace = hasCaseProjectionDrawnFloorSpaceFeature(caseProjection);

  const levelFromSpec = localLevelsSpecification.levels.find((l) => l.level === levelIndex);
  const levelSurfacesFromGranulo = topLevelsSurfaces.find((l) => l.level === levelIndex);

  const levelSurfacePlaceholder =
    levelSurfacesFromGranulo && Math.max(0, levelSurfacesFromGranulo[selectedLevelSurface].value);

  const levelSurfaceValue =
    isRbsSelected && !hasFloorSpace
      ? levelFromSpec?.realBuiltSurface
      : isGfsEffSelected && hasFloorSpace && levelIndex !== 0
      ? levelFromSpec?.grossFloorSurfaceEff
      : levelSurfacePlaceholder;

  return (
    <>
      {isGfsEffSelected && levelIndex === 0 && caseId ? (
        <GroundLevelGrossFloorSurfaceEffButton
          projectId={projectId}
          caseId={caseId}
          caseLabel={caseLabel}
          isCaseFloorSpaceOpened={isCaseFloorSpaceOpened}
          grossFloorSurfaceEff={levelSurfaceValue}
          dispatch={dispatch}
          isFilled={hasFloorSpace}
        />
      ) : (
        <TopLevelSurfaceInput
          isRbsSelected={isRbsSelected}
          isGfsEffSelected={isGfsEffSelected}
          hasFloorSpace={hasFloorSpace}
          handleLevelSurfaceChange={handleLevelSurfaceChange}
          caseLabel={caseLabel}
          levelIndex={levelIndex}
          selectedLevelSurface={selectedLevelSurface}
          localLevelsSpecification={localLevelsSpecification}
          levelSurfaceValue={levelSurfaceValue}
          levelSurfacePlaceholder={levelSurfacePlaceholder}
          editingStage={editingStage}
          isDisabled={
            /*! isSfsSelected &&
            ( */ (hasFloorSpace && !isGfsEffSelected) || (!hasFloorSpace && !isRbsSelected) /* ) */
          }
          asCustomValue={
            (isGfsEffSelected && hasFloorSpace && !!levelSurfaceValue) ||
            (isRbsSelected && !hasFloorSpace && !!levelSurfaceValue)
          }
        />
      )}
    </>
  );
};

export const Column4 = ({ levelSpecification, selectedLevelSurface }: Column4Props) => (
  <div className="column-4">
    <div className="cell">
      <Column4Inner
        levelSpecification={levelSpecification}
        selectedLevelSurface={selectedLevelSurface}
      />
    </div>
  </div>
);
