import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectMapElementsToDraw } from '../../../../../store/selectors/map/mapElementsToDraw.selector';
import { FloorSpaceFeature } from '../../../../../domain/specification/floorSpace/FloorSpaceFeature';
import { Card } from '../../../../ui/Card';
import { SpecialLabel } from '../../../../ui/SpecialLabel';
import { CardTitle } from '../../../../ui/CardTitle';
import { FloorSpaceFacadeForm } from './FloorSpaceCasesCard/FloorSpaceFacadeForm';
import { FloorSpaceFeatureForm } from './FloorSpaceCasesCard/FloorSpaceFeatureForm';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { MaybeUiFloorSpace } from '../FloorSpace';
import { selectCurrentCaseLabel } from '../../../../../store/selectors/project/currentCaseLabel.selector';
import { Note } from '../../../../ui/Note';
import { useTranslation } from 'react-i18next';

interface FloorSpaceCasesCardProps {
  ui: MaybeUiFloorSpace;
  dispatchChange: (feature: FloorSpaceFeature) => { type: string; payload: FloorSpaceFeature };
}

export const FloorSpaceCasesCard = ({ ui, dispatchChange }: FloorSpaceCasesCardProps) => {
  const { floorSpaceFeatures } = useSelector(selectMapElementsToDraw);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const { t } = useTranslation();

  useEffect(() => {
    const tab = caseLabel
      ? floorSpaceFeatures.findIndex((fFS) => fFS.properties?.name === caseLabel)
      : floorSpaceFeatures.findIndex((f) => f.properties.shape?.isSelected);
    setSelectedTab(tab);
  }, [floorSpaceFeatures, caseLabel]);

  if (floorSpaceFeatures.length === 0) {
    return (
      <Card>
        <CardTitle>Cages</CardTitle>
        <Note>{t('floorSpaceWindow.casesCard.note')}</Note>
      </Card>
    );
  } else {
    return (
      <>
        <Tabs selectedIndex={selectedTab} onSelect={setSelectedTab}>
          {!caseLabel && (
            <Card>
              <CardTitle>Cages</CardTitle>
              <TabList>
                {floorSpaceFeatures.map((floorSpaceFeature, index) => {
                  if (floorSpaceFeature.properties) {
                    return <Tab key={index}>{floorSpaceFeature.properties.name}</Tab>;
                  }
                })}
              </TabList>
            </Card>
          )}
          {floorSpaceFeatures.map((floorSpaceFeature, index) => {
            if (!floorSpaceFeature) return null;
            if (floorSpaceFeature.properties) {
              return (
                <TabPanel key={index}>
                  <Card>
                    <SpecialLabel label={t('floorSpaceWindow.casesCard.feature.specialLabel')} />
                    <FloorSpaceFeatureForm floorSpaceFeature={floorSpaceFeature} />
                    <SpecialLabel label={t('floorSpaceWindow.casesCard.facades.specialLabel')} />
                    {floorSpaceFeature.properties.facades &&
                      floorSpaceFeature.properties.facades.map((facade, index) => (
                        <FloorSpaceFacadeForm
                          key={index}
                          floorSpace={floorSpaceFeature}
                          facade={facade}
                          index={index}
                          ui={ui}
                          dispatchChange={dispatchChange}
                        />
                      ))}
                  </Card>
                </TabPanel>
              );
            }
          })}
        </Tabs>
        <Card>
          <Note>{t('floorSpaceWindow.casesCard.note')}</Note>
        </Card>
      </>
    );
  }
};
