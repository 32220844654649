import * as React from 'react';
import { Route } from 'react-router';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { withConnect } from './withConnect';
import { Project } from '../../../domain/project/Project';
import { IconObject } from '../../../components/ui/Icons/iconObject';
import AppMenu from './AppMenu';
import ProjectTree from './ProjectTree/ProjectTree';
import { selectRoute } from '../../../store/selectors/navigation/getRoute.selector';
import { selectProjectId } from '../../../store/selectors/project';
import { appMenuRoute } from '../../../routes/appMenu';
import { goToAppMenu, goToProject } from '../../../store/actions/navigations.actions';
import { selectIsInitializeProject } from '../../../store/selectors/project/isInitializeProject.selector';

interface MenuProps {
  onClick: () => void;
}

const Menu = ({ onClick }: MenuProps) => (
  <button className="menu" onClick={onClick}>
    <IconObject iconName="appMenu" type="menu" />
  </button>
);

export interface MainBarViewProps {
  projectName?: string;
  currentProject?: Project;
}

const MainBarView = ({ projectName, currentProject }: MainBarViewProps) => {
  const currentRoute = useSelector(selectRoute);
  const isInitializedProject = useSelector(selectIsInitializeProject);

  const projectId = useSelector(selectProjectId);

  const dispatch = useDispatch();

  return (
    <div className={classNames('app-menu', { 'panel-hidden': !currentRoute.isAppMenu })}>
      <Route path={appMenuRoute.path} component={AppMenu} />
      <div className="main-bar bar">
        {isInitializedProject && (
          <div className="project-name" title={projectName}>
            {projectName}
            {process.env.GB_ENV === 'dev' || process.env.GB_ENV === 'recette'
              ? ' [' + process.env.GB_ENV + ']'
              : ''}
          </div>
        )}
        <Menu
          onClick={() => {
            if (projectId && currentRoute) {
              dispatch(currentRoute.isAppMenu ? goToProject(projectId) : goToAppMenu(projectId));
            }
          }}
        />
        {isInitializedProject && currentProject && <ProjectTree project={currentProject} />}
      </div>
    </div>
  );
};

export default withConnect(MainBarView);
