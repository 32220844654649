import { ValidationRule } from '../ValidationsRule';
import { Notification } from '../Notification';
import { getProjectCasesProjections } from '../../projection/project/queries/cases/getProjectCasesProjections';
import { ProjectProjection } from '../../projection/project/ProjectProjection';
import { roundWith2Decimal } from '../../../utils/round/roundWith2Decimal';
import { MAXIMUM_PROJECT_CBS } from '../../../constants/appConstants';
import { Surface } from '../../specification/Surface';
import { caseCuttedBuiltSurfaceIsSmallerThanTheMinimum } from '../../checks/cases/caseCuttedBuiltSurfaceIsSmallerThanTheMinimum';
import { caseCuttedBuiltSurfaceIsLargerThanTheMaximum } from '../../checks/cases/caseCuttedBuiltSurfaceIsLargerThanTheMaximum';
import { getCaseCuttedBuiltSurfaceMinimum } from '../../specification/cases/queries/get/surfaces/getCaseCuttedBuiltSurfaceMinimum';
import { getCaseCuttedBuiltSurfaceMaximum } from '../../specification/cases/queries/get/surfaces/getCaseCuttedBuiltSurfaceMaximum';
import { caseRealBuiltSurfaceIsSmallerThanTheMinimum } from '../../checks/cases/caseRealBuiltSurfaceIsSmallerThanTheMinimum';
import { caseRealBuiltSurfaceIsLargerThanTheMaximum } from '../../checks/cases/caseRealBuiltSurfaceIsLargerThanTheMaximum';
import { getCaseRealBuiltSurfaceMinimum } from '../../specification/cases/queries/get/surfaces/getCaseRealBuiltSurfaceMinimum';
import { getCaseRealBuiltSurfaceMaximum } from '../../specification/cases/queries/get/surfaces/getCaseRealBuiltSurfaceMaximum';
import { caseSurfaceForSaleIsSmallerThanTheMinimum } from '../../checks/cases/caseSurfaceForSaleIsSmallerThanTheMinimum';
import { caseSurfaceForSaleIsLargerThanTheMaximum } from '../../checks/cases/caseSurfaceForSaleIsLargerThanTheMaximum';
import { getCaseSurfaceForSaleMinimum } from '../../specification/cases/queries/get/surfaces/getCaseSurfaceForSaleMinimum';
import { getCaseSurfaceForSaleMaximum } from '../../specification/cases/queries/get/surfaces/getCaseSurfaceForSaleMaximum';
import { caseSurfaceForSaleIsLargerThanTheWarningLimit } from '../../checks/cases/caseSurfaceForSaleIsLargerThanTheWarningLimit';
import { letterFromIndex } from '../../../utils/letterFromIndex';

export const projectCasesSurfacesMustBeValid: ValidationRule = (
  projectProjection: ProjectProjection
) => {
  const notifications: Notification[] = getProjectCasesProjections(projectProjection).reduce(
    (accNotifications, caseProjection) => {
      const caseLabel =
        caseProjection.buildingIndex + 1 + letterFromIndex(caseProjection.indexInBuilding);

      // CUTTED BUILT SURFACE
      const cbsDefinedValue = caseProjection.surfaces?.cuttedBuiltSurface?.value;
      const cbsProjectedValue = roundWith2Decimal(
        (caseProjection.projectedSurfaces.cuttedBuiltSurface as Surface).value
      );
      const cbsFilledOrCalculated = cbsDefinedValue !== undefined ? 'saisie' : 'calculée';
      const cbsIsTooSmall = caseCuttedBuiltSurfaceIsSmallerThanTheMinimum(
        projectProjection,
        caseProjection
      );
      const cbsIsTooLarge = caseCuttedBuiltSurfaceIsLargerThanTheMaximum(
        projectProjection,
        caseProjection
      );
      let caseNotifications: Notification[] = [];

      if (cbsIsTooSmall) {
        caseNotifications = [
          ...caseNotifications,
          {
            title:
              'La SdP PC de la cage ' +
              caseLabel +
              ' actuellement ' +
              cbsFilledOrCalculated +
              ' à ' +
              cbsProjectedValue +
              ' m\u00B2 est trop petite',
            explanation:
              'Il est retenue une surface minimum de ' +
              roundWith2Decimal(getCaseCuttedBuiltSurfaceMinimum()) +
              ' m\u00B2 en SdP PC.',
            type: 'error'
          }
        ];
      }
      if (cbsIsTooLarge) {
        caseNotifications = [
          ...caseNotifications,
          {
            title:
              'La SdP PC de la cage ' +
              caseLabel +
              ' actuellement ' +
              cbsFilledOrCalculated +
              ' à ' +
              cbsProjectedValue +
              ' m\u00B2 est trop grande',
            explanation:
              'La SdP PC maximum d‘une cage correspond à la surface du projet déduite de la somme SdP PC des autres cages, soit pour cette cage : ' +
              roundWith2Decimal(
                getCaseCuttedBuiltSurfaceMaximum(projectProjection, caseProjection)
              ) +
              ' m\u00B2.',
            type: 'error'
          }
        ];
      }

      // REAL BUILT SURFACE
      const rbsDefinedValue = caseProjection.surfaces?.realBuiltSurface?.value;
      const rbsProjectedValue = roundWith2Decimal(
        (caseProjection.projectedSurfaces.realBuiltSurface as Surface).value
      );
      const rbsFilledOrCalculated = rbsDefinedValue !== undefined ? 'saisie' : 'calculée';
      const rbsIsTooSmall = caseRealBuiltSurfaceIsSmallerThanTheMinimum(
        projectProjection,
        caseProjection
      );
      const rbsIsTooLarge = caseRealBuiltSurfaceIsLargerThanTheMaximum(
        projectProjection,
        caseProjection
      );

      if (!cbsIsTooSmall && !cbsIsTooLarge && rbsIsTooSmall) {
        caseNotifications = [
          ...caseNotifications,
          {
            title:
              'La SdP réelle de la cage ' +
              caseLabel +
              ' actuellement ' +
              rbsFilledOrCalculated +
              ' à ' +
              rbsProjectedValue +
              ' m\u00B2 est trop petite',
            explanation:
              'La SdP réelle minimum d‘une cage est égale à sa SdP PC, soit pour cette cage : ' +
              roundWith2Decimal(getCaseRealBuiltSurfaceMinimum(caseProjection)) +
              ' m\u00B2.',
            type: 'error'
          }
        ];
      }
      if (!cbsIsTooSmall && !cbsIsTooLarge && rbsIsTooLarge) {
        caseNotifications = [
          ...caseNotifications,
          {
            title:
              'La SdP réelle de la cage ' +
              caseLabel +
              ' actuellement ' +
              rbsFilledOrCalculated +
              ' à ' +
              rbsProjectedValue +
              ' m\u00B2 est trop grande',
            explanation:
              'La SdP réelle maximum d‘une cage est égale à ' +
              MAXIMUM_PROJECT_CBS +
              ' m\u00B2 (max projet) moins la somme des SdP réelles des autres cages, soit pour cette cage : ' +
              roundWith2Decimal(getCaseRealBuiltSurfaceMaximum(projectProjection, caseProjection)) +
              ' m\u00B2.',
            type: 'error'
          }
        ];
      }

      // SURFACE FOR SALE
      const sfsDefinedValue = caseProjection.surfaces?.surfaceForSale?.value;
      const sfsProjectedValue = roundWith2Decimal(
        (caseProjection.projectedSurfaces.surfaceForSale as Surface).value
      );
      const sfsFilledOrCalculated = sfsDefinedValue !== undefined ? 'saisie' : 'calculée';
      const sfsIsTooSmall = caseSurfaceForSaleIsSmallerThanTheMinimum(
        projectProjection,
        caseProjection
      );
      const sfsIsTooLarge = caseSurfaceForSaleIsLargerThanTheMaximum(
        projectProjection,
        caseProjection
      );
      const sfsIsWarning =
        !sfsIsTooLarge && caseSurfaceForSaleIsLargerThanTheWarningLimit(caseProjection);

      if (!cbsIsTooSmall && !cbsIsTooLarge && sfsIsTooSmall) {
        caseNotifications = [
          ...caseNotifications,
          {
            title:
              'La SHab de la cage ' +
              caseLabel +
              ' actuellement ' +
              sfsFilledOrCalculated +
              ' à ' +
              sfsProjectedValue +
              ' m\u00B2 est trop petite',
            explanation:
              'La surface minimum de SHAB correspond à celle d‘un logement à ' +
              roundWith2Decimal(getCaseSurfaceForSaleMinimum()) +
              ' m\u00B2 en conformité avec les directives du ministère de référence.',
            type: 'error'
          }
        ];
      }
      if (!cbsIsTooSmall && !cbsIsTooLarge && sfsIsTooLarge) {
        caseNotifications = [
          ...caseNotifications,
          {
            title:
              'La SHab de la cage ' +
              caseLabel +
              ' actuellement ' +
              sfsFilledOrCalculated +
              ' à ' +
              sfsProjectedValue +
              ' m\u00B2 est trop grande',
            explanation:
              'La SHab maximum d‘une cage est égale à sa SdP PC, soit pour cette cage : ' +
              roundWith2Decimal(getCaseSurfaceForSaleMaximum(caseProjection)) +
              ' m\u00B2',
            type: 'error'
          }
        ];
      }
      if (!cbsIsTooSmall && !cbsIsTooLarge && sfsIsWarning) {
        caseNotifications = [
          ...caseNotifications,
          {
            title:
              'La SHab de la cage ' +
              caseLabel +
              ' actuellement ' +
              sfsFilledOrCalculated +
              ' à ' +
              sfsProjectedValue +
              ' m\u00B2 ne permet pas d’intégrer la surface de palier minimum',
            explanation:
              'La SHab actuellement saisie ne permet pas d’intégrer les ' +
              roundWith2Decimal(caseProjection.projectedSurfaces.sumOfMinimumBearingSurface.value) +
              ' m\u00B2 nécessaires pour respecter les surfaces minimum de palier pour cette cage. Il convient de réduire la SHab.',
            type: 'warning'
          }
        ];
      }
      return [...accNotifications, ...caseNotifications];
    },
    [] as Notification[]
  );

  return notifications.length !== 0 ? notifications : true;
};
