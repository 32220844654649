import { DEFAULT_RBS_TO_SFS_RATIO_DEPRECATED } from '../../../constants/appConstants';
import { getCaseDataTheoricalRealBuiltSurfaceFromRBS } from './getCaseDataTheoricalRealBuiltSurfaceFromRBS';
import { CaseFormDataLegacy } from '../caseFormData';

export const getCaseDataSurfaceForSaleRealRate = (caseData: CaseFormDataLegacy) => {
  let surfaceForSaleRealRate: number;
  if (caseData.surfaceForSale && caseData.realBuiltSurface) {
    surfaceForSaleRealRate =
      caseData.surfaceForSale / getCaseDataTheoricalRealBuiltSurfaceFromRBS(caseData);
  } else if (caseData.surfaceForSale && caseData.cuttedBuiltSurface) {
    surfaceForSaleRealRate =
      caseData.surfaceForSale / getCaseDataTheoricalRealBuiltSurfaceFromRBS(caseData);
  } else {
    surfaceForSaleRealRate = DEFAULT_RBS_TO_SFS_RATIO_DEPRECATED;
  }
  return surfaceForSaleRealRate;
};
