import { Project } from '../../project/Project';
import { mapCases } from '../../project/map/mapCases';
import * as R from 'ramda';
import { CaseSpecification } from '../../specification/cases/CaseSpecification';
import { LevelSpecification } from '../../specification/levels/LevelSpecification';

export const migrateProject27To28 = (project: Project): Project =>
  mapCases((eachCase) =>
    R.pipe<[CaseSpecification], CaseSpecification>(
      R.over(
        R.lensProp('levels'),
        R.map<LevelSpecification, LevelSpecification>((level) =>
          R.pipe<[LevelSpecification], LevelSpecification, LevelSpecification>(
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            R.assoc('technicalPremiseSections', level.technicalPremises || []),
            R.dissoc('technicalPremises')
          )(level)
        )
      )
    )(eachCase)
  )(project);
