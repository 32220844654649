import * as React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { CardsList } from 'components/ui/CardsList';
import PanelTitle from '../PanelTitle';
import { ProjectNameCard } from './ProjectPanelView/ProjectNameCard';
import { ProjectSurfaceCard } from './ProjectPanelView/ProjectSurfaceCard';
import { ProjectLocationCard } from './ProjectPanelView/ProjectLocationCard';
import { ProjectSoilPreStudyCard } from './ProjectPanelView/ProjectSoilPreStudyCard';
import { projectDetailsEditRoute } from '../../../../routes/toolboxPanels/projectDetails';
import { Route } from 'react-router';
import { ProjectPanelWindows } from '../../windows/ProjectPanelWindows';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsProjectDetailsGingerOpened } from '../../../../store/selectors/navigation/windows/isProjectDetailsGingerOpened.selector';
import { HighlightOverlay } from '../../../layouts/HighlightOverlay';
import {
  getProjectDetailsFloorSpaceTriggerId,
  getProjectDetailsGingerTriggerId
} from '../../../../routes/toolboxPanels/triggers';
import { goToProjectDetails } from '../../../../store/actions/navigations.actions';
import { selectProjectId } from '../../../../store/selectors/project';
import { selectIsProjectDetailsFloorSpaceOpened } from '../../../../store/selectors/navigation/windows/isProjectDetailsFloorSpaceOpened.selector';
import { ProjectFloorSpaceCard } from './ProjectPanelView/ProjectFloorSpaceCard';
import { ProjectCstbIndicatorsParametersCard } from './ProjectPanelView/ProjectCstbIndicatorsParametersCard';

const ProjectPanelView: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const projectId = useSelector(selectProjectId);
  const isProjectDetailsGingerOpened = useSelector(selectIsProjectDetailsGingerOpened);
  const isProjectDetailsFloorSpaceOpened = useSelector(selectIsProjectDetailsFloorSpaceOpened);
  const isHighlighted = isProjectDetailsGingerOpened || isProjectDetailsFloorSpaceOpened;
  const triggerId = isHighlighted
    ? isProjectDetailsGingerOpened
      ? getProjectDetailsGingerTriggerId()
      : getProjectDetailsFloorSpaceTriggerId()
    : undefined;
  const handleGoToProjectDetails = React.useCallback(() => {
    if (projectId) dispatch(goToProjectDetails(projectId));
  }, [dispatch, projectId]);

  return (
    <div className="panel">
      <Route path={projectDetailsEditRoute.path} component={ProjectPanelWindows} />
      <CardsList>
        <PanelTitle title={t('Project')} />
        <Scrollbars
          autoHide={true}
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeight={true}
          autoHeightMin="100%"
          autoHeightMax="100%"
          style={{ width: '100%', height: 'calc(100vh - 116px)' }}
          renderTrackVertical={(properties: any) => (
            <div {...properties} className="track-vertical" />
          )}>
          <HighlightOverlay
            highlighted={isHighlighted}
            triggerId={triggerId}
            onReturn={handleGoToProjectDetails}>
            <ProjectNameCard />
            <ProjectSurfaceCard />
            {process.env.GB_FEAT_PROJECT_LOCATION !== 'false' && <ProjectLocationCard />}
            {process.env.GB_FEAT_FLOOR_SPACE !== 'false' && <ProjectFloorSpaceCard />}
            {process.env.GB_FEAT_GINGER_MAP !== 'false' && <ProjectSoilPreStudyCard />}
            {process.env.GB_FEAT_CSTB_API !== 'false' &&
              process.env.GB_FEAT_CSTB_INDICATOR_IC_CONSTRUCTION !== 'false' && (
                <ProjectCstbIndicatorsParametersCard />
              )}
          </HighlightOverlay>
        </Scrollbars>
      </CardsList>
    </div>
  );
};

export default ProjectPanelView;
