import * as R from 'ramda';
import {
  LevelGranulometry,
  LevelGranulometryFullFilled,
  LevelGranulometryWithBearingRelativeSections,
  LevelGranulometryWithLodgments
} from '../../../LevelGranulometry';
import { getTopLevelCommonPremiseSections } from '../sections/getTopLevelCommonPremiseSections';

export const getTopLevelCommonPremiseSectionsCount = (
  levelGranulometry:
    | LevelGranulometryWithLodgments
    | LevelGranulometryWithBearingRelativeSections
    | LevelGranulometryFullFilled
    | LevelGranulometry
): number => R.pipe(getTopLevelCommonPremiseSections, R.reduce(R.inc, 0))(levelGranulometry);
