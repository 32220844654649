import { LevelGranulometryFullFilled } from '../../LevelGranulometry';
import { getTopLevelHoppersSurface } from '../topLevels/surfaces/getTopLevelHoppersSurface';
import { getLevelAlreadyCalculatedRealBuiltSurface } from './getLevelAlreadyCalculatedRealBuiltSurface';
import { getLevelRealBuiltSurfaceEff } from './getLevelRealBuiltSurfaceEff';
import { roundWith2Decimal } from '../../../../../utils/round/roundWith2Decimal';
import { CaseGranulometry } from '../../../cases/CaseGranulometry';

export const getLevelRealBuiltSurfaceEffDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled
): string =>
  '<b>' +
  roundWith2Decimal(getLevelAlreadyCalculatedRealBuiltSurface(levelGranulometry).value) +
  ' m\u00B2</b> de SdP réelle<br /><b>+ ' +
  roundWith2Decimal(getTopLevelHoppersSurface(caseGranulometry, levelGranulometry)) +
  ' m\u00B2</b> de <i>Trémies</i><br /><b>= ' +
  roundWith2Decimal(getLevelRealBuiltSurfaceEff(caseGranulometry, levelGranulometry)) +
  ' m\u00B2</b>';
