import * as React from 'react';
import { Route } from 'react-router';
import { WindowsGroup } from '../../../../ui/WindowsGroup';
import CaseOtherPropertiesWindow from './CaseOtherPropertiesWindow';
import PropertyWindow from '../PropertyWindow/PropertyWindow';
import { caseGranulometryEditPropertyRoute } from '../../../../../routes/toolboxPanels/cases';

export const CaseOtherPropertiesWindowsGroup = () => {
  return (
    <WindowsGroup>
      <CaseOtherPropertiesWindow />
      <Route path={caseGranulometryEditPropertyRoute.path} component={PropertyWindow} />
    </WindowsGroup>
  );
};

export default CaseOtherPropertiesWindowsGroup;
