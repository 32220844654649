import { CaseGranulometry } from '../../cases/CaseGranulometry';
import { LevelGranulometry } from '../LevelGranulometry';
import { getCaseLevels } from '../../cases/queries/levels/getCaseLevels';
import { isUpperLevel } from './is/isUpperLevel';

export const getPreviousUpperLevel = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry
): LevelGranulometry[] =>
  getCaseLevels(caseGranulometry).reduce(
    (acc, level) =>
      level.level < levelGranulometry.level && isUpperLevel(caseGranulometry, level)
        ? [...acc, level]
        : acc,
    [] as LevelGranulometry[]
  );
