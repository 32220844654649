import MapboxDraw from '@mapbox/mapbox-gl-draw';
import {
  FloorSpaceFeature,
  MaybeFloorSpaceFeature
} from '../../../domain/specification/floorSpace/FloorSpaceFeature';
import * as R from 'ramda';
import mapboxgl from 'mapbox-gl';
import { Feature, FeatureCollection, Geometry, Polygon } from 'geojson';
import { DRAW_HELPER_MOVING_FEATURE } from '../domain/drawHelper/drawHelper.model';
import { normalizeSourceCoordinates } from '../utils/geometry/coordinates';

type AnyProperties<T = any> = { [name: string]: any } & T;

export interface FeatureTarget extends Feature {
  layer: AnyProperties<{ id: string }>;
  _geometry: Polygon;
  properties: AnyProperties<{
    id: string;
    mode: string;
    user_area: number;
    user_deactivate: boolean;
    user_perimeter: number;
    active: boolean;
    user_name: string;
  }>;
  source: string;
}

export type FeatureWithId = Feature & { id: string; properties: AnyProperties };
export type FeaturePolygonWithId = Feature<Polygon> & {
  id: string;
  properties: AnyProperties;
};
export type FeatureCollectionWithId = { type: 'FeatureCollection'; features: FeatureWithId[] };

export class DrawRepository {
  map: mapboxgl.Map;
  draw: MapboxDraw;
  constructor(map: mapboxgl.Map, draw: MapboxDraw) {
    this.map = map;
    this.draw = draw;
  }

  updateProperties(
    feature: { id: string } & { properties: Partial<FloorSpaceFeature['properties']> }
  ) {
    R.mapObjIndexed((value, key) => {
      this.draw.setFeatureProperty(feature.id, key, value);
    })(feature?.properties);
  }

  reCreate(feature: FeaturePolygonWithId) {
    this.draw.delete(feature.id);
    feature.geometry.coordinates[0] = normalizeSourceCoordinates(feature.geometry.coordinates[0]);
    this.draw.add(feature);
  }

  create(geojson: Feature | FeatureCollection | Geometry) {
    return this.draw.add(geojson);
  }

  removeFacade(floorSpaces: FeatureWithId[]) {
    floorSpaces.forEach(
      (floorSpace) =>
        floorSpace.id &&
        this.map.getSource('facades_markers_' + floorSpace.id) &&
        this.map.getLayer('facades_markers_layer_' + floorSpace.id) &&
        this.map.removeLayer('facades_markers_layer_' + floorSpace.id) &&
        this.map.removeSource('facades_markers_' + floorSpace.id)
    );
  }

  findOne(id: string): Feature {
    const polygon = this.draw.get(id);
    if (!polygon) throw new Error("L'identifiant du polygon n'existe pas");
    return polygon;
  }

  findAll() {
    return this.draw.getAll();
  }

  findMovingFeatures() {
    return this.draw
      .getAll()
      ?.features.filter((feature) => feature.properties?.type === DRAW_HELPER_MOVING_FEATURE);
  }
}

export type RemoveFacades = (floorSpaces: MaybeFloorSpaceFeature[]) => void;
