import { CaseFormDataLegacy, TopLevelDataLegacy } from '../../../../../legacy/caseFormData';
import { LevelGranulometryInitialEntries } from '../../../LevelGranulometry';

export const getTopLevelRealBuiltForcedSurface = (
  levelGranulometry: LevelGranulometryInitialEntries | TopLevelDataLegacy,
  caseData: CaseFormDataLegacy
): number | false => {
  const clonedTopLevelData = [...caseData.topLevelsData];
  const needToBeReversed = clonedTopLevelData[0].level !== 0;
  if (needToBeReversed) {
    clonedTopLevelData.reverse();
  }
  const hasFloorSpace = caseData.floorSpace.properties?.area;
  if (
    !hasFloorSpace &&
    clonedTopLevelData[levelGranulometry.level] &&
    clonedTopLevelData[levelGranulometry.level].realBuiltSurface
  ) {
    // Return the forced (filled) value
    return clonedTopLevelData[levelGranulometry.level].realBuiltSurface as number;
  } else {
    return false;
  }
};
