import * as R from 'ramda';
import { MaybeFloorSpaceFeature } from '../../FloorSpaceFeature';

export const getFloorSpaceFeaturesWithoutGeometry = (
  floorSpaceFeatures: MaybeFloorSpaceFeature[]
): MaybeFloorSpaceFeature[] =>
  R.pipe<[MaybeFloorSpaceFeature[]], MaybeFloorSpaceFeature[], MaybeFloorSpaceFeature[]>(
    R.reject(R.has('geometry')),
    R.reject(R.isNil)
  )(floorSpaceFeatures);
