import * as R from 'ramda';
import {
  LevelGranulometryFullFilled,
  LevelGranulometryWithBearingRelativeSections,
  LevelGranulometryWithLodgments
} from '../../../LevelGranulometry';
import { getLevelSections } from '../../sections/getLevelSections';
import {
  CommonPremiseSection,
  filterCommonPremiseSections
} from '../../../../sections/commonPremiseSections/CommonPremiseSection';

export const getTopLevelCommonPremiseSections = (
  levelGranulometry:
    | LevelGranulometryWithLodgments
    | LevelGranulometryWithBearingRelativeSections
    | LevelGranulometryFullFilled
): CommonPremiseSection[] =>
  R.pipe(getLevelSections, filterCommonPremiseSections)(levelGranulometry);
