import { DataTypeValue } from '../../domain/DataType';
import { matchesPath } from '../matchesPath';
import { RouteWithMakePath } from '../panel';

export type DeliverablesExportReportRouteParams = {
  projectId: string;
  reportId: string;
  reportDataType: DataTypeValue;
};
export const deliverablesExportReportRoute: RouteWithMakePath<DeliverablesExportReportRouteParams> =
  {
    path: `/project/:projectId/deliverables/export/:reportId/:reportDataType`,
    makePath: ({ projectId, reportId, reportDataType }) =>
      `/project/${projectId}/deliverables/export/${reportId}/${reportDataType}`,
    matchesPath: matchesPath<DeliverablesExportReportRouteParams>(
      /\/project\/([^/]+)\/deliverables\/export\/([^/])\/([^/]+)/,
      { projectId: 1, reportId: 2, reportDataType: 3 }
    )
  };

export type DeliverablesExportRouteParams = { projectId: string };
export const deliverablesExportRoute: RouteWithMakePath<DeliverablesExportRouteParams> = {
  path: `/project/:projectId/deliverables/export`,
  makePath: ({ projectId }) => `/project/${projectId}/deliverables/export`,
  matchesPath: matchesPath<DeliverablesExportRouteParams>(
    /\/project\/([^/]+)\/deliverables\/export/,
    {
      projectId: 1
    }
  )
};

export type DeliverablesArchivesRouteParams = { projectId: string };
export const deliverablesArchivesRoute: RouteWithMakePath<DeliverablesArchivesRouteParams> = {
  path: `/project/:projectId/deliverables/archives`,
  makePath: ({ projectId }) => `/project/${projectId}/deliverables/archives`,
  matchesPath: matchesPath<DeliverablesArchivesRouteParams>(
    /\/project\/([^/]+)\/deliverables\/archives/,
    {
      projectId: 1
    }
  )
};

export type DeliverablesRouteParams = { projectId: string };
export const deliverablesRoute: RouteWithMakePath<DeliverablesRouteParams> = {
  path: `/project/:projectId/deliverables`,
  makePath: deliverablesExportRoute.makePath,
  matchesPath: matchesPath<DeliverablesRouteParams>(/\/project\/([^/]+)\/deliverables/, {
    projectId: 1
  })
};
