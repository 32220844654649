import { LevelGranulometry, LevelGranulometryFullFilled } from '../../../../LevelGranulometry';
import { getLevelRealBuiltSurfaceEff } from '../../../surfaces/getLevelRealBuiltSurfaceEff';
import { getTopLevelRealOutsideWallsSurface } from '../../walls/realWalls/getTopLevelRealOutsideWallsSurface';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { getTopLevelGrossFloorSurfaceEff } from './getTopLevelGrossFloorSurfaceEff';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { getCaseGranulometryDrawnFloorSpaceFeatureArea } from '../../../../../cases/queries/get/getCaseGranulometryDrawnFloorSpaceFeatureArea';
import { isGroundLevel } from '../../../is/isGroundLevel';
import { getTopLevelForcedGrossFloorSurfaceEff } from './getTopLevelForcedGrossFloorSurfaceEff';
import { mustTopLevelGfsEffBeAdjusted } from './mustTopLevelGfsEffBeAdjusted';
import { getTopLevelHoppersSurface } from '../getTopLevelHoppersSurface';
import { getTopLevelOutsideInsulatedWallsThickness } from '../../walls/wallsThickness/getTopLevelOutsideInsulatedWallsThickness';
import {
  LARGE_TOP_LEVEL_DEFAULT_WIDTH,
  SMALL_TOP_LEVEL_DEFAULT_WIDTH
} from '../../walls/getTopLevelDefaultWidth';
import { roundWith3Decimal } from '../../../../../../../utils/round/roundWith3Decimal';
import { getCaseUpperLevelsWithAdjustedGfsEffCount } from '../../../../../cases/queries/levels/counts/getCaseUpperLevelsWithAdjustedGfsEffCount';
import { getCaseUpperLevelsGrossFloorSurfaceDistribution } from '../../../../../cases/queries/getCaseUpperLevelsGrossFloorSurfaceDistribution';
import { getCaseUpperLevelsGrossFloorSurfaceDistributionDetails } from '../../../../../cases/queries/getCaseUpperLevelsGrossFloorSurfaceDistribution.details';

export const getTopLevelGrossFloorSurfaceEffDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled,
  hasFloorSpace: boolean
): string => {
  let details: string[] = [];
  if (hasFloorSpace) {
    const caseFilledFloorSpace = getCaseGranulometryDrawnFloorSpaceFeatureArea(
      caseGranulometry
    ) as number;
    if (isGroundLevel(levelGranulometry)) {
      return (
        '<b>' +
        roundWith2Decimal(caseFilledFloorSpace) +
        'm\u00B2</b> SHOB VPP saisie via le dessin d’une emprise au sol au RC par l’opérateur.'
      );
    } else if (
      getTopLevelForcedGrossFloorSurfaceEff(caseGranulometry, levelGranulometry) !== undefined
    ) {
      return (
        '<b>' +
        roundWith2Decimal(
          getTopLevelForcedGrossFloorSurfaceEff(caseGranulometry, levelGranulometry) as number
        ) +
        'm\u00B2</b> SHOB VPP saisie via le champ SHOB VPP du niveau.'
      );
    } else {
      details = getCaseUpperLevelsGrossFloorSurfaceDistributionDetails(
        caseGranulometry,
        levelGranulometry as LevelGranulometry
      );

      if (mustTopLevelGfsEffBeAdjusted(caseGranulometry, levelGranulometry)) {
        // see : getTopLevelAdjustedGrossFloorSurfaceEff
        const previousLevelGfsEff = getCaseUpperLevelsGrossFloorSurfaceDistribution(
          caseGranulometry
        ).find((l) => l.level === levelGranulometry.level)?.gfsEff as number;
        // Display the result of distribution instead (see above)
        details = [
          ...details,
          '<br /><b>= ' +
            roundWith2Decimal(previousLevelGfsEff) +
            ' m\u00B2</b> de SHOB VPP distribuée<br />'
        ];

        const levelRealBuildSurface = levelGranulometry.realBuiltSurface || 0;
        const levelHoppersSurface = getTopLevelHoppersSurface(caseGranulometry, levelGranulometry);
        const levelRealBuildSurfaceEff = levelRealBuildSurface + levelHoppersSurface;
        const diff = caseGranulometry.diffBetweenRBSWithoutFloorSpaceAndRBSWithFloorSpace as number; // checked before
        const upperLevelsWithAdjustedGfsEffCount =
          getCaseUpperLevelsWithAdjustedGfsEffCount(caseGranulometry);
        const outsideWallsThickness = getTopLevelOutsideInsulatedWallsThickness(caseGranulometry);
        const levelWidth =
          previousLevelGfsEff <= 200
            ? SMALL_TOP_LEVEL_DEFAULT_WIDTH
            : LARGE_TOP_LEVEL_DEFAULT_WIDTH;
        details = [...details, '<i>Ajustement pour robustesse avec SdP Réelle :</i><br />'];
        details = [
          ...details,
          '<b>(' +
            roundWith2Decimal(levelRealBuildSurfaceEff) +
            ' m\u00B2</b> de SdP réelle VPP ajustée'
        ];
        details = [
          ...details,
          '<b>- (' +
            roundWith3Decimal(diff) +
            ') m\u00B2</b> de différence entre la SdP réelle de la cage avant et après saisie d’une SHOB VPP'
        ];
        details = [
          ...details,
          '<b>/ ' +
            upperLevelsWithAdjustedGfsEffCount +
            ' étages</b> à la SHOB VPP nécessitant un ajustement'
        ];
        details = [
          ...details,
          '<b>+ 2 * ' + outsideWallsThickness + ' m</b> d’épaisseur de voiles exposés'
        ];
        details = [
          ...details,
          '<b>* ' +
            levelWidth +
            ' m</b> de largeur par défaut calculée <u><i>sur base des ' +
            roundWith2Decimal(previousLevelGfsEff) +
            ' m\u00B2 de SHOB VPP précédement distribuée</i></u>'
        ];
        details = [
          ...details,
          '<b>- 4 * ' +
            outsideWallsThickness +
            ' * ' +
            outsideWallsThickness +
            ' m</b> d’épaisseur de voiles exposés<b>)</b>'
        ];
        details = [
          ...details,
          '<b>/ (1 - (2 * ' + outsideWallsThickness + ' m</b> d’épaisseur de voiles exposés'
        ];
        details = [
          ...details,
          '<b>/ ' + levelWidth + ' m</b> de largeur par défaut calculée<b>)</b>'
        ];
      }
    }
  } else {
    details = [
      ...details,
      '<b>' +
        roundWith2Decimal(getLevelRealBuiltSurfaceEff(caseGranulometry, levelGranulometry)) +
        ' m\u00B2</b> de SdP réelle VPP'
    ];
    details = [
      ...details,
      '<b>+ ' +
        roundWith2Decimal(getTopLevelRealOutsideWallsSurface(caseGranulometry, levelGranulometry)) +
        ' m\u00B2</b> d’emprise réelle de voiles exposés'
    ];
  }
  details = [...details, ''];
  return (
    details.join('<br />') +
    '<br /><b>= ' +
    roundWith2Decimal(getTopLevelGrossFloorSurfaceEff(caseGranulometry, levelGranulometry)) +
    ' m\u00B2</b>'
  );
};
