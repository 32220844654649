import * as R from 'ramda';
import { Project } from '../../project/Project';
import { mapCases } from '../../project/map/mapCases';
import { createEmptyFloorSpaceFeature } from '../../specification/floorSpace/queries/floorSpaceFeature/createEmptyFloorSpaceFeature';
import { CaseSpecification } from '../../specification/cases/CaseSpecification';

export const migrateProject19To20 = (project: Project): Project =>
  mapCases((eachCase) =>
    R.pipe<[CaseSpecification], CaseSpecification, CaseSpecification>(
      R.assoc(
        'floorSpaceFeature',
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        eachCase.featureFloorSpace || createEmptyFloorSpaceFeature(eachCase.id)
      ),
      R.dissoc('featureFloorSpace')
    )(eachCase)
  )(project);
