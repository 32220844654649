import {
  MaybeFloorSpaceFeature,
  FloorSpaceFeatureId,
  FloorSpaceFeature
} from '../../domain/specification/floorSpace/FloorSpaceFeature';
const prefix = 'project/';

export const FLOOR_SPACE_CHANGED = prefix + 'floorspace_changed';
export const floorSpaceChanged = (floorSpaceFeature: FloorSpaceFeature) => ({
  type: FLOOR_SPACE_CHANGED,
  payload: floorSpaceFeature
});

export const lockFloorSpace = (floorSpace: FloorSpaceFeature) =>
  floorSpaceChanged({
    ...floorSpace,
    properties: {
      ...floorSpace.properties,
      shape: {
        lock: true
      }
    }
  });

export type FloorSpaceChangedAction = ReturnType<typeof floorSpaceChanged>;

export const FLOOR_SPACE_ADDED = prefix + 'floorspace_added';
export const floorSpaceAdded = (floorSpaceFeature: MaybeFloorSpaceFeature) => ({
  type: FLOOR_SPACE_ADDED,
  payload: floorSpaceFeature
});
export type FloorSpaceAddedAction = ReturnType<typeof floorSpaceAdded>;

export const FLOOR_SPACE_REMOVED = prefix + 'remove_floor_spaces';
export const removeFloorSpace = (floorSpaceId: FloorSpaceFeatureId) => ({
  type: FLOOR_SPACE_REMOVED,
  payload: floorSpaceId
});
export type RemoveFloorSpacesAction = ReturnType<typeof removeFloorSpace>;

export const FLOOR_SPACE_SELECTED = prefix + 'floor_space_select';
export const selectFloorSpace = (floorSpaceId: FloorSpaceFeatureId) => ({
  type: FLOOR_SPACE_SELECTED,
  payload: floorSpaceId
});
export type FloorSpacesSelectAction = ReturnType<typeof selectFloorSpace>;
