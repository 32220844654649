export const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
export const APP_COLOR = '#1c3444';
export const DARK_GRAY_COLOR = '#8f9899';
export const DEFAULT_CASE_WALL_THICKNESS = 0.3; // 0.3m
export const DEFAULT_CEILING_HEIGHT = 2.5; // 2.5m
export const DEFAULT_FLOOR_THICKNESS = 0.3; // 0.3m
export const DEFAULT_LEVEL_WALL_THICKNESS = 0.2; // 0.2m
export const DEFAULT_ZOOM_VALUE = 0.5;
export const ERROR_COLOR = '#ff0000';
export const FUNDATIONS_COLOR = '#9b4101';
export const GRAY_COLOR = '#b2bebf';
export const GROUND_COLOR = '#7fa8b2';
export const HARDROCKS_COLOR = '#4e6e7b';
export const LAND_SIZE = 1000; // m
export const LIGHT_GRAY_COLOR = '#d6e3e5';
export const LINEAR_METER_SCALE = 40; // 1ml = 40px
export const LODGEMENT_COLOR = '#478d72';
export const OFFICE_COLOR = '#5300ab';
export const OTHERS_COLOR = '#c20375';
export const RESIZE_SMALL_SECTION = true;
export const ROOF_COLOR = '#41111d';
export const SCALED_LAND_SIZE = LAND_SIZE * LINEAR_METER_SCALE; // px
export const SDP_COLOR = '#0000ff';
export const SECTION_WRAPPERS = true;
export const MINIMUM_OUTSIDE_RULER_WIDTH = 100; // px
export const SHOP_COLOR = '#ff6450';
export const SKY1_COLOR = '#cfeeff';
export const SKY2_COLOR = '#e6f7ff';
export const SMALL_SECTION_SURFACE = 9;
export const SOFTROCKS_COLOR = '#668b96';
export const SQUARE_METER_SCALE = 4; // 1m2 = 4px
export const T1_COLOR = '#1fb7ca';
export const T2_COLOR = '#3ca19c';
export const T3_COLOR = LODGEMENT_COLOR;
export const T4_COLOR = '#4c794c';
export const T5_COLOR = '#4c672d';
export const OTHER_SFS_SECTIONS_COLOR = '#253d33';
export const OTHER_RBS_SECTIONS_COLOR = '#111196';
export const SHAB_COLOR = T5_COLOR;
export const VALID_COLOR = '#009900';
export const WARNING_COLOR = '#ff6600';
export const INFO_COLOR = '#1894c4';
export const WATER_COLOR = '#0096ff';
export const DEFAULT_CAR_BOX_COUNT_PER_LODGMENT = 1;
export const MAXIMUM_CAR_BOX_COUNT_PER_LODGMENT = 3;
export const DEFAULT_LODGMENT_COUNT_PER_CAR_BOX = 1;
export const MINIMUM_LODGMENT_COUNT_PER_CAR_BOX = 1;
export const DEFAULT_CAR_PARK_COUNT_PER_SHAB = 1;
export const MAXIMUM_CAR_PARK_COUNT_PER_SHAB = 2;
export const DEFAULT_SHAB_PER_CAR_PARK = 75;
export const MINIMUM_SHAB_PER_CAR_PARK = 30;
export const MINIMUM_BASEMENT_LEVEL_NUMBER = 0;
export const MAXIMUM_BASEMENT_LEVEL_NUMBER = 2;
export const MINIMUM_TOP_LEVEL_NUMBER = 1;
export const MAXIMUM_TOP_LEVEL_NUMBER = 11;
export const MINIMUM_ZOOM_VALUE = 0.1;
export const MAXIMUM_ZOOM_VALUE = 2;
export const DEFAULT_TOP_LEVEL_SURFACE = 300; // for createCase only
export const DEFAULT_PROJECT_SURFACE = 1000;
export const MINIMUM_PROJECT_CBS = 100;
export const MAXIMUM_PROJECT_CBS = 10000;
export const MINIMUM_CASE_CBS = MINIMUM_PROJECT_CBS;
export const MAX_ALLOWED_NUMBER_INPUT = MAXIMUM_PROJECT_CBS + 5000;
export const BEARING_EMERGENCY_STAIRS_SURFACE = 2.25; // 1.5*1.5*1

// IMPORTANT : The displayed rate in GranulometryView/SurfaceCalculation component is not used for calculation.
export const DEFAULT_RBS_TO_CBS_RATIO = 0.9;
export const DEFAULT_CBS_TO_SFS_RATIO = 0.9;
export const DEFAULT_RBS_TO_SFS_RATIO_DEPRECATED =
  DEFAULT_RBS_TO_CBS_RATIO * DEFAULT_CBS_TO_SFS_RATIO;
