import { createSelector, Selector } from 'reselect';
import { selectPath } from '../navigation/getPath.selector';
import { CaseId } from '../../../domain/specification/cases/CaseSpecification';
import { State } from '../../reducers';
import { Maybe } from '../../../utils/Maybe';
import { caseRoute } from '../../../routes/toolboxPanels/cases';

export const selectCurrentCaseIdFromRoute: Selector<State, Maybe<CaseId>> = createSelector(
  [selectPath],
  (path) => caseRoute.matchesPath(path).params?.caseId
);
