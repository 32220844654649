import stringify from 'json-stringify-safe';
import { ApiServerError } from './errors/server.error';
import { ApiAuthError } from './errors/auth.error';
import { ApiRedirectError } from './errors/redirect.error';

export const CONVERT_API_URL = () =>
  process.env.GB_SERVICES_PDF_TO_IMAGES || 'http://localhost:8081';

export const ENDPOINT_CREATE_IMAGE = '/image';
export const ENDPOINT_DELETE_IMAGE = '/image';

const fetchConvertApi = async (
  path: string,
  method: string,
  body?: object,
  additionalHeaders: Record<string, string> = {}
): Promise<Response> => {
  const headers: HeadersInit = {
    'Access-Control-Allow-Origin': 'Allow',
    Accept: 'application/json',
    ...additionalHeaders
  };
  const response = await fetch(CONVERT_API_URL() + path, {
    method,
    headers,
    mode: 'cors',
    credentials: 'include',
    body: body ? stringify(body, null, 2) : undefined
  });

  if (response.status === 401 || response.status === 403)
    throw new ApiAuthError(path, response.status, response.statusText);
  if (response.status >= 400) throw new ApiServerError(path, response.status, response.statusText);
  if (response.status >= 300 || response.redirected)
    throw new ApiRedirectError(
      path,
      response.status,
      response.statusText,
      response.headers.get('location') || 'other URL'
    );

  return response;
};

export const get = (path: string) => fetchConvertApi(path, 'GET');

export const post = (path: string, body: object): Promise<Response> =>
  fetchConvertApi(path, 'POST', body, { 'Content-Type': 'application/json' });

export const remove = (path: string, body: { path: string }): Promise<Response> =>
  fetchConvertApi(path, 'DELETE', body, { 'Content-Type': 'application/json' });
