import ramp, { RampSection } from '../../../../../sections/circulationSections/ramp';
import { getDefaultUnitConvertedPropertyValue } from '../../../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const createTopLevelRampSection = (
  caseGranulometry: CaseGranulometry,
  group: string
): RampSection => {
  const length = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'rampLength'
  ) as number;
  const width = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'rampWidth'
  ) as number;
  const surface = length * width;
  const exposureRate =
    (getDefaultUnitConvertedPropertyValue(
      caseGranulometry.initialSpecifications,
      'rampExposureRate'
    ) as number) * 4;
  const garageDoorHeight = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'rampGarageDoorHeight'
  ) as number;
  const garageDoorWidth = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'rampGarageDoorWidth'
  ) as number;
  return ramp({
    surface,
    length,
    width,
    exposureRate,
    garageDoorWidth,
    garageDoorHeight,
    group
  });
};
