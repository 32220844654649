import { Card } from '../../../../ui/Card';
import { CardTitle } from '../../../../ui/CardTitle';
import React, { useState } from 'react';
import { Note } from '../../../../ui/Note';
import { Button } from '../../../../ui/Button';
import { FormGroup } from '../../../../ui/FormGroup';
import { StepState } from './index';
import { SpecialLabel } from '../../../../ui/SpecialLabel';
import { FileInput } from '../../../../ui/input/FileInput';
import { Input } from '../../../../ui/input/Input';
import { countPageNumbers } from '../../../../../services/pdfToImages/utils/countPageNumbers';

type AddFile = (file: File, pageToConvert: number) => Promise<void>;

interface UploadCardProps {
  addImage: AddFile;
  onClickRemoveImage: () => void;
  goStepForward: () => void;
  step: StepState;
}

export const UploadCard = ({
  addImage,
  onClickRemoveImage,
  step,
  goStepForward
}: UploadCardProps) => {
  const [pageToConvert, setPageToConvert] = useState<number>(1);
  const [countNumberPage, setCountNumberPage] = useState<number>(1);
  const [disabled, setDisabled] = useState(false);

  const handleChangeFile = async (file: File) => {
    const count = (await countPageNumbers(file)) || 1;
    setCountNumberPage(count);
  };
  const handleAddFile = (file: File) => {
    setDisabled(false);
    addImage(file, pageToConvert);
  };

  return (
    <Card>
      <FormGroup>
        <CardTitle>1/ Envoyez un fichier pdf</CardTitle>
        <div>
          {!step.upload && (
            <>
              <FileInput
                disabled={pageToConvert > countNumberPage || pageToConvert < 1 || disabled}
                setDisabled={setDisabled}
                addFile={handleAddFile}
                handleChange={handleChangeFile}
              />
              <div>
                <Input
                  label={'Séléctionnez le numéro de la page à ajouter'}
                  placeholder={'Numéro de la page'}
                  name={'pageToConvert'}
                  type="number"
                  value={pageToConvert}
                  suffix={countNumberPage + ' page(s)'}
                  handleChange={setPageToConvert}
                  useDebounce={false}
                  disabled={false}
                  max={countNumberPage}
                  min={1}
                />
              </div>
            </>
          )}
          {step.upload && (
            <FormGroup horizontal={true}>
              <div>
                <Button content={'Suivant'} handleClick={goStepForward} appearance="plain" />
              </div>{' '}
              <div>
                <Button
                  content={'Supprimer le fichier'}
                  handleClick={onClickRemoveImage}
                  appearance="outline"
                />
              </div>
            </FormGroup>
          )}
        </div>

        {step.upload && (
          <div>
            <SpecialLabel label={step.upload.imagePath} />
          </div>
        )}
      </FormGroup>
      <Note>Veuillez sélectionner un PDF</Note>
    </Card>
  );
};
