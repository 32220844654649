import * as React from 'react';
import { Route } from 'react-router';
import {
  caseGranulometryEditEnergyAndNetworksRoute,
  caseGranulometryEditFifthFacadeRoute,
  caseGranulometryEditFloorSpaceRoute,
  caseGranulometryEditGroundAdaptationsRoute,
  caseGranulometryEditInfrastructureRoute,
  caseGranulometryEditLevelRoute,
  caseGranulometryEditLodgmentTypeRoute,
  caseGranulometryEditOtherPropertiesRoute,
  caseGranulometryEditOutdoorsRoute,
  caseGranulometryEditSuperstructureRoute
} from 'routes/toolboxPanels/cases';
import LodgmentsTypesWindow from './CasesPanelWindows/LodgmentsTypesWindow/LodgmentsTypesWindow';
import LevelWindow from './CasesPanelWindows/LevelWindow/LevelWindow';
import CaseFifthFacadeWindow from './CasesPanelWindows/CaseFifthFacadeWindow/CaseFifthFacadeWindow';
import CaseSuperstructureWindow from './CasesPanelWindows/CaseSuperstructureWindow/CaseSuperstructureWindow';
import { CaseEnergyAndNetworksWindow } from './CasesPanelWindows/CaseEnergyAndNetworksWindow/CaseEnergyAndNetworksWindow';
import CaseInfrastructureWindow from './CasesPanelWindows/CaseInfrastructureWindow/CaseInfrastructureWindow';
import CaseGroundAdaptationsWindow from './CasesPanelWindows/CaseGroundAdaptationsWindow/CaseGroundAdaptationsWindow';
import CaseOutdoorsWindow from './CasesPanelWindows/CaseOutdoorsWindow/CaseOutdoorsWindow';
import CaseOtherPropertiesWindowsGroup from './CasesPanelWindows/CaseOtherPropertiesWindowsGroup/CaseOtherPropertiesWindowsGroup';
import { ToolboxWindows } from './ToolboxWindows';
import { FloorSpaceWindow } from './FloorSpaceWindow/FloorSpaceWindow';

export const CasesPanelWindows = () => (
  <ToolboxWindows>
    <Route path={caseGranulometryEditFloorSpaceRoute.path} component={FloorSpaceWindow} />
    <Route path={caseGranulometryEditFifthFacadeRoute.path} component={CaseFifthFacadeWindow} />
    <Route
      path={caseGranulometryEditSuperstructureRoute.path}
      component={CaseSuperstructureWindow}
    />
    <Route
      path={caseGranulometryEditEnergyAndNetworksRoute.path}
      component={CaseEnergyAndNetworksWindow}
    />
    <Route
      path={caseGranulometryEditInfrastructureRoute.path}
      component={CaseInfrastructureWindow}
    />
    <Route
      path={caseGranulometryEditGroundAdaptationsRoute.path}
      component={CaseGroundAdaptationsWindow}
    />
    <Route path={caseGranulometryEditOutdoorsRoute.path} component={CaseOutdoorsWindow} />
    <Route
      path={caseGranulometryEditOtherPropertiesRoute.path}
      component={CaseOtherPropertiesWindowsGroup}
    />
    <Route path={caseGranulometryEditLodgmentTypeRoute.path} component={LodgmentsTypesWindow} />
    <Route path={caseGranulometryEditLevelRoute.path} component={LevelWindow} />
  </ToolboxWindows>
);
