import { CaseProjection } from '../../CaseProjection';
import { BuildingGranulometry } from '../../../../granulometry/buildings/BuildingGranulometry';
import { casesProjectionsToCasesFormData } from '../../../../legacy/casesProjectionsToCasesFormData';
import { renderBuilding } from '../../../../legacy/methodsForGranulo/renderBuilding';
import { BuildingProjection } from '../../../buildings/BuildingProjection';

export const runFakeGranulo = (caseProjection: CaseProjection): BuildingGranulometry => {
  // FIXME This is called more than 10 times on simple refresh !
  // console.log('running fake granulo');
  const modifiedCaseProjection = {
    ...caseProjection,
    distribution: caseProjection.projectedDistribution,
    properties: caseProjection.projectedProperties,
    surfaces: caseProjection.projectedSurfaces
  };
  const casesFormData = casesProjectionsToCasesFormData([modifiedCaseProjection]);
  const fakeBuildingProjection = {
    id: 'fakeGranuloBuildingId',
    title: 'fakeGranuloBuildingTitle',
    cases: [caseProjection]
  } as BuildingProjection;
  return renderBuilding(fakeBuildingProjection, 0, casesFormData);
};
