import i18next from 'i18next';
import { OTHER_SFS_SECTIONS_COLOR } from '../../../../constants/appConstants';
import { Section, SectionDrawn } from '../Section';
import { CommonPremiseSectionName } from './CommonPremiseSection';
import { SectionWithExposureRate } from '../SectionWithExposureRate';
import { SectionWithTechnicalSheaths } from '../SectionWithTechnicalSheaths';
import { IconObjectProps } from '../../../../components/ui/Icons/iconObject';
import { SectionWithGlazedSurface } from '../SectionWithGlazedSurface';

interface CollectiveKitchenSectionProps {
  surface?: number;
  exposureRate?: number;
  width?: number;
}

export interface CollectiveKitchenSection
  extends Section,
    SectionDrawn,
    SectionWithExposureRate,
    SectionWithTechnicalSheaths,
    SectionWithGlazedSurface {
  name: CommonPremiseSectionName.CollectiveKitchenSection;
  width?: number;
  defaultWidth: number;
  minWidth: number;
  maxWidth: number;
  defaultSurface: number;
  minSurface: number;
  maxSurface: number;
}

export const COLLECTIVE_KITCHEN_SECTION_DEFAULT_SURFACE = 140;
export const COLLECTIVE_KITCHEN_SECTION_MIN_SURFACE = 60;
export const COLLECTIVE_KITCHEN_SECTION_MAX_SURFACE = 170;
export const COLLECTIVE_KITCHEN_SECTION_DEFAULT_EXPOSURE_RATE = 1;
export const COLLECTIVE_KITCHEN_SECTION_DEFAULT_WITH = 10;
export const COLLECTIVE_KITCHEN_SECTION_MIN_WITH = 8;
export const COLLECTIVE_KITCHEN_SECTION_MAX_WITH = 20;
export const COLLECTIVE_KITCHEN_SECTION_ICON = {
  type: 'object',
  iconName: 'collectiveKitchen',
  color: OTHER_SFS_SECTIONS_COLOR
} as IconObjectProps;

const collectiveKitchenSection = ({
  surface,
  exposureRate,
  width
}: CollectiveKitchenSectionProps): CollectiveKitchenSection => ({
  // Section
  id: 'NOID',
  name: CommonPremiseSectionName.CollectiveKitchenSection,
  title: i18next.t('collectiveKitchenSection.title'),
  type: 'others',
  displayedSurface: surface || COLLECTIVE_KITCHEN_SECTION_DEFAULT_SURFACE,
  // SectionDrawn
  surface: surface || COLLECTIVE_KITCHEN_SECTION_DEFAULT_SURFACE,
  color: OTHER_SFS_SECTIONS_COLOR,
  icon: COLLECTIVE_KITCHEN_SECTION_ICON,
  // SectionWithExposureRate
  exposureRate: exposureRate || undefined,
  defaultExposureRate: COLLECTIVE_KITCHEN_SECTION_DEFAULT_EXPOSURE_RATE,
  // SectionWithTechnicalSheaths
  technicalSheathCount: 0,
  // SectionWithGlazedSurface
  glazedLinear: undefined,
  defaultGlazedRate: 0.5,
  // CollectiveKitchenSection
  width: width || undefined,
  defaultWidth: COLLECTIVE_KITCHEN_SECTION_DEFAULT_WITH,
  minWidth: COLLECTIVE_KITCHEN_SECTION_MIN_WITH,
  maxWidth: COLLECTIVE_KITCHEN_SECTION_MAX_WITH,
  defaultSurface: COLLECTIVE_KITCHEN_SECTION_DEFAULT_SURFACE,
  minSurface: COLLECTIVE_KITCHEN_SECTION_MIN_SURFACE,
  maxSurface: COLLECTIVE_KITCHEN_SECTION_MAX_SURFACE
});

export default collectiveKitchenSection;
