import { LevelGranulometry } from '../../levels/LevelGranulometry';
import { SectionWithGlazedSurface } from '../SectionWithGlazedSurface';
import { CaseGranulometry } from '../../cases/CaseGranulometry';
import { SectionWithExposureRate } from '../SectionWithExposureRate';
import { getSectionDefaultGlazedLinear } from './getSectionDefaultGlazedLinear';

export const getSectionGlazedLinear = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry,
  section: SectionWithGlazedSurface & SectionWithExposureRate
): number =>
  section.glazedLinear !== undefined
    ? section.glazedLinear
    : getSectionDefaultGlazedLinear(caseGranulometry, levelGranulometry, section);
