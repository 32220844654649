import { Project } from '../../project/Project';
import { mapCases } from '../../project/map/mapCases';
import { DEFAULT_HEATING_DISTRIBUTION } from '../../specification/cases/HeatingDistribution';

export const migrateProject15To16 = (project: Project): Project => {
  return mapCases((eachCase) => {
    return {
      ...eachCase,
      heatingDistribution: eachCase.heatingDistribution || DEFAULT_HEATING_DISTRIBUTION
    };
  })(project);
};
