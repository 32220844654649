import { matchesPath } from './matchesPath';
import { RouteWithMakePath } from './panel';

export type AppMenuRouteParams = { projectId: string };
export const appMenuRoute: RouteWithMakePath<AppMenuRouteParams> = {
  path: `/project/:projectId/appMenu`,
  makePath: ({ projectId }) => `/project/${projectId}/appMenu`,
  matchesPath: matchesPath<AppMenuRouteParams>(/\/project\/([^/]+)\/appMenu/, {
    projectId: 1
  })
};

export type AppMenuEditUserRouteParams = { projectId: string };
export const appMenuEditUserRoute: RouteWithMakePath<AppMenuEditUserRouteParams> = {
  path: `/project/:projectId/appMenu/editUser`,
  makePath: ({ projectId }) => `/project/${projectId}/appMenu/editUser`,
  matchesPath: matchesPath<AppMenuEditUserRouteParams>(/\/project\/([^/]+)\/appMenu\/editUser/, {
    projectId: 1
  })
};
