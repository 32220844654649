import { MapElementsToDraw } from '../../../../store/selectors/map/mapElementsToDraw.selector';
import MapboxDraw from '@mapbox/mapbox-gl-draw';

export const activateOrDeactivateFloorSpace =
  (draw: MapboxDraw, floorSpaceState: MapElementsToDraw) => (currentCaseLabel?: string) => {
    if (!currentCaseLabel) return null;
    const floorSpaceCurent = floorSpaceState.floorSpaceFeatures.find(
      (fFS) => fFS.properties?.name === currentCaseLabel
    );
    if (currentCaseLabel) {
      floorSpaceCurent && draw.changeMode('direct_select', { featureId: floorSpaceCurent.id });
      floorSpaceState.floorSpaceFeatures
        .filter((fFS) => fFS.properties?.name !== currentCaseLabel)
        .forEach((f) => {
          draw.setFeatureProperty(f.id, 'deactivate', true);
        });
    } else {
      floorSpaceState.floorSpaceFeatures.forEach((f) => {
        draw.setFeatureProperty(f.id, 'deactivate', false);
      });
    }
  };
