import { createSelector } from 'reselect';
import { State } from '../../reducers';
import { FloorSpaceFeature } from '../../../domain/specification/floorSpace/FloorSpaceFeature';
import { selectProject } from '../project';
import { ExistingProject, UnsavedProject } from '../../../domain/project/Project';
import { getProjectFloorSpaceFeatures } from '../../../domain/specification/floorSpace/queries/floorSpaceFeature/getProjectFloorSpaceFeatures';

export const selectFeaturesFloorSpaces = createSelector<
  State,
  UnsavedProject | ExistingProject,
  FloorSpaceFeature[]
>([selectProject], (project) => getProjectFloorSpaceFeatures(project.buildings));
