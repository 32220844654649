import { MapRepository } from './map.repository';
import mapboxgl, { AnySourceImpl } from 'mapbox-gl';
import { Position } from 'geojson';
import { RasterServices } from './raster/raster.services';
import { toggleHideLayer } from '../domain/drawHelper/drawHelper.utils';

export class MapServices extends RasterServices {
  repo: MapRepository;
  constructor(mapRepo: MapRepository) {
    super(mapRepo);
    this.repo = mapRepo;
  }

  findLayersByPrefix(prefix: string) {
    const testRegex = new RegExp(prefix);
    const filterGetAllHelperLayers = (layer: mapboxgl.AnyLayer) => testRegex.test(layer.id);
    return this.repo.findLayers(filterGetAllHelperLayers);
  }

  toggleHideLayout(layerId: string) {
    this.repo.toggleHideLayoutProperty(layerId);
  }

  toggleHideLayer(layerId: string, hide: boolean) {
    toggleHideLayer((layerId: string, filter?: any[]) => this.repo.map.setFilter(layerId, filter))(
      layerId,
      hide
    );
  }

  findOneLayer(id: string) {
    return this.repo.findOneLayer(id);
  }

  getLayerCoordinates(sourceId: string): Position[] {
    const source = this.repo.getSource(sourceId) as AnySourceImpl & { coordinates: Position[] };
    return source.coordinates;
  }

  addPolygon(coordinates: Position[], sourceId: string) {
    this.repo.addPolygon(coordinates, sourceId);
  }

  addMarker(coordinates: Position, options?: mapboxgl.MarkerOptions) {
    options = options || {
      color: '#000000',
      draggable: true
    };
    return this.repo.addMarker(coordinates, options);
  }
}
