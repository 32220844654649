import { Surface } from '../../../../specification/Surface';
import { CaseGranulometry } from '../../CaseGranulometry';
import { getCaseGrossFloorSurfaceEff } from './getCaseGrossFloorSurfaceEff';

const removeFloorSpaceFromCaseGranulometry = (
  caseGranulometry: CaseGranulometry
): CaseGranulometry => ({
  ...caseGranulometry,
  initialSpecifications: {
    ...caseGranulometry.initialSpecifications,
    floorSpace: {
      ...caseGranulometry.initialSpecifications.floorSpace,
      properties: undefined
    }
  }
});

const removeGfsEffRelativeTopLevelsRBSFromCaseInitialSpec = (
  caseGranulometry: CaseGranulometry
): CaseGranulometry => ({
  ...caseGranulometry,
  initialSpecifications: {
    ...caseGranulometry.initialSpecifications,
    gfsEffRelativeTopLevelsRBS: undefined
  }
});

export const getTheoreticalCaseGrossFloorSurfaceEff = (
  caseGranulometry: CaseGranulometry
): Surface =>
  new Surface(
    getCaseGrossFloorSurfaceEff(
      removeFloorSpaceFromCaseGranulometry(
        removeGfsEffRelativeTopLevelsRBSFromCaseInitialSpec(caseGranulometry)
      ),
      true
    ).value
  );
