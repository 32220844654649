import { FeatureCollection } from 'geojson';
import mapboxgl from 'mapbox-gl';
import { selectedParcelFillLayer, selectedParcelLineLayer } from '../../styles/layers';
import { FeatureParcel } from '../../../../domain/Parcel';

export const initParcelLayer = (map: mapboxgl.Map, parcels: FeatureParcel[]): FeatureParcel[] => {
  const geoJsonData: FeatureCollection<GeoJSON.Geometry> = {
    type: 'FeatureCollection',
    features: parcels
  };

  map.addSource('selected_parcels', {
    type: 'geojson',
    data: geoJsonData
  });

  map.addLayer(selectedParcelFillLayer);
  map.addLayer(selectedParcelLineLayer);

  map.moveLayer('selectedParcelFillLayer', 'numeroSymbolLayer');
  map.moveLayer('selectedParcelLineLayer', 'numeroSymbolLayer');

  return parcels;
};
