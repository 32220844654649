import carParkInfra, {
  CarParkInfraSection
} from '../../../../../sections/parkingSections/carParkInfra';
import { getCaseDataPropertyValue } from '../../../../../../specification/cases/queries/get/properties/getCaseDataPropertyValue';
import { getDefaultUnitConvertedPropertyValue } from '../../../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const createBasementLevelCarParkInfra = (
  caseGranulometry: CaseGranulometry
): CarParkInfraSection => {
  const depth = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'carParkDepth'
  ) as number;
  const width = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'carParkWidth'
  ) as number;
  const surface = depth * width;
  const outsideWallsConcreteRatio = getCaseDataPropertyValue(
    caseGranulometry.initialSpecifications,
    'carParkOutsideWallsConcreteRatio'
  ) as number;
  const beamHeight = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'carParkBeamHeight'
  ) as number;
  const beamWidth = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'carParkBeamWidth'
  ) as number;
  const pillarThickness = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'carParkPillarThickness'
  ) as number;
  const pillarWidth = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'carParkPillarWidth'
  ) as number;

  return carParkInfra({
    surface,
    depth,
    width,
    outsideWallsConcreteRatio,
    beamHeight,
    beamWidth,
    pillarThickness,
    pillarWidth
  });
};
