import * as R from 'ramda';
import { Project } from '../../project/Project';
import {
  BuildingSpecification,
  casesFromBuilding
} from '../../specification/buildings/BuildingSpecification';
import { CaseSpecification } from '../../specification/cases/CaseSpecification';
import { checkThat } from '../checkThat';
import { Surface } from '../../specification/Surface';
import { Maybe } from '../../../utils/Maybe';
import { MINIMUM_CASE_CBS } from '../../../constants/appConstants';

export const projectHasSufficientRemainingSurfaceForANewCase = <P extends Project>(
  project: P
): boolean =>
  R.pipe<
    [Project],
    BuildingSpecification[],
    CaseSpecification[],
    Surface[],
    Surface,
    number,
    boolean
  >(
    R.prop('buildings'),
    R.chain(casesFromBuilding),
    R.map(
      R.pipe<[CaseSpecification], Maybe<Surface>, Surface>(
        (c) => c.surfaces.cuttedBuiltSurface,
        R.defaultTo(new Surface(MINIMUM_CASE_CBS))
      )
    ),
    Surface.sum,
    (totalUsed) => project.projectedSurface.value - totalUsed.value,
    R.lte(MINIMUM_CASE_CBS)
  )(project);

export const checkThatProjectHasSufficientRemainingSurfaceForANewCase = (project: Project) =>
  checkThat(
    projectHasSufficientRemainingSurfaceForANewCase,
    'project has insufficient remaining surface for a new case',
    project
  );
