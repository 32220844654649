import { CaseGranulometry } from '../../../CaseGranulometry';
import { getDefaultUnitConvertedPropertyValue } from '../../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';

export const getCaseSmokeExtractionDuctSectionsCount = (
  caseGranulometry: CaseGranulometry
): number =>
  getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'smokeExtractionDuctsCount'
  ) as number;
