import { CaseSpecification } from '../../../specification/cases/CaseSpecification';
import { CaseProjection } from '../CaseProjection';
import { Surface } from '../../../specification/Surface';
import { getPropertyValue } from '../../../specification/cases/Property';
import { getMinRbsSurface } from './caseSurfaceProjection/getMinRbsSurface';
import { getCbsToSfsRatio } from './caseSurfaceProjection/getCbsToSfsRatio';
import { calculateMaxSurfaceForSale } from './caseSurfaceProjection/calculateMaxSurfaceForSale';
import { setCaseProjectedDistribution } from './setCaseProjectedDistribution';
import { setCaseProjectedProperties } from './setCaseProjectedProperties';
import { DEFAULT_CBS_TO_SFS_RATIO } from '../../../../constants/appConstants';
import { setCaseProjectedTopLevelsCount } from './levels/topLevelsCount/setCaseProjectedTopLevelsCount';
import { getCaseTopLevels } from '../../../specification/cases/queries/get/levels/getCaseTopLevels';
import { getLevelTechnicalPermiseSections } from '../../../specification/levels/queries/get/getLevelTechnicalPermiseSections';
import { getSectionDisplayedSurface } from '../../../granulometry/sections/queries/surfaces/getSectionDisplayedSurface';

export const getCaseProjectionFromCaseSpecification =
  (cuttedBuiltSurface: Surface, withMaxSfs?: boolean) =>
  (caseSpecification: CaseSpecification): CaseProjection => {
    const cbs = caseSpecification.surfaces.cuttedBuiltSurface || cuttedBuiltSurface;
    const sfs =
      caseSpecification.surfaces.surfaceForSale ||
      new Surface(cbs.value * DEFAULT_CBS_TO_SFS_RATIO);
    const cbsToSfsRatio = getCbsToSfsRatio(sfs, cbs);
    const hallSurface = new Surface(
      (getPropertyValue('hallSurface')(caseSpecification.properties) as number) || 0
    );
    const technicalPremiseSectionsSurface = new Surface(
      getCaseTopLevels(caseSpecification).reduce(
        (accL, levelSpecification) =>
          accL +
          getLevelTechnicalPermiseSections(levelSpecification).reduce(
            (accTPS, technicalPremiseSection) =>
              accTPS + getSectionDisplayedSurface(technicalPremiseSection),
            0
          ),
        0
      )
    );

    const minRbs = getMinRbsSurface(cbs, technicalPremiseSectionsSurface, cbsToSfsRatio);
    const rbs = caseSpecification.surfaces.realBuiltSurface || minRbs;
    const tempEmptySurface = new Surface(0); // It will be calculated after a granulo run

    let caseProjection: CaseProjection = {
      ...caseSpecification,
      projectedDistribution: [], // It will be calculated later
      projectedProperties: [], // It will be calculated later
      projectedTopLevelsCount: 0, // It will be calculated later
      projectedBasementLevelsCount: 0, // It will be calculated later
      projectedSurfaces: {
        realBuiltSurface: new Surface(Math.max(0, rbs.value)),
        cuttedBuiltSurface: new Surface(Math.max(0, cbs.value)),
        surfaceForSale: new Surface(Math.max(0, sfs.value)),
        minRealBuiltSurface: new Surface(Math.max(0, minRbs.value)),
        hallSurface: new Surface(Math.max(0, hallSurface.value)),
        technicalPremiseSectionsSurface,
        maxSurfaceForSale: tempEmptySurface, // It will be calculated later
        maxSurfaceForSaleHasBeenForced: false,
        maxSurfaceForSaleByLevels: [],
        sumOfMinimumBearingSurface: tempEmptySurface, // It will be calculated later
        validity: {
          realBuiltSurface: 'invalid', // TODO : Can't be calculated here cause we need the project projection
          cuttedBuiltSurface: 'invalid', // TODO : Can't be calculated here cause we need the project projection
          surfaceForSale: 'invalid' // TODO : Can't be calculated here cause we need the project projection
        }
      }
    };

    caseProjection = setCaseProjectedDistribution(caseProjection);

    caseProjection = setCaseProjectedProperties(caseProjection);

    caseProjection = setCaseProjectedTopLevelsCount(caseProjection);

    // Calculate Max SFS
    if (withMaxSfs) {
      caseProjection = calculateMaxSurfaceForSale(caseProjection);
    }

    return caseProjection;
  };
