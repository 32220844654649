import * as React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { ParkingsCountByType } from '../../../domain/granulometry/levels/queries/basementLevels/counts/getBasementLevelParkingsCountByType';
import { OTHERS_COLOR } from '../../../constants/appConstants';
import { getLevelTriggerId } from '../../../routes/toolboxPanels/triggers';
import { Button } from '../../../components/ui/Button';
import { Input } from '../../ui/input/Input';
import { IconObject } from '../../../components/ui/Icons/iconObject';
import { selectCurrentCaseLabel } from '../../../store/selectors/project/currentCaseLabel.selector';
import { selectCurrentCaseBasementLevelsCount } from '../../../store/selectors/granulometry/currentCase/basementLevelsCount.selector';
import { getLabel } from '../../../domain/specification/levels/queries/get/getLabel';
import { roundForSurfacesInputs } from '../../../utils/round/roundForSurfacesInputs';
import { LevelSpecification } from '../../../domain/specification/levels/LevelSpecification';
import { isFirstBasementLevel } from '../../../domain/specification/levels/queries/is/isFirstBasementLevel';
import { selectCurrentCaseGranulometry } from '../../../store/selectors/granulometry/currentCase/currentCaseGranulometry.selector';
import { getCaseBasementLevels } from '../../../domain/granulometry/cases/queries/levels/getCaseBasementLevels';
import { CaseGranulometry } from '../../../domain/granulometry/cases/CaseGranulometry';
import { LevelGranulometry } from '../../../domain/granulometry/levels/LevelGranulometry';

interface BasementLevelLineProps {
  levelSpecification: LevelSpecification;
  onEditClick: () => void;
  handleParkingRateChange: (value: number | null) => void;
  handleRealBuildSurfaceChange: (value: number | null) => void;
  parkingRate: number | undefined;
  parkingRatePH: number;
  parkingsCountByType: ParkingsCountByType;
  isValid: boolean;
  calculatedRealBuiltSurface: number;
}

export const BasementLevelLine = ({
  levelSpecification,
  onEditClick,
  handleParkingRateChange,
  handleRealBuildSurfaceChange,
  parkingRate,
  parkingRatePH,
  parkingsCountByType,
  isValid,
  calculatedRealBuiltSurface
}: BasementLevelLineProps) => {
  const caseGranulometry = useSelector(selectCurrentCaseGranulometry) as CaseGranulometry;
  const basementSurfaceBeforeSpecifiedRBS = (
    getCaseBasementLevels(caseGranulometry).find(
      (l) => l.level === levelSpecification.level
    ) as LevelGranulometry
  )?.basementSurfaceBeforeSpecifiedRBS;
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const basementLevelsCount = useSelector(selectCurrentCaseBasementLevelsCount);
  const specifiedRealBuiltSurface = levelSpecification.realBuiltSurface;

  if (!caseLabel || !levelSpecification.level) return null;

  return (
    <div
      className={classNames('line', 'basement-level-line', {
        first: isFirstBasementLevel(levelSpecification)
      })}>
      <div className="column-1">
        <div className="cell">
          <Button
            id={getLevelTriggerId(caseLabel, levelSpecification.level)}
            fullWidth={true}
            size="small"
            content={getLabel(levelSpecification)}
            appearance="outline"
            handleClick={onEditClick}
            disabled={false}
          />
        </div>
      </div>
      <div className="column-2" tabIndex={-1}>
        <div style={{ width: '34px', marginRight: '5px' }}>
          <Input
            name={'basementLevel' + levelSpecification.level + 'ParkingRate'}
            type="number"
            size="small"
            handleChange={handleParkingRateChange}
            value={parkingRate}
            color={isValid ? undefined : 'orange'}
            placeholder={parkingRatePH < 0 ? '0' : parkingRatePH.toString()}
            disabled={basementLevelsCount < 2}
          />
        </div>
        <div style={{ marginTop: '-5px' }}>
          <IconObject
            type="object"
            iconName="carBox"
            color={OTHERS_COLOR}
            customCss=" { transform: scale(0.6875); }"
            clickable={false}
          />
        </div>
        {!parkingsCountByType ? (
          <Input
            disabled={true}
            name={'basementLevel' + levelSpecification.level + 'CarBoxCount'}
            size="small"
            style={{ width: '32px', marginLeft: '-6px' }}
            type="text"
            value="-"
          />
        ) : (
          <Input
            disabled={true}
            min={0}
            name={'basementLevel' + levelSpecification.level + 'CarBoxCount'}
            size="small"
            style={{ width: '32px', marginLeft: '-6px' }}
            type="number"
            value={parkingsCountByType.carBoxInfra || 0}
          />
        )}

        <div style={{ marginTop: '-5px' }}>
          <IconObject
            type="object"
            iconName="carPark"
            color={OTHERS_COLOR}
            customCss=" { transform: scale(0.6875); }"
            clickable={false}
          />
        </div>
        {!parkingsCountByType ? (
          <Input
            disabled={true}
            name={'basementLevel' + levelSpecification.level + 'CarParkCount'}
            size="small"
            style={{ width: '32px', marginLeft: '-6px' }}
            type="text"
            value="-"
          />
        ) : (
          <Input
            disabled={true}
            min={0}
            name={'basementLevel' + levelSpecification.level + 'CarParkCount'}
            size="small"
            style={{ width: '32px', marginLeft: '-6px' }}
            type="number"
            value={parkingsCountByType.carParkInfra || 0}
          />
        )}
      </div>
      <div className="column-3">
        {!parkingsCountByType ? (
          <Input
            disabled={true}
            name={'basementLevel' + levelSpecification.level + 'ParkingSpotCount'}
            size="small"
            type="text"
            value="-"
          />
        ) : (
          <Input
            disabled={true}
            name={'basementLevel' + levelSpecification.level + 'ParkingSpotCount'}
            size="small"
            type="number"
            value={(parkingsCountByType.carBoxInfra || 0) + (parkingsCountByType.carParkInfra || 0)}
          />
        )}
      </div>
      <div className="column-4">
        <div className="cell">
          <Input
            handleChange={handleRealBuildSurfaceChange}
            color={
              specifiedRealBuiltSurface === undefined ||
              basementSurfaceBeforeSpecifiedRBS === undefined ||
              specifiedRealBuiltSurface >= basementSurfaceBeforeSpecifiedRBS.value
                ? undefined
                : 'orange'
            }
            name={'basementLevel' + levelSpecification.level + 'RBS'}
            type="number"
            size="small"
            placeholder={
              !parkingsCountByType ? undefined : roundForSurfacesInputs(calculatedRealBuiltSurface)
            }
            value={specifiedRealBuiltSurface}
          />
        </div>
      </div>
    </div>
  );
};
