import { getDefaultUnitConvertedPropertyValue } from '../../../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { getTopLevelInternalInsulationThickness } from './getTopLevelInternalInsulationThickness';

export const getTopLevelInternalInsulationThicknessDetails = (
  caseGranulometry: CaseGranulometry
): string => {
  const internalInsulationRate = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'internalInsulationRate'
  ) as number;
  const internalInsulationThickness = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'internalInsulationThickness'
  ) as number;
  return (
    '<b>' +
    internalInsulationThickness * 100 +
    ' cm</b> d’épaisseur d’isolation int. <b>* ' +
    internalInsulationRate * 100 +
    '%</b> de taux d’isolation int.<br /><b>= ' +
    roundWith2Decimal(getTopLevelInternalInsulationThickness(caseGranulometry) * 100) +
    ' cm</b>'
  );
};
