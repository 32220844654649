import { getDefaultUnitConvertedPropertyValue } from '../../../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getTopLevelInsideWallsThicknessDetails = (
  caseGranulometry: CaseGranulometry
): string => {
  const insideWallsThickness = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'insideWallsThickness'
  ) as number;
  return (
    '<b>' +
    roundWith2Decimal(insideWallsThickness * 100) +
    ' cm</b> d’épaisseur de voiles non-exposés paramétrés dans les propriétés de la cage.'
  );
};
