import { CaseGranulometry } from '../../../cases/CaseGranulometry';
import { HeatingSystem } from '../../../../specification/lodgmentTypes/HeatingSystem';
import { getLodgmentSectionHeatingSystem } from '../heatingSystem/getLodgmentSectionHeatingSystem';

export const mustHaveGasBearingTechnicalSheaths = (caseGranulometry: CaseGranulometry): boolean => {
  const areAllCaseLodgmentsAreWithIndividualElectricHeating =
    caseGranulometry.initialSpecifications.distribution.reduce(
      (acc, distributedLodgmentSection) =>
        getLodgmentSectionHeatingSystem(distributedLodgmentSection) !==
        HeatingSystem.IndividualElectricHeating
          ? false
          : acc,
      true
    );
  return !areAllCaseLodgmentsAreWithIndividualElectricHeating;
};
