import * as R from 'ramda';
import { Project } from '../../project/Project';
import { RoomSpecification } from '../../specification/rooms/RoomSpecification';
import {
  defaultLivingRoomKitchenSpecification,
  isRoomSpecificationAnyLivingRoomKitchen,
  LivingRoomKitchenSpecification
} from '../../specification/rooms/LivingRoomKitchenSpecification';
import {
  BalconySpecification,
  isRoomSpecificationABalcony
} from '../../specification/rooms/BalconySpecification';
import balcony from '../../granulometry/rooms/balcony';
import {
  BasicRoomSpecification,
  isRoomSpecificationABasicRoom
} from '../../specification/rooms/BasicRoomSpecification';
import { LodgmentTypeSpecification } from '../../specification/lodgmentTypes/LodgmentTypeSpecification';
import { defaultLodgmentsSections } from '../../granulometry/sections/lodgmentSections/LodgmentSection';
import { mapCases } from '../../project/map/mapCases';
import { LodgmentType } from '../../specification/lodgmentTypes/LodgmentType';

const migrateLivingRoomKitchen = (
  roomSpecification: LivingRoomKitchenSpecification
): LivingRoomKitchenSpecification =>
  R.pipe<
    [LivingRoomKitchenSpecification],
    LivingRoomKitchenSpecification,
    LivingRoomKitchenSpecification,
    LivingRoomKitchenSpecification,
    LivingRoomKitchenSpecification
  >(
    R.dissoc('width'),
    R.dissoc('length'),
    R.dissoc('surface'),
    R.assoc(
      'defaultWidth',
      defaultLivingRoomKitchenSpecification[roomSpecification.name].defaultWidth
    )
  )(roomSpecification);

const migrateBalcony = (roomSpecification: BalconySpecification): BalconySpecification =>
  R.pipe<
    [BalconySpecification],
    BalconySpecification,
    BalconySpecification,
    BalconySpecification,
    BalconySpecification
  >(
    R.dissoc('width'),
    R.dissoc('length'),
    R.assoc('defaultDepth', balcony().defaultDepth),
    R.when<BalconySpecification, BalconySpecification>(
      (r) => balcony().defaultDepth === r.defaultDepth,
      R.dissoc('depth')
    )
  )(roomSpecification);

const migrateBasicRoom =
  (lodgmentTypeSpecification: LodgmentTypeSpecification) =>
  (roomSpecification: BasicRoomSpecification): BasicRoomSpecification =>
    R.pipe<
      [BasicRoomSpecification],
      BasicRoomSpecification,
      BasicRoomSpecification,
      BasicRoomSpecification,
      BasicRoomSpecification
    >(
      R.assoc(
        'defaultWidth',
        (
          defaultLodgmentsSections[lodgmentTypeSpecification.lodgmentType].rooms.find(
            (lr) => lr.name === roomSpecification.name
          ) as BasicRoomSpecification
        ).defaultWidth
      ),
      R.when<BasicRoomSpecification, BasicRoomSpecification>(
        (r) =>
          (
            defaultLodgmentsSections[lodgmentTypeSpecification.lodgmentType].rooms.find(
              (lr) => lr.name === roomSpecification.name
            ) as BasicRoomSpecification
          ).defaultWidth === r.width,
        R.dissoc('width')
      ),
      R.assoc(
        'defaultLength',
        // see : migrateProject23To24
        (lodgmentTypeSpecification.lodgmentType === LodgmentType.T4 ||
          lodgmentTypeSpecification.lodgmentType === LodgmentType.T4C ||
          lodgmentTypeSpecification.lodgmentType === LodgmentType.T5) &&
          roomSpecification.name === 'clearance'
          ? 1.5
          : (
              defaultLodgmentsSections[lodgmentTypeSpecification.lodgmentType].rooms.find(
                (lr) => lr.name === roomSpecification.name
              ) as BasicRoomSpecification
            ).defaultLength
      ),
      R.dissoc('length')
    )(roomSpecification);

const migrateRooms = (
  lodgmentTypeSpecification: LodgmentTypeSpecification,
  roomSpecifications: RoomSpecification[]
) =>
  R.map((roomSpecification: RoomSpecification) =>
    R.pipe<[RoomSpecification], RoomSpecification, RoomSpecification, RoomSpecification>(
      R.when(
        (r) => isRoomSpecificationAnyLivingRoomKitchen(r),
        (r) => migrateLivingRoomKitchen(r as LivingRoomKitchenSpecification)
      ),
      R.when(
        (r) => isRoomSpecificationABalcony(r),
        (r) => migrateBalcony(r as BalconySpecification)
      ),
      R.when(
        (r) => isRoomSpecificationABasicRoom(r),
        (r) => migrateBasicRoom(lodgmentTypeSpecification)(r as BasicRoomSpecification)
      )
    )(roomSpecification)
  )(roomSpecifications);

export const migrateProject5To6 = (project: Project): Project =>
  mapCases((eachCase) => ({
    ...eachCase,
    lodgmentsTypesSpecifications: R.map(
      (lodgmentTypeSpecification: LodgmentTypeSpecification): LodgmentTypeSpecification =>
        R.over<LodgmentTypeSpecification, RoomSpecification[]>(
          R.lensProp('rooms'),
          (roomSprecifications: RoomSpecification[]) =>
            migrateRooms(lodgmentTypeSpecification, roomSprecifications)
        )(lodgmentTypeSpecification)
    )(eachCase.lodgmentsTypesSpecifications)
  }))(project);
