import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { Button } from '../../../../src/components/ui/Button';
import { useDispatch, useSelector } from 'react-redux';
import { goToProjectDetails } from '../../../store/actions/navigations.actions';
import { selectRoute } from '../../../store/selectors/navigation/getRoute.selector';
import { selectSessionState } from 'store/selectors/user/sessionState.selector';
import { selectIsNewProject, selectProjectId } from '../../../store/selectors/project';
import { ActionModal } from '../../ui/ActionModal';

export const StartUpWizardIntro = () => {
  const currentRoute = useSelector(selectRoute);
  const isNewProject = useSelector(selectIsNewProject);
  const projectId = useSelector(selectProjectId);
  const sessionState = useSelector(selectSessionState);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  return isNewProject &&
    sessionState !== 'EXPIRED' &&
    currentRoute.isProject &&
    !currentRoute.isPanel &&
    !currentRoute.isAppMenu &&
    !currentRoute.isProjectDetailsFloorSpace &&
    !currentRoute.isCaseFloorSpace ? (
    <ActionModal
      title={
        <>
          <b>{t('startUpWizard.titlePart1')}</b> {t('startUpWizard.titlePart2')}
        </>
      }
      content={t('startUpWizard.content')}
      buttons={[
        <Button
          key="0"
          color="grey"
          content={t('startUpWizard.backButton')}
          handleClick={() => dispatch(push('/projects'))}
        />,
        <Button
          key="1"
          content={t('startUpWizard.letsGoButton')}
          handleClick={() => dispatch(goToProjectDetails(projectId))}
        />
      ]}
    />
  ) : null;
};
