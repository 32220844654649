import { FilledLevelsData } from '../getCaseFilledLevelsData';
import { CaseGranulometry } from '../../../granulometry/cases/CaseGranulometry';
import {
  getCaseRealTechnicalSheathsCountByLevel,
  SectionsWithSheathsCountByLevel
} from '../../../granulometry/cases/queries/technicalSheaths/getCaseRealTechnicalSheathsCountByLevel';
import { createTopLevel } from '../../../granulometry/levels/queries/topLevels/createTopLevel';
import { isGroundLevel } from '../../../granulometry/levels/queries/is/isGroundLevel';
import { createLevelOffsetSlabSection } from '../../../granulometry/levels/queries/sections/create/createLevelOffsetSlabSection';
import { LevelGranulometry } from '../../../granulometry/levels/LevelGranulometry';
import { BasementLevelSpecification } from '../../../specification/levels/BasementLevelSpecification';
import { createBasementLevel } from '../../../granulometry/levels/queries/basementLevels/createBasementLevel';
import { createLevelFundationsSection } from '../../../granulometry/levels/queries/sections/create/createLevelFundationsSection';
import { isBasementLevel } from '../../../granulometry/levels/queries/is/isBasementLevel';
import { createBasementLevelOutsideSlabSection } from '../../../granulometry/levels/queries/basementLevels/sections/create/createBasementLevelOutsideSlabSection';
import { createFundationsLevel } from '../../../granulometry/levels/queries/fundationsLevels/createFundationsLevel';
import { getCaseLowestLevel } from '../../../granulometry/cases/queries/levels/getCaseLowestLevel';
import { getCaseGroundLevel } from '../../../granulometry/cases/queries/levels/getCaseGroundLevel';
import { getCaseFirstBasementLevel } from '../../../granulometry/cases/queries/levels/getCaseFirstBasementLevel';
import { roundWith2Decimal } from '../../../../utils/round/roundWith2Decimal';
import { SQUARE_METER_SCALE } from '../../../../constants/appConstants';
import { isFirstBasementLevel } from '../../../granulometry/levels/queries/is/isFirstBasementLevel';

export const getFilledCaseGranulometry = (
  adjustedLevelsData: FilledLevelsData[],
  caseGranulometry: CaseGranulometry,
  withMinimumBearingSurface: boolean
): CaseGranulometry => {
  const filledCaseGranulometry = {
    ...caseGranulometry,
    technicalSheathCountByLevel: getCaseRealTechnicalSheathsCountByLevel(
      adjustedLevelsData.reduce(
        (acc, aLD) => [
          ...acc,
          {
            level: aLD.level,
            lodgmentsCount: aLD.lodgmentCount,
            technicalPremisesCount: aLD.technicalPremiseSections
              ? aLD.technicalPremiseSections.length
              : 0,
            commonPremisesCount: aLD.commonPremiseSections ? aLD.commonPremiseSections.length : 0,
            shopsCount: aLD.shopSections ? aLD.shopSections.length : 0,
            officesCount: aLD.officeSections ? aLD.officeSections.length : 0
          }
        ],
        [] as SectionsWithSheathsCountByLevel[]
      )
    )
  };
  // For each level :
  for (let i = 0; i < adjustedLevelsData.length; i++) {
    // Get level data
    const levelData = {
      ...adjustedLevelsData[i]
    };
    // We skip basement levels
    if (levelData.level < 0) continue;
    // Create top level
    const topLevelGranulometry = createTopLevel(
      levelData,
      filledCaseGranulometry,
      withMinimumBearingSurface
    );
    // Add top level in the current case
    filledCaseGranulometry.levels = [...filledCaseGranulometry.levels, topLevelGranulometry];
    // If current level is ground level (level 0)
    if (isGroundLevel(levelData)) {
      // Check if offsetSlab section is needed
      filledCaseGranulometry.levels.map((level, key) => {
        if (filledCaseGranulometry.levels[key + 1]) {
          const currentLevelSurface = level.surface;
          const nextLevelSurface = filledCaseGranulometry.levels[key + 1].surface;
          if (currentLevelSurface > nextLevelSurface) {
            const offsetSlabSurface = currentLevelSurface - nextLevelSurface;
            // Add offset slab section (for backend only)
            filledCaseGranulometry.levels[key].offsetSlab =
              createLevelOffsetSlabSection(offsetSlabSurface);
          }
        }
      });
      // Create basement levels and set the lowest levels
      let lowestLevel = topLevelGranulometry;
      let basementLevels: LevelGranulometry[] = [];
      for (let j = 0; j < caseGranulometry.initialSpecifications.basementLevelCount; j++) {
        // Get level data
        const levelData = adjustedLevelsData.find(
          (level: BasementLevelSpecification) => level.level === lowestLevel.level - 1
        );
        // Create parkings level
        const basementLevel = createBasementLevel(levelData, filledCaseGranulometry, lowestLevel);
        // Udpate lowest level
        lowestLevel = basementLevel;
        // Add basement level in the current case
        basementLevels = [...basementLevels, basementLevel];
      }

      // If the -1 surface is lower than -2 : adjusting -1 surface
      // Note : This is displaying an empty section in the -1 but it's not wanted
      /* if (basementLevels[1] && basementLevels[0].surface < basementLevels[1].surface) {
        basementLevels[0] = {
          ...basementLevels[0],
          surface: basementLevels[1].surface,
          displayedSurface: basementLevels[1].surface,
          realBuiltSurface: basementLevels[1].surface
        };
      } */

      // DURTY : Should be in filterDataForBackEnd
      // Add empty fundations if basement surfaces are equals (only for admin calculations)
      basementLevels.forEach((basementLevel, index) => {
        if (
          basementLevel.fundations === undefined &&
          ((basementLevels[index + 1] !== undefined &&
            basementLevel.surface === basementLevels[index + 1].surface) ||
            (basementLevels[index - 1] !== undefined && // Theoretically impossible...
              basementLevel.surface === basementLevels[index - 1].surface))
        ) {
          const emptyFundationSection = [createLevelFundationsSection(0, 'superficial')];
          basementLevel.fundations = emptyFundationSection;
        }
        filledCaseGranulometry.levels = [...filledCaseGranulometry.levels, basementLevel];
      });

      // Check if outsideSlab section is needed
      filledCaseGranulometry.levels.forEach((levelGranulometry, key) => {
        if (levelGranulometry.level === -1 || levelGranulometry.level === -2) {
          // Note: do not use isBasementLevel
          const prevLevel = { ...filledCaseGranulometry.levels[key - 1] };
          if (levelGranulometry.surface > prevLevel.surface) {
            const outsideSlabSurface = levelGranulometry.surface - prevLevel.surface;
            // Add outside slab section (for backend only)
            filledCaseGranulometry.levels[key].outsideSlab =
              createBasementLevelOutsideSlabSection(outsideSlabSurface);
            levelGranulometry.outsideRulerWidth = outsideSlabSurface * SQUARE_METER_SCALE;
            levelGranulometry.outsideRulerLeft =
              (prevLevel.realBuiltSurface || 0) * SQUARE_METER_SCALE;
            levelGranulometry.outsideSurface = roundWith2Decimal(outsideSlabSurface); // TODO : To remove – Can be found with outsideSlab
          }
        }
      });

      // Create fundations level
      const fundationsLevel = createFundationsLevel(
        caseGranulometry,
        getCaseLowestLevel(filledCaseGranulometry)
      );
      // Add fundations level in the current case
      filledCaseGranulometry.levels = [...filledCaseGranulometry.levels, fundationsLevel];
    }
  }
  return filledCaseGranulometry;
};
