import { uniqId } from '../../../../infra/uniqId';
import { BuildingSpecification } from '../BuildingSpecification';
import { createCaseSpecification } from '../../cases/queries/create/createCaseSpecification';

export const createBuildingSpecification = (): BuildingSpecification => ({
  id: uniqId(),
  cases: [
    createCaseSpecification({
      withRamp: true
    })
  ]
});
