import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getLodgmentTypeRoomTriggerId } from '../../../../../routes/toolboxPanels/triggers';
import { setLodgmentTypeTheoreticalSurface } from '../../../../../store/actions/setLodgmentTypeSurface.action';
import { setLodgmentTypeExposureRate } from '../../../../../store/actions/setLodgmentTypeExposureRate.action';
import { selectCurrentlyEditedLodgmentTypeRoom } from '../../../../../store/selectors/navigation/currentlyEditedLodgmentTypeRoom.selector';
import { selectCurrentCaseLodgmentsCountByType } from '../../../../../store/selectors/granulometry/currentCase/lodgmentsCountByType.selector';
import { selectCurrentCaseLodgmentsAverageSurfaceByType } from '../../../../../store/selectors/granulometry/currentCase/lodgmentsAverageSurfaceByType.selector';
import {
  goToGranulometryLodgmentType,
  goToGranulometryLodgmentTypeRoom
} from '../../../../../store/actions/navigations.actions';
import { roundWith2Decimal } from '../../../../../utils/round/roundWith2Decimal';
import { Card } from '../../../../ui/Card';
import { FormGroup } from '../../../../ui/FormGroup';
import { SpecialLabel } from '../../../../ui/SpecialLabel';
import SectionMap from '../../../../ui/SectionMap';
import { HighlightOverlay } from '../../../../layouts/HighlightOverlay';
import { Note } from '../../../../ui/Note';
import { Form } from '../../../../ui/Form';
import RoomsTable from './RoomsTable';
import { selectCurrentCaseLabel } from '../../../../../store/selectors/project/currentCaseLabel.selector';
import { secureInputChange } from '../../../../../utils/secureInputChange';
import { selectCurrentlyEditedLodgmentType } from '../../../../../store/selectors/specification/currentCase/currentlyEditedLodgmentType.selector';
import { Input } from '../../../../ui/input/Input';
import { CaseGranulometryEditLodgmentTypeRouteParams } from '../../../../../routes/toolboxPanels/cases';

export const LodgmentsTypesInner = () => {
  const { projectId, caseId } = useParams<CaseGranulometryEditLodgmentTypeRouteParams>();
  const currentlyEditedLodgmentTypeRoom = useSelector(selectCurrentlyEditedLodgmentTypeRoom);
  const lodgmentsCountByType = useSelector(selectCurrentCaseLodgmentsCountByType);
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const lodgmentsAverageSurfaceByType = useSelector(selectCurrentCaseLodgmentsAverageSurfaceByType);
  const lodgmentTypeSpecification = useSelector(selectCurrentlyEditedLodgmentType);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  if (!lodgmentTypeSpecification) return null;

  const handleSetLodgmentTheoreticalSurface = React.useCallback(
    (typedNumber: number | null) => {
      if (caseId)
        dispatch(
          setLodgmentTypeTheoreticalSurface(
            caseId,
            lodgmentTypeSpecification.lodgmentType,
            typedNumber || undefined
          )
        );
    },
    [dispatch, caseId, lodgmentTypeSpecification]
  );

  const handleSetLodgmentExposureRate = React.useCallback(
    (typedNumber: number | null) => {
      if (caseId)
        dispatch(
          setLodgmentTypeExposureRate(
            caseId,
            lodgmentTypeSpecification.lodgmentType,
            typedNumber ? (secureInputChange(typedNumber, 0, 100) * 4) / 100 : undefined
          )
        );
    },
    [dispatch, caseId, lodgmentTypeSpecification]
  );

  const handleOpenRoom = React.useCallback(
    (mouseEvent, room, roomIndex: number) => {
      if (projectId && caseId && lodgmentTypeSpecification?.lodgmentType) {
        dispatch(
          goToGranulometryLodgmentTypeRoom(
            projectId,
            caseId,
            lodgmentTypeSpecification.lodgmentType,
            roomIndex
          )
        );
      }
    },
    [dispatch, projectId, caseId, lodgmentTypeSpecification.lodgmentType]
  );

  const handleHighlightReturn = React.useCallback(() => {
    if (caseId && projectId)
      dispatch(
        goToGranulometryLodgmentType(projectId, caseId, lodgmentTypeSpecification.lodgmentType)
      );
  }, [dispatch, projectId, caseId, lodgmentTypeSpecification.lodgmentType]);

  if (!caseLabel) return null;

  const isHighlighted = Boolean(
    currentlyEditedLodgmentTypeRoom !== undefined && !isNaN(currentlyEditedLodgmentTypeRoom)
  );

  const triggerId = isHighlighted
    ? getLodgmentTypeRoomTriggerId(
        caseLabel,
        lodgmentTypeSpecification.lodgmentType,
        currentlyEditedLodgmentTypeRoom as number
      )
    : undefined;

  const lodgmentsCount = lodgmentsCountByType
    ? // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      lodgmentsCountByType[lodgmentTypeSpecification.lodgmentType] || 0
    : 0;

  return (
    <HighlightOverlay
      highlighted={isHighlighted}
      triggerId={triggerId}
      onReturn={handleHighlightReturn}>
      <Form>
        <Card>
          <SpecialLabel label={`${t('Section map')} / ${t('Room management')}`} />
          <FormGroup>
            <SectionMap
              id={`sectionMap${caseLabel}${lodgmentTypeSpecification.lodgmentType}`}
              lodgmentType={lodgmentTypeSpecification}
              sectionIconProps={lodgmentTypeSpecification.icon}
              rooms={lodgmentTypeSpecification.rooms}
              roomIconHandleClick={handleOpenRoom}
            />
            <RoomsTable lodgmentTypeSpecification={lodgmentTypeSpecification} />
          </FormGroup>
        </Card>
        <Card>
          <SpecialLabel label={t('Areas')} />
          <FormGroup horizontal={true} className="area">
            <div style={{ width: '40%' }}>
              <label htmlFor="surface">{t('Theoretical surface')}</label>
              <Input
                name="theoreticalSurface"
                type="number"
                value={
                  lodgmentTypeSpecification.theoreticalSurface
                    ? roundWith2Decimal(lodgmentTypeSpecification.theoreticalSurface)
                    : undefined
                }
                placeholder={lodgmentTypeSpecification.defaultTheoreticalSurface}
                handleChange={handleSetLodgmentTheoreticalSurface}
                suffix="m<sup>2</sup>"
              />
            </div>
            <div style={{ width: '60%', display: 'flex', alignItems: 'flex-end' }}>
              <Note style={{ marginBottom: '5px', padding: '3px 6px 3px 6px' }}>
                {t('Calculation basis for generating the granulometry.')}
              </Note>
              &nbsp;
            </div>
          </FormGroup>
          <FormGroup horizontal={true} className="area">
            <div style={{ width: '40%' }}>
              <label htmlFor="surface">{t('Average surface')}</label>
              <Input
                name="averageSurface"
                type="number"
                value={roundWith2Decimal(
                  lodgmentsAverageSurfaceByType[lodgmentTypeSpecification.lodgmentType] || 0
                )}
                disabled={true}
                suffix="m<sup>2</sup>"
              />
            </div>
            <div style={{ width: '60%', display: 'flex', alignItems: 'flex-end' }}>
              <Note style={{ marginBottom: '5px', padding: '3px 6px 3px 6px' }}>
                {t('averageSurfaceNote', {
                  count: lodgmentsCount,
                  lodgmentType: lodgmentTypeSpecification.lodgmentType.toUpperCase(),
                  caseLabelIndex: caseLabel
                })}
              </Note>
              &nbsp;
            </div>
          </FormGroup>
          <FormGroup horizontal={true} className="area">
            <div style={{ width: '40%' }}>
              <label htmlFor="surface">{t('lodgmentMinimumSurface.label')}</label>
              <Input
                name="surface"
                type="number"
                value={roundWith2Decimal(lodgmentTypeSpecification.minimumDisplayedSurface || 0)}
                disabled={true}
                suffix="m<sup>2</sup>"
              />
            </div>
            <div style={{ width: '60%', display: 'flex', alignItems: 'flex-end' }}>
              <Note style={{ marginBottom: '5px', padding: '3px 6px 3px 6px' }}>
                {t('lodgmentMinimumSurface.infos')}
              </Note>
              &nbsp;
            </div>
          </FormGroup>
        </Card>
        <Card>
          <SpecialLabel label={t('Exposure')} />
          <FormGroup horizontal={true} wrap={true}>
            <Input
              label={t('theoreticalExposureRate.label')}
              info={t('theoreticalExposureRate.infos', {
                lodgmentType: lodgmentTypeSpecification.lodgmentType.toUpperCase(),
                defaultExposureRate: (lodgmentTypeSpecification.defaultExposureRate / 4) * 100
              })}
              minMax={{
                min: 0,
                max: 100,
                unit: '%'
              }}
              style={{ width: '40%', flex: '0 0 40%' }}
              name="exposureRate"
              type="number"
              value={
                lodgmentTypeSpecification.exposureRate
                  ? roundWith2Decimal((lodgmentTypeSpecification.exposureRate / 4) * 100)
                  : undefined
              }
              placeholder={roundWith2Decimal(
                (lodgmentTypeSpecification.defaultExposureRate / 4) * 100
              )}
              handleChange={handleSetLodgmentExposureRate}
              suffix="%"
            />
          </FormGroup>
        </Card>
      </Form>
    </HighlightOverlay>
  );
};

export default LodgmentsTypesInner;
