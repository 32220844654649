import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from 'components/ui/SpecialLabel';
import { Card } from '../../../ui/Card';
import { selectCurrentSectionGranulometry } from '../../../../store/selectors/granulometry/currentSection/currentSection.selector';
import { isSectionWithExposureRate } from '../../../../domain/granulometry/sections/SectionWithExposureRate';
import { selectCurrentSectionCaseGranulometry } from '../../../../store/selectors/granulometry/currentSection/currentSectionCase.selector';
import { selectCurrentSectionLevelGranulometry } from '../../../../store/selectors/granulometry/currentSection/currentSectionLevel.selector';
import { isSectionWithTheoreticalSurface } from '../../../../domain/granulometry/sections/SectionWithTheoreticalSurface';
import { getSectionTheoreticalSurface } from '../../../../domain/granulometry/sections/queries/surfaces/getSectionTheoreticalSurface';
import { getSectionTheoreticalExposureRate } from '../../../../domain/granulometry/sections/queries/exposureRate/getSectionTheoreticalExposureRate';
import { getSectionRealExposureRate } from '../../../../domain/granulometry/sections/queries/exposureRate/getSectionRealExposureRate';
import { getSectionTheoreticalOutsideWallsLinear } from '../../../../domain/granulometry/sections/queries/walls/theoreticalWalls/outsideWalls/getSectionTheoreticalOutsideWallsLinear';
import { getSectionTheoreticalOutsideWallsSurface } from '../../../../domain/granulometry/sections/queries/walls/theoreticalWalls/outsideWalls/getSectionTheoreticalOutsideWallsSurface';
import { getSectionTheoreticalInsideWallsLinear } from '../../../../domain/granulometry/sections/queries/walls/theoreticalWalls/insideWalls/getSectionTheoreticalInsideWallsLinear';
import { getSectionTheoreticalInsideWallsSurface } from '../../../../domain/granulometry/sections/queries/walls/theoreticalWalls/insideWalls/getSectionTheoreticalInsideWallsSurface';
import { getSectionInsideWallsThickness } from '../../../../domain/granulometry/sections/queries/walls/wallsThickness/getSectionInsideWallsThickness';
import { getSectionOutsideWallsThickness } from '../../../../domain/granulometry/sections/queries/walls/wallsThickness/getSectionOutsideWallsThickness';
import { getLodgmentSectionOutsideWallsLinearLevelRatio } from '../../../../domain/granulometry/sections/queries/walls/lodgmentWalls/getLodgmentSectionOutsideWallsLinearLevelRatio';
import { getSectionRealOutsideWallsLinear } from '../../../../domain/granulometry/sections/queries/walls/realWalls/getSectionRealOutsideWallsLinear';
import { getSectionWallsLinear } from '../../../../domain/granulometry/sections/queries/walls/getSectionWallsLinear';
import { getSectionRealInsideWallsLinear } from '../../../../domain/granulometry/sections/queries/walls/realWalls/getSectionRealInsideWallsLinear';
import { getSectionRealOutsideWallsSurface } from '../../../../domain/granulometry/sections/queries/walls/realWalls/getSectionRealOutsideWallsSurface';
import { getSectionRealInsideWallsSurface } from '../../../../domain/granulometry/sections/queries/walls/realWalls/getSectionRealInsideWallsSurface';
import { DetailsTable } from '../../windows/CasesPanelWindows/LevelWindow/DetailsTable';
import { getSectionTheoreticalOutsideWallsSurfaceDetails } from '../../../../domain/granulometry/sections/queries/walls/theoreticalWalls/outsideWalls/getSectionTheoreticalOutsideWallsSurface.details';
import { isSectionWithLength } from '../../../../domain/granulometry/sections/SectionWithLength';
import { isSectionWithWidth } from '../../../../domain/granulometry/sections/SectionWithWidth';
import { isSectionWithTechnicalSheaths } from '../../../../domain/granulometry/sections/SectionWithTechnicalSheaths';
import { getSectionTheoreticalSurfaceDetails } from '../../../../domain/granulometry/sections/queries/surfaces/getSectionTheoreticalSurface.details';
import * as R from 'ramda';
import { getSectionInsideWallsThicknessDetails } from '../../../../domain/granulometry/sections/queries/walls/wallsThickness/getSectionInsideWallsThickness.details';
import { getSectionOutsideWallsThicknessDetails } from '../../../../domain/granulometry/sections/queries/walls/wallsThickness/getSectionOutsideWallsThickness.details';
import { getSectionTheoreticalExposureRateDetails } from '../../../../domain/granulometry/sections/queries/exposureRate/getSectionTheoreticalExposureRate.details';
import { getSectionTheoreticalInsideWallsLinearDetails } from '../../../../domain/granulometry/sections/queries/walls/theoreticalWalls/insideWalls/getSectionTheoreticalInsideWallsLinear.details';
import { getSectionWallsLinearDetails } from '../../../../domain/granulometry/sections/queries/walls/getSectionWallsLinear.details';
import { getSectionTheoreticalInsideWallsSurfaceDetails } from '../../../../domain/granulometry/sections/queries/walls/theoreticalWalls/insideWalls/getSectionTheoreticalInsideWallsSurface.details';
import { getSectionTheoreticalOutsideWallsLinearDetails } from '../../../../domain/granulometry/sections/queries/walls/theoreticalWalls/outsideWalls/getSectionTheoreticalOutisideWallsLinear.details';
import { getLodgmentSectionOutsideWallsLinearLevelRatioDetails } from '../../../../domain/granulometry/sections/queries/walls/lodgmentWalls/getLodgmentSectionOutsideWallsLinearLevelRatioDetails';
import { getSectionRealInsideWallsLinearDetails } from '../../../../domain/granulometry/sections/queries/walls/realWalls/getSectionRealInsideWallsLinear.details';
import { getSectionRealInsideWallsSurfaceDetails } from '../../../../domain/granulometry/sections/queries/walls/realWalls/getSectionRealInsideWallsSurface.details';
import { getSectionRealOutsideWallsLinearDetails } from '../../../../domain/granulometry/sections/queries/walls/realWalls/getSectionRealOutsideWallsLinear.details';
import { getSectionRealOutsideWallsSurfaceDetails } from '../../../../domain/granulometry/sections/queries/walls/realWalls/getSectionRealOutsideWallsSurface.details';
import { getSectionRealExposureRateDetails } from '../../../../domain/granulometry/sections/queries/exposureRate/getSectionRealExposureRate.details';
import { isSectionABearing } from '../../../../domain/granulometry/sections/circulationSections/bearing';

export interface SectionDetail {
  label: string;
  value?: number;
  unit?: string;
  separator?: boolean;
  details?: string;
  bold?: boolean;
}

export const SectionDetails = () => {
  const currentSection = useSelector(selectCurrentSectionGranulometry);
  const currentLevel = useSelector(selectCurrentSectionLevelGranulometry);
  const currentCase = useSelector(selectCurrentSectionCaseGranulometry);

  const { t } = useTranslation();

  if (!currentSection || !currentLevel || !currentCase) return null;

  const isGbEnvDevOrRecette = process.env.GB_ENV === 'dev' || process.env.GB_ENV === 'recette';

  let infos: (SectionDetail | null)[] = [];

  if (isSectionWithTheoreticalSurface(currentSection)) {
    infos = [
      ...infos,
      {
        label: t('theoreticalSurface.label'),
        value: getSectionTheoreticalSurface(currentSection),
        unit: 'm\u00B2',
        details: getSectionTheoreticalSurfaceDetails(currentSection)
      }
    ];
  }

  infos = [
    ...infos,
    {
      label: isSectionWithTheoreticalSurface(currentSection) ? t('Real surface') : t('Surface'),
      value: currentSection.displayedSurface,
      unit: 'm\u00B2',
      details: isSectionWithTheoreticalSurface(currentSection)
        ? 'Surface calculée dans la granulométrie.'
        : undefined
    }
  ];

  if (isSectionWithLength(currentSection)) {
    infos = [
      ...infos,
      {
        label: t('length.label'),
        value: currentSection.length,
        unit: 'm',
        separator: true
      }
    ];
  }

  if (isSectionWithWidth(currentSection)) {
    infos = [
      ...infos,
      {
        label: t('width.label'),
        value: currentSection.width,
        unit: 'm',
        separator: true
      }
    ];
  }

  if (isSectionWithExposureRate(currentSection) && !isSectionABearing(currentSection)) {
    infos = [
      ...infos,
      {
        label: t('theoreticalExposureRate.label'),
        value: getSectionTheoreticalExposureRate(currentSection) * 100,
        unit: '%',
        details: getSectionTheoreticalExposureRateDetails(currentSection),
        separator: true
      }
    ];
  }

  if (isSectionWithExposureRate(currentSection)) {
    infos = [
      ...infos,
      {
        label: t('insideWallsThickness.label'),
        value: getSectionInsideWallsThickness(currentCase) * 100,
        unit: 'cm',
        details: getSectionInsideWallsThicknessDetails(),
        separator: true
      },
      {
        label: t('outsideWallsThickness.label'),
        value: getSectionOutsideWallsThickness(currentCase) * 100,
        unit: 'cm',
        details: getSectionOutsideWallsThicknessDetails()
      },
      {
        label: t('wallsLinear.label'),
        value: getSectionWallsLinear(currentCase, currentLevel, currentSection),
        unit: 'mL',
        details: getSectionWallsLinearDetails(currentCase, currentLevel, currentSection),
        separator: true
      },
      isGbEnvDevOrRecette
        ? {
            label: t('theoreticalInsideWallsLinear.label'),
            value: getSectionTheoreticalInsideWallsLinear(
              currentCase,
              currentLevel,
              currentSection
            ),
            unit: 'mL',
            details: getSectionTheoreticalInsideWallsLinearDetails(
              currentCase,
              currentLevel,
              currentSection
            ),
            separator: true
          }
        : null,
      isGbEnvDevOrRecette
        ? {
            label: t('theoreticalInsideWallsSurface.label'),
            value: getSectionTheoreticalInsideWallsSurface(
              currentCase,
              currentLevel,
              currentSection
            ),
            unit: 'm\u00B2',
            details: getSectionTheoreticalInsideWallsSurfaceDetails(
              currentCase,
              currentLevel,
              currentSection
            )
          }
        : null,
      isGbEnvDevOrRecette
        ? {
            label: t('theoreticalOutsideWallsLinear.label'),
            value: getSectionTheoreticalOutsideWallsLinear(
              currentCase,
              currentLevel,
              currentSection
            ),
            unit: 'mL',
            details: getSectionTheoreticalOutsideWallsLinearDetails(
              currentCase,
              currentLevel,
              currentSection
            )
          }
        : null,
      isGbEnvDevOrRecette
        ? {
            label: t('theoreticalOutsideWallsSurface.label'),
            value: getSectionTheoreticalOutsideWallsSurface(
              currentCase,
              currentLevel,
              currentSection
            ),
            unit: 'm\u00B2',
            details: getSectionTheoreticalOutsideWallsSurfaceDetails(
              currentCase,
              currentLevel,
              currentSection
            )
          }
        : null,
      isGbEnvDevOrRecette
        ? {
            label: t('theoreticalOutsideWallsLinearLevelRatio.label'),
            value:
              getLodgmentSectionOutsideWallsLinearLevelRatio(
                currentCase,
                currentLevel,
                currentSection
              ) * 100,
            unit: '%',
            details: getLodgmentSectionOutsideWallsLinearLevelRatioDetails(
              currentCase,
              currentLevel,
              currentSection
            )
          }
        : null,
      {
        label: t('realInsideWallsLinear.label'),
        value: getSectionRealInsideWallsLinear(currentCase, currentLevel, currentSection),
        unit: 'mL',
        details: getSectionRealInsideWallsLinearDetails(currentCase, currentLevel, currentSection),
        separator: true
      },
      {
        label: t('realInsideWallsSurface.label'),
        value: getSectionRealInsideWallsSurface(currentCase, currentLevel, currentSection),
        unit: 'm\u00B2',
        details: getSectionRealInsideWallsSurfaceDetails(currentCase, currentLevel, currentSection)
      },
      {
        label: t('realOutsideInsulatedWallsInternalLinear.label'),
        value: getSectionRealOutsideWallsLinear(currentCase, currentLevel, currentSection),
        unit: 'mL',
        details: getSectionRealOutsideWallsLinearDetails(currentCase, currentLevel, currentSection)
      },
      {
        label: t('realOutsideWallsSurface.label'),
        value: getSectionRealOutsideWallsSurface(currentCase, currentLevel, currentSection),
        unit: 'm\u00B2',
        details: getSectionRealOutsideWallsSurfaceDetails(currentCase, currentLevel, currentSection)
      },
      {
        label: t('realExposureRate.label'),
        value: getSectionRealExposureRate(currentCase, currentLevel, currentSection) * 100,
        unit: '%',
        details: getSectionRealExposureRateDetails(currentCase, currentLevel, currentSection),
        separator: true
      }
    ];
  }

  if (isSectionWithTechnicalSheaths(currentSection)) {
    infos = [
      ...infos,
      {
        label: t('technicalSheathCount.label'),
        value: currentSection.technicalSheathCount,
        unit: 'u',
        separator: true
      }
    ];
  }

  return (
    <Card>
      <SpecialLabel label={t('Section details')} />
      <DetailsTable infos={R.reject(R.isNil)(infos)} />
    </Card>
  );
};
