import * as React from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from '../../../../ui/SpecialLabel';
import { selectCurrentlyEditedLevel } from '../../../../../store/selectors/navigation/currentlyEditedLevel.selector';
import { Card } from '../../../../ui/Card';
import { selectCurrentCaseProjection } from '../../../../../store/selectors/projection/currentCase/projection.selector';
import { IconObject } from '../../../../ui/Icons/iconObject';
import {
  CommonPremiseSection,
  CommonPremiseSectionName,
  getCommonPremiseSectionsList
} from '../../../../../domain/granulometry/sections/commonPremiseSections/CommonPremiseSection';
import { FormGroup } from '../../../../ui/FormGroup';
import { Input } from '../../../../ui/input/Input';
import { Option, Select } from '../../../../ui/Select';
import { setCaseLevelSpecification } from '../../../../../store/actions/setCaseLevelSpecification.action';
import { Button } from '../../../../ui/Button';
import { secureInputChange } from '../../../../../utils/secureInputChange';
import { LevelSpecification } from '../../../../../domain/specification/levels/LevelSpecification';
import { getLevelCommonPermiseSections } from '../../../../../domain/specification/levels/queries/get/getLevelCommonPermiseSections';
import { GlazedSurfaceInput } from './GlazedSurfaceInput';

export const CommonPremiseSections = () => {
  const currentlyEditedCase = useSelector(selectCurrentCaseProjection);
  const currentlyEditedLevel = useSelector(selectCurrentlyEditedLevel) as LevelSpecification;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const commonPremiseSectionsList = getCommonPremiseSectionsList();

  const commonPremiseSectionsOptions: Option[] = commonPremiseSectionsList.reduce(
    (acc, commonPremiseSection) => [
      ...acc,
      {
        label: <IconObject {...commonPremiseSection.icon} clickable={false} />,
        value: commonPremiseSection.name
      }
    ],
    [] as Option[]
  );

  const [localCurrentLevelCommonPremiseSections, setLocalCurrentLevelCommonPremiseSections] =
    React.useState<CommonPremiseSection[]>(getLevelCommonPermiseSections(currentlyEditedLevel));

  React.useEffect(() => {
    setLocalCurrentLevelCommonPremiseSections(getLevelCommonPermiseSections(currentlyEditedLevel));
  }, [currentlyEditedLevel]);

  const handleCommonPremiseSectionsSpecificationsChange = React.useCallback(
    (value: number | string | null, index: number, input: string) => {
      const getCleanCommonPremiseSectionFromName = (
        name: CommonPremiseSectionName
      ): CommonPremiseSection =>
        commonPremiseSectionsList.find((cP) => cP.name === name) as CommonPremiseSection;

      const updateCommonPremiseSectionInput = (
        commonPremiseSection: CommonPremiseSection,
        input: string
      ): CommonPremiseSection =>
        R.assoc(
          input,
          value === null
            ? input === 'surface'
              ? commonPremiseSection.defaultSurface
              : undefined
            : value
        )(commonPremiseSection) as unknown as CommonPremiseSection;

      const newCommonPremiseSections = R.over(
        R.lensIndex<CommonPremiseSection>(index),
        R.compose((commonPremiseSection) => {
          if (input === 'name') {
            return getCleanCommonPremiseSectionFromName(value as CommonPremiseSectionName);
          } else {
            if (input === 'exposureRate' && typeof value === 'number') {
              value = (secureInputChange(value, 0, 100) * 4) / 100;
            }
            if (input === 'surface') {
              commonPremiseSection = updateCommonPremiseSectionInput(
                commonPremiseSection,
                'displayedSurface'
              );
            }
            return updateCommonPremiseSectionInput(commonPremiseSection, input);
          }
        })
      )(localCurrentLevelCommonPremiseSections);

      if (currentlyEditedCase && currentlyEditedLevel) {
        setLocalCurrentLevelCommonPremiseSections(newCommonPremiseSections);
        dispatch(
          setCaseLevelSpecification(currentlyEditedCase.id, {
            ...currentlyEditedLevel,
            commonPremiseSections: newCommonPremiseSections ?? []
          })
        );
      }
    },
    [dispatch, currentlyEditedCase, currentlyEditedLevel]
  );

  const handleRemoveCommonPremiseSection = React.useCallback(
    (index: number) => {
      const newCommonPremiseSections = R.remove(index, 1, localCurrentLevelCommonPremiseSections);
      if (currentlyEditedCase && currentlyEditedLevel) {
        setLocalCurrentLevelCommonPremiseSections(newCommonPremiseSections);
        dispatch(
          setCaseLevelSpecification(currentlyEditedCase.id, {
            ...currentlyEditedLevel,
            commonPremiseSections: newCommonPremiseSections ?? []
          })
        );
      }
    },
    [dispatch, currentlyEditedCase, currentlyEditedLevel]
  );

  const handleDuplicateCommonPremiseSection = React.useCallback(
    (index: number) => {
      const newCommonPremiseSections = R.insert(
        index,
        R.view(R.lensIndex<CommonPremiseSection>(index), localCurrentLevelCommonPremiseSections),
        localCurrentLevelCommonPremiseSections
      );
      if (currentlyEditedCase && currentlyEditedLevel) {
        setLocalCurrentLevelCommonPremiseSections(newCommonPremiseSections);
        dispatch(
          setCaseLevelSpecification(currentlyEditedCase.id, {
            ...currentlyEditedLevel,
            commonPremiseSections: newCommonPremiseSections ?? []
          })
        );
      }
    },
    [dispatch, currentlyEditedCase, currentlyEditedLevel]
  );

  const handleAddFirstCommonPremiseSection = React.useCallback(() => {
    const newCommonPremiseSections = [
      R.view(R.lensIndex<CommonPremiseSection>(0), commonPremiseSectionsList)
    ];
    if (currentlyEditedCase && currentlyEditedLevel) {
      setLocalCurrentLevelCommonPremiseSections(newCommonPremiseSections);
      dispatch(
        setCaseLevelSpecification(currentlyEditedCase.id, {
          ...currentlyEditedLevel,
          commonPremiseSections: newCommonPremiseSections
        })
      );
    }
  }, [dispatch, currentlyEditedCase, currentlyEditedLevel]);

  if (!currentlyEditedCase || !currentlyEditedLevel) return null;

  return (
    <Card className="commonPremiseSections">
      <SpecialLabel label={t('commonPremiseSections.specialLabel')} />
      {localCurrentLevelCommonPremiseSections.length > 0 ? (
        localCurrentLevelCommonPremiseSections.map((commonPremiseSection, index) => {
          const prefix =
            'level' + currentlyEditedLevel.level + 'CommonPremiseSectionsSpecification' + index;
          return (
            <React.Fragment key={index}>
              <SpecialLabel label={t(commonPremiseSection.name + '.title')} small />
              <FormGroup horizontal={true} wrap={false}>
                <div style={{ width: '53px', flex: '0 0 53px' }}>
                  <label htmlFor={prefix + 'Type'}>
                    {t('commonPremiseSections.typeSelect.label')}
                  </label>
                  <Select
                    id={prefix + 'Type'}
                    className={'iconObjectSelector'}
                    options={commonPremiseSectionsOptions}
                    handleChange={(option: Option) =>
                      handleCommonPremiseSectionsSpecificationsChange(option.value, index, 'name')
                    }
                    value={commonPremiseSectionsOptions.find(
                      (option) => option.value === commonPremiseSection.name
                    )}
                  />
                </div>
                <div style={{ width: '21.5%', flex: '0 0 21.5%' }}>
                  <Input
                    name={prefix + 'Surface'}
                    label={t('commonPremiseSections.surfaceInput.label')}
                    type="number"
                    min={commonPremiseSection.minSurface}
                    max={commonPremiseSection.maxSurface}
                    minMax={{
                      min: commonPremiseSection.minSurface,
                      max: commonPremiseSection.maxSurface,
                      unit: ''
                    }}
                    value={
                      commonPremiseSection.surface !== commonPremiseSection.defaultSurface
                        ? commonPremiseSection.surface
                        : undefined
                    }
                    handleChange={(value) =>
                      handleCommonPremiseSectionsSpecificationsChange(
                        secureInputChange(
                          value,
                          commonPremiseSection.minSurface,
                          commonPremiseSection.maxSurface
                        ),
                        index,
                        'surface'
                      )
                    }
                    suffix="m2"
                    placeholder={commonPremiseSection.defaultSurface}
                  />
                </div>
                <div style={{ width: '21.5%', flex: '0 0 21.5%' }}>
                  <Input
                    name={prefix + 'Width'}
                    label={t('commonPremiseSections.widthInput.label')}
                    type="number"
                    min={commonPremiseSection.minWidth}
                    max={commonPremiseSection.maxWidth}
                    minMax={{
                      min: commonPremiseSection.minWidth,
                      max: commonPremiseSection.maxWidth,
                      unit: ''
                    }}
                    value={
                      commonPremiseSection.width !== commonPremiseSection.defaultWidth
                        ? commonPremiseSection.width
                        : undefined
                    }
                    handleChange={(value) =>
                      handleCommonPremiseSectionsSpecificationsChange(
                        secureInputChange(
                          value,
                          commonPremiseSection.minWidth,
                          commonPremiseSection.maxWidth
                        ),
                        index,
                        'width'
                      )
                    }
                    suffix="m"
                    placeholder={commonPremiseSection.defaultWidth}
                  />
                </div>
                <div style={{ width: '21.5%', flex: '0 0 21.5%' }}>
                  <Input
                    name={prefix + 'ExposureRate'}
                    label={t('commonPremiseSections.exposureRateInput.label')}
                    type="number"
                    min={0}
                    max={100}
                    minMax={{
                      min: 0,
                      max: 100,
                      unit: ''
                    }}
                    value={
                      commonPremiseSection.exposureRate !== undefined &&
                      commonPremiseSection.exposureRate !== commonPremiseSection.defaultExposureRate
                        ? (commonPremiseSection.exposureRate / 4) * 100
                        : undefined
                    }
                    handleChange={(value) =>
                      handleCommonPremiseSectionsSpecificationsChange(
                        secureInputChange(value, 0, 100),
                        index,
                        'exposureRate'
                      )
                    }
                    suffix="%"
                    placeholder={(commonPremiseSection.defaultExposureRate / 4) * 100}
                  />
                </div>
                <div className="add-remove-buttons">
                  <IconObject
                    iconName="remove"
                    type="menu"
                    onClick={() => {
                      handleRemoveCommonPremiseSection(index);
                    }}
                    // title={t('Remove case {{caseLabel}}', { caseLabel: selectedCaseLabel })}
                  />
                  <IconObject
                    iconName="add"
                    type="menu"
                    onClick={() => {
                      handleDuplicateCommonPremiseSection(index);
                    }}
                  />
                </div>
              </FormGroup>
              <GlazedSurfaceInput
                prefix={prefix}
                index={index}
                section={commonPremiseSection}
                handleChange={handleCommonPremiseSectionsSpecificationsChange}
              />
            </React.Fragment>
          );
        })
      ) : (
        <FormGroup horizontal={true} wrap={false}>
          <Button
            content={t('commonPremiseSections.addButton.content') as string}
            handleClick={() => {
              handleAddFirstCommonPremiseSection();
            }}
            appearance="outline"
            size="small"
            style={{ margin: '10px auto' }}
          />
        </FormGroup>
      )}
    </Card>
  );
};
