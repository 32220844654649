import { getDefaultUnitConvertedPropertyValue } from '../../../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import smokeExtractionDuctsInfra, {
  SmokeExtractionDuctsInfraSection
} from '../../../../../sections/circulationSections/smokeExtractionDuctsInfra';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const createBasementLevelSmokeExtractionDuctsInfra = (
  caseGranulometry: CaseGranulometry,
  group: string
): SmokeExtractionDuctsInfraSection => {
  const length = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'smokeExtractionDuctsDepth'
  ) as number;
  const width = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'smokeExtractionDuctsWidth'
  ) as number;
  const surface = length * width;
  return smokeExtractionDuctsInfra({
    surface,
    length,
    width,
    group
  });
};
