import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from '../../../../../components/ui/SpecialLabel';
import { FormGroup } from '../../../../../components/ui/FormGroup';
import { Card } from '../../../../ui/Card';
import { Button } from '../../../../ui/Button';
import { goToProjectFloorSpace } from '../../../../../store/actions/navigations.actions';
import { selectProjectId } from '../../../../../store/selectors/project';
import { getProjectDetailsFloorSpaceTriggerId } from '../../../../../routes/toolboxPanels/triggers';
import { selectIsProjectDetailsFloorSpaceOpened } from '../../../../../store/selectors/navigation/windows/isProjectDetailsFloorSpaceOpened.selector';

export const ProjectFloorSpaceCard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isProjectDetailsGingerOpened = useSelector(selectIsProjectDetailsFloorSpaceOpened);
  const projectId = useSelector(selectProjectId);

  return (
    <Card>
      <SpecialLabel label={t('projectFloorSpaceCard.label')} />
      <FormGroup flexEnd>
        <div>
          <Button
            id={getProjectDetailsFloorSpaceTriggerId()}
            size="small"
            content={t('projectFloorSpaceCard.buttonContent')}
            selected={isProjectDetailsGingerOpened}
            appearance="outline"
            handleClick={() => {
              if (projectId) {
                dispatch(goToProjectFloorSpace(projectId));
              }
            }}
          />
        </div>
      </FormGroup>
    </Card>
  );
};
