import {
  LevelGranulometry,
  LevelGranulometryWithBearingRelativeSections
} from '../../../LevelGranulometry';
import { getBearingTechnicalSheathsSurface } from '../../../../sections/queries/bearingTechnicalSheaths/getBearingTechnicalSheathsSurface';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { getCaseElevatorShaftSectionsCount } from '../../../../cases/queries/sections/counts/getCaseElevatorShaftSectionsCount';
import { getCaseDataPropertyValue } from '../../../../../specification/cases/queries/get/properties/getCaseDataPropertyValue';
import { getTopLevelSectionsWithTechnicalSheathsCount } from '../counts/getTopLeveSectionWithTechnicalSheathsCount';
import { Surface } from '../../../../../specification/Surface';

export const getTopLevelMinimumBearingSurface = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry | LevelGranulometryWithBearingRelativeSections
): Surface => {
  const elevatorsCount = getCaseElevatorShaftSectionsCount(caseGranulometry);
  const bearingWidth = getCaseDataPropertyValue(
    caseGranulometry.initialSpecifications,
    'bearingWidth'
  ) as number;
  const floorSurface =
    bearingWidth * (elevatorsCount === 0 ? 1.3 : elevatorsCount === 1 ? 1.6 : 1.6 + 1.3);

  const sectionsWithTechnicalSheathsCount = getTopLevelSectionsWithTechnicalSheathsCount(
    levelGranulometry as LevelGranulometry
  );
  const bearingTechnicalSheathsSurface = getBearingTechnicalSheathsSurface(
    caseGranulometry,
    levelGranulometry
  ).value;

  const minimumBearingSurface =
    floorSurface * (1 + Math.max(0, sectionsWithTechnicalSheathsCount - 3) / 2) +
    bearingTechnicalSheathsSurface;
  return new Surface(minimumBearingSurface);
};
