import { CaseGranulometry } from '../../../granulometry/cases/CaseGranulometry';
import { getCaseUpperLevelsGrossFloorSurfaceDistribution } from '../../../granulometry/cases/queries/getCaseUpperLevelsGrossFloorSurfaceDistribution';
import { updateCaseGranulometryTopLevelsCount } from './refillCaseGranulometryRelativelyToFloorSpaceFeature/updateCaseGranulometryTopLevelsCount';
import { updateCaseGranulometryTopLevelsData } from './refillCaseGranulometryRelativelyToFloorSpaceFeature/updateCaseGranulometryTopLevelsData';
import { emptyCaseGranulometryLevels } from './refillCaseGranulometryRelativelyToFloorSpaceFeature/emptyCaseGranulometryLevels';
import { fillCaseLevels } from '../fillCaseLevels';
import { addGfsEffRelativeTopLevelsRbs } from './refillCaseGranulometryRelativelyToFloorSpaceFeature/addGfsEffRelativeTopLevelsRbs';
import { adjustGfsEffRelativeHighestLevelRbs } from './refillCaseGranulometryRelativelyToFloorSpaceFeature/adjustGfsEffRelativeHighestLevelRbs';
import { CaseProjection } from '../../../projection/cases/CaseProjection';
import { getCaseProjectionSpecifiedTopLevelsCount } from '../../../projection/cases/queries/get/getCaseProjectionSpecifiedTopLevelsCount';

export const refillCaseGranulometryRelativelyToFloorSpaceFeature = (
  caseProjection: CaseProjection,
  caseGranulometry: CaseGranulometry
): CaseGranulometry => {
  let resultingTopLevelCount = getCaseProjectionSpecifiedTopLevelsCount(caseProjection);
  // If there is no forced top level count :
  if (resultingTopLevelCount === undefined) {
    // Determine the top level count from the gross floor surface distribution
    resultingTopLevelCount =
      getCaseUpperLevelsGrossFloorSurfaceDistribution(caseGranulometry).filter(
        (l) => l.gfsEff !== 0
      ).length + 1;
    // Update the case granulometry considering the resulting top level count :
    // Update projected top levels count
    caseGranulometry = updateCaseGranulometryTopLevelsCount(
      caseGranulometry,
      resultingTopLevelCount
    );
    // Update topLevelsData
    caseGranulometry = updateCaseGranulometryTopLevelsData(
      caseProjection,
      caseGranulometry,
      resultingTopLevelCount
    );
    // Empty levels
    caseGranulometry = emptyCaseGranulometryLevels(caseGranulometry);
    // Refill the case
    caseGranulometry = fillCaseLevels(caseGranulometry);
  }
  // Record level RBS from the previous pass because we don't have access to caseGranulometry before filling the case
  caseGranulometry = addGfsEffRelativeTopLevelsRbs(
    caseGranulometry,
    resultingTopLevelCount as number
  );
  // Add savedTopLevelsGfsEffFirstDistribution (see: mustHaveFacadeLinearsRelativeToDefaultWidthAndLength)
  caseGranulometry.savedTopLevelsGfsEffFirstDistribution =
    getCaseUpperLevelsGrossFloorSurfaceDistribution(caseGranulometry);
  // Adjust the highest level RBS (cause of the difference between RBS without and with drawn floor space)
  caseGranulometry = adjustGfsEffRelativeHighestLevelRbs(caseGranulometry);
  // Empty levels
  caseGranulometry = emptyCaseGranulometryLevels(caseGranulometry);
  // Refill the case
  caseGranulometry = fillCaseLevels(caseGranulometry);

  return caseGranulometry;
};
