import carBoxInfra, {
  CarBoxInfraSection
} from '../../../../../sections/parkingSections/carBoxInfra';
import { getDefaultUnitConvertedPropertyValue } from '../../../../../../legacy/methodsForGranulo/getDefaultUnitConvertedPropertyValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const createBasementLevelCarBoxInfra = (
  caseGranulometry: CaseGranulometry
): CarBoxInfraSection => {
  const depth = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'carBoxDepth'
  ) as number;
  const width = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'carBoxWidth'
  ) as number;
  const surface = depth * width;
  const outsideWallsConcreteRatio = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'carBoxOutsideWallsConcreteRatio'
  ) as number;
  const beamHeight = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'carBoxBeamHeight'
  ) as number;
  const beamWidth = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'carBoxBeamWidth'
  ) as number;
  const pillarThickness = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'carBoxPillarThickness'
  ) as number;
  const pillarWidth = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'carBoxPillarWidth'
  ) as number;
  const partitionThickness = getDefaultUnitConvertedPropertyValue(
    caseGranulometry.initialSpecifications,
    'carBoxPartitionThickness'
  ) as number;
  return carBoxInfra({
    surface,
    depth,
    width,
    outsideWallsConcreteRatio,
    beamHeight,
    beamWidth,
    pillarThickness,
    pillarWidth,
    partitionThickness
  });
};
