import { createSelector, Selector } from 'reselect';
import { selectPath } from './getPath.selector';
import { buildingRoute, buildingsRoute } from '../../../routes/toolboxPanels/buildings';
import {
  caseGranulometryEditFloorSpaceRoute,
  caseGranulometryRoute,
  caseRoute,
  casesRoute
} from '../../../routes/toolboxPanels/cases';
import {
  deliverablesArchivesRoute,
  deliverablesExportReportRoute,
  deliverablesExportRoute,
  deliverablesRoute
} from '../../../routes/toolboxPanels/deliverables';
import {
  projectDetailsEditFloorSpaceRoute,
  projectDetailsRoute
} from '../../../routes/toolboxPanels/projectDetails';
import {
  sectionsEditRoute,
  sectionsIndividualRoute,
  sectionsRoute
} from '../../../routes/toolboxPanels/sections';
import { smartRequestsRoute } from '../../../routes/toolboxPanels/smartRequest';
import { State } from '../../reducers';
import { matchesProject } from '../../../routes/matches/matchesProject';
import { appMenuEditUserRoute, appMenuRoute } from '../../../routes/appMenu';

export interface RouteDescriptor {
  isProject: boolean;
  isPanel: boolean;
  isProjectDetails: boolean;
  isProjectDetailsFloorSpace: boolean;
  isBuildings: boolean;
  isBuilding: boolean;
  isCases: boolean;
  isCase: boolean;
  isCaseGranulometry: boolean;
  isSections: boolean;
  isSectionsEdit: boolean;
  isSectionsIndividual: boolean;
  isSmartRequests: boolean;
  isDeliverables: boolean;
  isDeliverablesExport: boolean;
  isDeliverablesExportReport: boolean;
  isDeliverablesArchives: boolean;
  isAppMenu: boolean;
  isAppMenuEditUser: boolean;
  isCaseFloorSpace: boolean;
}

export const selectRoute: Selector<State, RouteDescriptor> = createSelector(
  [selectPath],
  (path) => {
    const isProjectDetails = projectDetailsRoute.matchesPath(path).matches;
    const isBuildings = buildingsRoute.matchesPath(path).matches;
    const isCases = casesRoute.matchesPath(path).matches;
    const isSections = sectionsRoute.matchesPath(path).matches;
    const isSmartRequests = smartRequestsRoute.matchesPath(path).matches;
    const isDeliverables = deliverablesRoute.matchesPath(path).matches;

    const isPanel =
      isProjectDetails || isBuildings || isCases || isSections || isSmartRequests || isDeliverables;

    return {
      isProject: matchesProject(path).matches,
      isPanel,
      isProjectDetails,
      isProjectDetailsFloorSpace: projectDetailsEditFloorSpaceRoute.matchesPath(path).matches,
      isBuildings,
      isBuilding: buildingRoute.matchesPath(path).matches,
      isCases,
      isCase: caseRoute.matchesPath(path).matches,
      isCaseGranulometry: caseGranulometryRoute.matchesPath(path).matches,
      isCaseFloorSpace: caseGranulometryEditFloorSpaceRoute.matchesPath(path).matches,
      isSections,
      isSectionsEdit: sectionsEditRoute.matchesPath(path).matches,
      isSectionsIndividual: sectionsIndividualRoute.matchesPath(path).matches,
      isSmartRequests,
      isDeliverables,
      isDeliverablesExport: deliverablesExportRoute.matchesPath(path).matches,
      isDeliverablesExportReport: deliverablesExportReportRoute.matchesPath(path).matches,
      isDeliverablesArchives: deliverablesArchivesRoute.matchesPath(path).matches,
      isAppMenu: appMenuRoute.matchesPath(path).matches,
      isAppMenuEditUser: appMenuEditUserRoute.matchesPath(path).matches
    };
  }
);
